//Import libraries
// import * as React from 'react';
import { useEffect, useState } from 'react';
import {useMediaQuery} from '@mui/material';
// import Departure from '../../flights/techLog/departure/DepartureDesktop.js';
// import DepartureMobile from './Departure.js'

// Import CSS, images, icons & static data
import { mobileThreshold} from "./EnvironmentData.js"

//Import fonts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';



// ******************************* MAIN FUNCTION ************************************************
export default function Media() {

  var media = useMediaQuery(`(min-width:${mobileThreshold}px)`)

 
  
  const [mediaType, setMediaType] = useState("")
    // console.log("In Media, mediaType -> ", mediaType);

  useEffect (() => {
  
    if (media) {
        setMediaType("")
      } else {
        setMediaType("-mobile")
      } 

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [media]);


  return (
       mediaType
      );
}