import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '../components/Button';
import Typography from '../components/Typography';
import mobileIcon from '../../../../images/mobileIcon.svg'
import chatIcon from '../../../../images/chatIcon.svg'
import instructorIcon from '../../../../images/instructorIcon.svg'
import { fontWeight } from '@mui/system';
import { baseBlue } from '../../../../../utilities/environment/EnvironmentData';
import azTechTheme from '../../../../../utilities/environment/ColourTheme';

const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

const number = {
  fontSize: 24,
  fontFamily: 'default',
  color: baseBlue,
  fontWeight: 'medium',
};

const image = {
  height: 55,
  my: 4,
};

const backgroundColour = azTechTheme.palette.blanchedAlmond.rgb;

function ProductHowItWorks() {
  return (
    <Box
      component="section"
      sx={{ display: 'flex', bgcolor: backgroundColour, overflow: 'hidden' }}
    >
      <Container
        sx={{
          mt: 10,
          mb: 15,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          component="img"
          // src="/static/themes/onepirate/productCurvyLines.png"
          alt="curvy lines"
          sx={{
            pointerEvents: 'none',
            position: 'absolute',
            top: -180,
            opacity: 0.7,
          }}
        />
        <Typography variant="h6" marked="center" component="h2" sx={{ mb: 14, fontWeight: "bolder"}}>

          HOW TO GET STARTED...
        </Typography>
        <div>
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={number}>1.</Box>
                <Box
                  component="img"
                  src = {mobileIcon}
                  // src="/static/themes/onepirate/productHowItWorks1.svg"
                  alt="valigia"
                  sx={image}
                />
                <Typography variant="h5" align="center">
                  Call or mail us to arrange an initial chat...
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={number}>2.</Box>
                <Box
                  component="img"
                  src = {chatIcon}
                  // src="/static/themes/onepirate/productHowItWorks2.svg"
                  alt="persone a una scrivania"
                  sx={image}
                />
                <Typography variant="h5" align="center">
                  During the chat you'll get to know the school and which course might suit you...
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={number}>3.</Box>
                <Box
                  component="img"
                  src = {instructorIcon}
                  // src="/static/themes/onepirate/productHowItWorks3.svg"
                  alt="pilota"
                  sx={image}
                />
                <Typography variant="h5" align="center">
                  {'Once you\'ve made a choice, '}
                  {'we\'ll pair you with an instructor for your first lesson! '}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
        {/* <Button
          color="primary"
          size="large"
          variant="contained"
          component="a"
          href="/premium-themes/onepirate/sign-up/"
          sx={{ mt: 8 }}
        >
          Get started
        </Button> */}
      </Container>
    </Box>
  );
}

export default ProductHowItWorks;
