// Import libraries
// import { isBefore, parseISO} from "date-fns";
import dayjs from "dayjs";
import { emptyAircraftSubmission } from "../../aircraft/AircraftData";
import isAlphanumeric from "../numbersAndStrings/IsAlphaNumeric";



// Import CSS, images, icons and static


// ********** ERROR & WARNING MESSAGES **********
const registration = {
    PLANE: "PLEASE PROVIDE A VALID REGISTRATION",
    duplicate: "CANNOT CREATE A DUPLICATE REGISTRATION",
    invalid: "REGISTRATION MUST BE 5 ALPHANUMERIC CHARACTERS ONLY"
}
const missingMandatoryField = "ONE OR MORE REQUIRED FIELDS ARE MISSING";
const nextService = {
    dateInPast: "NEXT SERVICE DATE IS IN THE PAST",
    hours: "NEXT SERVICE HOURS ARE LESS THAN CURRENT HOURS"
}

const fuelErrorMessages ={
    overCapacity: "FUEL STATE IS GREATER THAN TANK CAPACITY",
}

export const warningMessages = {
    dateInPast: "DATE IS IN THE PAST...    CONTINUE?",
    dateInFuture: "DATE IS IN THE FUTURE...    CONTINUE?"
}

export const successMessages = {
    updated: "AIRCRAFT SUCCESSFULLY UPDATED",
    created: "AIRCRAFT SUCCESSFULLY CREATED",
    clear: "PAGE SUCCESSFULLY CLEARED"
}





export default function AircraftValidations(aircraftSubmission, selectedAircraft, aircraftList) {
    let today = dayjs(new Date());
    let result = {}
    let validationResultList = [];
        // console.log("In AircraftValidations, aircraftSubmission->",aircraftSubmission,"selectedAircraft->",selectedAircraft ,"aircraftList->",aircraftList)
        // console.log("In AircraftValidations, selectedAircraft->",selectedAircraft)
        // console.log("In AircraftValidations, aircraftList->",aircraftList)
        // console.log("In AircraftValidations, emptyAircraftSubmission->",emptyAircraftSubmission)
    // Registration
    // 1. Not equal to PLANE
    if (aircraftSubmission.registration === "PLANE") {
        result = {
            status: "error",
            message: registration.PLANE,
            field1: "registration",
            field2: "",
            field3: "",
            field4: ""
        }
        validationResultList.push(result);
    }
    // 2. Not a duplicate of an existing plane
    aircraftList.forEach((plane) => {
        if (aircraftSubmission.registration === plane._registration) {
            result = {
                status: "error",
                message: registration.duplicate,
                field1: "registration",
                field2: "",
                field3: "",
                field4: ""
            }
            validationResultList.push(result);
        }
    })

    // 3. Greater than 5 characters or contains special characters
    if (
        (!isAlphanumeric(aircraftSubmission.registration)) || 
        (aircraftSubmission.registration.value.length !== 5)) {
        result = {
            status: "error",
            message: registration.invalid,
            field1: "registration",
            field2: "",
            field3: "",
            field4: ""
        }
        validationResultList.push(result);
    }
    // 4. Check Maintenance Details
    // A. Date is in the past - warning
    
        // console.log("In AircraftValidations, dayjs(aircraftSubmission.dateOfNextService.value)->", dayjs(aircraftSubmission.dateOfNextService.value)    );
        // console.log("In AircraftValidations, today->", today);
        // console.log("In AircraftValidations, today.isBefore(aircraftSubmission.dateOfNextService.value, day)->", today.isBefore(aircraftSubmission.dateOfNextService.value, "day"))
        // console.log("In AircraftValidations, aircraftSubmission.dateOfNextService.value.isBefore(today, day)->", dayjs(aircraftSubmission.dateOfNextService.value).isBefore(today, "day"));
    if (dayjs(aircraftSubmission.dateOfNextService.value).isBefore(today, "day")) {
        result = {
            status: "warning",
            message: nextService.dateInPast,
            field1: "dateOfNextService",
            field2: "",
            field3: "",
            field4: ""
        }
        validationResultList.push(result);
    }
    // B. Hours at next check are less than current hours - warning
        // console.log("In aircraft validations, nextServiceHours->", aircraftSubmission.nextServiceHours.value, "& currentHours->", aircraftSubmission.currentHours.value)
    if (Number(aircraftSubmission.nextServiceHours.value) < Number(aircraftSubmission.currentHours.value)) {
        result = {
            status: "warning",
            message: nextService.hours,
            field1: "nextServiceHours",
            field2: "",
            field3: "",
            field4: ""
        }
        validationResultList.push(result);
    }
    // Make And Model
    // 1. Number of engines is between 0 & 5 = DONE
    // 2. Select List for Complexity - DONE
    // 3. Select List for Type - DONE
    // Fuel
    // 1. Select List for Fuel Type - DONE
    // 2. Minimum for fuel tank capacity of 0 - DONE
    // 3. Minimum for current fuel of 0 and - DONE
    // 4. Maximum of fuel tank capacity - DONE
        // console.log("In AircraftValidations, aircraftSubmission.fuelTank1State->", Number(aircraftSubmission.fuelTank1State.value), "aircraftSubmission._fuelTank1Capacity->", aircraftSubmission.fuelTank1Capacity.value);
    if (aircraftSubmission.planeType.value !== "SIM") {
        if (Number(aircraftSubmission.fuelTank1State.value) > aircraftSubmission.fuelTank1Capacity.value) {
            result = {
                status: "error",
                message: fuelErrorMessages.overCapacity,
                field1: "fuelTank1State",
                field2: "",
                field3: "",
                field4: ""
            }
            validationResultList.push(result);
        }


        if (aircraftSubmission.fuelTank2State.value > aircraftSubmission.fuelTank2Capacity.value) {
            result = {
                status: "error",
                message: fuelErrorMessages.overCapacity,
                field1: "fuelTank2State",
                field2: "",
                field3: "",
                field4: ""
            }
            validationResultList.push(result);
        }
    }


    // Check all mandatory fields are filled in (except Oil Levels)
    Object.keys(aircraftSubmission).forEach((field) => {
            // console.log("In aircraftValidations, mandatory fields check, field->", field);
        if (emptyAircraftSubmission[field].required 
            && field.indexOf("oilLevels") === -1 
            && (aircraftSubmission[field].value === "" 
            || aircraftSubmission[field].value === undefined 
            || aircraftSubmission[field].value === null)) {
            result = {
                status: "error",
                message: missingMandatoryField,
                field1: field,
                field2: "",
                field3: "",
                field4: ""
            }
            validationResultList.push(result);
        }
    });

    // Check all oil fields are filled in
    Object.keys(aircraftSubmission).forEach((field) => {
        if (field.indexOf("oilLevels") !== -1 ) {
            aircraftSubmission.oilLevels.forEach((level) => {
                    // console.log("In aircraftValidations, mandatory fields check, field->", field);
                if (level.required 
                    && (level.value === "" 
                    || level.value === undefined 
                    || level.value === null)) {
                    result = {
                        status: "error",
                        message: missingMandatoryField,
                        field1: field+aircraftSubmission.oilLevels.indexOf(level),
                        field2: "",
                        field3: "",
                        field4: ""
                    } 
                    validationResultList.push(result);  
                        // console.log("In AircraftValidations, result.field1->", result.field1)
                }
            })
        }
        
    })
        
    return validationResultList;
}




