// Import libraries
import { useState, useEffect } from "react";
import { useMediaQuery } from '@mui/material';

//Import CSS & images
import "./Banner.css"
import { mobileThreshold } from "../../../utilities/environment/EnvironmentData";


// ******************************* MAIN FUNCTION ************************************************
export default function BannerTitle(props) {

    const desktop = "titleDesktop";
    const mobile = "titleMobile";

    var media = useMediaQuery(`(min-width:${mobileThreshold}px)`)
    const [bannerTitleClassName, setBannerTitleClassName] = useState(desktop);
     

    useEffect (() => {
        if (media) {
            setBannerTitleClassName(desktop)
          } else {
            setBannerTitleClassName(mobile);
          } 
            // console.log("In BannerTitle, props.title->",props.title,"media->", media);
            // console.log("In BannerTitle, bannerTitleClassName->",bannerTitleClassName);
        }, [media]);
    
        // console.log("In Banner, media -> ", media, "bannerTitleClassName->", bannerTitleClassName);
    
      return (
        <div className = {bannerTitleClassName}>{props.title}</div>
          );

}