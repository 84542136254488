// *************** IMPORT LIBRARIES *****************

import { emptySubmission} from "../flights/flightData/FlightData.js";
import { emptySubmissionSim } from "../flights/flightData/FlightData.js";
import { background } from "../flights/flightData/FlightData.js";
import dayjs from "dayjs";
import { successMessages } from "../utilities/validation/AircraftValidations.js";
import SubmissionFieldReset from "./SubmissionFieldReset.js";
import _ from "lodash";


// ************** TO DO *****************


function TechLogFormReducer(submission, action) {

   let field = action.name
   let submissionTemplate = _.cloneDeep(emptySubmission);
   if (submission.data.planeType.value === "SIM") {
    submissionTemplate = _.cloneDeep(emptySubmissionSim);
   }
        // console.log("In Reducer, submission -> ", submission, "& action -> ", action);
    switch (action.type) { 

    case 'clear':
    case 'clearAuto':
        // Update the most fields except MostSevereIssue and oilStatusRight
            // console.log("In TechLogReducer, clear, submission->", submission);
        Object.keys(emptySubmission.data).forEach((field) => {
                // console.log("In TechLogReducer, submission->",submission,"submission.data[field]->", submission.data[field], "field->", field);
            if (field !== "mostSevereIssue" && field !== "oilStatusRight" ) {
            submission.data[field].value = "";
            submission.data[field].status = "";
            // submission.data[field].background = emptySubmission.data[field].background;
            submission.data[field].background = background.black;
            submission.data[field].variant = "filled";
            submission.data[field].reset = submissionTemplate.data[field].reset;
            submission.data[field].requiredOpen = submissionTemplate.data[field].requiredOpen;
            submission.data[field].requiredSubmit = submissionTemplate.data[field].requiredSubmit;
                // console.log("In Reducer, Clear, field->",field," submission.data[field]->", submission.data[field], "emptySubmission[field]->", emptySubmission[field]);
            }
        });
        submission.data.dateOfFlight.background = background.normal;
        submission.data.mostSevereIssue = {status: "", message: ""};
        if (action.numberOfEngines === 2) {
            submission.data.oilStatusRight.value = "";
            submission.data.oilStatusRight.status = "";
            submission.data.oilStatusRight.background = background.grey;
            submission.data.oilStatusRight.variant = "filled";
            submission.data.oilStatusRight.reset = submissionTemplate.data.oilStatusRight.reset;
            submission.data.oilStatusRight.requiredOpen = submissionTemplate.data.oilStatusRight.requiredOpen;
            submission.data.oilStatusRight.requiredSubmit = submissionTemplate.data.oilStatusRight.requiredSubmit;
        } else if (action.numberOfEngines === 1) {
            submission.data.oilStatusRight.value = "";
            submission.data.oilStatusRight.status = "";
            submission.data.oilStatusRight.background = background.normal;
            submission.data.oilStatusRight.variant = "filled";
            submission.data.oilStatusRight.reset = submissionTemplate.data.oilStatusRight.reset;
            submission.data.oilStatusRight.requiredOpen = submissionTemplate.data.oilStatusRight.requiredOpen;
            submission.data.oilStatusRight.requiredSubmit = submissionTemplate.data.oilStatusRight.requiredSubmit;
        }

        if (action.type === 'clear') {
                submission.mostSevereIssue = {
                    status: "info",
                    message: successMessages.clear
                }
            }
        return {
            ...submission
            };

    case 'edit':
            // console.log("In Reducer, edit, action->", action);
            // console.log("In TechLogFormReducer, field->", field, "submission->", submission)
        if (action === null || action === undefined) {  
            return;
        } else {
                // console.log("In TechLog Reducer, submission->", submission)
            if (action.fieldType === "checkbox") {
                    // console.log("In TechLog Reducer, action->", action);
                if (action.event.checked) {
                    action.value = true;
                        // console.log("In TechLog Reducer, action.status", action.value);
                } else {
                    action.value = false;
                        // console.log("In TechLog Reducer, action.status", action.value);
                }
            } else {
                    
                submission.data[field] = {
                    value: action.value,
                    status: submission.data[field].status,
                    background: submission.data[field].background,
                    variant: submission.data[field].variant,
                    reset: submission.data[field].reset,
                    requiredOpen: submission.data[field].requiredOpen,
                    requiredSubmit: submission.data[field].requiredSubmit
                }
            }
                // console.log("In TechLogFormReducer, submission->", submission)
            return {
                ...submission
            };
        };
    case "editOilTwin":
            // console.log("In Reducer, editOilTwin, action->", action);
        if (submission.data.oilStatusRight.status === "error") {
                submission.data.oilStatusRight = {
                    value: submission.data.oilStatusRight.value,    
                    status: submission.data.oilStatusRight.status,
                    background: background.error,
                    variant: "filled",
                    reset: submission.data.oilStatusRight.reset,
                    requiredOpen: false,
                    requiredSubmit: true
                }
        } else {
                submission.data.oilStatusRight = {
                    value: submission.data.oilStatusRight.value,    
                    status: submission.data.oilStatusRight.status,
                    background: background.grey,
                    variant: "filled",
                    reset: submission.data.oilStatusRight.reset,
                    requiredOpen: false,
                    requiredSubmit: true
                }
            }
        return {...submission}
        
    case "editOilSingle":
            // console.log("In Reducer, action -> ", action);
            submission.data.oilStatusLeft = {
                value: submission.data.oilStatusLeft.value,    
                status: submission.data.oilStatusLeft.status,
                background: background.grey,
                variant: submission.data.oilStatusLeft.variant,
                reset: submission.data.oilStatusLeft.reset,
                requiredOpen: submission.data.oilStatusLeft.requiredOpen,
                requiredSubmit: submissionTemplate.data.oilStatusLeft.requiredSubmit
            }
            submission.data.oilStatusRight = {
                value: submission.data.oilStatusRight.value,    
                status: submission.data.oilStatusRight.status,
                background: background.normal,
                variant: submission.data.oilStatusRight.variant,
                reset: submission.data.oilStatusRight.reset,
                requiredOpen: false,
                requiredSubmit: false
            }
        return {...submission}
   
    case "openFlight":
        Object.keys(action.flight).forEach((field) => {
                // console.log("In Reducer, OpenFlight, submission->", submission);
                // console.log("In Reducer, OpenFlight, field->", field, "action.flight->", action.flight);
            if (field !== "mostSevereIssue") {
                // submission[field].value = emptySubmission[field].value;
                submission.data[field].value = action.flight[field].value;
            }
        });   
            // console.log("In Reducer, OpenFlight, submission->", submission);
        return {
            ...submission
        }

    case "open":
    case "submit":
            // console.log("In Reducer, Open/ Submit, action -> ", action);
        if (action.type === "open") {
            submission.data.status.value = "OPEN";
            submission.data.dateOpened.value = dayjs(new Date()).format("YYYY-MM-DD, HH:mm");
                // console.log("In Reducer, submission -> ", submission);
        } else {
                // console.log("In Reducer, status to CLOSED, submission-> ", submission);
            submission.data.status.value = "CLOSED";
            // submission.data.planeType.value = submission.selectedAircraft._planeType;
                // console.log("In Reducer, status to CLOSED -> ", submission.data.status.value);
        }
            // console.log("In Reducer, open/ submit, submission->", submission)
        // Reset formatting of each field
        SubmissionFieldReset(submission);

        // Update the status of each field with an issue   
        action.validationResultList.forEach((result) => {
            if (result !== {}) {
                // Create a standard field background and variant
                let backgroundTmp = background.normal;
                let variantTmp = "filled";

                // Set the background and variant for the fields related to the current issue
                if (result.status === "error" || result.status === "warning") {
                    variantTmp = "outlined";
                    if (result.status === "error") {
                        backgroundTmp = background.error;
                    } else {
                        backgroundTmp = background.warning;
                    }

                    // Update the status of each field within an issue
                    let submissionField = "";
                    // Field 1
                    submissionField = submission.data[result.field1];
                        // console.log("In Reducer, setting field1, result.field1 -> ", result.field1);
                    if (result.field1 !== "") {
                        submission.data[result.field1] = {
                            value: submissionField.value,
                            status: result.status,
                            background: backgroundTmp,
                            variant: variantTmp, 
                            reset: submissionField.reset,
                            requiredOpen: submissionField.requiredOpen,
                            requiredSubmit: submissionField.requiredSubmit,
                        }
                    }
                    // Fields 2 - 4
                        // console.log("In Reducer, Just before the For Loop, result", result);
                   
                    for (let i = 2; i < 5; i++) {
                        field = `field${i}`
                        let resultField = result[field];
                        if (result[field] !== "") {
                                // console.log("In Reducer, For Loop, backgroundTmp, variantTmp ->", backgroundTmp, variantTmp)
                                // console.log("In Reducer, For Loop, field, result, result[field], result.status -> ", field, result, result[field], result.status);
                            submission.data[resultField] = {
                                value: submission.data[resultField].value,
                                status: result.status,
                                background: backgroundTmp,
                                variant: variantTmp,
                                reset: submission.data[resultField].reset,
                                requiredOpen: submission.data[resultField].requiredOpen,
                                requiredSubmit: submission.data[resultField].requiredSubmit,
                            }
                                // console.log("In Reducer, For Loop, submission", submission)
                        }
                    }
                }
            }
        });    
        return {...submission};

    case "append":
        submission.data[action.data.appendixName] = action.data.appendix;
            // console.log("In Reducer, append, action->", action);
            // console.log("In Reducer, append, submission->", submission);
        return {...submission};
    default: console.log("Unknown Tech. Log Action");

    
}};

export default TechLogFormReducer;