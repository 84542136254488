// Import libraries
import { differenceInDays, isBefore } from 'date-fns'
// import dayjs from 'dayjs';


// Import CSS, images & icons
    


    
export default function DaysToNextService(selectedAircraft) {
    var today = new Date();
    let dateOfNextService = new Date(selectedAircraft._dateOfNextService);  
        // console.log("In DaysToNextService, selectedAircraft._registration->",selectedAircraft._registration, " selectedAircraft._dateOfNextService->", selectedAircraft._dateOfNextService);
        // console.log("In DaysToNextService, dateOfNextService->", dateOfNextService,"today->", today);
    
    // Calculate maintenance values
    var daysToNextService = 0
    if (isBefore(dateOfNextService, today)) {
        daysToNextService = differenceInDays(today, dateOfNextService) * -1;
        } else {
            daysToNextService = (differenceInDays(dateOfNextService, today));
        }   
            // console.log("In DaysToNextService, daysToNextService->", daysToNextService)
    return daysToNextService;
}