// Import libraries
// import axios from "axios";
import { API_URL } from "../../../config/config";
import axiosInstance from "../security/AxiosConfig";
import { databaseConnectionMessages } from "./DatabaseConnectionMessages";

// Import CSS, images, icons & static data

// Import fonts

//*********************************** MAIN FUNCTION ************************************** */
export async function GetEnrollments(dimension, id) {
  let responseObject = {};
  let response = {};
      try {
        switch(dimension) {
          case "student":
            response = await axiosInstance.get(`${API_URL}/enrollments/findByStudent`, {params: { studentId: id }});
            break;
          case "instructor":
            // dimension = instructor or student
            response = await axiosInstance.get(`${API_URL}/enrollments/findByInstructor`, {params: { instructorId: id }});
          break;
          case "admin":
            // dimension = admin
            response = await axiosInstance.get(`${API_URL}/enrollments/getAllOpen`);
          break;
          default:
            response.data = "Error fetching enrollments - UNKNOWN ROLE"
        }
            responseObject = 
                {
                    data: response.data,
                    databaseConnection: true
                }
            // return responseObject;
      } catch (error) {
        console.error('Error fetching enrollments', error);
        responseObject =            
            {
                data: databaseConnectionMessages.error,
                databaseConnection: false
            }
            
        // return responseObject;

      }
  return responseObject
}