// Import libraries



// Import CSS, images, icons & static data



//*********************** MAIN FUNCTION ************************** */
export default function isAlphanumeric(string) {

var alphaNumeric = /^[0-9a-z]+$/i;
var result = false;

if (string.value.match(alphaNumeric)) {
    result = true;
}

return result;

}