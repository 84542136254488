// Import libraries
// import axios from "axios";
import { API_URL } from "../../../config/config";
import axiosInstance from "../security/AxiosConfig";
import _ from "lodash";
import { databaseConnectionMessages } from "./DatabaseConnectionMessages";


// Import CSS, images, icons & static data


// Import fonts



//*********************************** MAIN FUNCTION ************************************** */
export async function GetAuthoritiesList(UpdateAuthoritiesList) {
  let responseObject = {};
  try {
    // Modify to retrieve flights by selectedAircraft.registration
    // if (registration !== "SELECT") {
      // console.log("In FLightLog, GetFlightList, API_URL ->", `${API_URL}/flights/closedFlights?registration=${registration}`)
      // const response = await axiosInstance.get(`${API_URL}/flights/closedFlights?registration=${registration}`);
      const response = await axiosInstance.get(`${API_URL}/authorities`);
      // responseObject.data.push("NEW AUTHORITY");
      responseObject.data = _.cloneDeep(response.data);
      responseObject.databaseConnection = true;
          // console.log("In GetAuthorities, responseObject.data success -> ", responseObject.data);
      return responseObject;
    // }
  } catch (error) {
    console.error('Error fetching authorities', error);
    // responseObject.data.push("NEW AUTHORITY");
    responseObject.data = [];
    responseObject.databaseConnection = false;
      // console.log("In GetAuthorities, responseObject.data success -> ", responseObject.data);
   
    return responseObject;
  }

}