// Import libraries
import React, {useState, useEffect} from "react";
import { TextField, Box } from "@mui/material"

// Import CSS, images, icons & static data
import petrolPump from "../images/petrolPump.png";
import shortArrow from "../images/shortArrow.png";
import { gallonsToLitres } from "../../../aircraft/AircraftData";
import "./Arrival.css";


export default function ArrivalTableFuel(props) {
    

    // const galsToLitres = 3.8;

    let excludedForAircraftType = ["SIM"];
    if (excludedForAircraftType.indexOf(props.submission.data.planeType.value) === -1) {

        return (
            <Box className = "arrival-table-fuel">
                <table>
                    <tbody>
                        <tr>
                            <td className = "label">Fuel</td>
                            <td ><img src = {petrolPump} alt = "Petrol Pump" className = "petrolImg"></img></td>
                            <td className = "blank" colSpan = "1"></td>
                        </tr>
                        <tr>
                            <td className = "blank2" colSpan = "1"></td>
                            <td className = "LR">Left</td>
                            <td className = "LR">Right</td>
                        </tr>
                        <tr>
                            <td className = "label" colSpan = "1" >Uplift <br></br><span className = "footnote">(L)</span></td>
                            <td className = "inputNum" colSpan = "1" STYLE = {props.submission.data.arrivalFuelUpliftLeft.background}>
                                <TextField 
                                    value = {props.submission.data.arrivalFuelUpliftLeft.value} 
                                    InputProps = {{ inputProps: { min: 0, max: props.selectedAircraft._fuelTank1Capacity * gallonsToLitres }}} 
                                    type = "number" 
                                    label = {props.submission.data.arrivalFuelUpliftLeft.required} 
                                    error = {props.submission.data.arrivalFuelUpliftLeft.status === "error"} 
                                    variant = {props.submission.data.arrivalFuelUpliftLeft.variant} 
                                    fullWidth 
                                    name ="arrivalFuelUpliftLeft" 
                                    id = "arrivalFuelUpliftLeft" 
                                    onChange = {e => props.TechLogHandleChange(e.target)}>
                                </TextField></td>
                            <td className = "inputNum" colSpan = "1" STYLE = {props.submission.data.arrivalFuelUpliftRight.background}>
                                <TextField 
                                    value = {props.submission.data.arrivalFuelUpliftRight.value} 
                                    InputProps={{ inputProps: { min: 0, max: props.selectedAircraft._fuelTank2Capacity * gallonsToLitres }}} 
                                    type = "number" label = {props.submission.data.arrivalFuelUpliftRight.required} 
                                    error = {props.submission.data.arrivalFuelUpliftRight.status === "error"} 
                                    variant = {props.submission.data.arrivalFuelUpliftRight.variant} 
                                    fullWidth 
                                    name = "arrivalFuelUpliftRight" 
                                    id = "arrivalFuelUpliftRight" 
                                    onChange = {e => props.TechLogHandleChange(e.target)}>
                                </TextField></td>
                            <td className = "blank"></td>
                            <td className = "label">Used<br></br><span className = "footnote">(USg)</span></td>
                        </tr>
                        <tr>
                            <td className = "label">Status* <br></br><span className = "footnote">(USg)</span></td>
                            <td className = "inputNum" STYLE = {props.submission.data.arrivalFuelLeft.background}>
                                <TextField 
                                    value = {props.submission.data.arrivalFuelLeft.value} 
                                    InputProps={{ inputProps: { min: 0, max: props.selectedAircraft._fuelTank1Capacity }}} 
                                    type = "number" 
                                    error = {props.submission.data.arrivalFuelLeft.status === "error"} 
                                    fullWidth 
                                    variant = {props.submission.data.arrivalFuelLeft.variant} 
                                    name = "arrivalFuelLeft" 
                                    id ="arrivalFuelLeft" 
                                    required 
                                    onChange = {e => props.TechLogHandleChange(e.target)}>
                                </TextField></td>
                            <td className = "inputNum" STYLE = {props.submission.data.arrivalFuelRight.background}>
                                <TextField 
                                    value = {props.submission.data.arrivalFuelRight.value} 
                                    InputProps={{ inputProps: { min: 0, max: props.selectedAircraft._fuelTank2Capacity }}} 
                                    type = "number" error = {props.submission.data.arrivalFuelRight.status === "error"} 
                                    fullWidth 
                                    variant = {props.submission.data.arrivalFuelRight.variant} 
                                    name = "arrivalFuelRight" id ="arrivalFuelRight" 
                                    required 
                                    onChange = {e => props.TechLogHandleChange(e.target)}>
                                </TextField></td>
                            <td><img src = {shortArrow} alt = "Arrow" className = "shortArrow"></img></td>
                            <td className = "calc" name="calculatedFuelUsed" id="calculatedFuelUsed">{props.calculatedFuelUsed}</td>
                        </tr>
                        <tr>
                            <td className = "LR" colSpan = "1">Tank Capacity</td>
                            <td className = "fuelCapacity" colSpan = "1">{(props.selectedAircraft._fuelTank1Capacity)}</td>
                            <td className = "fuelCapacity" colSpan = "1">{(props.selectedAircraft._fuelTank2Capacity)}</td>
                        </tr>
                    </tbody>
                </table>    
            </Box>
        );
    }
}