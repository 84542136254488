// Import libraries
// import { isBefore, parseISO} from "date-fns";
// import dayjs from "dayjs";
// import { aircraftTypes, emptyAircraftSubmission } from "../../aircraft/AircraftData";
// import isAlphanumeric from "../IsAlphaNumeric";



// Import CSS, images, icons and static


// ********** ERROR & WARNING MESSAGES **********

export const roles ={
    generalWarning: "ROLES ---> Are you sure you want to set these roles for this person?",
    grantAdmin: "ADMIN RIGHTS ---> Are you sure you want to GRANT admin rights to this person?",
    revokeAdmin: "ADMIN RIGHTS ---> Are you sure you want to REVOKE admin rights from this person?",
    grantSchoolAdmin: "SCHOOL ADMIN RIGHTS ---> Are you sure you want to GRANT school admin rights to this person?",
    revokeSchoolAdmin: "SCHOOL ADMIN RIGHTS ---> Are you sure you want to REVOKE school admin rights from this person?",
}

export const errorMessages = {
    databaseConnection: "THE DATABASE CANNOT BE REACHED!",
    identityProvider: "CANNOT CONNECT TO THE IDENTITY PROVIDER"
}

export const successMessages = {
    acknowledged: " SUCCESSFULLY ACKNOWLEDGED... ",
    roleUpdate: "ROLES SUCCESSFULLY UPDATED"
}



export default function PersonValidation() {
    
    let validationResultList = [] 
    let result = {
            status: "warning",
            message: roles.generalWarning,
    }

    validationResultList.push(result);
    
        // console.log("In PersonValidation, validationResultList->", validationResultList)

        
    return validationResultList;
}




