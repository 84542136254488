// Import libraries
import { useMediaQuery } from "@mui/material";
import {Switch} from "@mui/material";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import { useState, useEffect } from "react";

// Import CSS, images, icons & static
import "./AdminAircraft.css"
import { mobileThreshold } from "../../utilities/environment/EnvironmentData";


// ************************* MAIN FUNCTION *******************************
export default function EditSwitch(props) {

    // const blank = "";
    const [switchClass, setSwitchClass] = useState("switch");
    const [direction, setDirection] = useState("row")
    const [justification, setJustification] = useState("flex-end")
    var media = useMediaQuery(`(min-width:${mobileThreshold}px)`);
    const [checked, setChecked] = useState(false)
    const [buttonLabel, setButtonLabel] = useState("CREATE")

    useEffect (() => {
        if (media) {
            setSwitchClass("switch-box")
            setDirection("row")
            setJustification("flex-end")
          } else {
            setSwitchClass("switch-box-mobile")
            setDirection("column")
            setJustification("flex-bottom")
          } 
        }, [media]);
    
    const handleChangeToggle = (e) => {
        // setChecked(e.target.checked);
            // console.log("In EditSwitch, checked->", e.target.checked);
        setChecked(e.target.checked);
        
        props.UpdateEditing(e.target.checked);
    }
    
    useEffect(() => {
        if (props.selectedAircraft._registration === "PLANE" || props.selectedAircraft._registration === "NEW PLANE") {
            setButtonLabel("CREATE");
        } else {
            setButtonLabel("UPDATE");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.selectedAircraft]);

    // if (media) {
        return (
            <Box className = {switchClass}>
                <Box >
                    {/* <Box className = {switchClass} sx = {{fontSize: "large", backgroundColor: "lightGrey"}}></Box>     */}
                        EDIT <Switch onChange = {handleChangeToggle}/> 
                {/* </Box>
                <Box> */}
                    <Button 
                        disabled = {!checked}
                        size = "medium" 
                        variant = "contained" 
                        name = "adminAircraft" 
                        sx = {{background: "darkGreen"}}
                        onClick={props.AircraftSubmit}
                        >{buttonLabel} 
                    </Button>
                </Box>

            </Box>
        )
    // } else {

    // }

};