// Import libraries
// import DateTimeOptions from "../utilities/DateTimeOptions.js"



// ******************************* MAIN FUNCTION ************************************************
export function CreateFlightLogRow(selectedAircraft, flight) {
    // console.log("In CreateFlightRow flight->", flight)
  flight.dateOfFlight = new Date(flight.dateOfFlight);
  var sortKeyHours = Number(flight.offBlocks.slice(0,2)) * 60
  var sortKeyMinutes = Number(flight.offBlocks.slice(3,5))
  var sortKeyTime = sortKeyHours + sortKeyMinutes
  var offBlocks = new Date(flight.dateOfFlight.setHours(sortKeyHours/60,sortKeyMinutes,0,0))


  // console.log("In CreateFlightLogRow, dateOfFlight->",flight.dateOfFlight);
  // console.log("In CreateFlightLogRow, offBlocks->",offBlocks);
  // console.log("In CreateFlightLogRow, sortKeyHours->", sortKeyHours/60,"sortKeyMinutes",sortKeyMinutes);
   

    // console.log("In CreateFlightRow, sortKeyHours->", sortKeyHours, "sortKeyMinutes->",sortKeyMinutes, "sortKeyTime->", sortKeyTime)
    // console.log("In CreateFlightRow,  Number((flight.dateOfFlight.valueOf()) + Math.random.toFixed(3)) -> ", Number((flight.dateOfFlight.valueOf()) + Math.random().toFixed(3)));
    // console.log("In CreateFlightRow,  flight.dateOfFlight.valueOf() + flight.svcOff ->", Number(flight.dateOfFlight.valueOf()) + flight.svcOff);
    // console.log("In CreateFlightRow,  flight.svcOff->", flight.svcOff+Math.random().toFixed(3));
    // console.log("In FlightLog, flight.offBlocks.valueOf()->", Number(flight.offBlocks))
    // console.log("In CreateFlightLogRow, FlightLog, flight->", flight);
  let flightLogRowPart1 = 
  {
  // Total (66)
  // *******  SORT ID & CHANGE MANAGEMENT (3) *******
  sortId: ((Number(flight.dateOfFlight.valueOf())/100+sortKeyTime)+Math.random().toFixed(4)),
  lastModifiedBy: flight.lastModifiedBy,
  lastModifiedAt: flight.lastModifiedAt,

  // ******* Base Details (9) *******
  id: flight.id,
  invoice: flight.invoice,
  registration: flight.registration,
  plateType: flight.planeType,
  status: flight.status,
  dateOpened: flight.dateOpened,
  dateOfFlight: new Date(flight.dateOfFlight),
  pilot: flight.pilot,
  passenger1: flight.passenger1,
  passenger2: flight.passenger2,

  // Times (13)
  offBlocks: flight.offBlocks,
  onBlocks: flight.onBlocks,
  blockDifference: Number((Math.floor(flight.blockDifference * 10))/10),
  takeOff: flight.takeOff,
  landing: flight.landing,
  flightTime: Number((Math.floor(flight.flightTime * 10))/10),
  svcOff: Number((Math.floor(flight.svcOff * 10))/10),
  svcOn: Number((Math.floor(flight.svcOn * 10))/10),
  svcDifference: Number((Math.floor(flight.svcDifference * 10))/10),
  nightTime: flight.nightTime,
  instrumentTime: flight.instrumentTime,
  instructorTime: flight.instructorTime,
  multiPilotTime: flight.multiPilotTime,

  // Flight Details (6)
  fromAirfield: flight.fromAirfield,
  toAirfield: flight.toAirfield,
  flightDetails: flight.flightDetails,
  homeLandings: flight.homeLandings,
  awayLandings: flight.homeLandings,
  nightLandings: flight.homeLandings,

  // Departure Fuel (4)
  departureFuelUpliftLeft: flight.departureFuelUpliftLeft,
  departureFuelLeft: flight.departureFuelLeft,
  departureFuelUpliftRight: flight.departureFuelUpliftRight,
  departureFuelRight: flight.departureFuelRight,
  // Arrival Fuel (5)
  arrivalFuelUpliftLeft: flight.arrivalFuelUpliftLeft,
  arrivalFuelLeft: flight.arrivalFuelLeft,
  arrivalFuelUpliftRight: flight.arrivalFuelUpliftRight,
  arrivalFuelRight: flight.arrivalFuelRight,
  fuelUsed: flight.fuelUsed,
  }

  // Departure Oil (4)
  let flightLogRowOilOneEngine = 
    {oilUpliftLeft: flight.oilUpliftLeft,
    oilStatusLeft: flight.oilStatusLeft,
    // oilUpliftRight: "N/A",
    // oilStatusRight: "N/A",}
    }

  let flightLogRowOilTwoEngines = 
  {oilUpliftLeft: flight.oilUpliftLeft,
  oilStatusLeft: flight.oilStatusLeft,
  oilUpliftRight: flight.oilUpliftRight,
  oilStatusRight: flight.oilStatusRight,
  }

  // Defects (2)
  let flightLogRowDefects = {}
  let i = 1;
    // console.log("In flight.defects, flight->", flight);
    // console.log("In flight.defects, flightLogRowsDefects->", flightLogRowDefects);
  if (flight.defects[0] !== undefined) {
    flightLogRowDefects.newDefect1 = flight.defects[0].details
  } else {
    flightLogRowDefects.newDefect1 = ""
  }
  if (flight.defects[1] !== undefined) {
    flightLogRowDefects.newDefect2 = flight.defects[1].details
  } else {
    flightLogRowDefects.newDefect2 = ""
  }
    // console.log("In CreateFlightLogRow, flightLogRowDefects->", flightLogRowDefects);

  // Away Landings (20)
  let flightLogRowPart2 = {}
  let j = 1;
    // console.log("In CreateFlightLogRow, flight.awayLanding->", flight.awayLanding);
  flight.awayLanding.forEach((awayLanding) => {
    
    if (awayLanding.location !== undefined && awayLanding.location !== "") {
      flightLogRowPart2[`awayLandingLocation${j}`] = awayLanding.location;
        // console.log(`In CreateFlightLogRow, flightLogRowPart2[awayLandingLocation$(j)]->`, flightLogRowPart2[`awayLandingLocation${j}`],"awayLanding.location->", awayLanding.location);
      flightLogRowPart2[`awayLandingQuantity${j}`] = awayLanding.quantity;
      flightLogRowPart2[`awayLandingDetails${j}`] = awayLanding.details;
      flightLogRowPart2[`awayLandingId${j}}`] = awayLanding.id;
      if (awayLanding.paid) {
        flightLogRowPart2[`awayLandingPaid${j}`] = "PAID";
      } else {
        flightLogRowPart2[`awayLandingPaid${j}`] = "NOT PAID";
      }
    }
    j++;
  });

    // console.log("In CreateFlightLogRow, flightLogRowPart2->", flightLogRowPart2);



let flightLogRow = [];

if (selectedAircraft._numberOfEngines === 1) {
  flightLogRow = {
    ...flightLogRowPart1,
    ...flightLogRowOilOneEngine,
    ...flightLogRowDefects,
    ...flightLogRowPart2
  }
  } else {
    flightLogRow = {
      ...flightLogRowPart1,
      ...flightLogRowOilTwoEngines,
      ...flightLogRowDefects,
      ...flightLogRowPart2}
  }
    // console.log("In CreateFlightLogRow, flightLogRow->", flightLogRow)
 
return flightLogRow
}
