// Import libraries 
import { compareAsc, format, formatDistanceToNowStrict } from 'date-fns'
import dayjs from 'dayjs';

// Import CSS, images, icons and static data
import courseIconSample from "../../courses/icons/courseIconSample.png"
import courseImageSample from "../../homePage/images/flightMEP2.jpeg"

let wright = "1903-12-17";
let QANTAS = "1920-11-16";

const background = {
    error: "background-color: rgb(245, 247, 177)",  // Soft Yellow
    // error: "background-color: rgb(240,240,240)", // Grey
    // error: "background-color: rgb(255,255,88)", // Bright Yellow
    // warning: "background-color: rgb(184,251,253)", // Bright Blue 
    newLesson: "background-color: aliceblue",
    warning: "background-color: rgba(217,240,240,255)", // Soft Blue
    normal: "background-color: white",  
    grey: "background-color: rgb(240,240,240)"
}

let newCourse =
{
    // Total (17)
    // Audit Information (3)
    id: null,
    version: 1.1,
    status: "DRAFT",

    // General Information (8)
    name: "NEW COURSE",
    abbreviation: "XXX",
    description: "A brief description of the course",
    hasCurriculum: true,
    minimumHoursToComplete: 30,
    planeType: "JET",
    planeComplexity: "COMPLEX",

    // Approvals - list (1)
    approvals: [
        // {
        //     authority: 
        //     {
        //         name: "CAA",
        //         country: "UK"
        //     },
        //     approvalDate: dayjs(Date("17/12/1903")).format("DD MMM YYYY"),
        //     expiryDate: ""
        // },
        // {
        //     authority: 
        //     {
        //         name: "CASA",
        //         country: "Australia"
        //     },
        //     approvalDate: dayjs(Date("16/11/1920")).format("DD MMM YYYY"),
        //     expiryDate: ""
        // }
    ],

    // Prerequisites (4)
    medicalClassRequired: "CLASS2",
    prerequisiteHoursAsPIC: 0, 
    prerequisiteHoursInAnAircraft: 0,
    prerequisiteHoursInType: 0,

    // Images & Icons (2)
    // courseImage: {courseImageSample},
    // courseIcon: {courseIconSample},
    courseImage: "",
    courseIcon: "",

    // Lessons - list (1)
    lessons:
    [
        {
            id: 1,
            // courseId: null,
            section: 1,
            name: "E1 - Example Lesson",
            objective: "The aims of the lesson",
            details: "Description of the standard required",
            minimumHours: 1
        },

    ]
}

let newLesson = {
        section:
        {
            value: "",
            status: "",
            background: background.normal,
            variant: "outlined",
            reset: true,
            required: true
        },
        name:
        {
            value: "",
            status: "",
            background: background.normal,
            variant: "outlined",
            reset: true,
            required: true
        },
        objective:
        {
            value: "",
            status: "",
            background: background.normal,
            variant: "outlined",
            reset: true,
            required: true
        },
        details:
        {
            value: "",
            status: "",
            background: background.normal,
            variant: "outlined",
            reset: true,
            required: true
        },
        minimumHours:
        {
            value: 0,
            status: "",
            background: background.normal,
            variant: "outlined",
            reset: true,
            required: true
        }
}

let emptyCourseSubmission = {
    // Total (20)
    // Audit Information (4)
    id: {
        value: null
    },
    version: {
        value: 0.0
    },
    status: {
        value: "DRAFT",
    },


    // General Information (8)
    name: {
        value: "",
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: false,
        required: true
    }, 
    abbreviation: {
        value: "",
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: true,
        required: true
    }, 
    description: {
        value: "",
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: true,
        required: true
    }, 
    hasCurriculum: {
        value: true,
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: true,
        required: true,
    }, 
    minimumHoursToComplete: {
        value: 0,
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: true,
        required: true
    }, 
    planeType:
    {
        value: "",
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: true,
        required: true
    }, 
    planeComplexity:
    {
        value: "",
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: true,
        required: true
    }, 

    // Approvals - list (1)
    approvals: [
        // {
        //     id: null,
        //     authority: {
        //         name: "",
        //         country: "",
        //     },
        //     approvalDate: "",
        //     expiryDate: ""
        // },
    ],

    // Prerequisites (4)
    medicalClassRequired: {
        value: "",
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: true,
        required: true
    }, 
    prerequisiteHoursAsPIC: {
        value: 0,
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: true,
        required: true
    }, 
    prerequisiteHoursInAnAircraft:   
    {
        value: 0,
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: true,
        required: true
    }, 
    prerequisiteHoursInType:
   {
        value: 0,
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: true,
        required: true
    }, 

    // Images & Icons (2)
    courseImage:{},
    courseIcon:{},

    // Lessons (list)
    newLesson: {
        // courseId: null,
        section: 
        {
            value: 0,
            status: "",
            background: background.newLesson,
            variant: "outlined",
            reset: true,
            required: true
        }, 
        name: 
        {
            value: "",
            status: "",
            background: background.newLesson,
            variant: "outlined",
            reset: true,
            required: true
        }, 
        objective:
        {
            value: "",
            status: "",
            background: background.newLesson,
            variant: "outlined",
            reset: true,
            required: true
        }, 
        details:
        {
            value: "",
            status: "",
            background: background.newLesson,
            variant: "outlined",
            reset: true,
            required: true
        }, 
        minimumHours:
        {
            value: 0,
            status: "",
            background: background.newLesson,
            variant: "outlined",
            reset: true,
            required: true
        }, 
    },

    // lessons - List (1)
    lessons:
    [
        {
            id: null,
            courseId: null,
            displayNumber: null,
            section: null,
            name:
            {
                value: "",
                status: "",
                background: background.normal,
                variant: "outlined",
                reset: true,
                required: true
            },
            objective:
            {
                value: "",
                status: "",
                background: background.normal,
                variant: "outlined",
                reset: true,
                required: true
            },
            details:
            {
                value: "",
                status: "",
                background: background.normal,
                variant: "outlined",
                reset: true,
                required: true
            },
            minimumHours:
            {
                value: 0,
                status: "",
                background: background.normal,
                variant: "outlined",
                reset: true,
                required: true
            }
        },
    ]
}



export {emptyCourseSubmission, newLesson, newCourse};

