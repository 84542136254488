//Import libraries
import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';

//Import Images
import departure from "../../../../../flights/techLog/images/departure.png";
import bookingIcon from "../../../../images/bookingIcon.png";
import bookingIcon2 from "../../../../images/bookingIcon2.jpeg";
import coursesIcon from "../../../../images/coursesIcon.png";




const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};



function ProductValues() {
  return (
    <Box
      component="section"
      sx={{ display: 'flex', overflow: 'hidden', bgcolor: "white" }}
    >
      <Container sx={{ mt: 15, mb: 10, display: 'flex', position: 'relative' }}>
        <Box
          component="img"
          // src="/static/themes/onepirate/productCurvyLines.png"
          alt="curvy lines"
          sx={{ pointerEvents: 'none', position: 'absolute', top: -180 }}
        />
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={coursesIcon}
                // src="/static/themes/onepirate/productValues1.svg"
                alt="classRoom"
                sx={{ height: 55,  }}
              />
              <Typography variant="h6" sx={{ my: 5, bgcolor: "white" }}>
                COURSES & EXPERIENCES
              </Typography>
              <Typography variant="h5">
                {
                  'Trial flights, private pilots license, full commercial licenses and instrument ratings'
                }
                <br></br><br></br>
                {
                  'Learn to fly over the pituresque Isle Of Wight and The Solent from one of southern England\'s historic airfields'
                }
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src = {departure}
                // src="/static/themes/onepirate/productValues2.svg"
                alt="departure"
                sx={{ height: 55 }}
              />
              <Typography variant="h6" sx={{ my: 5, bgcolor: "white"}}>
                PLANES & SIMULATORS
              </Typography>
              <Typography variant="h5">
                {
                  'Diamond DA40 and DA42 aircraft and on-site simulators rated for full instrument training '
                }
                <br></br><br></br>
                {'  FUJI FA-200-160 and other single engine aircraft perfect for PPL and other training'}
              </Typography>
            </Box  >
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={bookingIcon2}
                // src="/static/themes/onepirate/productValues3.svg"
                alt="calendar"
                sx={{ height: 55 }}
              />
              <Typography variant="h6" sx={{ my: 5, bgcolor: "white"}}>
                BOOKINGS
              </Typography>
              <Typography variant="h5">
                {'Once Registered you will have access to schedule training for your chosen course'}
                <br></br><br></br>
                {/* {'Call or email us to set up an initial chat...'} */}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductValues;
