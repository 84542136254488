

var columnGroups1 = 
    [
        {
            groupId: 'ROLES',
            children: [ {field: 'pilotInCommand'},
                        {field: 'pilotUnderTraining'}, 
                        {field: 'coPilot'},
                        {field: 'multiPilot'},
                        {field: 'instructor'}]     
        },
    ]
var columnGroups2 = 
    [
        {
            groupId: 'TYPE OF AIRCRAFT',
            children:   [{field: 'sepTime'}, 
                        {field: 'mepTime'}, 
                        {field: 'jetTime'},
                        {field: 'simulator'}]
        },
    ]
    var columnGroups3 = 
    [
        {
            groupId: 'TYPE OF FLIGHT',
            children:   [{field: 'instrumentTime'},
                        {field: 'nightTime'}]
        },
        {
            groupId: 'LANDINGS',
            children:   [{field: 'landings'}, 
                        {field: 'nightLandings'}]
        }
    ]


export {
    columnGroups1, columnGroups2, columnGroups3
};


 