//Import libraries
// import { Box } from "@mui/system";
// import React, { useEffect, useState } from 'react';
import { AuthProviderFirebase } from "./AuthContextFirebase";
import { useContext } from "react";
import { databaseConnectionMessages } from "../database/DatabaseConnectionMessages"; 

// Import CSS, images, icons & static data



// Import fonts



//**************************** MAIN FUNCTION ***************************** */
export const GetCurrentUser = () => {

    var responseObject = {
        databaseConnection: true,
        data: ""
    }

    // try {
        responseObject.data = useContext(AuthProviderFirebase);
            console.log("In GetCurrentUser, responseObject.data->", responseObject.data); 
    if (!responseObject.data) {
        responseObject.data = databaseConnectionMessages.error;
        responseObject.databaseConnection = false
    }

    return responseObject;
      
};



