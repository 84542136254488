// Import libraries
import { useMediaQuery } from "@mui/material";
import {Switch} from "@mui/material";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import { useState, useEffect } from "react";

// Import CSS, images, icons & static
import "./AdminPerson.css"
import { mobileThreshold } from "../../utilities/environment/EnvironmentData";


// ************************* MAIN FUNCTION *******************************
export default function AdminPersonButtons(props) {

    // const blank = "";
    const [buttonClass, setButtonClass] = useState("button");
    const [direction, setDirection] = useState("row")
    const [justification, setJustification] = useState("flex-end")
    var media = useMediaQuery(`(min-width:${mobileThreshold}px)`);
    const [buttonLabel, setButtonLabel] = useState()
    const [checked, setChecked] = useState(false)



    // setButtonLabel(props.buttonLabel)

    const handleClick = () => {
            // console.log("In EditSwitch, e->", e)
        if (props.buttonLabel === "ACKNOWLEDGE") {
            props.AcknowledgeNewPerson();
         } else {
            props.HandleClick();
         }
            // console.log("In EditSwitch, Submit button clicked")
    } 

    useEffect (() => {
        if (media) {
            setButtonClass("button")
            setDirection("row")
            setJustification("flex-end")
          } else {
            setButtonClass("button-mobile")
            setDirection("column")
            setJustification("flex-bottom")
          } 
        }, [media]);
    

    if (props.buttonLabel === "UPDATE" || 
        (props.buttonLabel === "ACKNOWLEDGE" && 
        props.newPersonList !== undefined && (
        props.newPersonList.length !== 0))) {
        return (
            <Box sx = {{display: "flex", flexDirection: direction, justifyContent: justification}}>
                <Box className = {buttonClass} >
                    <Button 
                        disabled = {!props.editing}
                        size = "medium" 
                        variant = "contained" 
                        name = {`personAdmin${props.buttonLabel}`} 
                        sx = {{background: "darkGreen"}}
                        onClick={e => handleClick()}
                        >{props.buttonLabel} 
                    </Button>
                </Box>
            </Box>
        )
    }
};