

var columnGroupingModelSim = 
  [
    {
      groupId: 'ADMINISTRATION',
      children: [{field: 'invoice'},{field: 'registration'},{ field: 'databaseId'},{ field: 'id'}, {field: 'invoiceNumber'}]
    },
    {
      groupId: 'TIMES',
      children: [{ field: 'dateOfFlight' }, 
                  {field: 'takeOff'}, 
                  {field: 'landing'}, 
                  {field: 'flightTime'},]
    },
    {
      groupId: 'PILOT & INSTRUCTOR',
      children: [{ field: 'pilot' }, {field: 'passenger1'}, {field: 'passenger2'}]
    },
    {
      groupId: 'SESSION',
      children: [
                  {field: 'flightDetails'}, 
                ]
    },



    {
      groupId: 'DEFECTS',
      children: [{ field: 'newDefect1' }, {field: 'newDefect2'}]
    },
    ]
   
  
    export {
      columnGroupingModelSim
    };


 