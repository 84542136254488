// Import libraries
// import axios from "axios";
import axiosInstance from "../security/AxiosConfig";
import { CreateAircraftList } from "../../aircraft/CreateAircraftList";
import Update from "../../flights/techLog/TechLog.js"
import { defaultAircraftList } from "../../aircraft/AircraftData";
import _ from "lodash";

// Import CSS, images, icons and Static Data
import { API_URL } from "../../../config/config";
import { databaseConnectionMessages } from "./DatabaseConnectionMessages";


// ************************* RETRIEVE AIRCRAFT LIST FROM DATABASE *******************************
export async function GetAircraftData()  {
    let responseObject = {
        data: {},
        databaseConnection: true
    }
    try {
        const response = await axiosInstance.get(`${API_URL}/planes`);
        responseObject.data.planeList = response.data;
        responseObject.databaseConnection = true
    } catch (error) {
        console.error('Error fetching planes:', error);
        responseObject.data.planeList = databaseConnectionMessages.error;
        responseObject.databaseConnection = false
        return responseObject;
    }
    // Get Service Events For All Planes
    try {
        const response = await axiosInstance.get(`${API_URL}/nextServiceEvents`);
        responseObject.data.serviceEvents = response.data;
        responseObject.databaseConnection = true;
    } catch (error) {
        console.error('Error fetching service events:', error);
        responseObject.data.serviceEvents = databaseConnectionMessages.error;
        responseObject.databaseConnection = false;
        return responseObject;
    }
    // Build aircraft list in standard Flight Object
    try {
        let list = CreateAircraftList(responseObject.data.planeList, responseObject.data.serviceEvents);
        responseObject.data.aircraftList = _.cloneDeep(list);
        responseObject.databaseConnection = true;
            // console.log("In GetAircraftData, responseObject->", responseObject);
        return responseObject;
    } catch (error) {
            // console.log("In GetAircraftData, planeList->", defaultAircraftList);
        console.error('Error building a plane list', error);
        responseObject.data.aircraftList = databaseConnectionMessages.error;
        responseObject.databaseConnection = false;
        return responseObject;
    }
}