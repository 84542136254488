// Import libraries
import React, {useState, useEffect, useReducer} from 'react';
import { API_URL } from '../../../config/config.js';
import axiosInstance from '../../utilities/security/AxiosConfig.js';
import { emptyPersonSubmission } from '../../utilities/database/GetRolePermissions.js';
import { emptyNewPersonSubmission } from '../../utilities/database/GetRolePermissions.js';
import { rolesTemplate } from '../../utilities/database/GetRolePermissions.js';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import {Box} from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import AdminPersonBanner from './AdminPersonBanner.js';
import AdminPersonNew from './AdminPersonNew.js';
import AdminPersonRoles from './AdminPersonRoles.js';
import Feedback from '../../utilities/feedback/Feedback.js';
import Validate from '../../utilities/validation/Validate.js';
import { GetPostPerson } from '../../utilities/database/GetPostPerson.js';
import { useAuthFirebase } from '../../utilities/security/AuthContextFirebase.js';
import PersonFormReducer from '../../dispatchersAndReducers/PersonFormReducer.js';
import NewPersonFormReducer from '../../dispatchersAndReducers/NewPersonFormReducer.js';
import { PostAcknowledgement } from '../../utilities/database/PostAcknowledgement.js';
import { UpdateClaims } from '../../utilities/security/AuthUtilities.js';
import { Progress } from '../../utilities/feedback/Progress.js';
import { GetUserRoles } from '../../utilities/security/AuthUtilities.js';
// import { GetRoles } from '../../utilities/database/GetRoles.js';
import _ from "lodash";


// Import fonts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// Import CSS, images, icons & static data
import '../../flights/techLog/TechLog.css';
import './AdminPerson.css'
import { successMessages } from '../../utilities/validation/PersonValidation.js';
import { errorMessages } from '../../utilities/validation/PersonValidation.js';
import { authorisationMessages } from '../../utilities/security/AuthorisationMessages.js';
import { PersonAddDisabledOutlined } from '@mui/icons-material';

// import { background } from '../../flights/flightData/FlightData.js';
// import { defaultNewPersonList } from '../../utilities/database/GetPerson.js';



// ******************************* MAIN FUNCTION ************************************************
function AdminPersonMain(props) {
    const bannerTitle = "People Admin.";
    const calledFrom = "AdminPerson"


    // *********************** INITIALISE STATE VARIABLES **********************************
    const [personList, setPersonList] = useState();
    const [newPersonList, setNewPersonList] = useState();
    const [currentUser, setCurrentUser] = useState();
    const [selectedPerson, setSelectedPerson] = useState();
    const [databaseConnection, setDatabaseConnection] = useState(true);
    const [personSubmission, dispatch] = useReducer(PersonFormReducer, emptyPersonSubmission);
    const [newPersonSubmission, newDispatch] = useReducer(NewPersonFormReducer, emptyNewPersonSubmission);
        // console.log("In AdminPersonMain, personSubmission->", personSubmission);
    // const [checked, setChecked] = useState(false);
    const [editing, setEditing] = useState(false)
   
    dayjs.extend(utc);

    // Security set up
    const {authUser} = useAuthFirebase();
    const [jwt, setJwt] = useState(null);
    
    // Validations & Feedback 
    const [result, setResult] = useState({status: "", message: "" })
    const [snackBarStatus, setSnackBarStatus] = useState(false);
    const [progressStatus, setProgressStatus] = useState(false);
    const [dialogueStatus, setDialogueStatus] = useState(false);
    // const [dialogueResponse, setDialogueResponse] = useState("dialogueCancel");

    // Initialise the most severe issue container 
    let mostSevereIssue = {
        status: "success",
        message: successMessages.roleUpdate,
    };

   

    // ********************* Snackbar & Dialogue Update functions ***************************
    function UpdateSnackBarStatus (newStatus) {
            // console.log("In AdminPersonMain, UpdateSnackBarStatus, newStatus->", newStatus);
        setSnackBarStatus(newStatus);
            // console.log("In AdminPersonMain, UpdateSnackBarStatus, snackBarStatus->", snackBarStatus);
    }
    function UpdateProgressStatus (newStatus) {
        // console.log("In AdminPersonMain, UpdateSnackBarStatus, newStatus->", newStatus);
        setProgressStatus(newStatus);
        // console.log("In AdminPersonMain, UpdateSnackBarStatus, snackBarStatus->", snackBarStatus);
    }
    function UpdateDialogueStatus (newStatus) {
            // console.log("In AdminPersonMain, UpdateDialogueStatus, newStatus->", newStatus);
        setDialogueStatus(newStatus);
            // console.log("In AdminPersonMain, UpdateDialogueStatus, DialogueStatus->", DialogueStatus);
    }
    function UpdateResult (newResult) {
        // console.log("In AdminPersonMain, UpdateResult, newResult -> ", newResult)
        if (newResult !== undefined) {
            setResult(newResult);
        }
    }
    function UpdateDatabaseConnection (response) {
        setDatabaseConnection(response);
    }
    function UpdateEditing(isChecked) {
            // console.log("In AdminAircraftMain, UpdateEditing, isChecked->", isChecked);
        setEditing(isChecked);
    }

    //******************************* REDUCER FUNCTIONS ****************************** */
    function UpdateNewPerson(newPerson, requestedCheckBoxValue) {
        let action = {
            type: "acknowledge",
            value: {
                name: newPerson,
                acknowledged: requestedCheckBoxValue
            }
        }
            // console.log("In AdminPersonMain, UpdateNewPersonSubmission, action->", action)
        newDispatch(action);
    }

    function AddRoles(selectedPerson, roles) {
        selectedPerson.roles = _.cloneDeep(roles);
            // console.log("In AddRoles, selectedPerson->", selectedPerson,"roles->", roles);
        let action = {
            type: "addRoles",
            value: {
                person: selectedPerson,
                roles: roles,
            }
        }
        dispatch(action);
            // console.log("In AddRoles, personSubmission->", personSubmission)
    }

    function UpdateRoles (selectedPerson, role, requestedValue) {
            // console.log("In UpdateRoles, personSubmission->", personSubmission)
            // console.log("In UpdateRoles, selectedPerson->", selectedPerson,"role->", role,"requestedValue->",requestedValue);
        let action = {
            type: "update",
            value: {
                person: selectedPerson,
                roleForUpdate: role,
                requestedValue: requestedValue
            }
        }
        dispatch(action);
        let selectedPersonTmp = _.cloneDeep(selectedPerson);
        selectedPersonTmp.roles[role].status = requestedValue;
        setSelectedPerson(selectedPersonTmp);
    }


    const HandleSelect = async (selectedPersonName) => {
        
        if (selectedPersonName !== "" && selectedPersonName !== undefined) { 
            let selectedPersonTmp = {}
                // console.log("in AdminPersonMain, HandleSelect, selectedPersonName->", selectedPersonName);
            personList.forEach((person) => {
                if (selectedPersonName === person.name) {
                        // console.log("in AdminPersonMain, HandleSelect, personSubmission->", personSubmission,"selectedPersonName->", selectedPersonName);
                    setSelectedPerson(person);
                    selectedPersonTmp = person;
                }
            })
            let responseObject = {
                roles: {},
                databaseConnection: databaseConnection
            }
                // console.log("In AdminPersonMain, HandleSelect, selectedPersonTmp->", selectedPersonTmp)
            //******************** GET ROLES FROM FIREBASE AND UPDATE SELECTED PERSON, SUBMISSION AND ROLES DISPLAY */
         
            UpdateProgressStatus(true);
            responseObject = await GetUserRoles(selectedPersonTmp);
                // console.log("in AdminPersonMain, HandleSelect, responseObject->", responseObject);
            UpdateDatabaseConnection(responseObject.databaseConnection)
                // console.log("in AdminPersonMain, HandleSelect, selectedPerson->", selectedPerson, "responseObject->", responseObject);
            AddRoles(selectedPersonTmp, responseObject.data)
                // console.log("in AdminPersonMain, HandleSelect, post-Update, personSubmission->", personSubmission,"e.target.value->", e.target.value);
                // console.log("in AdminPersonMain, HandleSelect, personSubmission[e.target.value]->", personSubmission[e.target.value]);
            setSelectedPerson(selectedPersonTmp);
            UpdateProgressStatus(false);
        } else {
            setSelectedPerson({selectedPersonName});
            // CREATE SUBMISSION CLEAR (IE SET TO emptyPersonSubmission) 
         }

 
      
    }

  
    // ****************** SECURITY MANAGEMENT *************************************/
    useEffect( () => {
        const TestJwt = async () => {
            if (authUser) {
                const token = await authUser.getIdToken();
                setJwt(token);
            }
        }
        TestJwt();
    },[jwt])


    //******************** POPULATE EXISTING AND NEW PERSON SECTIONS *****************/
    async function GetPersonData(type) {
        let responseObject = {}
        responseObject = await GetPostPerson(type);
        if (responseObject.databaseConnection) {
            setDatabaseConnection(responseObject.databaseConnection);
            if (type === "unacknowledged") {
                    // console.log("In AdminPersonMain, (new)response.list->", response.list)
                setNewPersonList(responseObject.data);
                if (responseObject.data) {
                    let action = {
                        type: "initialise",
                        value: responseObject.data,
                    }
                    newDispatch(action);
                        
                }
                if (responseObject.data) {
                    let action = {
                        type: "mergeNames",
                        value: responseObject.data,
                    }
                    newDispatch(action);
                }
            } else {
                setPersonList(responseObject.data);
                    // console.log("In AdminPersonMain, AddRoles, response.list->", responseObject);
                if (responseObject.data && selectedPerson) {
                    let roles = await GetUserRoles(selectedPerson)
                    if (roles.databaseConnection) { 
                        // console.log("In GetPersonData, roles->", roles);
                    AddRoles(selectedPerson, roles.data) 
                    } else {
                        UpdateDatabaseConnection(roles.databaseConnection)
                    }
                
                    // console.log("In AdminPersonMain, AddRoles, response.list->", response.list);
                }
                if (responseObject.data) {
                    let action = {
                        type: "mergeNames",
                        value: responseObject.data,
                    }
                    dispatch(action);
                }
                    // console.log("In AdminPersonMain, personSubmission->", personSubmission)
                // return responseObject
            }
        } else {
            UpdateDatabaseConnection(responseObject.databaseConnection)
        }
    }

    useEffect(() => {

        GetPersonData("unacknowledged");
        GetPersonData("acknowledged");
            // console.log("In useEffect, personSubmission->",personSubmission)
    
    }, [])

    //************** VALIDATE ROLES, SUBMIT ROLES TO FIREBASE AND PROVIDE FEEDBACK *****************/

    function SubmitClaims (selectedPerson) {
        // console.log("In submitClaims, selectedPerson->", selectedPerson)

        //***** VALIDATE *****/
        let validationResultList = Validate({},{},"claims",{})
            // console.log("In submitClaims, validationList->", validationResultList)
        // let mostSevereIssue = []
        
        validationResultList.forEach((issue) => {
            if (mostSevereIssue.status !== "error") {
                mostSevereIssue = _.cloneDeep(issue)
            }
        })
        if (mostSevereIssue.status === "success") {
            mostSevereIssue = CallUpdateClaims();
        }
            // console.log("In SubmitClaims, post UpdateClaims, mostSeverIssue->", mostSevereIssue);   
        TriggerFeedback(mostSevereIssue)

    }

    async function CallUpdateClaims() {
        let responseObject ={
            databaseConnection: true,
            data: ""
        }
        responseObject = await UpdateClaims(selectedPerson)
            // console.log("In CallUpdateClaims, responseObject->", responseObject);
        if (responseObject && responseObject.databaseConnection) {
            mostSevereIssue = {
                status: "success",
                message: responseObject.data
            }
            UpdateResult(mostSevereIssue)
            // responseObject.data.mostSevereIssue = mostSevereIssue;
                // console.log("In CallUpdateClaims, responseObject->", responseObject);
                // console.log("In CallUpdateClaims, mostSevereIssue->", mostSevereIssue);
        } else {
            mostSevereIssue = {
                status: "error",
                message: authorisationMessages.error
            }
            UpdateResult(mostSevereIssue);
            responseObject.databaseConnection = false;
            responseObject.data = authorisationMessages.error
            // responseObject.data.mostSevereIssue = mostSevereIssue;
        }
            // console.log("In CallUpdateClaims, mostSevereIssue->", mostSevereIssue,"responseObject->", responseObject);
        return responseObject;

        // TriggerFeedback(mostSevereIssue)
    }

    function setNewPersonFeedback(newPerson, responseObject) {

        // let mostSevereIssue = {}
        if (responseObject.databaseConnection) {
            let nameList = newPerson.filter((person) => {
                return person.acknowledged
            });
            let feedbackList = [];

            let position = 1
            nameList.forEach((person) => {
    
                switch (position) {
                    case (nameList.length): 
                        feedbackList = (feedbackList + person.name);
                    break;
                    case (nameList.length - 1): 
                        feedbackList = (feedbackList + person.name + "  &  ");
                    break;
                    default: 
                        feedbackList = (feedbackList + person.name + ",  ");
                }
                position++;
            })
    
            mostSevereIssue =  _.cloneDeep({
                status: "success",
                message: successMessages.acknowledged + feedbackList
            });
        } else {
            mostSevereIssue = _.cloneDeep({
                status: "error",
                message: errorMessages.databaseConnection,
            }); 
        } 
        TriggerFeedback(mostSevereIssue)
    }


    async function TriggerFeedback(mostSevereIssue) {
            // console.log("In TriggerFeedback Switch, PersonSubmit, mostSevereIssue->", mostSevereIssue);
        switch (mostSevereIssue.status) {
            

            case "success":
                    
                UpdateResult(mostSevereIssue);
                GetPersonData("unacknowledged");
                GetPersonData("acknowledged");
                    // console.log("In AdminPersonMain, personList->", personList);
                // let e = {
                //     target: {
                //         value: selectedPerson
                //     }
                // };
                // await HandleSelect(e);
                // InitialiseRoles(selectedPerson, responseObject.data)
                UpdateSnackBarStatus(true);
            case "error":
                    // console.log("In AdminPersonMain Switch, PersonSubmit, mostSevereIssue->", mostSevereIssue);
                UpdateResult(mostSevereIssue);
                UpdateSnackBarStatus(true);
            break;
            case "warning":
                UpdateResult(mostSevereIssue)
                UpdateDialogueStatus(true);
            break;

            default: console.log("Unknown validation result")
        }
    }

    // Handle aircraft Submit
    async function AcknowledgeNewPerson() {

        let databaseSubmission = []
        let responseObject = {}
        // let mostSevereIssue =  {}
            // console.log("In AdminPersonMain, AcknowledgeNewPerson, NewPersonSubmission->", newPersonSubmission);
        Object.keys(newPersonSubmission).forEach((person) => {
            databaseSubmission.push(newPersonSubmission[person])
        })
            // console.log("In AdminPersonMain, AcknowledgeNewPerson, databaseSubmission->", databaseSubmission);
        responseObject = await PostAcknowledgement(databaseSubmission);
        UpdateDatabaseConnection(responseObject.databaseConnection);  
        mostSevereIssue = setNewPersonFeedback(databaseSubmission, responseObject)
            // console.log("In AdminPersonMain, AcknowledgeNewPerson, mostSevereIssue->", mostSevereIssue);
    }

    // *********************** HANDLE WARNING BOX CONTINUE AIRCRAFT *****************************   
    const HandleContinue = async () => {
        // NEED TO CLARIFY WHY mostSeverIssue = ROES SUCCESSFULLY UPDATED at this point
        // ON CALLING THE WARNING MODAL IT WAS ROLES ---> Are you sure you want to set the roles for this person?
            // console.log("In handleContinue, mostSevereIssue->", mostSevereIssue);
        if (mostSevereIssue.status !== "error") { 
                
            // await UpdateDialogueResponse("dialogueCancel")
            mostSevereIssue = {
                status: "success",
                message: successMessages.roleUpdate,
            };
                // console.log("In handleContinue, post status check, mostSevereIssue->", mostSevereIssue);
            UpdateResult(mostSevereIssue);
            UpdateProgressStatus(true);
            let responseObject = await CallUpdateClaims();
                // console.log("In HandleContinue, responseObject->", responseObject);
            
            if (!responseObject.databaseConnection) {
                    // console.log("In HandleContinue, responseObject->", responseObject);
                mostSevereIssue = {
                    status: "error",
                    message: errorMessages.identityProvider
                };
                UpdateResult(mostSevereIssue);
                UpdateProgressStatus(false);
                snackBarStatus(true)
            }
            
            if (responseObject.databaseConnection) {
                mostSevereIssue = {
                    status: "success",
                    message: successMessages.roleUpdate,
                };
                UpdateResult(mostSevereIssue)
                    // console.log("In HandleContinue, responseObject->", responseObject);
                GetPersonData("acknowledged");
                    // console.log("In AdminPersonMain, HandleContinue, selectedPerson->", selectedPerson,"responseObject->", personSubmission)
                // await AddRoles(selectedPerson, responseObject.data.roles)
                UpdateResult(mostSevereIssue);
                UpdateProgressStatus(false);
                UpdateSnackBarStatus(true);
            }
            


        } else {
            mostSevereIssue = {
                status: "error",
                message: errorMessages.identityProvider
            }
                // console.log("In HandleContinue, mostSevereIssue.message->",mostSevereIssue.message)
            await UpdateResult(mostSevereIssue);
            await UpdateSnackBarStatus(true);
                // console.log("In HandleContinue, mostSevereIssue.message->",mostSevereIssue.message)
        }   
    } 
    
    // *********************** AIRCRAFT ADMIN RENDER ****************************
        // console.log("In AdminPersonMain, progressStatus->", progressStatus)
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            
            <meta name="viewport" content="initial-scale=1, width=device-width" />
            
            <Box>
                <AdminPersonBanner 
                    UpdateSnackBarStatus = {UpdateSnackBarStatus} 
                    UpdateEditing = {UpdateEditing}
                    snackBarStatus = {snackBarStatus} 
                    UpdateResult = {UpdateResult}
                    personList = {personList}
                    AcknowledgeNewPerson = {AcknowledgeNewPerson}
                    HandleSelect = {HandleSelect}
                    className = "banner" 
                    title = {bannerTitle} 
                    personSubmission = {personSubmission} 
                    databaseConnection = {databaseConnection} 
                    UpdateDatabaseConnection = {UpdateDatabaseConnection}
                    selectedPerson = {selectedPerson} 
                    calledFrom = {calledFrom}
                    authUser = {authUser}
                    /> 
            </Box>
            <Box> <Progress progressStatus = {progressStatus}/></Box>
            <Box className = "admin-frame">
                <AdminPersonRoles 
                    editing = {editing}
                    selectedPerson = {selectedPerson} 
                    AddRoles = {AddRoles}
                    UpdateRoles = {UpdateRoles}
                    SubmitClaims = {SubmitClaims}
                    />
                <AdminPersonNew 
                    editing = {editing}
                    UpdateNewPerson = {UpdateNewPerson}
                    AcknowledgeNewPerson = {AcknowledgeNewPerson}
                    newPersonSubmission = {newPersonSubmission}
                    newPersonList = {newPersonList}
                    
                    // PersonHandleChange = {PersonHandleChange}
                    />

            {/*</Box> */}
            </Box>

            <Box className = "feedback" sx={{zIndex: "6", position: "sticky", left: "0px", bottom: "80px", margin: "80px"}}>
                <Feedback  HandleContinue = {HandleContinue} 
                        dialogueStatus = {dialogueStatus} 
                        UpdateDialogueStatus = {UpdateDialogueStatus} 
                        snackBarStatus = {snackBarStatus} 
                        UpdateSnackBarStatus = {UpdateSnackBarStatus} 
                        result = {result}/> 
            </Box>
        </LocalizationProvider>
        
    );
};

export default AdminPersonMain;
