// Import libraries
// import DateTimeOptions from "../utilities/DateTimeOptions.js"


// ******************************* MAIN FUNCTION ************************************************
export function CreatePilotLogRow(selectedPilot, flight) {
    // console.log("In CreateFlightRow, selectedPilot->", selectedPilot, "flight->", flight)
  flight.dateOfFlight = new Date(flight.dateOfFlight);
  var sortKeyHours = Number(flight.offBlocks.slice(0,2)) * 60
  var sortKeyMinutes = Number(flight.offBlocks.slice(3,5))
  var sortKeyTime = sortKeyHours + sortKeyMinutes

    // console.log("In CreateFlightRow, sortKeyHours->", sortKeyHours, "sortKeyMinutes->",sortKeyMinutes, "sortKeyTime->", sortKeyTime)
    // console.log("In CreateFlightRow,  Number((flight.dateOfFlight.valueOf()) + Math.random.toFixed(3)) -> ", Number((flight.dateOfFlight.valueOf()) + Math.random().toFixed(3)));
    // console.log("In CreateFlightRow,  flight.dateOfFlight.valueOf() + flight.svcOff ->", Number(flight.dateOfFlight.valueOf()) + flight.svcOff);
    // console.log("In CreateFlightRow,  flight.svcOff->", flight.svcOff+Math.random().toFixed(3));
    // console.log("In FlightLog, flight.offBlocks.valueOf()->", Number(flight.offBlocks))
    // console.log("In FlightLog, flight->", flight);
  let flightLogRowPart1 = 
  {
    // registration: flight.registration,
    id: ((Number(flight.dateOfFlight.valueOf())/100+sortKeyTime)+Math.random().toFixed(4)),
    dateOfFlight: new Date(flight.dateOfFlight),
    registration: flight.registration,
    planeType: flight.planeType, 
    from: flight.from,
    to: flight.to,
    pilot: flight.pilot,
    offBlocks: flight.offBlocks.toString(),
    onBlocks: flight.onBlocks.toString(),
    blockTime: flight.blockDifference,
  }

  let flightLogRowPart2 = {
    pilotInCommand: flight.pic,
    pilotUnderTraining: flight.put,
    multiPilot: flight.multiPilot,
    instructor: flight.instructor,
    sepTime: flight.sep,
    mepTime: flight.mep,
    jetTime: flight.jet,
    simulator: flight.sim,
    nightTime: flight.night,
    instrumentTime: flight.ifr,
    nightTime: flight.night,
    flightDetails: flight.routeDetails,
    landings: flight.landings,
    nightLanding: flight.nightLandings
  }


let flightLogRow = [];

  flightLogRow = {
    ...flightLogRowPart1,
    ...flightLogRowPart2,
  }
    // console.log("In CreateFlightLogRow, flightLogRow->", flightLogRow)
    // console.log(flightLogRow);
return flightLogRow
}
