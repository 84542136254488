// Import libraries
import axiosInstance from "../security/AxiosConfig";

// Import CSS, images, icons and Static Data



import { API_URL } from "../../../config/config";


// ************************* RETRIEVE AIRCRAFT LIST FROM DATABASE *******************************
export async function GetPilotData(UpdateDatabaseConnection)  {
    // const [pilotList, setPilotList] = useState([]);
   
    let responseObject = {};
    responseObject.data = [];

    try {
        const response = await axiosInstance.get(`${API_URL}/flights/pilots`);
        let list = response.data;
        list.pilots.forEach((pilot) => {
            responseObject.data.push(pilot.pilot);
        });
        responseObject.databaseConnection = true;
           
    } catch (error) {
        var returnList
        console.error('Error fetching pilots:', error);
        responseObject.databaseConnection = false;
    }
     
    return responseObject;

};