
//Import libraries


// Import CSS, images, icons & static data



// Import fonts


//**************************** MAIN FUNCTION ***************************** */
function SortObjectList(list, attribute) {
   

    list.sort((a, b) => {
        if (a[attribute] < b[attribute]) {
          return -1;
        }
        if (a[attribute] > b[attribute]) {
          return 1;
        }
        return 0;
      });

    return list
};

export {SortObjectList}
