// Import libraries
import {Box} from "@mui/material";
import {useMediaQuery} from '@mui/material';
import CreateNewPersonDisplayList from "./CreateNewPersonDisplayList";
import { useEffect, useState } from "react";
import AdminPersonButtons from "./AdminPersonButtons";


// Import CSS, images & icons
import "./AdminPerson.css"


import { mobileThreshold } from "../../utilities/environment/EnvironmentData";



// ****************************** MAIN FUNCTION **********************************
export default function AdminPersonNew(props) {
    var media = useMediaQuery(`(min-width:${mobileThreshold}px)`);
    const [newPersonClassName, setNewPersonClassName] = useState("admin-new-user-container");
    const [adminButtonClass, setAdminButtonClass] = useState("button");

    var newPersonList = props.newPersonList;
        // console.log("In AdminPersonNew, newPersonList->", newPersonList)

        // console.log("In AdminPersonNew, newPersonList->", newPersonList);

    useEffect(() => {
        if (media) {
            setNewPersonClassName("admin-new-person-container");
            setAdminButtonClass("button");
        } else {
            setNewPersonClassName("admin-new-person-container-mobile");
            setAdminButtonClass("button-mobile");
        }
    },[media]);
        // console.log("In AminAircraftDefects, props.selectedAircraft->", props.selectedAircraft)
        // console.log("In AdminAircraftDefects, props.aircraftSubmission->", props.aircraftSubmission)

    // if (props.newPersonList) {
        return (
            <Box className = {newPersonClassName} disabled = {props.editing === false}>
                <Box className = "admin-subtitles-new">
                    <Box sx = {{display: "flex", flexDirection: "column"}}>
                        <Box>New Person</Box>
                        <Box sx = {{fontSize: "14px", fontWeight: "normal", color: "darkGrey", ml: "8px", mt: "3px"}}>(registered on)</Box>
                    </Box>
                    <Box>Acknowledge</Box>
                </Box>
                <Box disabled = {props.editing === false}>
                    <CreateNewPersonDisplayList 
                        editing = {props.editing} 
                        newPersonSubmission = {props.newPersonSubmission}
                        UpdateNewPerson = {props.UpdateNewPerson}
                        // NewPersonHandleChange = {NewPersonHandleChange} 
                        newPersonList = {newPersonList}/>
                </Box>
                <Box className = {adminButtonClass}>
                    <AdminPersonButtons 
                        buttonLabel = "ACKNOWLEDGE" 
                        editing = {props.editing} 
                        newPersonList = {newPersonList}
                        AcknowledgeNewPerson = {props.AcknowledgeNewPerson} 
                        selectedPerson = {props.selectedPerson}/>
                        
                </Box>
            </Box>
        );
    // }
}