// Import libraries
import { useEffect, useState } from "react";
import ClearButton from "./ClearButton";
import AircraftSelect from "./AircraftSelect.js";
import MakeAndModel from "./MakeAndModel.js";
import BannerMaintenanceRow from "./BannerMaintenanceRow"
import {Box} from "@mui/material"
import BannerLogo from "./BannerLogo.js";
import BannerTitle from "./BannerTitle.js"
import DatabaseConnectionWarning from "../../../utilities/database/DatabaseConnectionWarning.js";
import { useMediaQuery } from "@mui/material";
import CamoLogRadio from "../../../reports/camoLog/CamoLogRadio.js";
import { MaintenanceChipsBanner } from "../../../utilities/maintenance/MaintenanceChips";


// Import CSS, images, icons & static data
import "./Banner.css";
import { mobileThreshold, mobileThresholdNarrow } from "../../../utilities/environment/EnvironmentData";
import azTechTheme from "../../../utilities/environment/ColourTheme";
import DaysToNextService from "../../../utilities/maintenance/DaysToNextService";


//Import fonts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';




// ******************************* MAIN FUNCTION ************************************************
export default function Banner(props) {
    // const calledFrom = "TechLog"
    const backgroundColour = azTechTheme.palette.blanchedAlmond
    const [aircraftSelectClass, setAircraftSelectClass] = useState("aircraftSelect");
    const [clearButtonClass, setClearButtonClass] = useState("banner-clear-button");
    var media = useMediaQuery(`(min-width:${mobileThreshold}px)`);
    var mediaNarrow = useMediaQuery(`(min-width:${mobileThresholdNarrow}px)`);
    var bannerComponentGap = "";
    // Calculate maintenance values
        // console.log("In Banner, props.selectedAircraft -> ", props.selectedAircraft);
    var daysToNextService = DaysToNextService(props.selectedAircraft);
    var makeAndModelClass = "makeAndModel"
    
        // console.log("In Banner, props.calledFrom->", props.calledFrom)

    useEffect(() => {
        if (mediaNarrow) {
            setClearButtonClass("banner-clear-button")
        } else {
            setClearButtonClass("banner-clear-button-mobile")
        }
    }, [mediaNarrow])

    useEffect(() => {
        if (media) {
            setAircraftSelectClass("aircraftSelect")
            bannerComponentGap = "60px"
        } else {
            setAircraftSelectClass("aircraftSelectMobile")
            bannerComponentGap = "60px"
        }
    }, [media])
    // Render
    return (
        <Box className = "banner" sx = {{backgroundColor: backgroundColour, minWidth: "405px"}}>
            <Box className = "bannerRow1">
                <Box sx = {{backgroundColor: backgroundColour, display: "flex", flexDirection: "row", selfJustify: "spaceEvenly"}}>
                    <BannerTitle title = {props.title}/>
                    <Box className = "databaseConnectionWarning">
                        <DatabaseConnectionWarning databaseConnection = {props.databaseConnection}/>
                    </Box>  
                </Box>
                <div className = "logo"><BannerLogo/></div>
            </Box>
            <Box className = "bannerRow2" >
                <Box className = "banner-aircraft-details" >
                    <Box className = {aircraftSelectClass} >  
                        <AircraftSelect
                            calledFrom = {props.calledFrom} 
                            // databaseConnection = {props.databaseConnection} 
                            registration = {props.selectedAircraft._registration} 
                            aircraftList={props.aircraftList} 
                            UpdateSelection={props.UpdateSelection}/>
                    </Box>
                    <Box className = "makeAndModel"> 
                        <MakeAndModel 
                            aircraftList={props.aircraftList} 
                            update={props.update} 
                            registration={props.registration}/>
                    </Box>
                {/* </Box>
                <Box> */}
                    <MaintenanceChipsBanner selectedAircraft = {props.selectedAircraft} calledFrom = {props.calledFrom}/>
                </Box>
                {/* <Box name = "DefectClear" sx = {{display: "flex", flexWrap: "noWrap", width: "fitContent", flexDirection: "row", selfJustify: "spaceBetween"}}> */}
                    {/* <Box sx = {{display: "flex", flexDirection: "row", width: "auto", justifyContent: "space-between", wrap: "wrap", gap: "20px"}}> */}
                        <BannerMaintenanceRow 
                        // daysToNextService = {daysToNextService} 
                        calledFrom = {props.calledFrom}
                        selectedAircraft = {props.selectedAircraft}/>
                    
                        <Box className = {clearButtonClass}>
                            <ClearButton
                                handleClose = {props.handleClose} 
                                UpdateSnackBarStatus = {props.UpdateSnackBarStatus} 
                                snackBarStatus = {props.snackBarStatus} 
                                UpdateResult = {props.UpdateResult} 
                                TechLogHandleClear = {props.TechLogHandleClear} 
                                submission = {props.submission} 
                                title = {props.title}>
                            </ClearButton>
                        </Box>
                    {/* </Box> */}
            </Box>
            <Box sx = {{ml: "10px", selfJustify: "flexStart"}}>
                <CamoLogRadio handleChange = {props.handleChange} camoLogPeriod = {props.camoLogPeriod} calledFrom = {props.calledFrom}/>
            </Box>

        </Box>
        // </Box>
    );
};
