import * as React from 'react';
import PropTypes from 'prop-types';
// import CircularProgress from '@mui/material/CircularProgress';
import { LinearProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function LinearProgressWithValue(props) {


    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: '100%', mr: 1 }}>
            <LinearProgress variant="determinate" {...props} value = {props.progress}/>
          </Box>
          <Box sx={{ minWidth: 30 }}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {`${Math.round(props.progress)}%`}
            </Typography>
          </Box>
        </Box>
      );
}

LinearProgressWithValue.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    progress: PropTypes.number.isRequired,
  };

// export default function CircularWithValueLabel(props) {
// //   const [progress, setProgress] = React.useState(props.progress);

// //   React.useEffect(() => {
// //     // const timer = setInterval(() => {
// //     //   setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
// //     // }, 800);
// //     // return () => {
// //     //   clearInterval(timer);
// //     // };
// //     setProgress(props.progress)
// //   }, [value]);

//   return <CircularProgressWithValue value={props.progress} />;
// }