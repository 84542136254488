//Import libraries
import { GetUserRoles } from "./AuthUtilities";
import { GetCurrentUser } from "./GetCurrentUser";

// Import CSS, images, icons & static data


// Import fonts


//**************************** MAIN FUNCTION ***************************** */
export function HasPermission(menuName, currentUserRoles) {
    let result = false;
    // const {authUser, loading, signInWithGoogle, signOut} = useAuthFirebase();
    // var guestRoles = _.cloneDeep(rolesTemplate);
    // const [currentUserRoles, setCurrentUserRoles] = useState(guestRoles);
    
    // *************** SECURITY - GET USER PERMISSIONS *****************************
    function ValidatePermissions(menuName, currentUserRoles) {
     
        Object.keys(currentUserRoles).forEach((role) => {
            let thisRole = currentUserRoles[role]
            if (thisRole.status) {
                if (thisRole.menuItems.indexOf(menuName) !== -1)
                result = true       
            }
        });
    } 
    // *****************************************************************************

    // *************** SECURITY - GET CURRENT USER ROLES *****************************
    if (!currentUserRoles) {

        let currentUser = "";
        let responseObject = {
            data: {},
            databaseConnection: true
        }
     
        const UpdateRoles = async (currentUser) => {
                responseObject = await GetUserRoles(currentUser);
                currentUserRoles = responseObject.data;
        }

        responseObject = GetCurrentUser();
        if (responseObject.databaseConnection) {
            currentUserRoles = UpdateRoles(responseObject.data);
            ValidatePermissions(menuName, currentUserRoles);
        }
    
    // ***********************************************************************
    } else {
        ValidatePermissions(menuName, currentUserRoles);
    }

    return (
        result
    );
};