// Import libraries
import React, {useState} from "react";
import Switch from '@mui/material/Switch';
import AircraftSelect from "../../flights/techLog/banner/AircraftSelect.js";
import MakeAndModel from "../../flights/techLog/banner/MakeAndModel.js";
// import MaintenanceTable1 from "./MaintenanceTable1.js";
// import MaintenanceTable2 from "./MaintenanceTable2.js";
// import MaintenanceTable3 from "./MaintenanceTable3.js";
import {Box, Button} from "@mui/material"
import BannerLogo from "../../flights/techLog/banner/BannerLogo.js";
import BannerTitle from "../../flights/techLog/banner/BannerTitle.js"
import EditSwitch from "./EditSwitch.js";
import DatabaseConnectionWarning from "../../utilities/database/DatabaseConnectionWarning.js";
import { PersonSelect } from "./PersonSelect.js";
import { Progress } from "../../utilities/feedback/Progress.js";


// Import CSS & images
import "./AdminPerson.css";
import azTechTheme from "../../utilities/environment/ColourTheme.js";

//Import fonts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Update } from "@mui/icons-material";





// ******************************* MAIN FUNCTION ************************************************
export default function AdminPersonBanner(props) {
const calledFrom = props.calledFrom;
const backgroundColour = azTechTheme.palette.blanchedAlmond

    
    // Render
    return (
        <Box className = "banner" sx = {{backgroundColor: backgroundColour}}>
            <Box className = "bannerRow1" sx = {{backgroundColor: backgroundColour}} >
                <Box sx = {{display: "flex", flexDirection: "row", selfJustify: "flexStart", backgroundColor: backgroundColour}}>
                    <BannerTitle title = {props.title}/>
                    <Box className = "databaseConnectionWarning">
                        <DatabaseConnectionWarning databaseConnection = {props.databaseConnection}/>
                    </Box>  
                </Box>
                <div className = "logo"><BannerLogo/></div>
            </Box>
            <Box className = "bannerRowAdmin" sx = {{backgroundColor: backgroundColour}}>
                <Box className = "personSelectAdmin"> 
                    <PersonSelect 
                        calledFrom = {calledFrom}
                        personList = {props.personList}
                        databaseConnection = {props.databaseConnection} 
                        UpdateDatabaseConnection  = {props.UpdateDatabaseConnection}
                        HandleSelect = {props.HandleSelect}
                        Update={props.Update}/>
                </Box>
                <EditSwitch UpdateEditing = {props.UpdateEditing} AcknowledgeNewPerson={props.AcknowledgeNewPerson} selectedPerson = {props.selectedPerson}/>
            </Box>
        </Box>
    );
};
