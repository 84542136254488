// Import libraries
// import axios from "axios";
import { API_URL } from "../../../config/config";
import axiosInstance from "../security/AxiosConfig";
import { databaseConnectionMessages } from "./DatabaseConnectionMessages";


// Import CSS, images, icons & static data


// Import fonts



//*********************************** MAIN FUNCTION ************************************** */
export async function GetLatestFlight(registration) {
  let responseObject = {};
  try {
    // Modify to retrieve flights by selectedAircraft.registration
    if (registration !== "SELECT") {
      // console.log("In FLightLog, GetFlightList, API_URL ->", `${API_URL}/flights/closedFlights?registration=${registration}`)
      const response = await axiosInstance.get(`${API_URL}/flights/latestClosedFlight?registration=${registration}`);
      responseObject.data = response.data;
      responseObject.databaseConnection = true;
          // console.log("In FlightLog, responseObject success -> ", responseObject); 
    //   setFlightList(responseObject.data);
      return responseObject;
    }
  } catch (error) {
    console.error('Error fetching flights', error);
    // let list = defaultFlightList.filter((flight) => flight.registration === registration);
    // setFlightList(list);
    // console.log("In FlightLog, flightList -> ", flightList)
    responseObject.data = databaseConnectionMessages.error;
    responseObject.databaseConnection = false;
    // setDatabaseConnection(false);
    return responseObject;
  }

}