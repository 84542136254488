// Import libraries
import React, {useEffect, useState} from 'react';

import {Box} from "@mui/material"
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {Checkbox} from '@mui/material';
import _ from "lodash";
// import { mobileThreshold } from '../../utilities/environment/EnvironmentData';
import dayjs from 'dayjs';
import { emptyNewPersonSubmission } from '../../utilities/database/GetRolePermissions';

// Import CSS & images



// ******************************* MAIN FUNCTION ************************************************ */
export default function CreateNewPersonDisplayList(props) {


    function NewPersonHandleChange(e) {

        var newPerson = e.target.id;
        var requestedCheckBoxValue = null
        if (e.target.checked) {
            requestedCheckBoxValue = true
        } else {
            requestedCheckBoxValue = false
        }
        props.UpdateNewPerson(newPerson, requestedCheckBoxValue)
    }

    useEffect(() => {
        RenderList(props.newPersonList, props.newPersonSubmission)
    },[props.newPersonList] )

    //************************************************************************************ */

    function RenderRow(newPerson) {
        // let name = newPerson.firstName + " " + newPerson.lastName;
        let name = newPerson.name;
        let registeredDate = dayjs(newPerson.registeredDate).format("DD MMM YYYY");

        if (props.newPersonSubmission) {
            return (
                <ListItem key = {name} component = "div">
                {/* <ListItem key = {name} component = "div" > */}
                    <ListItemText primary = {name} secondary = {`(${registeredDate})`}/>
                    <Checkbox
                        checked = {props.newPersonSubmission[name].acknowledged}
                        value = {props.newPersonSubmission[name].acknowledged}
                        // status = {newPerson.acknowledged}    
                        name = "acknowledgeCheckbox" id = {name}
                        disabled = {props.editing === false}
                        onChange = {e => NewPersonHandleChange(e)}>
                    </Checkbox>
                </ListItem>
            );
        }
    }

    //********************* MAIN RENDER ****************************** */
    if (props.newPersonList && props.newPersonSubmission) {
        let newPersonList = props.newPersonList;
        let newPersonSubmission = props.newPersonSubmission;

        return (
            <List
                height={400}
                width={"auto"}
            >
            {newPersonList.map((newPerson) => {
                return RenderRow(newPersonSubmission[newPerson.name])
            })}
            </List>
        );
    }

    //********************* ONGOING RENDER FUNCTION ****************************** */
    function RenderList(newPersonList, newPersonSubmission) {
        if (props.newPersonList && props.newPersonSubmission) {
            newPersonList = props.newPersonList;
            newPersonSubmission = props.newPersonSubmission;
                
            return (
                <List
                    height={400}
                    width={"auto"}
                    // itemSize={46}
                    // itemCount={defectListLength}
                    // overscanCount={5}
                >
                {newPersonList.map((newPerson) => {
                    return RenderRow(newPersonSubmission[newPerson.name])
                })}
                </List>
            )
        }
    }
}