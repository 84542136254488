// Import libraries
import {Box, TextField, Select } from "@mui/material";
import { FormControl, MenuItem, InputLabel, FormHelperText } from "@mui/material";
import { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
// import AdminCourseLessons from "./AdminCourseLessons.js";



// Import CSS, images, icons & static data
import "./Course.css"
import { aircraftComplexities } from "../aircraft/AircraftData";
import { aircraftTypes } from "../aircraft/AircraftData";
import { courseStatuses } from "../aircraft/AircraftData";
import { medicals } from "../aircraft/AircraftData";
import { mobileThreshold, mobileThresholdNarrow } from "../utilities/environment/EnvironmentData";
import { registeringAuthorities } from "../aircraft/AircraftData";
import courseIconSample from "./icons/courseIconSample.png"
import preRequisitesMet from "./icons/preRequisitesMet.png"
import PreRequisiteChips from "./PreRequisiteChips";








// ****************************** MAIN FUNCTION **********************************
export default function AdminCourseTable(props) {
    const [wideFieldClass, setWideFieldClass] = useState("course-field-wide");
    const [courseSummaryContainer, setCourseSummaryContainer] = useState("course-Summary-container");
    const [courseFieldsContainer, setCourseFieldsContainer] = useState("course-fields-container")
    const [courseIconClass, setCourseIconClass] = useState("course-icon-box");
    let iconURL = "";
    let preRequisiteIconURL = Object.values({preRequisitesMet})[0];
    // const [selectedAircraft, setSelectedAircraft] = useState(props.selectedAircraft);
        console.log("In AdminCourseTable, props.enrollmentSubmission->", props.enrollmentSubmission);

    var media = useMediaQuery(`(min-width:${mobileThreshold}px)`);
    var mediaNarrow = useMediaQuery(`(min-width:${mobileThresholdNarrow}px)`);
    // var iconURL = Object.values(props.course.icon)[0];
    // var iconURL = props.enrollmentSubmission.
    if (props.enrollmentSubmission.courseIcon) {
        iconURL = Object.values(props.enrollmentSubmission.courseIcon)[0];  
    } else {
        iconURL = Object.values({courseIconSample})[0];
    }



    useEffect(() => {
        if (media) {
            setCourseSummaryContainer("course-Summary-container")
            setCourseFieldsContainer("course-fields-container")
            setWideFieldClass("course-field-wide")
            setCourseIconClass("course-icon-box")
        } else {
            setCourseSummaryContainer("course-Summary-container-mobile")
            setCourseFieldsContainer("course-fields-container-mobile")
            setWideFieldClass("course-field-wide-mobile")
            setCourseIconClass("course-icon-box-mobile")
        }
    }, [media])
    
    
    let editing = props.editing;
  

        // console.log("In AdminCourseTable, props.enrollmentSubmission->", props.enrollmentSubmission)
  
    
    // maintenanceWindow = CalculateMaintenanceWindow(props.selectedAircraft);

    // useEffect(() => {
    //     if (props.selectedCourse.name === "NEW PLANE") {
    //         setNewPlane(true);            
    //     } else {
    //         setNewPlane(false)
    //     }
    // },[props.selectedAircraft.registration] )

    const registeringAuthorityList = registeringAuthorities.map((type) =>
    <MenuItem className = "select-options" selected = {false} key = {type} value = {type}>{type}</MenuItem>
    );

    const statusList = courseStatuses.map((type) =>
    <MenuItem className = "select-options" selected = {false} key = {type} value = {type}>{type}</MenuItem>
    );

    const aircraftComplexitiesList = aircraftComplexities.map((complexity) =>
        <MenuItem className = "select-options" selected = {false} key = {complexity} value = {complexity}>{complexity}</MenuItem>
    );

    const aircraftTypesList = aircraftTypes.map((type) =>
        <MenuItem className = "select-options" selected = {false} key = {type} value = {type}>{type}</MenuItem>
    );

    const medicalClassList = medicals.map((medicalClass) =>
        <MenuItem className = "select-options" selected = {false} key = {medicalClass} value = {medicalClass}>{medicalClass}</MenuItem>
    );

    return (
        <Box className = {courseSummaryContainer}>
            <Box className = {courseFieldsContainer}>
                <Box className = "course-subtitles">
                    Progress Summary
                <Box/>
                <Box >

                <Box/>
               
            </Box>
        </Box>
        </Box>
           {/* **************************** SECTION ********************************** */}
            <Box className = {courseFieldsContainer}>
                <Box className = "course-subtitles">
                    Prerequisites
                    <img className = {courseIconClass} src = {`${preRequisiteIconURL}`}/>
                    {/* Object.values({courseIconSample})[0]; */}
                </Box>
                <Box className = "course-field-row">
                    {/* <PreRequisiteChips /> */}
                </Box>
            </Box>
        </Box> 
    );
}   