//Import libraries
import { Box } from "@mui/system";
import React from 'react';
import { Button } from "@mui/material";
import { excludedForAircraftTypes } from "../../../utilities/maintenance/Aircraft";
import { DeleteFlight } from "../../../utilities/database/DeleteFlight";

// Import CSS, images, icons & static data



// Import fonts


//**************************** MAIN FUNCTION ***************************** */
export default function DepartureOpenFlightsButton(props) {

    function handleDelete() {
            // console.log("In DepartureOpenFlightsButton, handleDelete openFlight->", props.submission);
        props.DeleteOpenFlight(props.submission)
    }

        // console.log("In DepartureOpenFlightsButton, props.openFlightPressed->", props.openFlightPressed);
    if (props.openFlightPressed) {
        return (
            <Box className = "dep-details-row3">
                <Button 
                    className = "button-open-flight-delete" 
                    // disabled = {props.openButton === false} 
                    size = "medium" 
                    variant = "outlined" 
                    name = "deleteOpenFlight" 
                    color = "error"
                    onClick={handleDelete}>DELETE OPEN FLIGHT</Button>
            </Box>
        );
    } else {
        if (excludedForAircraftTypes.indexOf(props.submission.data.planeType.value) === -1) {
            return (
                <Box className = "dep-details-row3">
                    <Button 
                        className = "buttonOpen" 
                        disabled = {props.openButton === false} 
                        size = "medium" 
                        variant = "contained" 
                        color = "primary"
                        name = "flightOpen" 
                        onClick={props.DepartureOpen}>OPEN</Button>
                </Box>
            );
        }
    }
};


