import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import TextField from '../components/TextField';


// Import CSS, images, icons & static data
import { currentSchool } from '../../../../schoolDetails';
import azTechTheme from '../../../../../utilities/environment/ColourTheme';

function Copyright() {
  return (
    <React.Fragment>
      {'© '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
    </React.Fragment>
  );
}

const backgroundColour = azTechTheme.palette.blanchedAlmond.rgb;

const iconStyle = {
  width: 48,
  height: 48,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'warning.main',
  mr: 1,
  '&:hover': {
    bgcolor: 'warning.dark',
  },
};

const LANGUAGES = [
  {
    code: 'en-US',
    name: 'English',
  },
  {
    code: 'fr-FR',
    name: 'Français',
  },
];

export default function AppFooter() {
  return (
    <Typography
      component="footer"
      sx={{ display: 'flex', bgcolor: backgroundColour}}
    >
      <Container sx={{ my: 8, display: 'flex' }}>
        <Grid container spacing={5}>
          <Grid item xs={6} sm={4} md={3}>
            <Grid
              container
              direction="column"
              justifyContent="flex-end"
              spacing={2}
              sx={{ height: 140 }}
            >
              <Grid item sx={{ display: 'flex' }}>

              </Grid>
              <Grid item sx = {{mt: "20px", fontSize: "large"}}>
                <Box>{currentSchool.name}</Box>
                <Box>{currentSchool.addressLine1}</Box>
                <Box>{currentSchool.addressLine2}</Box>
                <Box>{currentSchool.addressLine3}</Box>
                <Box>{currentSchool.city}</Box>
                <Box>{currentSchool.state}</Box>
                <Box>{currentSchool.postCode}</Box>
                <Box>{currentSchool.phone}</Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}
