//Import libraries
import React, {useState, useEffect}  from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { useAuthFirebase } from '../utilities/security/AuthContextFirebase';
import { HasPermission }  from '../utilities/security/HasPermission';

//Import CSS, images, icons & static data
import { rolesTemplate } from '../utilities/database/GetRolePermissions';



// ******************************* MAIN FUNCTION ************************************************
export default function ReportsMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // ***************** SECURITY - CURRENT UER CONTEXT AND ROLE **********************
  const {authUser, loading, signInWithGoogle, signOut} = useAuthFirebase();
  var currentUserRoles = props.currentUserRoles
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
 
  const handleClose = (path) => {
    setAnchorEl(null);
    props.handleNavigate(path)
  };

  return (
    <span>
      <Button
        id="reports"
        aria-controls={open ? 'reports' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        color = "inherit"
        disabled = {!(HasPermission("camoLog", currentUserRoles) || HasPermission("pilotLog", currentUserRoles) || HasPermission("flightLog", currentUserRoles))} 
      >
        Reports
      </Button>
      <Menu
        MenuListProps={{
          'aria-labelledby': 'reports',
        }}
        id = "reportsMenu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        TransitionComponent={Fade}
      >
        <MenuItem 
          id = "flightLot"
          disabled = {!HasPermission("flightLog", currentUserRoles)}
          onClick={() => handleClose("/flightLog")}>Flight Log</MenuItem>
        <MenuItem 
          id = "camoLog"
          disabled = {!HasPermission("camoLog", currentUserRoles)} 
          onClick={() => handleClose("/camoLog")}>CAMO Log</MenuItem>
        {/* <MenuItem onClick={props.handleMenuClick("/camoLog")}>CAMO Log</MenuItem> */}
        <MenuItem 
          id = "pilotLog"
          disabled = {!HasPermission("pilotLog", currentUserRoles)} 
          onClick={() => handleClose("/pilotLog")}>Pilot Log</MenuItem>
      </Menu>
    </span>
  );
};