// Import libraries


// Import CSS, images, icons & static data
import { background } from "../flights/flightData/FlightData";

// ****************************** MAIN FUNCTION ********************************
export default function MarkErrorsAndWarnings(submission, validationResultList, field, calledFrom) {
    // Update the status of each field with an issue   
    validationResultList.forEach((result) => {
        if (result !== {}) {
            // Create a standard field background and variant
            let backgroundTmp = background.normal;
            let variantTmp = "filled"
            if (calledFrom === "aircraftAdmin") {
                variantTmp = "outlined";
            }

            // Set the background and variant for the fields related to the current issue
            if (result.status === "error" || result.status === "warning") {
                if (calledFrom !== "aircraftAdmin") {
                    variantTmp = "outlined";
                }
                if (result.status === "error") {
                    backgroundTmp = background.error;
                } else {
                    backgroundTmp = background.warning;
                }

                // Update the status of each field within an issue
                let submissionField = "";
                // Field 1
                submissionField = submission[result.field1];
                    console.log("In Reducer, setting field1, result.field1 -> ", result.field1,"calledFrom->", calledFrom);
                if (result.field1 !== "" && calledFrom !== "aircraftAdmin") {
                    submission[result.field1] = {
                        value: submissionField.value,
                        status: result.status,
                        background: backgroundTmp,
                        variant: variantTmp, 
                        reset: submissionField.reset,
                        requiredOpen: submissionField.requiredOpen,
                        requiredSubmit: submissionField.requiredSubmit,
                    }
                } else if (result.field1 !== "") {
                    if (result.field1.indexOf("oilLevels") === -1) {
                        console.log("In MarkErrorsAndWarnings, result.field1->", result.field1)
                    submission[result.field1] = {
                        value: submissionField.value,
                        status: result.status,
                        background: backgroundTmp,
                        variant: variantTmp, 
                        reset: submissionField.reset,
                        required: submissionField.required,
                    }
                    } else {
                        let oilLevel = result.field1.slice(-1);
                        submission.oilLevels[oilLevel] = {
                            value: submission.oilLevels[oilLevel].value,
                            status: result.status,
                            background: backgroundTmp,
                            variant: variantTmp, 
                            reset: submission.oilLevels[oilLevel].reset,
                            required: submission.oilLevels[oilLevel].required,
                        }
                            console.log("In MarkErrorsAndWarnings, result.field1->", result.field1, "backgroundTmp->",backgroundTmp ,"level->", oilLevel)
                    }
                    
                }   
                // Fields 2 - 4
                    // console.log("In Reducer, Just before the For Loop, result", result);
                
                for (let i = 2; i < 5; i++) {
                    field = `field${i}`
                    let resultField = result[field];
                    if (result[field] !== "") {
                        if (calledFrom !== "aircraftAdmin") {
                            // console.log("In Reducer, For Loop, backgroundTmp, variantTmp ->", backgroundTmp, variantTmp)
                            // console.log("In Reducer, For Loop, field, result, result[field], result.status -> ", field, result, result[field], result.status);
                            submission[resultField] = {
                                value: submission[resultField].value,
                                status: result.status,
                                background: backgroundTmp,
                                variant: variantTmp,
                                reset: submission[resultField].reset,
                                requiredOpen: submission[resultField].requiredOpen,
                                requiredSubmit: submission[resultField].requiredSubmit,
                            }
                        } else {
                            if (result[field].indexOf("oilLevels") === -1) {
                                submission[resultField] = {
                                    value: submission[resultField].value,
                                    status: result.status,
                                    background: backgroundTmp,
                                    variant: variantTmp,
                                    reset: submission[resultField].reset,
                                    required: submission[resultField].required,
                                }
                            } else {
                                let oilLevel = result[field].slice(-1);
                                submission.oilLevels[oilLevel] = {
                                    value: submissionField.value,
                                    status: result.status,
                                    background: backgroundTmp,
                                    variant: variantTmp, 
                                    reset: submissionField.reset,
                                    required: submissionField.required,
                                }
                                    console.log("In Reducer, For Loop, oilLevel->",oilLevel,"submission->", submission)
                            }
                        }
                    }   
                        
                }
                
            }
        }
    });
    return submission
}