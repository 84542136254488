// Import libraries
import * as React from 'react';
import {useState, useEffect} from 'react';
import {Box} from "@mui/material"
import List from '@mui/material/List';
import { FixedSizeList } from 'react-window';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Create } from '@mui/icons-material';
import ScrollingArrow from './ScrollingArrow';

// Import CSS & images


// ******************************* MAIN FUNCTION ************************************************
export default function MaintenanceTable3(props) {
   
        // console.log(props.selectedAircraft);
    const [selectedAircraft, setSelectedAircraft] = useState(props.selectedAircraft);
    const [defectListLength, setDefectListLength] = useState(props.selectedAircraft._defects.length)

    useEffect(() => {
        setSelectedAircraft(props.selectedAircraft);
        setDefectListLength(props.selectedAircraft._defects.length);
        // CreateDefectDisplayList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedAircraft]);
     
    function CreateDefectDisplayList(props) {
        const {style, index} = props;
        return (     
            <ListItem  style={style} key = {index} component="div">  
                <ListItemText primary={selectedAircraft._defects[index].details}/>
            </ListItem>
            );
        };
  

    return (
        <Box>
            <Box className = "aircraft-label" sx = {{ml: "0px", mt: "7px"}}>
                Open Defects
            </Box>
            <Box className = "maintenance-defects-frame">
                <FixedSizeList
                    dense={false}
                    height={67}
                    width={345}     
                    itemSize={38}
                    itemCount={defectListLength}
                    overscanCount={1000}
                    sx={{pt: "2px"}}
                >
                    {CreateDefectDisplayList}
                </FixedSizeList>
                <ScrollingArrow defectListLength = {defectListLength}/>
            </Box>
        </Box>
    );
}