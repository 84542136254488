// Import libraries
import DaysToNextService from "./DaysToNextService";
// import azTechTheme from "../environment/ColourTheme";

// Import CSS, images, icons & static data


// **************************** MAIN FUNCTION **********************
function CalculateMaintenanceWindow(selectedAircraft) {
        // console.log("In CalculatedMaintenanceWindow, props.selectedAircraft->", selectedAircraft);
    let maintenanceWindow = {
        colours: {},
        serviceDaysRemaining: 0,
        serviceHoursRemaining: 0
    }
    let registration = selectedAircraft._registration;
    let serviceDaysRemaining = DaysToNextService(selectedAircraft)
    let serviceHoursRemaining = (selectedAircraft._nextServiceHours - selectedAircraft._currentHours);
    // Round down to the nearest 1 decimal place
    serviceHoursRemaining = Math.floor(serviceHoursRemaining * 10) / 10;    

    var colours = {
        days: "",
        hours: ""
    }
    const thresholds = {
        days: 10,
        hours: 10
    }
        // console.log("In CalculateMaintenanceColours, registration->", registration)
    if (registration === "NEW PLANE" ||registration === "SELECT"  ) {
        colours.hours = "primary";
        colours.days = "primary";
    } else {
        if (serviceHoursRemaining <= 0 || serviceHoursRemaining.isNaN || serviceHoursRemaining === undefined) {
            colours.hours = "error";
        } else if (serviceHoursRemaining < thresholds.hours) {
            colours.hours = "warning";
        } else {
            colours.hours = "success";
        }
            // console.log("In CalculateMaintenanceColours, colours->", colours)

        if (serviceDaysRemaining <= 0 || serviceDaysRemaining.isNaN || serviceDaysRemaining === undefined) {
            colours.days = "error";
        } else if (serviceDaysRemaining < thresholds.days) {
            colours.days = "warning";
        } else {
            colours.days = "success";
        }
    }
          
    maintenanceWindow.colours.days = colours.days;
    maintenanceWindow.colours.hours = colours.hours;
    maintenanceWindow.serviceDaysRemaining = serviceDaysRemaining;
    maintenanceWindow.serviceHoursRemaining = serviceHoursRemaining;
        // console.log("In CalculatedMaintenanceWindow, maintenanceWindow->", maintenanceWindow);
    return maintenanceWindow;

}

export {CalculateMaintenanceWindow}