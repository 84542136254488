

var columnGroups = 
  [
    {
        groupId: 'FLIGHT DETAILS',
        children: [{ field: 'id'},
                    {field: 'dateOfFlight'}, 
                    {field: 'registration'},
                    {field: 'planeType'},
                    {field: 'from'},
                    {field: 'to'},
                    {field: 'pilot'}]
    },
    {
        groupId: 'BLOCK TIME',
        children:   [{field: 'offBlocks'}, 
                  {field: 'onBlocks'}, 
                  {field: 'blockTime'}]
    },
    {
        groupId: 'ROLES',
        children:  [{field: 'pilotInCommand'}, 
                    {field: 'pilotUnderTraining'},
                    {field: 'multiPilot'},
                    {field: 'instructor'},]
    },
    {
        groupId: 'TYPE OF AIRCRAFT',
        children:  [{field: 'sepTime'}, 
                    {field: 'mepTime'},
                    {field: 'jetTime'}, 
                    {field: 'simulator'},]
    },
    {
        groupId: 'ROUTE DETAILS',
        children: [{field: 'instrumentTime'}, 
                   {field: 'nightTime'},
                   {field: 'landings' }, 
                   {field: 'nightLandings'}, 
                   {field: 'flightDetails'}]
    },
  ]
    export {
      columnGroups
    };


 