// Import libraries 
import {useState} from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useMediaQuery } from "@mui/material";
import FormLabel from '@mui/material/FormLabel';

// Import CSS, images, icons, static data & fonts
import { mobileThresholdWide } from '../../utilities/environment/EnvironmentData.js';

//***************************** MAIN FUNCTION ***************************** */
export default function CamoLogRadio(props) {
  var media = useMediaQuery(`(min-width:${mobileThresholdWide}px)`);

    const handleChange = (event) => {props.handleChange(event)};

    if (props.calledFrom === "CamoLog" && !media){
        return (
            <FormControl>
                <RadioGroup
                    color = "primary" 
                    name = "camoLogPeriod"
                    onChange = {handleChange}
                    value = {props.camoLogPeriod}
                    row>
                        <FormControlLabel value = "daily" control = {<Radio />} label = "Daily" />
                        <FormControlLabel value = "weekly" control = {<Radio />} label = "Weekly" />
                        <FormControlLabel value = "monthly" control = {<Radio />} label = "Monthly" />
                </RadioGroup>
            </FormControl>
        );
    }
}