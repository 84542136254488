// Import libraries
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { Box } from '@mui/system';

import azTechTheme from '../../utilities/environment/ColourTheme';
import { calendarFormat } from 'moment';

// Import CSS, images, icons & static data



//**************************** MAIN FUNCTION ******************************** */

export const PersonSelect = (props) => {

    let people = [];
    let calledFrom = props.calledFrom;
        // console.log("In PersonSelect, calledFrom->", calledFrom);
    if (props.personList) {
            // console.log("In PersonSelect, props.personList->", props.personList)
        people = props.personList;
    }
        // console.log("In PersonSelect, people->", people)

    function HandleSelect(e) {
        switch(calledFrom) {
            case "PilotLog":
                props.HandleSelect(e);
                    // console.log("In PersonSelect, HandleSelect, e.target.textContent->", e.target.textContent)
            break;
        case "AdminPerson":
            props.HandleSelect(e.target.textContent)
                // console.log("In PersonSelect, HandleSelect, e.target.textContent->", e.target.textContent)
        break;
        }
    }
    
        // console.log("In PersonSelect, people->", people);
    const options = people.map((option) => {
        if (option.name !== "") {
                // console.log("In PersonSelect, option->", option);
            let firstLetter = option.name[0].toUpperCase();
            let firstSpace = option.name.indexOf(" ");
                // console.log("In PersonSelect, firstSpace->", firstSpace);
            let firstLetterOfLastName = firstSpace + 1; 
            if (firstSpace !== -1) {
                firstLetter = option.name[firstLetterOfLastName]
                    // console.log("In PersonSelect, firstLetterOfLastName->", firstLetterOfLastName, " is...", firstLetter);
            }

                // console.log("In PersonSelect, firstLetter->", firstLetter);
        
            return {
                firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
                ...option,
                // name: person.name,
            };
        }
    });

        // console.log("In PersonSelect, options->",options);

    switch(calledFrom) {
        case "ChampionNuts":
            return (
                <Box>
                    <Box className = "person-label-banner">Person</Box>
                    <Autocomplete
                        id="personSearch" 
                        options = {options.sort((a, b) => -b.firstLetter.localeCompare(a.lastName))}
                        isOptionEqualToValue = {(option, value) => option.lastName === value.lastName}
                        groupBy = {(option) => option.firstLetter}
                        getOptionLabel = {(option) =>  option.name}
                        sx = {{width: 300}}
                        renderInput={(params) => 
                            <TextField {...params} label="SELECT"/>}
                        // onChange={e => HandleSelect(e)} 
                        onSelect={(e => HandleSelect(e))}
                        color = {azTechTheme.blueBright}
                    />
                </Box>
            );

        case "AdminPerson":
        case "PilotLog":
        case "CourseMain":
            return (
                <Box>
                    <Box className = "person-label-banner">Person</Box>
                    <Autocomplete
                        id="personSearch" 
                        options = {options.sort((a, b) => -b.firstLetter.localeCompare(a.lastName))}
                        isOptionEqualToValue = {(option, value) => option.lastName === value.lastName}
                        groupBy = {(option) => option.firstLetter}
                        getOptionLabel = {(option) =>  option.name}
                        sx = {{width: 300}}
                        renderInput={(params) => 
                            <TextField {...params} label="SELECT"/>}
                        onChange={e => HandleSelect(e)} 
                        // onSelect={(e => HandleSelect(e))}
                        color = {azTechTheme.blueBright}
                    />
                </Box>
            );
    }

}