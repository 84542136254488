const daily = [
  {
    field: 'id',
    headerName: 'sort Key',
    headerAlign: "left",
    align: "left",
    width: 60,
    type: 'number',
    editable: false,
    visibility: false,
    sort: "asc"
  },
  {
    field: 'date',
    headerName: 'Day',
    headerAlign: "left",
    align: "left",
    width: 120,
    type: 'text',
    editable: false,
    visibility: true,
    // sort: "asc"
  },
  {
    field: 'flights',
    headerName: 'Flights',
    headerAlign: "left",
    align: "left",
    width: 120,
    type: 'number',
    editable: false,
    visibility: true,
  },
  {
    field: 'flightTime',
    headerName: 'Flight Time',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 150,
    editable: false,
    visibility: true,
  },
];


const weekly = [
  {
    field: 'id',
    headerName: 'sort Key',
    headerAlign: "left",
    align: "left",
    width: 60,
    type: 'number',
    editable: false,
    visibility: false,
    sort: "asc"
  },
  {
    field: 'date',
    headerName: 'Week',
    headerAlign: "left",
    align: "left",
    width: 120,
    type: 'text',
    editable: false,
    visibility: true,
    // sort: "desc"
  },
  {
    field: 'flights',
    headerName: 'Flights',
    headerAlign: "left",
    align: "left",
    width: 120,
    type: 'number',
    editable: false,
    visibility: true,
  },
  {
    field: 'flightTime',
    headerName: 'Flight Time',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 150,
    editable: false,
    visibility: true,
  },
];

export const monthly = [
  {
    field: 'id',
    headerName: 'sort Key',
    headerAlign: "left",
    align: "left",
    width: 60,
    type: 'number',
    editable: false,
    visibility: false,
    sort: "asc"
  },
  {
    field: 'date',
    headerName: 'Month',
    headerAlign: "left",
    align: "left",
    width: 120,
    type: 'text',
    editable: false,
    visibility: true,
    // sort: "desc"
  },
  {
    field: 'flights',
    headerName: 'Flights',
    headerAlign: "left",
    align: "left",
    width: 120,
    type: 'number',
    editable: false,
    visibility: true,
  },
  {
    field: 'flightTime',
    headerName: 'Flight Time',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 150,
    editable: false,
    visibility: true,
  },
];

const camoLogColumns = {
  daily,
  weekly,
  monthly
}


export {camoLogColumns}