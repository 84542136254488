// Import libraries
import React, { useEffect, useState } from "react";
// import flightTimeSVCDifferenceTolerance from "../flightData/FlightData.js";

// Import CSS, images & icons
import  "./Arrival.css";


const tolerance = (6.5/60);

export {tolerance};


// ************** MAIN FUNCTION *******************
export function FlightTimeSVCAlignment (props) {


    
    // Calculate the difference between flight time and SVC Difference
    const [flightTimeSVCDifference, setFlightTimeSVCDifference] = useState(Math.abs(props.flightTime - props.svcDifference));
    useEffect(()=> {
    setFlightTimeSVCDifference(Math.abs(Number(props.flightTime) - Number(props.svcDifference)));
    // Compare the difference (flight time vs SVC Difference) to the tolerance
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

        // console.log("In FlightTimeSVCAlignment, props.submission->", props.submission);

    let excludedForAircraftType = ["SIM"];
    if (excludedForAircraftType.indexOf(props.submission.data.planeType.value) === -1  && props.submission.data.registration.value !== "GFOOJ") {

        if (Number(props.flightTime) === 0 ||
            ((Number(props.submission.data.svcOff.value) === 0) &&
            (Number(props.submission.data.svcOn.value) === 0)) || 
            flightTimeSVCDifference === undefined || 
            isNaN(Number(flightTimeSVCDifference))) {
                // console.log("FlightTimeSVCAlignment, props.flightTime->", props.flightTime);
            if (Number(props.flightTime) === undefined) {
                <div className = "noteBlue" >Flight Time: 0.0</div>
            } else {
            return (
                <div className = "noteBlue" >Flight Time: {Number(props.flightTime).toFixed(1)}</div>
            )}
        } else {

            // If the flight time and SVC Time Difference is a number greater than zero then render to the screen
            if (flightTimeSVCDifference > tolerance) {
                    // console.log("In FsvcDiff, tolerance ->", tolerance)
                return (
                    <div className = "noteError" >Flight Time & SVC out of line: {(flightTimeSVCDifference*60).toFixed(0)} mins difference</div>
                )
            } else {
                    // console.log("In FsvcDiff, tolerance ->", tolerance)
                return (
                    <div className = "noteSuccess" >Flight Time & SVC in line: {(flightTimeSVCDifference*60).toFixed(0)} mins difference</div>
                );
            }
        }
    }
}