// Import Libraries
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import firebase from 'firebase/compat/app';
import { getAuth } from 'firebase/auth';
import 'firebase/compat/auth';
// import axios from 'axios';
import { fireBaseConfig, firebaseTenantId } from '../../../config/config';
// import { RoleLookUp } from '../database/GetRoles';
// import { getIdTokenResult } from 'firebase/auth';


firebase.initializeApp(fireBaseConfig);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);


const AuthContextFirebase = createContext();


// ******************** MAIN FUNCTION ******************************************
export const AuthProviderFirebase = ({ children }) => {
  const [user, loading] = useAuthState(firebase.auth());
  const [authUser, setAuthUser] = useState(user);
  const auth = getAuth();
  auth.tenantId = firebaseTenantId;

  useEffect(() => {
    setAuthUser(user);
  }, [user]);

  const signInWithGoogle = async () => {
    const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
    googleAuthProvider.setCustomParameters({ prompt: 'select_account' });
    await firebase.auth().signInWithPopup(googleAuthProvider);
  };

  const signOut = async () => {
    await firebase.auth().signOut();
    // await gapi.auth2.getAuthInstance().signOut()
  };

  // IdTokenResult contains all the claims for a user
  const refreshAuthToken = async () => {
    if (authUser) {
      try {
        const refreshedToken = await authUser.getIdTokenResult(true);
          console.log('Token refreshed:', refreshedToken, "getIdTokenResult->",authUser.getIdTokenResult());
      } catch (error) {
        console.error('Error refreshing token:', error);
      }
    }
  };

  useEffect(() => {
      const tokenRefreshInterval = setInterval(() => {
          refreshAuthToken();
        }, 30 * 60 * 1000); // Refresh token every 30 minutes
    return () => clearInterval(tokenRefreshInterval);
    
  }, [authUser]);


  // useEffect(() => {
    // Function to handle periodic reauthentication

  return (
    <AuthContextFirebase.Provider value={{ authUser, loading, signInWithGoogle, signOut }}>
      {children}
    </AuthContextFirebase.Provider>
  );
};

export const useAuthFirebase = () => {
  return useContext(AuthContextFirebase);
};

export const reAuthenticate = () => {
  const handleReauthentication = () => {
        // Check if the user is signed in
        const user = firebase.auth().currentUser;
        if (user) {
          // Perform reauthentication (e.g., by reauthenticating with the same provider)
          const credential = firebase.auth.GoogleAuthProvider.credential(user.idToken);
          user.reauthenticateWithCredential(credential)
            .then(() => {
              // Reauthentication successful
              console.log("Reauthentication successful");
            })
            .catch((error) => {
              // Reauthentication failed
              console.error("Reauthentication failed", error);
             
            });
        }
      };
  
      // Set up a timer to trigger reauthentication periodically (e.g., every 30 minutes)
      const reauthenticationTimer = setInterval(() => {
        handleReauthentication();
      }, 0.25 * 60 * 1000); // 15sec in milliseconds
  
      // Clean up the timer when the component unmounts
      return () => {
        clearInterval(reauthenticationTimer);
      };
  };
    // }, [user]);
