// Import libraries
import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import {ClickAwayListener, Popper} from '@mui/material';
import { Box } from '@mui/system';
import { HasPermission } from '../utilities/security/HasPermission';

// Import icons
import MenuIcon from '@mui/icons-material/Menu';

// ******************************* MAIN FUNCTION ************************************************
export default function MenuMiniMobileLogs(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [anchorRef, setAnchorRef] = React.useRef(null);
  const open = Boolean(anchorEl);
    
  var currentUserRoles = props.currentUserRoles;
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
 
  const handleClose = (path) => {
    setAnchorEl(null);
    props.handleNavigate(path)
  };

  return (
    <Box>
        <MenuIcon
          id = "mobileMenuIcon"
          aria-controls={open ? 'menuMiniMobile' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick = {handleClick}
          color = "inherit" 
          sx = {{marginTop: '15px', marginLeft: "15px", verticalAlign: "middle"}}
        >
        </MenuIcon>
        <Menu
          id="menuMiniMobileLogs"
          // MenuListProps={{'aria-labelledby': 'mobileMenuIcon'}}
          anchorEl={anchorEl}
          open={open}
          onClose={() => handleClose()}
          TransitionComponent={Fade}

        >
          {/* <ClickAwayListener onClickAway={handleClose}> */}
            <MenuItem disabled = {!HasPermission("techLog", currentUserRoles)} onClick = {() => handleClose("/techLog")}>Tech Log</MenuItem>
            <MenuItem disabled = {!HasPermission("flightLog", currentUserRoles)} onClick = {() => handleClose("/flightLog")}>Flight Log</MenuItem>
            <MenuItem disabled = {!HasPermission("camoLog", currentUserRoles)} onClick = {() => handleClose("/camoLog")}>CAMO Log</MenuItem>
            <MenuItem disabled = {!HasPermission("pilotLog", currentUserRoles)} onClick = {() => handleClose("/pilotLog")}>Pilot Log</MenuItem>
          {/* </ClickAwayListener> */}
      </Menu>
    </Box>
  );
};