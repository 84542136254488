export const flightValidationMessages = {
    // ********** ERROR & WARNING MESSAGES **********
    planeSelectionMessage: "PLEASE SELECT AN AIRCRAFT",
    missingMandatoryField: "ONE OR MORE REQUIRED FIELDS ARE MISSING",
    zeroAwayLandingField: "1ST AWAY LANDING QUANTITY SHOULD BE: 0",
    timeErrorMessages: {
        blockDifference: "OFF BLOCKS TIME IS AFTER ON BLOCKS TIME",
        flightTime: "TAKE OFF TIME IS AFTER LANDING TIME",
        svcTime: "SVC OFF IS AFTER SVC ON",
        offBlockAfterTakeOff: "OFF BLOCKS TIME IS AFTER TAKE OFF TIME",
        landingTimeAfterOnBlockTIme: "LANDING TIME IS AFTER ON BLOCKS TIME",
        svcFlightTimeAlignment: "FLIGHT TIME AND SVC TIME ARE OUT OF LINE",
        
    },

    fuelErrorMessages: {
        departureStatus: "DEPARTURE FUEL IS LESS THAN UPLIFT",
        arrivalStatus: "ARRIVAL FUEL IS LESS THAN UPLIFT",
        overCapacity: "FUEL LEVEL GREATER THAN TANK CAPACITY",
        negativeFuelUsed: "FUEL USED IS NEGATIVE",
    },

    warningMessages: {
        dateInPast: "DATE IS IN THE PAST...    CONTINUE?",
        dateInFuture: "DATE IS IN THE FUTURE...    CONTINUE?",
        update: "ARE YOU SURE YOU WANT TO UPDATE THIS FLIGHT... CONTINUE?",
        delete: "ARE YOU SURE YOU WANT TO DELETE THIS FLIGHT... CONTINUE?",
    },
    successMessages: {
        clear: "FLIGHT CLEARED",
        departure: "FLIGHT SUCCESSFULLY OPENED",
        arrival: "FLIGHT SUCCESSFULLY SUBMITTED",
        update: "FLIGHT SUCCESSFULLY UPDATED",
        delete: "FLIGHT SUCCESSFULLY DELETED"
    }
}