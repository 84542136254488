//Import libraries
import { Box } from "@mui/system";
import React, {useState, useEffect} from 'react';
import { FormControl, MenuItem, InputLabel, Select, TextField } from "@mui/material";
import _ from "lodash";
import { GetAuthoritiesList } from "../database/GetAuthoritiesList";
import { SortObjectList } from "../numbersAndStrings/SortObjectList";

// Import CSS, images, icons & static data


// Import fonts


//**************************** MAIN FUNCTION ***************************** */
export default function AddApprovalFields(approvingAuthorities, HandleAuthorityChange, HandleApprovalDateChange) {
  
        // console.log("In AddApprovalFields, approvingAuthorities->", approvingAuthorities);  
  
    let approvingAuthoritiesDisplayList = approvingAuthorities.map((authority) =>
        <MenuItem 
            className = "select-options" 
            selected = {false} 
            key = {authority.id} 
            value = {authority}
            >
                {authority.name} - {authority.country}
        </MenuItem>
    );

        // console.log("In AddApprovalFields, approvingAuthoritiesDisplayList->", approvingAuthoritiesDisplayList);

    return (
        <Box sx = {{mt: "20px"}}>
            <FormControl>
                <InputLabel>Authority</InputLabel>
                    <Select className = "admin-course-select-field-approval" label = "Authority"
                        // InputProps = {{STYLE: props.courseSubmission.newApproval.background}}
                        // InputLabelProps={{ shrink: true }}
                        type = "text" name = "addApproval" id="addApproval" fullWidth
                        defaultValue = {""}
                        onChange={e => HandleAuthorityChange(e.target.value)} 
                        >
                        {approvingAuthoritiesDisplayList}
                    </Select>
            </FormControl>
            <TextField className = "admin-course-date-field-approval" label = "Approval Date" 
                // InputProps = {{ inputProps: {STYLE: props.courseSubmission.deprecatedDate.background}}}
                sx = {{minHeight: "35px", padding: "5px"}}
                type = "date" size = "normal" timezone = "UTC" name = "newApprovalDate" id="newApprovalDate" fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={e => HandleApprovalDateChange(e.target.value)} 
                >
            </TextField>
        </Box>
    );
};

