//Import libraries
import { Box } from "@mui/system";
import React, {useEffect, useState} from 'react';
import CircularProgressWithValue from "../utilities/feedback/CircularProgressWithValue";
// import LinearProgressWithValue from "../utilities/feedback/LinearProgressWithValue";
import { GetCurrentUser } from "../utilities/security/GetCurrentUser";
import LinearProgressWithValue from "../utilities/feedback/LinearProgressWithValue";
import CalculateCourseProgress from "./CalculateCourseProgress";
import { Chip } from "@mui/material";
import { GetPerson } from "../utilities/database/GetPerson";
import { GetEnrollments } from "../utilities/database/GetEnrollments";
// import { UpdateDatabaseConnection, UpdateResult } from "../utilities/UpdateFunctions";
import _ from "lodash";

import { Update } from "@mui/icons-material";
import { errorMessages } from "../utilities/validation/PersonValidation";
// import { UpdateDatabaseConnection } from "../utilities/UpdateFunctions";


// Import CSS, images, icons & static data



// Import fonts


//**************************** MAIN FUNCTION ***************************** */
export default function CourseProgress(props) {
    // const [enrollments, setEnrollments] = useState([]);
    let totalEnrollments = 0;
    let responseObject = {};
    let enrollments = props.enrollments;
        // console.log("In CourseProgress, props.authUser->", props.authUser);
    let chosenRole = props.chosenRole;
    let email = "";
    // let id = null;
    // const [userId, setUserId] = useState(props.userId);
    let userId = props.userId;
    let courseName = props.courseStaticName;
        // console.log("In CourseProgress, props.authUser->", props.authUser);
        // console.log("In CourseProgress, courseName->", courseName);

    // useEffect(() => {
    //     const GetUserEnrollments = async (chosenRole, id) => {
    //             console.log("In CourseProgress, GetEnrollments, id->", id);
        
    //         responseObject = await GetEnrollments(chosenRole, id)
    //             if (responseObject.databaseConnection) { 
    //                 setEnrollments(responseObject.data);
    //             } else {
    //                 props.UpdateDatabaseConnection(responseObject.databaseConnection);
    //             }
    //         }
    //             console.log("In CourseProgress, responseObject.data ->", responseObject.data);
    //             console.log("In CourseProgress, GetEnrollments, userId->", userId);
        
    //     if (userId !== null && userId !== undefined) {
    //         // if (typeof id.then !== "function") {
    //                 // console.log("In CourseProgress, GetEnrollments, id->", id);
    //                 console.log("In CourseProgress, GetEnrollments, userId->", userId);
    //             switch(chosenRole) {
    //                 case "student":
    //                 case "instructor":
    //                     GetUserEnrollments(chosenRole, userId);
    //                         console.log("In CourseProgress, responseObject.data ->", responseObject.data);
    //                         // totalEnrollments = responseObject.data.length;
    //                 break;
    //                 case "admin":
    //                     GetUserEnrollments(chosenRole);
    //                         // console.log("In CourseProgress, responseObject.data ->", responseObject.data);
    //                         if (responseObject.databaseConnection) {
    //                             totalEnrollments = responseObject.data.length;
    //                         }
    //             // }
    //         }
    //     }
    // }, [props.authUser, chosenRole])

    if (props.authUser !== null) {
            // console.log("In CourseProgress, props.chosenRole->", props.chosenRole);
        switch(props.chosenRole) {
            case "student":
                let progress = 0
                progress = CalculateCourseProgress(enrollments, props.courseStatic);
                if (progress > 0) {
                    if(props.progressType === "line") {
                        return (
                            <Box>
                                <LinearProgressWithValue
                                    progress = {progress}
                                />
                            </Box>
                        );
                    } else {
                        return (
                            <Box>
                                <CircularProgressWithValue
                                    progress = {progress}
                                />
                            </Box>
                        );
                    }
                }
            break;
            case "instructor":
            case "admin":
                if (totalEnrollments > 0) {      
                    return (
                        <Box>
                            <Chip label = {totalEnrollments} color = "primary"/>
                        </Box>
                    );
                }
            break;
            default:
                // console.log("Error rendering Progress - NO CURRENT USER")
            break;
        } 
    }
};



