// Import libraries
import React, {useState, useEffect} from "react";
import { TextField } from "@mui/material";

// Import CSS & images
import '../TechLog.css';

// Import fonts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


// ******************************* MAIN FUNCTION ************************************************
export default function OilUplift(props) {

    let sim = false;

    if (props.selectedAircraft._planeType === "SIM") {
        sim = true
    } else {
        sim = false
    }

    if (props.selectedAircraft._numberOfEngines === 2) {
        var nameAndId = `oilUplift${props.engine}`;
        return (
            <TextField 
                STYLE = {props.submission.data.background} 
                value = {props.submission.data[nameAndId].value} 
                InputProps={{ inputProps: { min: 0 }}} 
                type = "number" 
                error = {props.submission.data[nameAndId].status === "error"} 
                variant = {props.submission.data[nameAndId].variant} 
                fullWidth 
                name = {nameAndId} 
                id = {nameAndId} 
                disabled = {sim === true}
                onChange = {e => props.TechLogHandleChange(e.target)}/>
        );
    } else {
        if (props.engine === "Left") {
            return (
                <TextField 
                    STYLE = {props.submission.data.oilUpliftLeft.background} 
                    value = {props.submission.data.oilUpliftLeft.value} 
                    InputProps={{ inputProps: { min: 0 }}} 
                    type = "number" 
                    error = {props.submission.data.oilUpliftLeft.status === "error"} 
                    variant = {props.submission.data.oilUpliftLeft.variant} 
                    fullWidth 
                    disabled = {sim === true}
                    name = "oilUpliftLeft" 
                    id = "oilUpliftLeft" 
                    onChange = {e => props.TechLogHandleChange(e.target)}/>
            );
        } else {
            return (
                <></>
            );}
    };


}