import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';





export default function FlightTimeSlider(props) {
  var displayWidth = 200
  var width = props.width * 60;
  var flightType = props.type;
  let blockDifferenceDisplay = 0
  const [value, setValue] = useState(0)
    
    


  if (props.blockDifferenceDisplay === undefined) {
    blockDifferenceDisplay = 0;
  } else {
    blockDifferenceDisplay = props.blockDifferenceDisplay;
  }
  let disabled = true;
  if (props.blockDifferenceDisplay === "0.0") {
    disabled = true;
  } else {
    disabled = false;
  }

    // console.log("In FlightTimeSlider, props.blockDifferenceDisplay->", blockDifferenceDisplay)
    // console.log("In FlightTimeSlider, disabled->", disabled);

 const HandleChange = (e) => {
    setValue(e.target.value)
    let calculatedValue = Number(((e.target.value/100) * blockDifferenceDisplay).toFixed(1))
    let action = {
      name: e.target.name,
      value: calculatedValue,
    }
      // console.log("In Slider, action->", action);
    props.TechLogHandleChange(action)
  }

  let excludedForAircraftType = ["SIM"];
    // console.log("In FlightTimeSlider, props.submission.->", props.submission); 
  if (excludedForAircraftType.indexOf(props.submission.data.planeType.value) === -1) {

    return (
      <Box sx={{ width: {displayWidth}, mt: "20px" }}>
        <Slider
          disabled = {disabled}
          id = {flightType}
          name = {flightType}
          defaultValue={0}
          step={1} 
          onChange = {HandleChange}
          valueLabelDisplay="off"
        />
        <Box sx = {{display: "flex", flexDirection: "row", justifyItems: "spaceBetween"}}>
          {flightType}  -  {((value/100) * blockDifferenceDisplay).toFixed(1)} hrs
        </Box>
      </Box>
    );
  }
}