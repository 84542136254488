//Import libraries
import { Box } from "@mui/system";
import React from 'react';
import { useMediaQuery } from "@mui/material";
import { Chip } from "@mui/material";


// Import CSS, images, icons & static data
import "./PilotLog.css";
import { mobileThreshold } from "../../utilities/environment/EnvironmentData";
import azTechTheme from "../../utilities/environment/ColourTheme";

// Import fonts


//**************************** MAIN FUNCTION ***************************** */
export default function PilotLogTotalHours(props) {

    
    var media = useMediaQuery(`(min-width:${mobileThreshold}px)`);
    var TotalHoursChipClass = "total-hours-chip";
    var chipFontSize = "xxx-large"
    const chipColour = azTechTheme.palette.primary.main;

    if (media) {
        TotalHoursChipClass = "total-hours-chip"
        chipFontSize = "x-large"
    } else {
        TotalHoursChipClass = "total-hours-chip-mobile"
        chipFontSize = "large"
    }

    return (
        <Box className = "pilot-black-label">
                    TOTAL HOURS <br></br>
            <Chip label = {props.totalHours} className = {TotalHoursChipClass} 
                sx = {{backgroundColor: chipColour, fontSize: chipFontSize, color: "white", mt: "25px", width: "145px", height: "145px"}}/>
        </Box>
    );
};