// Import libraries 
import { compareAsc, format, formatDistanceToNowStrict } from 'date-fns'
import dayjs from 'dayjs';

// Import CSS, images, icons and static data
// import background from "../flights/flightData/FlightData.js"

const background = {
    error: "background-color: rgb(245, 247, 177)",  // Soft Yellow
    // error: "background-color: rgb(240,240,240)", // Grey
    // error: "background-color: rgb(255,255,88)", // Bright Yellow
    // warning: "background-color: rgb(184,251,253)", // Bright Blue 
    warning: "background-color: rgba(217,240,240,255)", // Soft Blue
    normal: "background-color: white",  
    grey: "background-color: rgb(240,240,240)"
}

let emptyAircraftSubmission = {

    // Default Open Flight (21)
    // Registration Details (5)
    id: {
        value: ""
    },
    status: {
        value: "",
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: false,
        required: false
    }, 
    registration: {
        value: "",
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: false,
        required: true
    }, 
    registeringAuthority: {
        value: "",
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: true,
        required: true
    }, 
    colour: {
        value: "",
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: true,
        required: true
    }, 

    // Make & Model Details (4)
    makeAndModelId: {
        value: "",
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: true,
        required: true,
    }, 
    make: {
        value: "",
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: true,
        required: true,
    }, 
    model: {
        value: "",
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: true,
        required: true
    },
    planeComplexity: {
        value: "",
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: true,
        required: true
    }, 
    planeType: {
        value: "",
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: true,
        required: true
    }, 
    numberOfEngines: {
        value: "",
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: true,
        required: true
    }, 
    oilLevels: [    
    {
        value: "",
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: true,
        required: true
    }, 
   {
        value: "",
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: true,
        required: true
    }, 
    {
        value: "",
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: true,
        required: true
    }, 
    {
        value: "",
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: true,
        required: true
    }, 
   {
        value: "",
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: true,
        required: true
    }, 
    {
        value: "",
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: true,
        required: true
    } ],

    //Fuel details (8)
    fuelType: {
        value: "",
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: true,
        required: true
    }, 
    fuelTank1Capacity: {
        value: "",
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: true,
        required: true
    }, 
    fuelTank2Capacity: {
        value: "",
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: true,
        required: true
    },  
    fuelTank3Capacity: {
        value: "",
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: true,
        required: false
    }, 
    fuelTank4Capacity: {
        value: "",
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: true,
        required: false
    }, 
    fuelTank1State: {
        value: "",
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: true,
        required: true
    },  
    fuelTank2State: {
        value: "",
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: true,
        required: true
    },  
    fuelTank3State: {
        value: "",
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: true,
        required: false
    },  
    fuelTank4State: {
        value: "",
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: true,
        required: false
    },  
    //Maintenance Details (4)
    dateOfNextService: {
        value: "",
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: true,
        required: true
    },  
    nextServiceHours: {
        value: 0.0,
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: true,
        required: true
    }, 
    currentHours: {
        value: 0.0,
        status: "",
        background: background.normal,
        variant: "outlined",
        reset: false,
        required: true
    },  
    defects: [
        {
            id:"",
            flightId:"",
            details: "",
            dateOpened: "",
            dateClosed:"",
            defectStatus:""
        }],
    mostSevereIssue: {}

}

class AirCraft {
    constructor (
        // Aircraft Total (19)
        // Registration details (4)
        id,
        registration, 
        registeringAuthority, 
        colour, 
        // Make and Model (1)
        // Update to Make & Model ID ONLY 
        makeAndModelId,
        make, 
        model, 
        planeComplexity,
        planeType,
        numberOfEngines, 
        // Fuel & oil (9)
        oilLevels, 
        fuelType,
        fuelTank1Capacity, 
        fuelTank2Capacity, 
        fuelTank3Capacity, 
        fuelTank4Capacity, 
        fuelTank1State, 
        fuelTank2State, 
        fuelTank3State, 
        fuelTank4State, 
        // Maintenance Details (4)
        dateOfNextService, 
        nextServiceHours, 
        currentHours, 
        defects) 
        
        {
        // Registration details (5)
        this._id = id;
        this._registration = registration;
        this._registeringAuthority = registeringAuthority;
        this._colour = colour;
         // Make and Model (6)
        this._makeAndModelId = makeAndModelId;
        this._make = make;
        this._model = model;
        this._planeComplexity = planeComplexity;
        this._planeType = planeType;
        this._numberOfEngines = numberOfEngines;
        // Fuel & Oil (9)
        this._oilLevels = oilLevels;
        this._fuelType = fuelType;
        this._fuelTank1Capacity = fuelTank1Capacity;
        this._fuelTank2Capacity = fuelTank2Capacity;
        this._fuelTank3Capacity = fuelTank3Capacity;
        this._fuelTank4Capacity = fuelTank4Capacity;
        this._fuelTank1State = fuelTank1State;
        this._fuelTank2State = fuelTank2State;
        this._fuelTank3State = fuelTank3State;
        this._fuelTank4State = fuelTank4State;
        // Maintenance Details (4)
        this._dateOfNextService = dateOfNextService;
        this._nextServiceHours = nextServiceHours;
        this._currentHours = currentHours;
        this._defects = defects;

    }

    get registration() {
        return this._registration;
    }

    get status() {
        return this._status;
    }

    get registeringAuthorityId() {
        return this._registeringAuthorityId;
    }

    get colour() {
        return this._type;
    }

    get make() {
        return this._colour;
    }

    get model() {
        return this._make;
    }

    get planeComplexity() {
        return this._planeComplexity;
    }

    get planeType() {
        return this._type;
    }

    get numberOfEngines() {
        return this._numberOfEngines;
    }

    get oilLevels() {
        return this._oilLevels;
    }

    get fuelType() {
        return this._fuelType;
    }

    get fuelTank1Capacity() {
        return this._fuelTank1Capacity;
    }

    get fuelTank2Capacity() {
        return this._fuelTank2Capacity;
    }

    get fuelTank3Capacity() {
        return this._fuelTank3Capacity;
    }

    get fuelTank4Capacity() {
        return this._fuelTank4Capacity;
    }

    get nextServiceDate() {
        return this._nextServiceDate;
    }

    get nextServiceHours() {
        return this._nextServiceHours;
    }

    get currentHours() {
        return this._currentHours;
    }  

    incrementHours(flightHours) {
        this.currentHours = this.currentHours + flightHours;
    }

    set nextServiceDate (nextServiceDate) {
        this._nextServiceDate = nextServiceDate;
    }
    get openDefects() {
        return this._defects;
    }
}

const datePLANE = dayjs(new Date()).format("YYYY-MM-DD");
const dateGELKI = dayjs(new Date("2024-01-11")).format("YYYY-MM-DD");
const dateGELKO = dayjs(new Date("2023-12-15")).format("YYYY-MM-DD");
const dateGXDEA = dayjs(new Date("2023-11-12")).format("YYYY-MM-DD");
const dateGFOOJ = dayjs(new Date("2023-12-25")).format("YYYY-MM-DD");

const defaultOilLevels = [
" ", "MAX", "3/4", "HALF", "1/4", "MIN"
];

const gallonsToLitres = 3.8;
const registeringAuthorities = [
    "BMVIT",
    "CAA",
    "CAA_NO",
    "CASA",
    "DTA",
    "FAA",
    "GDAC",
    "GDCAS",
    "IAA",
    "JCAA",
]
const aircraftComplexities = [
    "SIMPLE",
    "COMPLEX"
]
const aircraftTypes = [
    "SEP",
    "MEP",
    "JET",
    "SIM"
]

const courseStatuses = ["DRAFT", "APPROVED", "DEPRECATED"];
const medicals = ["CLASS1", "CLASS2"];

const fuelTypes = [
    "100LL",
    "JETA1"
]

const GFOOJOilLevels= [
    " ", "6.5", "6.0","5.5", "5.0"
]

const defaultDefectList = [
    "Right Rear Nav Light Unserviceable", "Left Rear Nav Light Unserviceable", "Transfer Pump Intermittent Failures", "None Whatsoever", "Never had one...", "...ever"
]

const defectsPLANE = []

const defectsTEST = [
    {
        id: 1,
        flightId: "",
        details: "Never had one...",
        dateRaised: "0/0/0000",
        dateClosed: "0/0/0000",
        defectStatus: "CLOSED",
        planeId: 0
    },
    {
        id: 2,
        flightId: "",
        details: "...ever",
        dateRaised: "0/0/0000",
        dateClosed: "",
        defectStatus: "OPEN",
        planeId: 0
    }
]



const defectsGELKO = [
    {   
        id: 1,
        flightId: "",
        details: "A philandering flap",
        dateRaised: "11/11/2023",
        dateClosed: "",
        defectStatus: "OPEN",
        planeId: 2
    },

]

const defectsGELKI = [
    {
        id: 1,
        flightId: "",
        details: "A tall tail",
        dateRaised: "15/12/2023",
        dateClosed: "",
        defectStatus: "OPEN",
        planeId: 1
    },

]


const PLANE = new AirCraft("","SELECT", "JCAA", "CLEAR", 0,"MAKE", "MODEL", "SIMPLE","SEP",1, defaultOilLevels, "100LL", 0, 0, 0, 0, 0, 0, 0, 0, datePLANE, 0, 0, defectsPLANE);
const GELKI = new AirCraft("S2","GELKI", "CAA", "White", 2,"Diamond", "DA40", "SIMPLE","SEP", 1, defaultOilLevels, "JETA1", 14, 14, 0, 0, 10, 10, 0, 0, dateGELKI, 1583.4, 1579.6, defectsGELKI);
const GELKO = new AirCraft("S3","GELKO", "CAA", "White", 1,"Diamond", "DA42", "COMPLEX","MEP",2, defaultOilLevels, "JETA1", 25, 25, 0, 0, 20, 20, 0, 0, dateGELKO, 1364.5, 1291.5,defectsGELKO);
const GXDEA = new AirCraft("S4","GXDEA", "CAA", "White", 1,"Diamond", "DA42","COMPLEX" ,"MEP",2, defaultOilLevels, "JETA1", 25, 25, 0, 0, 22, 22, 0, 0, dateGXDEA, 1364.5, 1291.5,[]);
const GFOOJ = new AirCraft("S5","GFOOJ", "CAA", "White", 3,"Fuji", "FA-200-160","SIMPLE" ,"SEP",1, GFOOJOilLevels, "100LL", 17.5, 17.5, 0, 0, 15, 15, 0, 0, dateGFOOJ, 2000, 1900,[]);

const emptyAircraftRequest = 
{
        // Aircraft Total (19)
        // Registration details (4)
        id: "",
        registration: "", 
        registeringAuthority: "", 
        colour: "", 
        // Make and Model (1)
        // Update to Make & Model ID ONLY 
        makeAndModelId: "",
        make: "", 
        model: "", 
        planeComplexity: "",
        planeType: "",
        numberOfEngines: "", 
        // Fuel & oil (9)
        oilLevels: [
        ], 
        fuelType: "",
        fuelTank1Capacity: "", 
        fuelTank2Capacity: "", 
        // fuelTank3Capacity: "", 
        // fuelTank4Capacity: "", 
        fuelTank1State: "", 
        fuelTank2State: "", 
        // fuelTank3State: "", 
        // fuelTank4State: "", 
        // Maintenance Details (4)
        dateOfNextService: "", 
        nextServiceHours: 0.0, 
        currentHours: 0.0, 
        defects: [
            {
                id:"",
                flightId: "",
                details: "",
                dateOpened: "",
                dateClosed: "",
                status: ""
            }
        ] 
}

const defaultAircraftList = [
    PLANE,
];

const planes = [
    PLANE,
    GELKI,
    GELKO,
    GXDEA,
    GFOOJ
];


export {AirCraft, planes, courseStatuses, medicals, emptyAircraftSubmission, emptyAircraftRequest, defaultAircraftList, PLANE, defaultDefectList, gallonsToLitres, registeringAuthorities, aircraftComplexities, aircraftTypes, fuelTypes};

