// ************************************************ ARRIVAL OBJECTS *************************************************

// Arrival object (40)
let arrivalMandatoryFields = {
    //Arrival details (4)
    from: true, 
    to: true, 
    flightDetails: false, 
    homeLandings: true,

    // Oil (2)
    oilStatusLeft: true,

    //Arrival Fuel (5)
    arrivalFuelUpliftLeft: false,
    arrivalFuelUpliftRight: false,
    arrivalFuelLeft: true, 
    arrivalFuelRight: true,  
    fuelUsed: true,

    //Flight times (9)
    offBlocks: false,
    onBlocks: false,
    blockDifference: true,
    takeOff: true,
    landing: true,
    flightTime: true,
    svcOff: true,
    svcOn: true,
    svcDifference: true,

    // Defects (2)
    newDefect1: false,
    newDefect2: false,

    // Away Landings (20)
    awayLandingLocation1: false,
    awayLandingQuantity1: true,
    awayLandingDetails1: false,
    awayLandingsPaid1: false,
    awayLandingLocation2: false,
    awayLandingQuantity2: false,
    awayLandingDetails2: false,
    awayLandingsPaid2: false,
    awayLandingLocation3: false,
    awayLandingQuantity3: false,
    awayLandingDetails3: false,
    awayLandingsPaid3: false,
    awayLandingLocation4: false,
    awayLandingQuantity4: false,
    awayLandingDetails4: false,
    awayLandingsPaid4: false,
    awayLandingLocation5: false,
    awayLandingQuantity5: false,
    awayLandingDetails5: false,
    awayLandingsPaid5: false,
    }

let simMandatoryFields = {
    // Base details (3)
    registration: true, 
    dateOfFlight: true, 
    pilot: true, 

    // Departure details (10)
    passenger1: true,
    passenger2: false,
    departureFuelUpliftLeft: false,
    departureFuelUpliftRight: false,
    departureFuelLeft: false, 
    departureFuelRight: false, 
    oilUpliftLeft: false,
    oilUpliftRight: false,
    oilStatusLeft: false, 
    oilStatusRight: false, 
    //Arrival details (4)
    fromAirfield: false, 
    toAirfield: false, 
    flightDetails: false, 
    homeLandings: false,

    // Oil (2)
    oilStatusLeft: false,

    //Arrival Fuel (5)
    arrivalFuelUpliftLeft: false,
    arrivalFuelUpliftRight: false,
    arrivalFuelLeft: false, 
    arrivalFuelRight: false,  
    fuelUsed: false,

    //Flight times (9)
    offBlocks: true,
    onBlocks: true,
    blockDifference: true,
    takeOff: true,
    landing: true,
    flightTime: true,
    svcOff: false,
    svcOn: false,
    svcDifference: false,

    // Defects (2)
    newDefect1: false,
    newDefect2: false,

    // Away Landings (20)
    awayLandingLocation1: false,
    awayLandingQuantity1: false,
    awayLandingDetails1: false,
    awayLandingsPaid1: false,
    awayLandingLocation2: false,
    awayLandingQuantity2: false,
    awayLandingDetails2: false,
    awayLandingsPaid2: false,
    awayLandingLocation3: false,
    awayLandingQuantity3: false,
    awayLandingDetails3: false,
    awayLandingsPaid3: false,
    awayLandingLocation4: false,
    awayLandingQuantity4: false,
    awayLandingDetails4: false,
    awayLandingsPaid4: false,
    awayLandingLocation5: false,
    awayLandingQuantity5: false,
    awayLandingDetails5: false,
    awayLandingPaid5: false,
    }

// Empty Arrival (40)
let emptyArrival = {
    //Arrival details (4)
    from: "", 
    to: "", 
    flightDetails: "", 
    homeLandings: "",
    
    //Arrival Fuel (5)
    arrivalFuelUpliftLeft: "",
    arrivalFuelUpliftRight: "",
    arrivalFuelLeft: "", 
    arrivalFuelRight: "",  
    fuelUsed: "",
    
    // Flight times (9)
    offBlocks: "",
    onBlocks: "",
    blockDifference: "",
    takeOff: "",
    landing: "",
    flightTime: "",
    svcOff: "",
    svcOn: "",
    svcDifference: "",

    // Defects (2)
    newDefect1: "",
    newDefect2: "",
    
    // Away Landings (20)
    awayLandingLocation1: "",
    awayLandingQuantity1: "",
    awayLandingDetails1: "",
    awayLandingPaid1: "",
    awayLandingLocation2: "",
    awayLandingQuantity2: "",
    awayLandingDetails2: "",
    awayLandingPaid2: "",
    awayLandingLocation3: "",
    awayLandingQuantity3: "",
    awayLandingDetails3: "",
    awayLandingPaid3: "",
    awayLandingLocation4: "",
    awayLandingQuantity4: "",
    awayLandingDetails4: "",
    awayLandingPaid4: "",
    awayLandingLocation5: "",
    awayLandingQuantity5: "",
    awayLandingDetails5: "",
    awayLandingPaid5: "",
    }

 // Empty arrival (40) 
let emptyArrivalList = [
    // Arrival details (4)
    "", // from 
    "ypmq", // to
    "", // flightDetails
    "", // homeLandings

    // Arrival Fuel (5)
    "", // arrivalFuelUpliftLeft
    "", // arrivalFuelUpliftRight
    "", // arrivalFuelLeft
    "", // arrivalFuelRight
    "", // fuelUsed

    //Flight times (9)
    "", // offBlocks
    "", // onBlocks
    "", // blockDifference
    "", // takeOff
    "", // landing
    "", // flightTime
    "", // svcOff
    "", // svcOn
    "", // svcDifference

    // Defects (2)
    "", // newDefect1
    "", // newDefect2

    // Away landings (20)
    "", // awayLandingLocation1
    "", // awayLandingQuantity1
    "", // awayLandingDetails1
    "", // awayLandingsPaid1
    "", // awayLandingLocation2
    "", // awayLandingQuantity2
    "", // awayLandingDetails2
    "", // awayLandingsPaid2
    "", // awayLandingLocation3
    "", // awayLandingQuantity3
    "", // awayLandingDetails3
    "", // awayLandingsPaid3
    "", // awayLandingLocation4
    "", // awayLandingQuantity4
    "", // awayLandingDetails4
    "", // awayLandingsPaid4
    "", // awayLandingLocation5
    "", // awayLandingQuantity5
    "", // awayLandingDetails5
    "", // awayLandingsPaid5
]




export {emptyArrival, emptyArrivalList, arrivalMandatoryFields, simMandatoryFields};