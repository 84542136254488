// Import libraries
import { useMediaQuery } from "@mui/material";
import {Switch} from "@mui/material";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import { useState, useEffect } from "react";

// Import CSS, images, icons & static
import "./AdminCourse.css"
import { mobileThreshold } from "../../utilities/environment/EnvironmentData";
import { PostCourseStatus } from "../../utilities/database/PostCourseStatus";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';


// ************************* MAIN FUNCTION *******************************
export default function CourseManagementButtons(props) {

    // const blank = "";
    const [courseManagementButtonsClass, setCourseManagementButtonsClass] = useState("switch");
    const [direction, setDirection] = useState("row")
    const [justification, setJustification] = useState("flex-end")
    var media = useMediaQuery(`(min-width:${mobileThreshold}px)`);
    const [checked, setChecked] = useState(props.editingSwitch)
    const [buttonLabel, setButtonLabel] = useState("DELETE COURSE")
    var editing


    useEffect (() => {
        if (media) {    
            setCourseManagementButtonsClass("course-management-buttons-frame")
            // setDirection("row")
            // setJustification("flex-end")
          } else {
            setCourseManagementButtonsClass("course-management-buttons-frame-mobile")
            // setDirection("row")
            // setJustification("flex-end")
          } 
        }, [media]);

    
    let deleteButtonStatus = false;

    if (props.editing && (props.courseSubmission.name.value !== "NEW COURSE")) {
        deleteButtonStatus = true
    }

    let style = "loud"
    // let style = "standard"
        

    if (style === "standard") {
        return (
            <Box className = {courseManagementButtonsClass}>
                <Box className = "course-delete-button">
                    <Button 
                        onClick = {props.HandleDeleteCourse}
                        disabled = {deleteButtonStatus === false}
                        color = "primary">
                        <DeleteOutlineOutlinedIcon 
                            id = {props.courseSubmission.id.value + "-deleteIcon"} 
                            sx = {{mr: "10px"}}/>
                        {buttonLabel}
                    </Button>
                </Box>
            </Box>
        );
    } else {
        return (
            <Box className = {courseManagementButtonsClass}>
                <Box className = "course-delete-button">
                    <Button 
                        disabled = {deleteButtonStatus === false}
                        size = "medium" 
                        variant = "contained" 
                        color = "warning"
                        name = "adminAircraftSubmit" 
                        sx = {{background: "error", width: "150px", mt: "5px"}}
                        className = "course-delete-button"
                        onClick = {props.HandleDeleteCourse}
                        >{buttonLabel} 
                    </Button>
                </Box>
            </Box>
        );
    }
};