import Radio from '@mui/material/Radio';
import { Box } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { HasRole } from '../utilities/security/HasRole';
import { rolesTemplate } from '../utilities/database/GetRolePermissions';
import _ from "lodash";
// import FormLabel from '@mui/material/FormLabel';

export default function CourseRadioButtons(props) {
    let isAdmin = false;
    let isInstructor = false;
    let isStudent = true;
    let highestUserRole = "student";
    let currentUserRoles = rolesTemplate;
    let chosenRole = props.chosenRole;

        // console.log("In CourseRadioButton, chosenRole->", chosenRole);

    // if (props.currentUserRoles) {
    //     // if (typeof props.currentUserRoles.then !== "function") {
    //         console.log("In CourseRadioButton, props.currentUserRoles->", props.currentUserRoles);
    //     // }
    // }


    // function handleChange(event) {;
    //     props.HandleChange(event)
    //         console.log("In CourseRadioButtons, event.target.value->", event.target.value); 
    //     // chosenRole = event.target.value;
    //         console.log("In CourseRadioButtons, props.choseRole->", props.chosenRole); 
    // }
        
    if (props.currentUserRoles) {
        // console.log("In CourseRadioButtons, props.currentUserRoles->", props.currentUserRoles);
    } 
        // console.log("In CourseRadioButton, typeof props.currentUserRoles.then->", typeof props.currentUserRoles.then);

    if (props.currentUserRoles) {
        if (typeof props.currentUserRoles.then !== "function") {
            currentUserRoles = _.cloneDeep(props.currentUserRoles)
                // console.log("In CourseRadioButton, currentUserRoles->", currentUserRoles);
            if (currentUserRoles.admin.status) {
                highestUserRole = "admin"
                    // console.log("In CourseRadioButton, highestUserRole->", highestUserRole) 
            } else {
                if (currentUserRoles.instructor.status) {
                    highestUserRole = "instructor"
                        // console.log("In CourseRadioButton,highestUserRole->", highestUserRole) 
                } else {
                    highestUserRole = "student"
                        // console.log("In CourseRadioButton, highestUserRole->", highestUserRole) 
                }
            }
        

    

        // const handleChange = (event) => {setCourseUserRole(event.target.value)};


        // isAdmin = await HasRole("admin");
        // isInstructor = await HasRole("instructor")

            // console.log("In CourseRadioButton, HasRole(admin)->", isAdmin) 
            // console.log("In CourseRadioButton, HasRole(admin)->", isInstructor) 

        // isAdmin = await HasRole("admin");
        //     console.log("In CourseRadioButton, isAdmin->", isAdmin) 
        // isInstructor = await HasRole("Instructor");
        // isStudent = await HasRole("student");

                // console.log("In CourseRadioButtons, highestUserRole->", highestUserRole);

            switch (highestUserRole) {
                case "student": 
                        // console.log("In CourseRadioButtons, highestUserRole->", highestUserRole);
                    return(
                        <Box></Box>
                        // <RadioGroup
                        //     className = "course-selection-radio"
                        //     color = "primary" 
                        //     onChange = {handleChange}
                        //     value = {chosenRole}
                        //     row
                        //     name="course-user-role">
                        // </RadioGroup>
                    );
                case "instructor":
                    return (
                        <RadioGroup
                            className = "course-selection-radio"
                            color = "primary" 
                            onChange = {props.HandleChange}
                            value = {chosenRole}
                            row
                            name="course-user-role">
                                <FormControlLabel value="student" control={<Radio />} label="Student" />
                                <FormControlLabel value="instructor" control={<Radio />} label="Instructor" />
                        </RadioGroup>
                    );
                case "admin":
                    return (
                        <RadioGroup
                            className = "course-selection-radio"
                            color = "primary" 
                            onChange = {props.HandleChange}
                            value = {chosenRole}
                            row
                            name="course-user-role">
                                <FormControlLabel value="student" control={<Radio />} label="Student" />
                                <FormControlLabel value="instructor" control={<Radio />} label="Instructor" />
                                <FormControlLabel value="admin" control={<Radio />} label="Admin" />
                        </RadioGroup>
                    );
                default: 
                return (
                    <Box></Box>
                )
            }
            
        }
    }
    
  }