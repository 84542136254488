// Import libraries
import React, {useEffect, useState} from 'react';
import Button from '@mui/joy/Button';
import { GetOpenFlightList } from '../../../utilities/database/GetOpenFlightList';
import Box from '@mui/joy/Button';

// Import CSS & images
import shortArrow from "../images/shortArrow.png";
import "./OpenFlights.css";
import { ThemeProvider, display } from '@mui/system';
import { Build } from '@mui/icons-material';






// ******************************* MAIN FUNCTION ************************************************
export default function OpenFlights(props) {
        // console.log("In OpenFlights, defaultOpenFlightList -> ", defaultOpenFlightList, "props.selectedAircraft.registration ->", props.selectedAircraft.registration);
    
    const [flightList, setFlightList] = useState([]);
    const [displayButtons, setDisplayButtons] = useState([]);
    let displayButtonsTest = [];
    // const [openFlightColour, setOpenFlightColour] = useState("primary");
    let openFlightColour = "primary"
    const variant = "outlined";
    const variantPressed = "solid";
    const [pressed, setPressed] = useState();

    // *************************** POPULATE FORM BASED ON THE CLICKED OPEN FLIGHT *********************************
    const handleClick = (e, flight) => {
        e.stopPropagation();
        openFlightColour = "primary";
        setDisplayButtons([]);
        setDisplayButtons(BuildOpenFlightButtons(flightList, Number(e.target.id)));
        props.UpdateOpenFlightPressed(true);
            
        let action = {
            type: "openFlight",
            flight: flight
        }
        props.TechLogOpenFlight(action)
    }
    // *************************** GET OPEN FLIGHTS FROM DATABASE BASED ON SELECTED REGISTRATION ********************************
    
    useEffect(() => {
        const GetOpenFlights =  async (selectedAircraft) => {
            let responseObject = await GetOpenFlightList(selectedAircraft); 
                setFlightList(responseObject.data);
                props.UpdateDatabaseConnection(responseObject.databaseConnection);
        }
        GetOpenFlights(props.selectedAircraft);

    }, [props.selectedAircraft._registration]);

    useEffect(() => {
        setDisplayButtons(BuildOpenFlightButtons(flightList, null));
    }, [flightList, props.selectedAircraft])
    

    // *************************** CREATE BUTTONS FOR EACH OPEN FLIGHT *********************************
    function BuildOpenFlightButtons(flightList, pressed) {
        if (flightList.length !== {}) {
            return flightList.map((flight) => {
                const isPressed = flight.id.value === pressed 
                const buttonVariant = isPressed ? variantPressed : variant;
                const openFlightColour = isPressed ? "primary" : "primary";
                return (
                    <Button 
                        className = "open-flight-buttons" 
                        variant = {buttonVariant} 
                        id = {flight.id.value}
                        disabled = {isPressed === true}
                        key = {flight.dateOpened.value} 
                        color = {openFlightColour}
                        onClick = {(e) => handleClick(e, flight)}>
                            {flight.pilot.value} ({flight.dateOpened.value})
                    </Button>
                );
            });
            
        }
    }


    // *************************** RENDER OPEN FLIGHT BUTTONS *********************************
        // console.log("In OpenFlights, displayButtons->", displayButtons);
    if (displayButtons.length !== 0) {
        return (
            <Box 
                color = "transparent" 
                sx = {{display: "flex", 
                flexWrap: "wrap", gap: "10px"}}
                onClick={(e) => e.stopPropagation()}>
            {/* // <Box color = "transparent"> */}
                <Box className = "open-flights-label" 
                    color = "transparent"
                    sx={{ cursor: 'default' }} 
                    onClick={(e) => e.stopPropagation()}>
                        <span>Open Flights</span>
                        <img src = {shortArrow} className = "open-flights-image" alt = "Arrow"/>
                </Box>
                {/* <Box > */}
                <Box className = "open-flights-buttons-container" color = "transparent">
                    {displayButtons}
                    {/* <BuildOpenFlightButtons flightList = {flightList} pressed = {pressed}/> */}
                </Box>
                {/* </Box> */}
            </Box>
        );
    }
    

};

