// Import libraries
import React, { useEffect, useState } from "react";

import { TextField, Checkbox, FormGroup, FormControlLabel, Box } from "@mui/material";
import { Container } from "@mui/system";

// Import CSS & images
import './AwayLandings.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import arrival from "../images/arrival.png";



// ******************************* MAIN FUNCTION ************************************************
export default function AwayLandings(props) {
    const [disabled, setDisabled] = useState(true)

        // console.log("In AwayLanding, props.submission.awayLandingQuantity1.value -> ", props.submission.awayLandingQuantity1.value);
        // console.log("In AwayLanding, disabled -> ", disabled)
    useEffect(()=> {
        if (props.submission.data.awayLandingQuantity1.value === "0" || props.submission.data.awayLandingQuantity1.value === "") {
            setDisabled(true);
        } else {
            setDisabled (false);
        }
    },[props.submission.data.awayLandingQuantity1.value]);

    let excludedForAircraftType = ["SIM"];
    if (excludedForAircraftType.indexOf(props.submission.data.planeType.value) === -1) {
        // console.log("In AwayLandings, props.submission.data.planeType.value->", props.submission.data.planeType.value);

        return (
            <Box className = "away-landings">
                <Box className = "away-landings-details" >
                    <Box sx={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                        <img className = "away-landings-image" src = {arrival} alt = "Arrival"/>
                        <div  className = "aircraft-header" id = "registration" name = "registration" onChange={e => props.TechLogHandleChange(e.target)}>{props.registration}</div>
                    </Box>
                    <Box>
                        <Box>
                            <div className = "away-landings-title">Away Landings & Approaches</div>
                        </Box>
                        <table>
                            <thead>
                                <tr>
                                    <td className = "label" >Location</td>
                                    <td className = "label" >Number*</td>
                                    <td className = "label-extra-wide" colSpan ="15" >Details <span className="footnote">(eg. RNP Low Approach)</span></td>
                                    <td className = "label" colSpan = "1">Paid</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td STYLE = {props.submission.data.awayLandingLocation1.background}>
                                        <TextField 
                                            disabled = {disabled} 
                                            value = {props.submission.data.awayLandingLocation1.value.toUpperCase()} 
                                            onChange={e => props.TechLogHandleChange(e.target)} 
                                            error = {props.submission.data.awayLandingLocation1.status === "error"} 
                                            variant = {props.submission.data.awayLandingLocation1.variant} 
                                            fullWidth 
                                            ype = "text" 
                                            name = "awayLandingLocation1" 
                                            id = "awayLandingLocation1">
                                        </TextField>
                                    </td>
                                    <td STYLE = {props.submission.data.awayLandingQuantity1.background}>
                                        <TextField 
                                            value = {props.submission.data.awayLandingQuantity1.value} 
                                            onChange={e => props.TechLogHandleChange(e.target)} 
                                            InputProps={{ inputProps: { min: 0 }}} 
                                            error = {props.submission.data.awayLandingQuantity1.status === "error"} 
                                            variant = {props.submission.data.awayLandingQuantity1.variant} 
                                            fullWidth 
                                            type = "number" 
                                            name = "awayLandingQuantity1" 
                                            id = "awayLandingQuantity1">
                                        </TextField>
                                    </td>
                                    <td STYLE = {props.submission.data.awayLandingDetails1.background} colSpan = "15">
                                        <TextField 
                                            disabled = {disabled} 
                                            value = {props.submission.data.awayLandingDetails1.value} 
                                            onChange={e => props.TechLogHandleChange(e.target)} 
                                            error = {props.submission.data.awayLandingDetails1.status === "error"} 
                                            variant = {props.submission.data.awayLandingDetails1.variant} 
                                            fullWidth type = "text" 
                                            name = "awayLandingDetails1" 
                                            id = "awayLandingDetails1">
                                        </TextField>
                                    </td>
                                    <td className = "checkBox" >
                                        <Checkbox 
                                            // checked = {props.submission.awayLandingPaid1.status === "PAID"}
                                            value = {props.submission.data.awayLandingPaid1.status === "PAID"} 
                                            status = {props.submission.data.awayLandingPaid1.status}
                                            disabled = {disabled} 
                                            onChange = {e => props.TechLogHandleChange(e.target)} 
                                            name="awayLandingPaid1" id="awayLandingPaid1">
                                        </Checkbox>
                                    </td>
                                </tr>
                                <tr>
                                    <td STYLE = {props.submission.data.awayLandingLocation2.background}><TextField disabled = {disabled} value = {props.submission.data.awayLandingLocation2.value.toUpperCase()} onChange={e => props.TechLogHandleChange(e.target)} error = {props.submission.data.awayLandingLocation2.status === "error"} variant = {props.submission.data.awayLandingLocation2.variant} fullWidth type = "text" name = "awayLandingLocation2" id = "awayLandingLocation2"></TextField></td>
                                    <td STYLE = {props.submission.data.awayLandingQuantity2.background}><TextField disabled = {disabled} value = {props.submission.data.awayLandingQuantity2.value} onChange={e => props.TechLogHandleChange(e.target)} InputProps={{ inputProps: { min: 0 }}} error = {props.submission.data.awayLandingQuantity2.status === "error"} variant = {props.submission.data.awayLandingQuantity2.variant} fullWidth type = "number" name = "awayLandingQuantity2" id = "awayLandingQuantity2"></TextField></td>
                                    <td STYLE = {props.submission.data.awayLandingDetails2.background} colSpan = "15"><TextField disabled = {disabled} value = {props.submission.data.awayLandingDetails2.value} onChange={e => props.TechLogHandleChange(e.target)} error = {props.submission.data.awayLandingDetails2.status === "error"} variant = {props.submission.data.awayLandingDetails2.variant} fullWidth type = "text" name = "awayLandingDetails2" id = "awayLandingDetails2"></TextField></td>
                                    <td className = "checkBox">
                                        <Checkbox
                                            // checked = {props.submission.awayLandingPaid2.value === true}
                                            value = {props.submission.data.awayLandingPaid2.value} 
                                            status = {props.submission.data.awayLandingPaid2.status}
                                            disabled = {disabled} 
                                            onChange={e => props.TechLogHandleChange(e.target)} 
                                            name="awayLandingPaid2" id="awayLandingPaid2">
                                        </Checkbox></td>
                                </tr>
                                <tr>
                                    <td STYLE = {props.submission.data.awayLandingLocation3.background}><TextField disabled = {disabled} value = {props.submission.data.awayLandingLocation3.value.toUpperCase()} onChange={e => props.TechLogHandleChange(e.target)} error = {props.submission.data.awayLandingLocation3.status === "error"} variant = {props.submission.data.awayLandingLocation3.variant} fullWidth type = "text" name = "awayLandingLocation3" id = "awayLandingLocation3"></TextField></td>
                                    <td STYLE = {props.submission.data.awayLandingQuantity3.background}><TextField disabled = {disabled} value = {props.submission.data.awayLandingQuantity3.value} onChange={e => props.TechLogHandleChange(e.target)} InputProps={{ inputProps: { min: 0 }}} error = {props.submission.data.awayLandingQuantity3.status === "error"} variant = {props.submission.data.awayLandingQuantity3.variant} fullWidth type = "number" name = "awayLandingQuantity3" id = "awayLandingQuantity3"></TextField></td>
                                    <td STYLE = {props.submission.data.awayLandingDetails3.background} colSpan = "15"><TextField disabled = {disabled} value = {props.submission.data.awayLandingDetails3.value} onChange={e => props.TechLogHandleChange(e.target)} error = {props.submission.data.awayLandingDetails3.status === "error"} variant = {props.submission.data.awayLandingDetails3.variant} fullWidth type = "text" name = "awayLandingDetails3" id = "awayLandingDetails3"></TextField></td>
                                    <td className = "checkBox">
                                        <Checkbox 
                                            value = {props.submission.data.awayLandingPaid3.value} 
                                            status = {props.submission.data.awayLandingPaid3.status}
                                            disabled = {disabled}
                                            onChange={e => props.TechLogHandleChange(e.target)} 
                                            name="awayLandingPaid3" id="awayLandingPaid3">
                                        </Checkbox></td>                        
                                </tr>
                                <tr>
                                    <td STYLE = {props.submission.data.awayLandingLocation4.background}><TextField disabled = {disabled} value = {props.submission.data.awayLandingLocation4.value.toUpperCase()} onChange={e => props.TechLogHandleChange(e.target)} error = {props.submission.data.awayLandingLocation4.status === "error"} variant = {props.submission.data.awayLandingLocation4.variant} fullWidth type = "text" name = "awayLandingLocation4" id = "awayLandingLocation4"></TextField></td>
                                    <td STYLE = {props.submission.data.awayLandingQuantity4.background}><TextField disabled = {disabled} value = {props.submission.data.awayLandingQuantity4.value} onChange={e => props.TechLogHandleChange(e.target)} InputProps={{ inputProps: { min: 0 }}} error = {props.submission.data.awayLandingQuantity4.status === "error"} variant = {props.submission.data.awayLandingQuantity4.variant} fullWidth type = "number" name = "awayLandingQuantity4" id = "awayLandingQuantity4"></TextField></td>
                                    <td STYLE = {props.submission.data.awayLandingDetails4.background} colSpan = "15"><TextField disabled = {disabled} value = {props.submission.data.awayLandingDetails4.value} onChange={e => props.TechLogHandleChange(e.target)} error = {props.submission.data.awayLandingDetails4.status === "error"} variant = {props.submission.data.awayLandingDetails4.variant} fullWidth type = "text" name = "awayLandingDetails4" id = "awayLandingDetails4"></TextField></td>
                                    <td className = "checkBox">
                                        <Checkbox 
                                            value = {props.submission.data.awayLandingPaid4.value} 
                                            status = {props.submission.data.awayLandingPaid4.status}
                                            disabled = {disabled} 
                                            onChange={e => props.TechLogHandleChange(e.target)} 
                                            name="awayLandingPaid4" id="awayLandingPaid4">
                                        </Checkbox></td>
                                </tr>
                                <tr>
                                    <td STYLE = {props.submission.data.awayLandingLocation5.background}><TextField disabled = {disabled} value = {props.submission.data.awayLandingLocation5.value.toUpperCase()} onChange={e => props.TechLogHandleChange(e.target)} error = {props.submission.data.awayLandingLocation5.status === "error"} variant = {props.submission.data.awayLandingLocation5.variant} fullWidth type = "text" name = "awayLandingLocation5" id = "awayLandingLocation5"></TextField></td>
                                    <td STYLE = {props.submission.data.awayLandingQuantity5.background}><TextField disabled = {disabled} value = {props.submission.data.awayLandingQuantity5.value} onChange={e => props.TechLogHandleChange(e.target)}  InputProps={{ inputProps: { min: 0 }}} error = {props.submission.data.awayLandingQuantity5.status === "error"} variant = {props.submission.data.awayLandingQuantity5.variant} fullWidth type = "number" name = "awayLandingQuantity5" id = "awayLandingQuantity5"></TextField></td>
                                    <td STYLE = {props.submission.data.awayLandingDetails5.background} colSpan = "15"><TextField disabled = {disabled} value = {props.submission.data.awayLandingDetails5.value} onChange={e => props.TechLogHandleChange(e.target)} error = {props.submission.data.awayLandingDetails5.status === "error"} variant = {props.submission.data.awayLandingDetails5.variant} fullWidth type = "text" name = "awayLandingDetails5" id = "awayLandingDetails5"></TextField></td>
                                    <td className = "checkBox">
                                        <Checkbox 
                                            value = {props.submission.data.awayLandingPaid5.value} 
                                            status = {props.submission.data.awayLandingPaid5.status}
                                            disabled = {disabled} 
                                            onChange={e => props.TechLogHandleChange(e.target)} 
                                            name="awayLandingPaid5" id="awayLandingPaid5">
                                        </Checkbox></td>
                                </tr>
                            </tbody>
                        </table>
                    </Box>
                </Box>
            </Box>

        );
    }
}