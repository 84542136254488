// Import libraries
// import { useState } from 'react';
import firebase from 'firebase/compat/app';
import _ from "lodash";
import { API_URL } from "../../../config/config";
import axiosInstance from "../security/AxiosConfig";
// import { useState } from "react";
// import 'firebase/compat/auth';


// Import static data
import { rolesTemplate } from '../database/GetRolePermissions';
import { authorisationMessages } from './AuthorisationMessages';
import { UpdateDatabaseConnection } from '../UpdateFunctions';




//*************************** MAIN FUNCTION ****************************** */
export const GetAuthToken = async () => {
 
  const user = firebase.auth().currentUser;

  if (user) {
    try {
      const token = await user.getIdToken();
      return token;
    } catch (error) {
      console.error('ERROR GETTING TOKEN:', error);
    }
  }

  return null;
};


export const clearAuthState = () => {
  // firebase.auth().signOut(); // Sign out the current user
};

//***************************** FIND AND SET CURRENT USER ******************************/
// async function GetCurrentUser() {
//   const [currentUser, setCurrentUser] = useState();
//   var authUser
//   authUser ? setCurrentUser(authUser.displayName) : setCurrentUser()
  
// }
//**************************************************************************************/

//***************************** UPDATE USER CLAIMS *************************************/
export async function GetUserClaims(userEmail) {

  // http://localhost:8080/security-admin/claims/tm139ty%40gmail.com
  // http://localhost:8080/security-admin/claims/tm139ty%40gmail.com

  let responseObject = {};

      // console.log("In AuthUtilities, GetUserClaims, userEmail->", userEmail);

    try {
          // console.log("In GetUserClaims, responseObject->", responseObject);
        const response = await axiosInstance.get(`${API_URL}/security-admin/claims/${userEmail}`);
      
      
        responseObject.data = response.data;
        responseObject.databaseConnection = true;
        // console.log("In GetUserClaims, responseObject->", responseObject);
     
      return responseObject;  
        
    } catch (error) {
        console.error('Error fetching claims', error);
        responseObject.data = authorisationMessages.error;
        responseObject.databaseConnection = false;
        return responseObject;
    }
  }
// }
//**************************************************************************************/

//***************************** UPDATE USER CLAIMS *************************************/
export async function InitialiseClaims(userEmail) {

  // await InitialiseClaims("grant","guest",personRequest.email);

    // console.log("In AuthUtilities, userEmail->", userEmail);
  // if (userEmail) {
  //   // encodedUserEmail = userEmail.replace(/@/g, "%40");
  // }
    // console.log("In AuthUtilities, encodedUserEmail->", encodedUserEmail);

  let responseObject = {}

  try {
    // const response = await axiosInstance.get(`${API_URL}/security-admin/${action}-${role}/${userEmail}`);
    const response = await axiosInstance.get(`${API_URL}/security-admin/grant-guest/${userEmail}`);
    
    responseObject.data = response.data;
    responseObject.DatabaseConnection = true;
      console.log("In InitialiseUserClaims, response.data->", responseObject.data);
    return responseObject;
       
  } catch (error) {
      console.error('Error updating claims', error);
      responseObject.DatabaseConnection = false;
      return responseObject;
  }
}

// ******************************** ASSIGN ROLES BASED ON ID_TOKEN CLAIMS **************/
const UpdateRoles = async (claims) => {
var UserRoles = _.cloneDeep(rolesTemplate);
if (claims) {
  Object.keys(UserRoles).forEach((role) => {
    let thisRole = UserRoles[role];
    if (thisRole.claimName !== "role_guest") {
      claims[thisRole.claimName] ? thisRole.status = claims[thisRole.claimName] : thisRole.status = false
    }
  });
  }
    // console.log("In UpdateRoles, allRoles->", UserRoles)
  return UserRoles;
}

//********************************* UPDATE CLAIMS IN FIREBASE *************************/
export const UpdateClaims = async (selectedPerson) => {
  let responseObject = {
    databaseConnection: false,
    data: authorisationMessages.error
  }

  let claimsRequest = _.cloneDeep(selectedPerson)

    // console.log("In AuthUtilities, UpdateClaims->", selectedPerson);

  if (selectedPerson.email) {
    let claimList = []
    Object.keys(selectedPerson.roles).forEach((role) => {
      let roleObject = {
        roleName: role,
        claimName: selectedPerson.roles[role].claimName,
        status: selectedPerson.roles[role].status
      }

        // console.log("In AuthUtilities, UpdateClaims, roleObject->", roleObject);
    
      claimList.push(_.cloneDeep(roleObject));
    })

      // console.log("In AuthUtilities, UpdateClaims, claimList->", claimList);
    
    claimsRequest.claims = _.cloneDeep(claimList)
      // console.log("In AuthUtilities, UpdateClaims, claimRequest->", claimsRequest);

    try {
        let response = await axiosInstance.put(`${API_URL}/security-admin/claims`, claimsRequest);
        responseObject.databaseConnection = true;
        responseObject.data = response.data;
          console.log("In AuthUtilities, responseObject->", responseObject)
      return responseObject

    } catch (error) {
        console.log("Error updating claims->", error)
        responseObject.databaseConnection = false
        responseObject.data = authorisationMessages.error
          // console.log("In AuthUtilities, responseObject->", responseObject)
      return responseObject
    }
  }
  // return responseObject

}

//********************************* GET FIREBASE CLAIMS *******************************/

export const GetUserRoles = async (selectedPerson) => {
  let responseObject = {}
  let user = {}
  let userRoles = _.cloneDeep(rolesTemplate);
    // console.log("GetUserRoles, selectedPerson->", selectedPerson);
    // console.log("AssignUserRoles, userRoles->", userRoles);

    // console.log("AssignUserRoles, userRoles->", userRoles);
  if (selectedPerson) { 
    // Get the selectedPerson's claims
    if (selectedPerson.email) {
      responseObject = await GetUserClaims(selectedPerson.email);
    }
      // console.log("AuthUtilities, AssignUserRoles, responseObject->", responseObject);
    if (responseObject.data) {
      Object.keys(responseObject.data).forEach((claim) => {
        // console.log("AuthUtilities, AssignUserRoles, Object.keys, claim->", claim);
        Object.keys(userRoles).forEach((role) => {
          if (userRoles[role].claimName === claim) {
            userRoles[role].status = _.cloneDeep(responseObject.data[claim]);
          }
        })
      })
    }
      // console.log("AuthUtilities, userRoles II->", userRoles);

    // return selected user privileges
    responseObject.data = userRoles

    return responseObject;

    } else {
      user = firebase.auth().currentUser;
        // console.log("AssignUserRoles, selectedPerson user->", user);
    }
  

  if (!selectedPerson && user) {

      try {
        let claimsToken = await user.getIdTokenResult();
        responseObject.data = await UpdateRoles(claimsToken.claims)
          // console.log("In AssignUserRoles, userRoles (signed-in Person)->", userRoles)
        // return Logged-in user privileges
        responseObject.databaseConnection = true
        return responseObject;
      } catch (error) {
          console.log("Error Getting Token", error)
          responseObject.databaseConnection = false
      }
  } else {
    // return guest privileges
    responseObject.databaseConnection = false
    responseObject.data = rolesTemplate
    return responseObject;
  }
}


