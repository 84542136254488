// Import libraries
// import axios from "axios";
import axiosInstance from "../security/AxiosConfig.js";
import _ from "lodash";

// Import CSS, images, icons and static data
import { API_URL } from '../../../config/config.js';
import { databaseConnectionMessages } from "./DatabaseConnectionMessages.js";

// import { useAuthFirebase } from "../../utilities/AuthContextFirebase.js";




    // ************************* SEND FLIGHT TO DATABASE *******************************
    
export async function PostFlight(submission, type) {

    var responseObject = {}
    const databaseSubmission = {}
       
        // console.log("In PostFlight, submission->", submission)
    Object.keys(submission).forEach((field) => {
        // console.log("In TechLog, SubmitFlight, object->",object ,"submission[object]->", submission[object])
        databaseSubmission[field] = _.cloneDeep(submission[field].value);

    });
        // console.log("In PostFlight, PostFlight, databaseSubmission->", databaseSubmission)
    try {
            // console.log("In PostFlight, databaseSubmission -> ", databaseSubmission)
            let response = {};
        if (type === "update") {
                // console.log("In PostFlight, PostFlight, type->", type);
                // console.log("In PostFlight, PostFlight, databaseSubmission->", databaseSubmission);
            response = await axiosInstance.post(`${API_URL}/flights/update`, databaseSubmission); 
        } else {
                // console.log("In PostFlight, PostFlight, databaseSubmission->", databaseSubmission);
            response = await axiosInstance.post(`${API_URL}/flights`, databaseSubmission); 
        }

        responseObject.data = response.data;
        responseObject.databaseConnection = true;
            // console.log("In PostFlight, resopnseObject->", responseObject)
            // console.log("In SubmitFlight, databaseSubmission->", databaseSubmission);
            // console.log("In SubmitFlight, response.status->", response.status);
        return responseObject;
    } catch (error) {
        console.error("Error submitting a flight", error);
        responseObject.data = databaseConnectionMessages.error;
            // console.log("In PostFlight, resopnseObject.data->", responseObject.data)
        responseObject.databaseConnection = false;
        return responseObject;
    }
}