// Import libraries
import {Alert} from "@mui/material"
import FlightValidations from "./FlightValidations.js";
import AircraftValidations from "./AircraftValidations.js";
import PersonValidation from "./PersonValidation.js";
import CourseValidation from "./CourseValidation.js";

// ******************************* MAIN FUNCTION ************************************************
export default function Validate(submission, selectedAircraft, type, aircraftList) {
    let validationResultList = {};
    switch(type) {
        case "arrival":
        case "departure":
        case "update":
            validationResultList = FlightValidations(submission, selectedAircraft, type);
                // console.log("In Validate, arrival, validationResultList", validationResultList);
            return validationResultList;

        case "newAircraft":
        case "updateAircraft":
            // console.log("In Validate, updateAircraft, submission", submission, "selectedAircraft->", selectedAircraft,"aircraftList->", aircraftList);
            validationResultList = AircraftValidations(submission, selectedAircraft, aircraftList);
            // <Alert severity = "info">There is no validation for this (yet)... .</Alert>
        return validationResultList;

        case "claims":
            validationResultList = PersonValidation()
                // console.log("In Validate, validationResultList->", validationResultList)
            return validationResultList;
        case "updateCourse":
        case "createCourse":
                console.log("In Validate, Submission->", submission, "type->", type);
            validationResultList = CourseValidation(submission, type)
                // console.log("In Validate, validationResultList->", validationResultList)
        return validationResultList;  

        default:
            
            console.log("There is no validation for this... and, there may never be... Please propose an idea to Support.");
            
    }

    
};