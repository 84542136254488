//Import libraries
import { departureMandatoryFields } from "../../flights/flightData/DepartureData.js";
import { flightMandatoryFields } from "../../flights/flightData/FlightData.js";
// import dayjs from 'dayjs';
import moment from "moment";
import {tolerance} from "../../flights/techLog/arrival/FlightTimeSVCAlignment.js";
import RowFieldsResults from "./RowFieldsResults.js";
import { gallonsToLitres } from "../../aircraft/AircraftData.js";
import _ from "lodash";


//Import CSS & images
import { courseValidationMessages } from "./CourseValidationMessages.js";

// ********** CHECKING FUNCTIONS **********



// ********** CHECK MANDATORY FIELDS - FUNCTION **********
function ValidateMandatoryFieldsCourse(courseSubmission, resultList) {
    // Construct mandatory fields template
    let result = {};


    // For each required field, check if not empty and, if so, add an item to the result list
        // console.log("In Validations, courseSubmission) -> ", courseSubmission);
    Object.keys(courseSubmission).forEach((field) => {
            // console.log("In Validations, field) -> ", field);
            // console.log("In Validations, resultList->", resultList);
        switch(field) {
            case "approvals":
            case "courseImage":
            case "courseIcon":
            break;
            case "lessons":
                courseSubmission.lessons.forEach((lesson, index) => {
                    Object.keys(lesson).forEach((field) => {
                            // console.log("In CourseValidation, field->", field);
                        switch(field) {
                            case "id":
                            case "courseId":
                            case "displayNumber":
                            case "section":
                            break;
                            default:
                                // console.log("In CourseValidation, lesson->", lesson);
                                // console.log("In CourseValidation, field->", field);
                            if (lesson[field].required &&     
                                (lesson[field].value === undefined || 
                                lesson[field].value === "" || 
                                lesson[field].value === " " || 
                                lesson[field].value === null)) 
                            {
                                console.log("In CourseValidation, index->", index);
                                result = {
                                    status: "error",
                                    message: courseValidationMessages.missingMandatoryField,
                                    field1: "lessons",
                                    field2: index,
                                    field3: field,
                                    field4: ""
                                }
                                // newResult = _.cloneDeep({});
                                resultList.push(result);
                                
                                // console.log("In CourseValidation, field->", field);
                            }
                        }
                    });
                });

            break;
            default:
                // console.log("In Validations, field & courseSubmission.[field]] -> )", field, " & ", courseSubmission[field]);
            if (courseSubmission[field].required && 
                        (courseSubmission[field].value === undefined || 
                        courseSubmission[field].value === "" || 
                        courseSubmission[field].value === " " || 
                        courseSubmission[field].value === null)) 
            {
                result = {
                    status: "error",
                    message: courseValidationMessages.missingMandatoryField,
                    field1: field,
                    field2: "",
                    field3: "",
                    field4: ""
                }
                resultList.push(result);
                result = _.cloneDeep({});
            }
        }
            // console.log("In FlightValidations, field->",field," field.includes(oil)->", field.includes("oil"));
            // console.log("In FlightValidations, selectedAircraft->", selectedAircraft);
            // console.log("In FlightValidations, !(field.includes(oil) && selectedAircraft._planeType === JET)->", !(field.includes("oil") && selectedAircraft._plateType === "JET"));
    });
  
    return resultList;
}


// ******************************* MAIN FUNCTION ************************************************
export default function CourseValidations(courseSubmission, type) {

    // Initialise status and message
    let result = {
        status: "success",
        message: courseValidationMessages.successMessages.update,
        field1: "",
        field2: "",
        field3: "",
        field4: ""
    };

    if (type === "create") {
        result.message = courseValidationMessages.successMessages.create
    }

    if (type === "update") {
        result.message = courseValidationMessages.successMessages.update
    }
   
    let list = [];
    let resultList = [];
    list.push(result);

    // ************* RUN CHECKS *************
    // CHECK -> Aircraft selection check
        console.log("In validations, courseSubmission.name.value->", courseSubmission.name.value);
    if (courseSubmission.name.value === "NEW COURSE" || courseSubmission.name.value === "" ) {
        result.status = "error";
        result.message = courseValidationMessages.courseSelectionMessage;
        result.field1 = "name";
        resultList.push(result);
    } else {
        // CHECK -> Mandatory field checks
        resultList = ValidateMandatoryFieldsCourse(courseSubmission, resultList);
            // console.log("In validations, post all checks performed resultList->", resultList);
        if (resultList.length === 0 || (resultList.length === 1 && (resultList[0].status === undefined || resultList[0].status === ""))) {
            result = {
                status: "success",
                message: courseValidationMessages.successMessages.create,
                field1: "",
                field2: "",
                field3: "",
                field4: ""
            };
        }
            // if (type === "update") {
            //     result.message = courseValidationMessages.successMessages.update
            //         // console.log("In Validations, result Arrival Success Post -> ", result)
            // }   
            // resultList.push(result)
            // console.log("In Validations, Post-ALL Checks, validationsResultList -> ", resultList);
        
    }
        // console.log("In Validations, At Return, resultList ->", resultList)
    return resultList;
}