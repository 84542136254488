// Import libraries
import { API_URL } from "../../../config/config";
import axiosInstance from "../security/AxiosConfig";
import _ from "lodash";

// Import CSS, images, icons & static data
import {databaseConnectionMessages} from "./DatabaseConnectionMessages";
import flightCommercial from "../../homePage/images/flightCommercial.jpg"
 //import flightClouds from "../../homePage/images/flightClouds.jpg";
import flightIoW from "../../homePage/images/flightIoW.JPG";
import flightNightLanding from "../../homePage/images/flightNightRating.jpeg";
//import flightMEP from "../../homePage/images/flightMEP.jpg"
import flightMEP2 from "../../homePage/images/flightMEP2.jpeg"
import flightLAPL from "../../homePage/images/flightLAPL.jpeg"
import flightIMC from "../../homePage/images/flightIMC.jpeg"
import flightInstruments from "../../homePage/images/flightInstruments.jpeg"
import flightSimulator from "../../homePage/images/flightSimulator.jpeg"
import flightRadioTelephony from "../../homePage/images/flightRadioTelephony.jpeg"
// Icons
import lightAircraftPilotsLicense from "../../courses/icons/lightAircraftPilotsLicense.png";
import privatePilotsLicense from "../../courses/icons/privatePilotsLicense.png";
import instrumentRatingRestricted from "../../courses/icons/instrumentRatingRestricted.png";
import nightRating from "../../courses/icons/nightRating.png";
import multiEngineRating from "../../courses/icons/multiEngineRating.png";
import fullInstrumentRating from "../../courses/icons/fullInstrumentRating.png";
import commercialPilotsLicense from "../../courses/icons/commercialPilotsLicense.png";
import groundSchool from '../../courses/icons/groundSchool.png';


//********************** MAIN FUNCTION *************************** */


async function GetCourseData(props) {
    let responseObject = {}
    try {
        // if (props.selectedCourse !== "SELECT") {
        const response = await axiosInstance.get(`${API_URL}/courses/all-by-status`);
        responseObject.data = _.cloneDeep(response.data);
        responseObject.databaseConnection = true;
        //   setFlightList(responseObject.data);
            // console.log("In GetCourseData, responseObject->", responseObject);
        return responseObject;

        // }
    } catch (error) {
        console.error('Error fetching courses', error);
        responseObject.data = databaseConnectionMessages.error;
        responseObject.databaseConnection = false;
        return responseObject;
    }

}


const courseData = [
    {
        name:"Light Aircraft Pilot's Licence",
        hasCurriculum: true,
        abbreviation: "LAPL",
        key: "lightAircraftPilotsLicence",
        description: 
        `The LAPL will allow you to fly with a total of 4 persons in a single-engine piston aircraft up to 2000 kgs in the UK.`,
        duration: "It can be shorter to complete than the PPL since it requires fewer minimum flight hours",
        medical: "Class 2 Medical (with less stringent requirements)",
        hours: "30 Flight Hours (Minimum)",
        planeType: "Single Engine Piston Aircraft up to 2000kg (which covers the majority of light aircraft).",
        image: {flightLAPL},
        icon: {lightAircraftPilotsLicense}
    },
    {
        name:"Private Pilot's Licence",
        hasCurriculum: true,
        abbreviation: "PPL",
        key: "privatePilotsLicence",
        description: `The PPL is an In ternational Civil Aviation Organisation (ICAO) licence recogised inside and outside the UK.   It allows
        flight of single engine propellar aircraft in visual conditions.  It is the first prerequisite to all other more complex ratings and licences.`,
        duration: "It generally takes longer than the LAPL because of the higher minimum flights hours.",
        medical: "Class 2 Medical",
        hours: "45 Flight Hours (Minimum)",
        planeType: "Single Engine Piston",
        image: {flightIoW},
        icon: {privatePilotsLicense}
    },
    {
        name:"Instrument Rating (Restricted)",
        hasCurriculum: true,
        abbreviation: "IRR",
        key: "InstrumentRatingRestricted",
        description: `The rating allows flight in Instrument Meteorological Conditions (eg. in clouds) in UK Uncontrolled Airspace.  
          This includes performing instrument approaches to airfields in the UK.`,
        duration: "",
        medical: "Class 2 Medical",
        hours: "25 Flight Hours (Minimum)",
        planeType: "Single Engine Piston",
        image: {flightIMC},
        icon: {instrumentRatingRestricted}
    },
    {
        name:"Night Rating",
        hasCurriculum: true,
        abbreviation: "",
        key: "nightRating",
        description: `This is a visual rating (ie. does not require instrument qualifications) which allows flight at night 
          including visual landings at appropriately equipped airfields`,
        duration: "",
        medical: "Class 2 Medical",
        hours: "8 Flight Hours (Minimum)",
        planeType: "Single Engine Piston",
        image: {flightNightLanding},
        icon: {nightRating}
    },
    {
        name:"Multi-Engine Piston Rating",
        hasCurriculum: true,
        abbreviation: "MEP",
        key: "multiEnginePistonRating",
        description: "Allows flight of multi-engine piston propeller aircraft and is required component of the Multi-Engine Instrument rating.",
        duration: "This rating has a pre-requisite of 70 hours  minimum as pilot in command (PIC)",
        medical: "Class 2 Medical",
        hours: "15 Flight Hours (Minimum)",
        planeType: "Multi-Engine Piston",
        image: {flightMEP2},
        icon: {multiEngineRating}
    },
    {
        name:"Instrument Rating",
        hasCurriculum: true,
        abbreviation: "IR",
        key: "InstrumentRating",
        description: `Flight in controlled airspace including airways and under Instrument Flight Rules in ICAO member states.
          This includes instrument approaches and holding patterns at controlled airfields down to a minimum height of 200 feet above the ground.`,
        duration: `This rating has a pre-requisite of the Air Transport Pilot's Licence (ATPL) ground school exams 
        OR the Instrument Rating sub-set of ATPL exams and 50 hours as Pilot In Command.`,
        medical: "Class 1 Medical",
        hours: "25 Flight Hours (Minimum)",
        planeType: "Single Engine Piston OR Multi-Engine Piston",
        image: {flightInstruments},
        icon: {fullInstrumentRating}
    },
    {
        name:"Commercial Pilot's Licence",
        hasCurriculum: true,
        abbreviation: "CPL",
        key: "commercialPilotsLicence",
        description: `A CPL is the first step towards a career as a commercial pilot and towards higher levels of instructing.  It allows
        holders to act as pilot in command (PIC) or co-pilot for aerial work and, under certain restrictions,
         act as PIC or co-pilot in commercial air transport operations`,
        duration: "The pre-requisites include 150 hours as pilot in command, ATPL or CPL ground school exams, PPL with a Night Rating and an MEP rating.",
        medical: "Class 1 Medical",
        hours: "25 FLight Hours (Minimum)",
        planeType: "Rating Dependent",
        image: {flightCommercial},
        icon: {commercialPilotsLicense}
    },
    {
        name:"Accredited Simulator Training",
        hasCurriculum: false,
        abbreviation: "",
        key: "accreditedSimulatorTraining",
        description: `Two FNPT II simulators manufactured by Diamond and approved by both the UK Civil Aviation Authority (CAA) 
        and European Aviation & Safety Agency (EASA) provide the opportunity to log between 5 and 35 hours toward different licences & ratings.`,
        duration: "They are also invaluable for training pilots on complex emergency procedures.",
        hours: "Up to 5 hours toward a PPL or IR(R), 30 hours toward a SEIR and 35 hours toward a MEIR",
        medical: "N/A",
        planeType: "Diamond DA42 & DA40",
        image: {flightSimulator},
        icon: {}
    },
    {
        name:"Ground School",
        hasCurriculum: true,
        abbreviation: "",
        key: "groundSchool",
        description: `Some find ground school daunting at first, however not only are they a regulatory requirement but 
         they also provide a foundation for your practical flying`,
        duration: `There are 9 subjects at PPL/LAPL level - 1. Air Law, 2. Operational Procedures, 3. Navigation, 4. Meteorology, 
        5. Human Performance, 6. Flight Planning and Performance, 7. Principles of Flight, 8. Aircraft General Knowledge and 9. Communications`,
        hours: "N/A",
        medical: "N/A",
        planeType: "N/A",
        image: {flightRadioTelephony},
        icon: {groundSchool}
    }

]


export {GetCourseData, courseData};
