// *************** IMPORT LIBRARIES *****************
import _ from "lodash";

// *************** IMPORT STATIC DATA *****************



// ************** MAIN FUNCTION *****************

function NewPersonFormReducer(newPersonSubmission, action) {

    // let field = action.name

    switch (action.type) {   
        case "initialise":
            newPersonSubmission = _.cloneDeep(action.value)
                // console.log("In NewPersonFormReducer, newPersonSubmission->", newPersonSubmission)
        return {
            ...newPersonSubmission
        }

        case 'mergeNames':
            let tempObject= {};
            action.value.forEach((person) => {
                let name = person.firstName + " " + person.lastName;
                person.name = name;
                tempObject[name] = person;
            })
                // console.log("In NewPersonFormReducer, tempObject->", tempObject)
            newPersonSubmission = _.cloneDeep(tempObject);
                // console.log("In NewPersonFormReducer, newPersonSubmission->", newPersonSubmission)
            return {
                ...newPersonSubmission
            }

        case "acknowledge":
                    // console.log("In NewPersonFormReducer, action->", action, "newPersonSubmission->", newPersonSubmission);
            if (newPersonSubmission !== {}) {
            Object.keys(newPersonSubmission).forEach((newPerson) => {
                    // console.log("In NewPersonFormReducer, newPersonSubmission[newPerson]->", newPersonSubmission[newPerson], "action->", action);
                    //console.log("In NewPersonFormReducer, A - newPerson->", newPerson, "B - action.value.name->", action.value.name, "A === B", (newPerson === action.value.name));
                if (newPerson === action.value.name) {
                    if (action.value.acknowledged) {
                        newPersonSubmission[newPerson].acknowledged = true;
                            // console.log("In NewPersonFormReducer, newPersonSubmission[newPerson].acknowledged->", newPersonSubmission[newPerson].acknowledged)
                    } else {
                        newPersonSubmission[newPerson].acknowledged = false;
                    }
                }
            })
            }
                // console.log("In NewPersonFormReducer, newPersonSubmission->", newPersonSubmission[action.value.name]);
            return {
                
                ...newPersonSubmission,

            }
        default: 
            // console.log("Unknown action updating New Person Submission")
            }   
};

export default NewPersonFormReducer;