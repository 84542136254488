// Import libraries
import Media from "../../../utilities/environment/Media.js"
import { Box } from "@mui/material";


// Import CSS, images & icons
import "./Departure.css";
import departure from "../images/departure.png";

// *********************** MAIN FUNCTION **************************
export default function DepartureImage(props) {
    var mobile = `dep-image${Media()}`;
        // console.log("In DepartureImage Part 0, mobile -> ", mobile);

    if (mobile === "dep-image") {
            // console.log("In DepartureImage Part I, mobile -> ", mobile);
        return(
            <Box className = {mobile} sx={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                <div className = "dep-title" >Departure</div>
                <img src = {departure} alt ="Departure"/>
            </Box>
        );
    } else {
            // console.log("In DepartureImage, Part II, mobile ", mobile)
        return (
            <Box className = {mobile} sx={{display: "flex", flexDirection: "row", }}>
                <img src = {departure} alt ="Departure"/>
                <span className = "dep-title-mobile">Departure</span>
                <div className = "aircraft-header-mobile" id = "registration" name = "registration" onChange={e => props.TechLogHandleChange(e.target)}>{props.registration}</div>
            </Box>
        );
    }
}