// Import libraries


// Import CSS, images and icons


export default function RowFieldsResults(row, result, rowFields, submission, validationResultList) {
    switch (row) {
        case "row1Zero":
            if (submission.data.awayLandingLocation1.value !== "") {
                result.field1 = rowFields.location;
            } else {result.field1 = ""}
            result.field2 = "";
            if (submission.data.awayLandingDetails1.value !== "") {
                result.field3 = rowFields.details;
            } else {result.field3 = ""}
            result.field4 = "";
            validationResultList.push(result);
            break;
        case "row1Null":
                // console.log("In RowFieldsResults, row1Null, submission -> ", submission)
                // console.log("In RowFieldsResults, row1Null, rowFields -> ", result)
            if (submission.data.awayLandingLocation1.value !== "") {
                result.field1 = rowFields.location;
            } else {result.field1 = ""};
            result.field2 = rowFields.quantity;
            if (submission.data.awayLandingDetails1.value !== "") {
                result.field3 = rowFields.details;
            } else {result.field3 = ""};
            result.field4 = "";
            validationResultList.push(result);
            break;
        case "row1To5": 
            // Log any required fields which are missing in the current row given some fields in the current row are populated
            if (submission.data[rowFields.location].value === "") {
                result.field1 = rowFields.location;
                    // console.log ("In validations, result.field1 ->", result.field1);
            } else {result.field1 = ""}
            if (submission.data[rowFields.quantity].value === null || submission.data[rowFields.quantity].value === "") {
                result.field2 = rowFields.quantity;
                    // console.log ("In validations, result.field2 ->", result.field2);  
            } else {result.field2 = ""}
            if (submission.data[rowFields.details].value === "") {
                result.field3 = rowFields.details;
                    // console.log ("In validations, result.field3 ->", result.field3);
            } else {result.field3 = ""}
            result.field4 = "";
            // Log the result of the current row validation in the validation list
            validationResultList.push(result);    
                // console.log("In awayLandings, END result i", i, result) 
            break;
        default: console.log("No Known Row");
    }
        console.log("In RowFieldsResults, validationResultList->",validationResultList);
    return validationResultList;
}