// Import libraries
import {Box, TextField} from "@mui/material";


// Import CSS, 
import "./Departure.css";
import petrolPump from "../images/petrolPump.png";
import { gallonsToLitres } from "../../../aircraft/AircraftData";

// ************************* MAIN FUNCTION ****************************
export default function TableDepartureFuel(props) {

    let sim = false;

    if (props.selectedAircraft.planeType === "SIM") {
        sim = true
    } else {
        sim = false
    }

    let excludedForAircraftType = ["SIM"];
    if (excludedForAircraftType.indexOf(props.selectedAircraft._planeType) === -1) {
    // props.submission.departureFuelLeft = props.selectedAircraft._fuelTank1Capacity
    // props.submission.departureFuelRight = props.selectedAircraft._fuelTank2State

        return (
            <Box className = "table2">
                <table>
                    <tbody>
                        <tr>
                            <td className = "label">Fuel</td>
                            <td ><img src = {petrolPump} alt = "Petrol Pump" className = "petrolImg"></img></td>
                            <td className = "blank" colSpan = "1"></td>
                        </tr>
                        <tr>
                            <td className = "blank2" colSpan = "1"></td>
                            <td className = "LR">Left</td>
                            <td className = "LR">Right</td>
                        </tr>
                        <tr>
                            <td className = "label" colSpan = "1" >Uplift <br></br><span className = "footnote">(L)</span></td>
                            <td className = "inputNum" colSpan = "1" STYLE = {props.submission.data.departureFuelUpliftLeft.background}>
                                <TextField 
                                    value = {props.submission.data.departureFuelUpliftLeft.value} 
                                    InputProps={{ inputProps: { min: 0, max: props.selectedAircraft._fuelTank1Capacity * gallonsToLitres}}} 
                                    type = "number" 
                                    label = {props.submission.data.departureFuelUpliftLeft.required} 
                                    error = {props.submission.data.departureFuelUpliftLeft.status === "error"} 
                                    variant = {props.submission.data.departureFuelUpliftLeft.variant} 
                                    fullWidth 
                                    name = "departureFuelUpliftLeft" 
                                    id = "departureFuelUpliftLeft" 
                                    disabled = {sim === true}
                                    onChange = {e => props.TechLogHandleChange(e.target)}>
                                </TextField>
                            </td>
                            <td className = "inputNum" colSpan = "1" STYLE = {props.submission.data.departureFuelUpliftRight.background}>
                                <TextField 
                                    value = {props.submission.data.departureFuelUpliftRight.value} 
                                    InputProps={{ inputProps: { min: 0, max: props.selectedAircraft._fuelTank2Capacity * gallonsToLitres }}} 
                                    type = "number" label = {props.submission.data.departureFuelUpliftRight.required} 
                                    error = {props.submission.data.departureFuelUpliftRight.status === "error"} 
                                    variant = {props.submission.data.departureFuelUpliftRight.variant} 
                                    fullWidth 
                                    name = "departureFuelUpliftRight" 
                                    id = "departureFuelUpliftRight" 
                                    disabled = {sim === true}
                                    onChange = {e => props.TechLogHandleChange(e.target)}>
                                </TextField>
                            </td>
                        </tr>
                        <tr>
                            <td className = "label">Status* <br></br><span className = "footnote">(USg)</span></td>
                            <td className = "inputNum" STYLE = {props.submission.data.departureFuelLeft.background}>
                                <TextField 
                                    value = {props.submission.data.departureFuelLeft.value} 
                                    InputProps={{ inputProps: { min: 0, max: props.selectedAircraft._fuelTank1Capacity * gallonsToLitres }}} 
                                    fullWidth
                                    error = {props.submission.data.departureFuelLeft.status === "error"} 
                                    variant = {props.submission.data.departureFuelLeft.variant} 
                                    type = "number" name = "departureFuelLeft" id ="departureFuelLeft" 
                                    disabled = {sim === true}
                                    onChange = {e => props.TechLogHandleChange(e.target)}>
                                </TextField>
                            </td>
                            <td className = "inputNum" STYLE = {props.submission.data.departureFuelRight.background}>
                                <TextField 
                                    value = {props.submission.data.departureFuelRight.value} 
                                    InputProps = {{ inputProps: { min: 0, max: props.selectedAircraft._fuelTank2Capacity }}} 
                                    fullWidth 
                                    type = "number" 
                                    error = {props.submission.data.departureFuelRight.status === "error"} 
                                    variant = {props.submission.data.departureFuelRight.variant} 
                                    name = "departureFuelRight" 
                                    id = "departureFuelRight" 
                                    // required 
                                    disabled = {sim === true}
                                    onChange = {e => props.TechLogHandleChange(e.target)}>
                                </TextField>
                            </td>
                        </tr>
                        <tr>
                            <td className = "LR" colSpan = "1">Tank Capacity</td>
                            <td className = "fuelCapacity" colSpan = "1">{(props.selectedAircraft._fuelTank1Capacity)}</td>
                            <td className = "fuelCapacity" colSpan = "1">{(props.selectedAircraft._fuelTank2Capacity)}</td>
                        </tr>
                    </tbody>
                </table>    
            </Box>
        );
    }
}