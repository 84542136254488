import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
// import { LinearProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';

// REDUNDANT... CHANGE TO SOME OTHER PROGRESS COMPONENT //
export function Progress(props) {
    let loading = props.progressStatus;
        // console.log("In Progress, loading->", loading)

    return (
      <Box sx={{ display: 'flex',  flexDirection: "row", justifyContent: "center", width: "100%" }}>
        <Fade
            in={loading}
            style={{ transitionDelay: props.loading ? '800ms' : '0ms' }}
            unmountOnExit
        >
            <CircularProgress />
            {/* <LinearProgress /> */}
        </Fade>
      </Box>
    );
  }