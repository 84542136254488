//Import libraries
import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";
import ReportsMenu from "./ReportsMenu.js";
import AdminMenu from "./AdminMenu.js"
import LoginMenu from './LoginMenu.js';
import { useNavigate } from "react-router-dom";
import { HasPermission } from '../utilities/security/HasPermission.js';
import { Progress } from '../utilities/feedback/Progress.js';



//Import fonts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import azTechTheme from '../utilities/environment/ColourTheme.js';

// import { permissionedRole } from '../utilities/database/RoleNames.js';


// Import CSS & images
// import "./MainMenu.css";


export default function MenuDesktop(props) {
  
  const backgroundColour = azTechTheme.palette.blanchedAlmond
 
  const navigate = useNavigate();
  const handleNavigate = (path) => {
      // console.log("In MenuDeskTop, handleMenuClick, path->", path);
    navigate(path);
  }

  let currentUser = props.currentUser;
  let currentUserRoles = props.currentUserRoles;
 
  // **************** DATABASE CONNECTION FACILITIES *********************
  // import database function from ./src/components/utilities/database/
  const [databaseConnection, setDatabaseConnection] = useState(false);
  const [progressStatus, setProgressStatus] = useState(false);

  function UpdateDatabaseConnection (response) {
      setDatabaseConnection(response);
  }
  // *********************************************************************


  function UpdateProgressStatus(newStatus) {
    setProgressStatus(newStatus);
  }



  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" top="0" sx = {{maxHeight: "60px"}} >
        {/* <Toolbar css = {`color:#e65100`}> */}
        <Toolbar sx = {{backgroundColor: backgroundColour}}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: "black", flexDirection: 'row', justifyContent: 'space-evenly', flexWrap: 'wrap'}}>
            <Box>
                <Button color = "inherit" id = "home" 
                        disabled = {!HasPermission("home", currentUserRoles)} 
                        onClick = {() => handleNavigate("/")}>Home</Button>
                <Button color = "inherit" id = "techLog" 
                        disabled = {!HasPermission("techLog", currentUserRoles)} 
                        onClick = {() => handleNavigate("/techLog")}>TechLog</Button>
                <ReportsMenu id = "reports" handleNavigate = {handleNavigate} currentUserRoles = {currentUserRoles}/>
                <Button color = "inherit" id = "bookings" 
                        // disabled = {!HasPermission("bookings", currentUserRoles)} 
                        disabled = {!HasPermission("bookings", currentUserRoles)} 
                        onClick = {() => handleNavigate("/bookings")}>Bookings</Button>
                <Button color = "inherit" id = "lessons" 
                        // disabled = {!HasPermission("lessons", currentUserRoles)} 
                        disabled = {!HasPermission("courseSelection", currentUserRoles)} 
                        onClick = {() => handleNavigate("/courseSelection")}>Courses</Button>
            </Box> 
          </Typography>
          {/* <Box sx = {{verticalAlign: "bottom"}}> <Progress progressStatus = {progressStatus}/></Box> */}
          <Box 
            color = "black" 
            sx = {{width: "200px", display: "flex", flexDirection: "row", justifySelf: "spaceBetween", mr:"5px",gap: "25px"}}>
            <Box sx = {{verticalAlign: "bottom"}}> <Progress progressStatus = {progressStatus}/></Box>
            <AdminMenu 
              sx = {{color: "black"}} id = "admin" 
              currentUserRoles = {currentUserRoles} 
              currentUser = {currentUser}
              handleNavigate = {handleNavigate}/>
            <LoginMenu 
              calledFrom = "MenuDesktop" 
              UpdateUser = {props.UpdateUser}
              currentUser = {currentUser} 
              ResetUser = {props.ResetUser}
              UpdateResult = {props.UpdateResult}
              UpdateSnackBarStatus = {props.UpdateSnackBarStatus}
              UpdateProgressStatus = {UpdateProgressStatus}/>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
   
  );
}