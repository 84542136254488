import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function CircularProgressWithValue(props) {


    return (
    <Box sx = {{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} value = {props.progress}/>
        <Box
        sx = {{
            top: 0, 
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}
        >
        <Typography
            variant="caption"
            component="div"
            sx={{ color: 'text.secondary' }}
        >
            {`${Math.round(props.progress)}%`}
        </Typography>
        </Box>
    </Box>
    );
}

CircularProgressWithValue.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

// export default function CircularWithValueLabel(props) {
// //   const [progress, setProgress] = React.useState(props.progress);

// //   React.useEffect(() => {
// //     // const timer = setInterval(() => {
// //     //   setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
// //     // }, 800);
// //     // return () => {
// //     //   clearInterval(timer);
// //     // };
// //     setProgress(props.progress)
// //   }, [value]);

//   return <CircularProgressWithValue value={props.progress} />;
// }