// Import libraries
import React, {useState} from "react";
import Switch from '@mui/material/Switch';
import AircraftSelect from "../../flights/techLog/banner/AircraftSelect.js";
import MakeAndModel from "../../flights/techLog/banner/MakeAndModel.js";
// import MaintenanceTable1 from "./MaintenanceTable1.js";
// import MaintenanceTable2 from "./MaintenanceTable2.js";
// import MaintenanceTable3 from "./MaintenanceTable3.js";
import {Box, Button} from "@mui/material"
import BannerLogo from "../../flights/techLog/banner/BannerLogo.js";
import BannerTitle from "../../flights/techLog/banner/BannerTitle.js"
import MuiAlert from '@mui/material/Alert';
import ClearButton from "../../flights/techLog/banner/ClearButton.js";
import EditSwitch from "./EditSwitch.js";
import DatabaseConnectionWarning from "../../utilities/database/DatabaseConnectionWarning.js";
import { MaintenanceChipsBanner } from "../../utilities/maintenance/MaintenanceChips.js";


// Import CSS & images
import "./AdminAircraft.css";
import azTechTheme from "../../utilities/environment/ColourTheme.js";

//Import fonts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';





// ******************************* MAIN FUNCTION ************************************************
export default function AdminAircraftBanner(props) {
const calledFrom = "AdminAircraft"
const backgroundColour = azTechTheme.palette.blanchedAlmond
  
    // Render
    return (
        <Box className = "banner" sx = {{backgroundColor: backgroundColour}}>
            <Box className = "bannerRow1" >
                <Box sx = {{display: "flex", flexDirection: "row", selfJustify: "flexStart"}}>
                    <BannerTitle title = {props.title}/>
                    <Box className = "databaseConnectionWarning">
                            <DatabaseConnectionWarning databaseConnection = {props.databaseConnection}/>
                    </Box>  
                </Box>
                <div className = "logo"><BannerLogo/></div>
            </Box>
            <Box className = "bannerRowAdmin" >
                <Box className = "aircraftSelectAdmin">  
                    <AircraftSelect 
                        calledFrom = {calledFrom}
                        databaseConnection = {props.databaseConnection} 
                        registration = {props.selectedAircraft._registration} 
                        aircraftList = {props.aircraftList} 
                        UpdateSelection={props.UpdateSelection}/>
                </Box>
                {/* <MaintenanceChipsBanner/>
                    User:{props.authUser.displayName} */}
                <Box>
                    <EditSwitch UpdateEditing = {props.UpdateEditing} AircraftSubmit={props.AircraftSubmit} selectedAircraft = {props.selectedAircraft}/>
                </Box>
            </Box>
        </Box>
    );
};
