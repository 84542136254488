//Import libraries
import _ from "lodash";
// import { UpdateDatabaseConnection, UpdateResult, UpdateSnackBarStatus } from "../utilities/UpdateFunctions";

// Import CSS, images, icons & static data



// Import fonts


//**************************** MAIN FUNCTION ***************************** */
export default async function CalculateCourseProgress(enrollments, course) {
    let student = {};
    // let enrollments = {};
    let totalLessons = 0;
    let completedLessons = 0;
    let percentageComplete = 0;
    // currentUserRoles && (typeof currentUserRoles.then !== "function")

    console.log("In CalculateCourseProgress, enrollments->", enrollments);

    // RETRIEVE THE DETAILS OF THE CURRENT USER IN THE ROLE OF A STUDENT


    // CALCULATE THE PROGRESS OF THE INFLIGHT COURSES BY COUNTING TOTAL LESSONS AND LESSONS COMPLETED
    if (enrollments.length !== 0) {
        enrollments.forEach((enrollment) => {
            if (enrollment.courseName === course) {
                totalLessons = 0;
                completedLessons = 0;
                course.lessons.forEach((lesson) => {
                    totalLessons += 1;
                    if (lesson.status = "COMPLETE") {
                        completedLessons += 1
                    }
                })
                percentageComplete = completedLessons/ totalLessons;
            } 
        })
    }

    return (
        percentageComplete
    );
};

