//Import libraries
import { Box } from "@mui/system";
import React from 'react';

// Import CSS, images, icons & static data



// Import fonts


//**************************** MAIN FUNCTION ***************************** */
export default function CreateSectionList(courseSubmission) {
    let sectionList = [];
        // console.log("In CreateSectionList, courseSubmission->", courseSubmission);

    courseSubmission.lessons.forEach((lesson) => {
        if (sectionList.indexOf(lesson.section) === -1) {
            sectionList.push(lesson.section)
        }

    });
        // console.log("In CreateSectionList, sectionList->", sectionList);
    sectionList.sort((a,b) => a - b)
        // console.log("In CreateSectionList, sectionList->", sectionList);
    let lessonDisplayCounter = 1
    sectionList.forEach((section) => {
        lessonDisplayCounter = 1
        courseSubmission.lessons.forEach((lesson) => {
            if (lesson.section === section) {
                    // console.log("In CreateSectionList, section->", section);
                    // console.log("In CreateSectionList, lesson.section->", lesson.section);
                    // console.log("In CreateSectionList, lessonDisplayCounter->", lessonDisplayCounter);
                lesson.displayNumber = lessonDisplayCounter
                lessonDisplayCounter++
            }
        })
    })
    
    return sectionList
};