//Import libraries
import React, { useEffect, useState } from 'react';
import { Box } from "@mui/system";
import dayjs from "dayjs";
import { Chip, useMediaQuery } from "@mui/material";
import { PostCourseStatus } from '../../utilities/database/PostCourseStatus';
// import { UseMediaQuery } from "@mui/material";


// Import CSS, images, icons & static data
import "./AdminCourse.css"
import azTechTheme from "../../utilities/environment/ColourTheme";



// Import fonts


//**************************** MAIN FUNCTION ***************************** */
function AdminCourseStatusChip(props) {
    let chipColour = "primary"
    let chipDisabled = true;
    let courseStatus = "DRAFT"
    let deprecatedDateDisplay = ""
        // console.log("In AdminCourseStatusChip, deprecatedDisplayDate->", deprecatedDateDisplay);

    // useEffect(() => {

    async function UpdateCourseStatus(courseId, courseStatus) {
        let responseObject = await PostCourseStatus(courseId, courseStatus);
        props.UpdateDatabaseConnection(responseObject.databaseConnection);
    }
 

        if (props.courseSubmission.approvals.length === 0) {
                // console.log("In AdminStatusChip, approvals.length === 0, courseStatus->", courseStatus);
                // console.log("In AdminStatusChip, approvals.length === 0, props.courseSubmission.id.value->", props.courseSubmission.id.value);
            // setCourseStatus("DRAFT")
            courseStatus = "DRAFT"
            chipColour = "primary"
            chipDisabled = true
            if (props.courseSubmission.id.value) {
                UpdateCourseStatus(props.courseSubmission.id.value, courseStatus);
            }
        } else {
                // console.log("In AdminStatusChip, courseStatus->", courseStatus);
                // console.log("In AdminStatusChip, props.courseSubmission.id.value->", props.courseSubmission.id.value);
                // console.log("In AdminStatusChip, approvals.length !== 0, courseStatus->", courseStatus);
            courseStatus = ""
            props.courseSubmission.approvals.forEach((approval) => {
                // console.log("In AdminStatusChip, courseStatus->", courseStatus);
                if (approval.expiryDate === "" || approval.expiryDate === null) {
                    // setCourseStatus("APPROVED")
                    courseStatus = "APPROVED"
                    chipColour = "success"
                    chipDisabled = false
                    deprecatedDateDisplay = "";
                    if (props.courseSubmission.id.value) {
                        UpdateCourseStatus(props.courseSubmission.id.value, courseStatus);
                    }
                    // console.log("In AdminStatusChip, approval.expireDate === null, courseStatus->", courseStatus);
                }
            });
            if (courseStatus !== "APPROVED") {
                courseStatus = "DEPRECATED"
                chipColour = "primary"
                chipDisabled = false
                UpdateCourseStatus(props.courseSubmission.id.value, courseStatus);
                deprecatedDateDisplay = "...since " + dayjs(props.courseSubmission.deprecatedDate).format("DD MMM, YYYY");
            }
        }
            // console.log("In AdminCourseStatusChip, props.courseSubmission.approvals->", props.courseSubmission.approvals);
            // console.log("In AdminCourseStatusChip, courseStatus->", courseStatus);
        // props.UpdateStatus(courseStatus);

    // },[props.courseSubmission.approvals]);
    
    // useEffect(() => { 
    //     switch (courseStatus) {
    //         case "DRAFT":
    //                 console.log("In AdminCourseStatusChip, courseStatus->", courseStatus);
    //                 // console.log("In switch, status->", props.courseSubmission.status.value);
    //             // setChipColour("primary")
    //             // setChipDisabled(true)
    //             chipColour = "primary"
    //             chipDisabled = true
    //         break;
    //         case "APPROVED":
    //                 // console.log("In AdminCourseStatusChip, courseStatus->", courseStatus);
    //                 // console.log("In switch, status->", props.courseSubmission.status.value);
    //             // setChipColour("success")
    //             // setChipDisabled(false)
    //             chipColour = "success"
    //             chipDisabled = false
    //                 // console.log("In AdminCourseStatusChip, chipColour->", chipColour);
    //                 // console.log("In AdminCourseStatusChip, chipDisabled->", chipDisabled);
    //         break;
    //         case "DEPRECATED":
    //                 // console.log("In AdminCourseStatusChip, courseStatus->", courseStatus);
    //                 // console.log("In switch, status->", props.courseSubmission.status.value);
    //             // setChipColour("primary")
    //             // setChipDisabled(true)
    //             chipColour = "primary"
    //             chipDisabled = false
                // deprecatedDateDisplay = "...since " + dayjs(props.courseSubmission.deprecatedDate).format("DD MMM, YYYY");
    //         break;
    //     }
     
    // // },[props.courseSubmission.approvals]);
    // },[courseStatus]);



    return (
        <Box className = "course-status-box">
            <Box className = "course-footnote">
                Version {props.courseSubmission.version.value}
            </Box>
            <Chip 
                className = "course-status-chip"
                type = "text" name = "courseStatus" id="courseStatus"
                variant = "filled"
                color = {chipColour}
                disabled = {chipDisabled === true}
                label = { courseStatus }
                >
            </Chip>
            <Box className = "course-footnote">
                {deprecatedDateDisplay}
            </Box>
        </Box>
    );

};


export {AdminCourseStatusChip}