// Import libraries
import * as React from 'react';
import ProductCategories from './MUITemplates/onepirate/modules/views/ProductCategories';
import ProductSmokingHero from './MUITemplates/onepirate/modules/views/ProductSmokingHero';
import AppFooter from './MUITemplates/onepirate/modules/views/AppFooter';
import ProductHero from './MUITemplates/onepirate/modules/views/ProductHero';
import ProductValues from './MUITemplates/onepirate/modules/views/ProductValues';
import ProductHowItWorks from './MUITemplates/onepirate/modules/views/ProductHowItWorks';
import ProductCTA from './MUITemplates/onepirate/modules/views/ProductCTA';
import { Box } from '@mui/material';
import AppAppBar from './MUITemplates/onepirate/modules/views/AppAppBar';
import withRoot from './MUITemplates/onepirate/modules/withRoot';
import MainMenu from '../mainMenu/MainMenu.js';


// ******************************* MAIN FUNCTION ************************************************
function Home() {
  return (
    <React.Fragment>
      <MainMenu/>
      {/* <AppAppBar /> */}
      <ProductHero />
      <ProductValues />
      <ProductCategories />
      <ProductHowItWorks />
      <ProductCTA />
      <ProductSmokingHero />
      <AppFooter />
    </React.Fragment>
  );
}

export default Home;
// export default withRoot(Index);
