// Import libraries
import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" 


import { Box, padding } from '@mui/system'

// Import CSS, images, icons and static data
import "./Bookings.css"
import { baseBlue } from '../utilities/environment/EnvironmentData'
import azTechTheme from '../utilities/environment/ColourTheme'

// Import fonts


//**************************** MAIN FUNCTION ****************************** */

export default class Bookings extends React.Component {
  render() {
    return (
        <Box>
            <Box sx = {{color: "rgb(113, 3, 3)",  
                        fontWeight: "bold", 
                        fontFamily: "arial", 
                        flexDirection: "row", 
                        justifyItems: 'flex-start', 
                        fontSize: "60px", mt:"80px", ml:"10px"}}>
                <Box>
                    Bookings
                </Box>
                <Box sx = {{color: "red", mt: "10px", ml: "10px", fontSize: "large"}}>
                    UNDER CONSTRUCTION
                </Box>
            </Box>
            <Box className = "calendar-frame" sx = {{mt: "20px", ml: "5px", mr: "5px", padding: "10px", backgroundColor: azTechTheme.palette.blanchedAlmond.rgb}}>
                <FullCalendar
                    weekends={true}
                    plugins={[ dayGridPlugin, interactionPlugin ]}
                    initialView="dayGridMonth"
                    eventContent={renderEventContent}
                />
            </Box>
        </Box>
    )
  }
}

function renderEventContent(eventInfo) {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    )
  }