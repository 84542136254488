// Import libraries
import { TimePickerEditor } from "../../utilities/dateAndTime/TimePicker";

// Import libraries


// Import CSS, images, icons & static data



// Import fonts



export const flightLogColumnsPart1 = [
  {
    field: 'id',
    headerName: 'Database Key',
    headerAlign: "left",
    align: "left",
    width: 180,
    editable: false,
    visibility: false,
  },
  // {
  //   field: 'invoiceNumber',
  //   headerName: 'Invoice',
  //   headerAlign: "left",
  //   align: "left",
  //   width: 180,
  //   editable: false,
  //   visibility: false,
  // },
  {
    field: 'sortId',
    headerName: 'Sort Key',
    headerAlign: "left",
    align: "left",
    width: 180,
    editable: false,
    visibility: false,
    sort: "desc",
  },
  {
    field: 'invoice',
    headerName: 'Invoice',
    headerAlign: "left",
    align: "left",
    width: 160,
    editable: true,
    visibility: true,
    headerClassName: "administration"
  },
  {
    field: 'dateOfFlight',
    headerName: 'Date Of Flight',
    headerAlign: "left",
    align: "left",
    width: 160,
    type: 'date',
    editable: true,
    headerClassName: "flight-times"
  },
  {
    field: 'offBlocks',
    headerName: 'Off Blocks',
    headerAlign: "left",
    align: "left",
    // type: 'date',
    width: 140,
    editable: true,
    headerClassName: "flight-times",
    renderCell: (params) => <div>{params.value}</div>,
    renderEditCell: (params) => <TimePickerEditor {...params}/>
  },
  {
    field: 'onBlocks',
    headerName: 'On Blocks',
    headerAlign: "left",
    align: "left",
    description: 'Pilot Log Time',
    width: 140,
    editable: true,
    headerClassName: "flight-times",
    renderCell: (params) => <div>{params.value}</div>,
    renderEditCell: (params) => <TimePickerEditor {...params}/>,
  },
  {
    field: 'blockDifference',
    headerName: 'Block Time',
    headerAlign: "left",
    align: "left",
    type: "number",
    width: 140,
    editable: true,
    headerClassName: "flight-times"
  },
  {
    field: 'takeOff',
    headerName: 'Take Off',
    headerAlign: "left",
    align: "left",
    type: 'time',
    width: 130,
    editable: true,
    headerClassName: "flight-times",
    renderCell: (params) => <div>{params.value}</div>,
    renderEditCell: (params) => <TimePickerEditor {...params} />,
  },
  {
    field: 'landing',
    headerName: 'Landing',
    headerAlign: "left",
    align: "left",
    type: 'time',
    width: 130,
    editable: true,
    headerClassName: "flight-times",
    renderCell: (params) => <div>{params.value}</div>,
    renderEditCell: (params) => <TimePickerEditor {...params} />,
  },
  {
    field: 'flightTime',
    headerName: 'Flight Time',
    headerAlign: "left",
    align: "left",
    type: "number",
    width: 150,
    editable: true,
    headerClassName: "flight-times"
  },
  {
    field: 'svcOff',
    headerName: 'SVC Off',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 120,
    editable: true,
    headerClassName: "flight-times"
  },
  {
    field: 'svcOn',
    headerName: 'SVC On',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 120,
    editable: true,
    headerClassName: "flight-times"
  },
  {
    field: 'svcDifference',
    headerName: 'SVC Difference',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 160,
    editable: true,
    headerClassName: "flight-times"
  },
  {
    field: 'instrumentTime',
    headerName: 'Instrument',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 160,
    editable: true,
    headerClassName: "flight-times"
  },    
  {
    field: 'nightTime',
    headerName: 'Night',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 160,
    editable: true,
    headerClassName: "flight-times"
  },
  {
    field: 'pilot',
    headerName: 'Pilot',
    headerAlign: "left",
    align: "left",
    width: 160,
    editable: true,
    headerClassName: "people"
  },
  {
    field: 'passenger1',
    headerName: 'passenger1',
    headerAlign: "left",
    align: "left",
    width: 160,
    editable: true,
    headerClassName: "people"
  },
  {
    field: 'passenger2',
    headerName: 'Passenger 2',
    headerAlign: "left",
    align: "left",
    width: 160,
    editable: true,
    headerClassName: "people"
  },
  {
    field: 'fromAirfield',
    headerName: 'From',
    headerAlign: "left",
    align: "left",
    width: 120,
    editable: true,
    headerClassName: "route"
  },
  {
    field: 'toAirfield',
    headerName: 'To',
    headerAlign: "left",
    align: "left",
    width: 120,
    editable: true,
    headerClassName: "route"
  },
  {
    field: 'flightDetails',
    headerName: 'Flight Details',
    headerAlign: "left",
    align: "left",
    width: 160,
    editable: true,
    headerClassName: "route"
  },
  {
    field: 'homeLandings',
    headerName: 'Home Landings',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 170,
    editable: true,
    headerClassName: "route"
  },
  {
    field: 'awayLandings',
    headerName: 'Away Landings',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 170,
    editable: true,
    headerClassName: "route"
  },
  {
    field: 'nightLandings',
    headerName: 'Night Landings',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 170,
    editable: true,
    headerClassName: "route"
  },
  {
    field: 'departureFuelUpliftLeft',
    headerName: 'Uplift Left',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 140,
    editable: true,
    headerClassName: "fuel-and-oil"
  },
  {
    field: 'departureFuelUpliftRight',
    headerName: 'Uplift Right',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 140,
    editable: true,
    headerClassName: "fuel-and-oil"
  },
  {
    field: 'departureFuelLeft',
    headerName: 'Status Left',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 150,
    editable: true,
    headerClassName: "fuel-and-oil"
  },  
  {
    field: 'departureFuelRight',
    headerName: 'Status Right',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 150,
    editable: true,
    headerClassName: "fuel-and-oil"
  },
  {
    field: 'arrivalFuelUpliftLeft',
    headerName: 'Uplift Left',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 140,
    editable: true,
    headerClassName: "fuel-and-oil"
  },
  {
    field: 'arrivalFuelUpliftRight',
    headerName: 'Uplift Right',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 140,
    editable: true,
    headerClassName: "fuel-and-oil"
  },  
  {
    field: 'arrivalFuelLeft',
    headerName: 'Status Left',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 150,
    editable: true,
    headerClassName: "fuel-and-oil"
  },
  {
    field: 'arrivalFuelRight',
    headerName: 'Status Right',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 150,
    editable: true,
    headerClassName: "fuel-and-oil"
  },
  {
    field: 'fuelUsed',
    headerName: 'FuelUsed',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 140,
    editable: true,
    headerClassName: "fuel-and-oil"
  }];




export const flightLogColumnsOilOneEngine = [
      // OilColumns(numberOfEngines),
      {
        field: 'oilUpliftLeft',
        headerName: 'Uplift',
        headerAlign: "left",
        align: "left",
        type: 'number',
        width: 120,
        editable: false,
        headerClassName: "fuel-and-oil"
      },
      {
        field: 'oilStatusLeft',
        headerName: 'Status',
        headerAlign: "left",
        align: "left",
        width: 120,
        editable: false,
        headerClassName: "fuel-and-oil"
      },]



export const flightLogColumnsOilTwoEngines = [
    // OilColumns(numberOfEngines),
    {
      field: 'oilUpliftLeft',
      headerName: 'Uplift Left',
      headerAlign: "left",
      align: "left",
      type: 'number',
      width: 120,
      editable: false,
      headerClassName: "fuel-and-oil"
    },
    {
      field: 'oilUpliftRight',
      headerName: 'Uplift Right',
      headerAlign: "left",
      align: "left",
      type: 'number',
      width: 120,
      editable: false,
      headerClassName: "fuel-and-oil"
    },
    {
      field: 'oilStatusLeft',
      headerName: 'Status Left',
      headerAlign: "left",
      align: "left",
      width: 120,
      editable: false,
      headerClassName: "fuel-and-oil"
    },
    {
      field: 'oilStatusRight',
      headerName: 'Status Right',
      headerAlign: "left",
      align: "left",
      width: 120,
      editable: false,
      headerClassName: "fuel-and-oil"
    }]

   

export const flightLogColumnsPart2 = [
    {
      field: 'newDefect1',
      headerName: 'New Defect',
      headerAlign: "left",
      align: "left",
      width: 160,
      editable: true,
      headerClassName: "maintenance"
    },
    {
      field: 'newDefect2',
      headerName: 'New Defect',
      headerAlign: "left",
      align: "left",
      width: 160,
      editable: true,
      headerClassName: "maintenance"
    },
    {
      field: 'awayLandingLocation1',
      headerName: '1st Location',
      headerAlign: "left",
      align: "left",
      width: 160,
      editable: true,
      headerClassName: "away-landing"
    },
    {
      field: 'awayLandingQuantity1',
      headerName: 'Quantity',
      headerAlign: "left",
      align: "left",
      type: "number",
      width: 120,
      editable: true,
      headerClassName: "away-landing"
    },
    {
      field: 'awayLandingDetails1',
      headerName: 'Details',
      headerAlign: "left",
      align: "left",
      width: 180,
      editable: true,
      headerClassName: "away-landing"
    },
    {
      field: 'awayLandingPaid1',
      headerName: 'Paid',
      headerAlign: "left",
      align: "left",
      type: 'string',
      width: 100,
      editable: true,
      headerClassName: "away-landing"
    },
    {
      field: 'awayLandingLocation2',
      headerName: '2nd Location',
      headerAlign: "left",
      align: "left",
      width: 160,
      editable: true,
      headerClassName: "away-landing-light"
    },
    {
      field: 'awayLandingQuantity2',
      headerName: 'Quantity',
      headerAlign: "left",
      align: "left",
      type: "number",
      width: 120,
      editable: true,
      headerClassName: "away-landing-light"
    },
    {
      field: 'awayLandingDetails2',
      headerName: 'Details',
      headerAlign: "left",
      align: "left",
      width: 180,
      editable: true,
      headerClassName: "away-landing-light"
    },
    {
      field: 'awayLandingPaid2',
      headerName: 'Paid',
      headerAlign: "left",
      align: "left",
      type: 'string',
      width: 100,
      editable: true,
      headerClassName: "away-landing-light"
    },
    {
      field: 'awayLandingLocation3',
      headerName: '3rd Location',
      headerAlign: "left",
      align: "left",
      width: 160,
      editable: true,
      headerClassName: "away-landing"
    },
    {
      field: 'awayLandingQuantity3',
      headerName: 'Quantity',
      headerAlign: "left",
      align: "left",
      type: "number",
      width: 120,
      editable: true,
      headerClassName: "away-landing"
    },
    {
      field: 'awayLandingDetails3',
      headerName: 'Details',
      headerAlign: "left",
      align: "left",
      width: 180,
      editable: true,
      headerClassName: "away-landing"
    },
    {
      field: 'awayLandingPaid3',
      headerName: 'Paid',
      headerAlign: "left",
      align: "left",
      type: 'string',
      width: 100,
      editable: true,
      headerClassName: "away-landing"
    },
    {
      field: 'awayLandingLocation4',
      headerName: '4th Location',
      headerAlign: "left",
      align: "left",
      width: 160,
      editable: true,
      headerClassName: "away-landing-light"
    },
    {
      field: 'awayLandingQuantity4',
      headerName: 'Quantity',
      headerAlign: "left",
      align: "left",
      type: "number",
      width: 120,
      editable: true,
      headerClassName: "away-landing-light"
    },
    {
      field: 'awayLandingDetails4',
      headerName: 'Details',
      headerAlign: "left",
      align: "left",
      width: 180,
      editable: true,
      headerClassName: "away-landing-light"
    },
    {
      field: 'awayLandingPaid4',
      headerName: 'Paid',
      headerAlign: "left",
      align: "left",
      type: 'string',
      width: 100,
      editable: true,
      headerClassName: "away-landing-light"
    },
    {
      field: 'awayLandingLocation5',
      headerName: '5th Location',
      headerAlign: "left",
      align: "left",
      width: 140,
      editable: true,
      headerClassName: "away-landing"
    },
    {
      field: 'awayLandingQuantity5',
      headerName: 'Quantity',
      headerAlign: "left",
      align: "left",
      type: "number",
      width: 120,
      editable: true,
      headerClassName: "away-landing"
    },
    {
      field: 'awayLandingDetails5',
      headerName: 'Details',
      headerAlign: "left",
      align: "left",
      width: 180,
      editable: true,
      headerClassName: "away-landing"
    },
    {
      field: 'awayLandingPaid5',
      headerName: 'Paid',
      headerAlign: "left",
      align: "left",
      type: 'string',
      width: 100,
      editable: true,
      headerClassName: "away-landing"
    }
  ];


