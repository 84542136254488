//Import libraries
import { Box } from "@mui/system";
import { TextField } from "@mui/material"
import React from 'react';
import ArrivalTable1Navigation from "./ArrivalTable1Navigation.js"
import ArrivalTable2Navigation from "./ArrivalTable2Navigation.js"
import ArrivalTable3Navigation from "./ArrivalTable3Navigation.js"
import ArrivalTableFuel from "./ArrivalTableFuel.js";


// Import CSS, images, icons & static data
import "./Arrival.css"
import map from "../images/map.png";
import shortArrow from "../images/shortArrow.png";


// Import fonts


//**************************** MAIN FUNCTION ***************************** */
export default function ArrivalOptions(props) {

    let excludedForAircraftType = ["SIM"];
        // console.log("In SVCTimes, props.submission.data.plateType.value->", props.submission.data.planeType.value)
    if (excludedForAircraftType.indexOf(props.submission.data.planeType.value) === -1) {
        // return (
            // <Box className = "arrival-table2-navigation-wide">
            //     {/* <img className = "map" src = {map} alt = "Map"></img> */}
            //     <ArrivalTable2Navigation 
            //     TechLogHandleChange = {props.TechLogHandleChange} 
            //     TechLogHandleChangeNumber = {props.TechLogHandleChangeNumber} 
            //     submission = {props.submission}/>
            // </Box>
        // );
    // } else {
        return (
            <Box className = "arr-details-row2">
                {/* <tr> */}
                    <Box className = "arrival-table1-navigation">
                        <ArrivalTable1Navigation 
                        TechLogHandleChange = {props.TechLogHandleChange} 
                        submission = {props.submission}/>
                    </Box>
                    <Box className = "arrival-table2-navigation">
                        <ArrivalTable2Navigation 
                        TechLogHandleChange = {props.TechLogHandleChange} 
                        TechLogHandleChangeNumber = {props.TechLogHandleChangeNumber} 
                        submission = {props.submission}/>
                    </Box>
                    <Box className = "arrival-table3-navigation">
                        <ArrivalTable3Navigation 
                        TechLogHandleChange = {props.TechLogHandleChange} 
                        TechLogHandleChangeNumber = {props.TechLogHandleChangeNumber} 
                        submission = {props.submission}/>
                    </Box>
                    <div className = "arrival-table-fuel">
                        <ArrivalTableFuel 
                        calculatedFuelUsed = {props.calculatedFuelUsed} 
                        selectedAircraft = {props.selectedAircraft} 
                        TechLogHandleChange = {props.TechLogHandleChange} 
                        submission = {props.submission}/> 
                    </div>
                {/* </tr> */}
            </Box>
        );
    }
}





