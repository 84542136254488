// Import libraries
// import dayjs from "dayjs";


// *************** MAIN FUNCTION ************************
export default function CalculateOffset(landingTOTime, offsetMinutes) {

        // console.log("In CalculatedOffset, failed input checks, landingTOTime->",landingTOTime ," offsetMinutes->", offsetMinutes)
    
    // Check each input is a number
    if ((landingTOTime === "") || (offsetMinutes === "" || offsetMinutes === 0)) {
            // console.log("In CalculatedOffset, failed input checks, landingTOTime->",landingTOTime ," offsetMinutes->", offsetMinutes)
        return "";
    } else {
        // Set Offsets based on landing or T/O time
            // console.log("In CalculatedOffset, passed input checks")

        // Split times into hours and minutes
        let landingTOTimeHoursAndMins = {
                hours: Number(landingTOTime.slice(0,2)),
                mins: Number(landingTOTime.slice(3))
            }
            // console.log("In CalculatedOffset, landingTOTimeHoursAndMinutes -> ", landingTOTimeHoursAndMins);

        // Calculate offsets for block times vs take off and landing
        let blockTimeHoursAndMins = {}
        // If calculating offBlocks time
        if (offsetMinutes > 0) {
            // If NOT close to the previous hour
            if (landingTOTimeHoursAndMins.mins >= offsetMinutes) { 
                blockTimeHoursAndMins.mins = landingTOTimeHoursAndMins.mins - offsetMinutes
                blockTimeHoursAndMins.hours = landingTOTimeHoursAndMins.hours
                    // console.log("In CalculatedOffset, PATH I offBlocks, blockTimeHoursAndMinutes.hours -> ", blockTimeHoursAndMins.hours,".mins -> ",blockTimeHoursAndMins.mins);
                } else {
                    // If close to the previous hour
                    blockTimeHoursAndMins.mins = landingTOTimeHoursAndMins.mins + (60-offsetMinutes)
                    // If NOT close to the previous day
                    if (landingTOTimeHoursAndMins.hours > 0) {
                        blockTimeHoursAndMins.hours = landingTOTimeHoursAndMins.hours - 1
                    } else {
                        // If close to the previous day
                        blockTimeHoursAndMins.hours = 23
                    }
                    //  console.log("In CalculatedOffset, PATH II offBlocks, blockTimeHoursAndMinutes.hours -> ", blockTimeHoursAndMins.hours, ".mins -> " ,blockTimeHoursAndMins.mins);
                }
        } else {
            // If calculating onBlocks time
            if (offsetMinutes < 0) {
                // If NOT close to the next hour
                if ((landingTOTimeHoursAndMins.mins - offsetMinutes) < 60) { 
                    blockTimeHoursAndMins.mins = landingTOTimeHoursAndMins.mins - offsetMinutes
                    blockTimeHoursAndMins.hours = landingTOTimeHoursAndMins.hours
                        // console.log("In CalculatedOffset, PATH I offBlocks, blockTimeHoursAndMinutes.hours -> ", blockTimeHoursAndMins.hours,".mins -> ",blockTimeHoursAndMins.mins);
                    } else {
                        // If close to the next hour
                        blockTimeHoursAndMins.mins = (landingTOTimeHoursAndMins.mins - offsetMinutes) - 60
                        // If NOT close to the next day
                        if (landingTOTimeHoursAndMins.hours < 23) {
                            blockTimeHoursAndMins.hours = landingTOTimeHoursAndMins.hours + 1
                        } else {
                            // If close to the previous day
                            blockTimeHoursAndMins.hours = 0
                        }
                        // console.log("In CalculatedOffset, PATH II offBlocks, blockTimeHoursAndMinutes.hours -> ", blockTimeHoursAndMins.hours, ".mins -> " ,blockTimeHoursAndMins.mins);
                    }
                }
            }

        // Concatenate and return blockTime offset by the offset minutes provided
        if (blockTimeHoursAndMins.hours < 10) {
            blockTimeHoursAndMins.hours = `0${blockTimeHoursAndMins.hours}`;
        }
        if (blockTimeHoursAndMins.mins < 10) {
            blockTimeHoursAndMins.mins = `0${blockTimeHoursAndMins.mins}`;
        }
        let blockTimeOffset = `${blockTimeHoursAndMins.hours}:${blockTimeHoursAndMins.mins}`
            // console.log ("In CalculateOffset, blockTimeOffset -> ", blockTimeOffset);
        return blockTimeOffset;
    }

}