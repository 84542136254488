// Import libraries
// import axios from "axios";
import axiosInstance from "../security/AxiosConfig.js";
import _ from "lodash";

// Import CSS, images, icons and static data
import { API_URL } from '../../../config/config.js';
import { databaseConnectionMessages } from "./DatabaseConnectionMessages.js";
import { flightValidationMessages } from "../validation/FlightValidationMessages.js";

// import { useAuthFirebase } from "../../utilities/AuthContextFirebase.js";




    // ************************* SEND FLIGHT TO DATABASE *******************************
    
export async function DeleteFlight(submission) {

    var responseObject = {}
    var databaseSubmission = {};
        databaseSubmission = _.cloneDeep(submission);
            // console.log("In DeleteFlight, databaseSubmission->", databaseSubmission);

    try {
            // console.log("In DeleteFlight databaseSubmission -> ", databaseSubmission)
        // let response = await axiosInstance.delete(`${API_URL}/flights/`, databaseSubmission);
        let response = await axiosInstance.post(`${API_URL}/flights/delete`, databaseSubmission);
        responseObject.data = flightValidationMessages.successMessages.delete + " - " + databaseSubmission.id;
        responseObject.databaseConnection = true;
            // console.log("In DeleteFlight, responseObject->", responseObject);
            // console.log("In SubmitFlight, response.status->", response.status);
        return responseObject;
    } catch (error) {
        console.error("Error deleting a flight", error);
        responseObject.data = databaseConnectionMessages.error;
        responseObject.databaseConnection = false;
            // console.log("In DeleteFlight responseObject -> ", responseObject)
        return responseObject;
    }
}