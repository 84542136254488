//Import libraries
import { Box } from "@mui/system";
import React from 'react';
import { Chip, useMediaQuery } from "@mui/material";
// import { UseMediaQuery } from "@mui/material";
import { CalculateMaintenanceWindow } from "./CalculateMaintenanceWindow";

// Import CSS, images, icons & static data
import { mobileThreshold, mobileThresholdWide } from "../environment/EnvironmentData";
// import "../admin/aircraft/AdminAircraft.css";
import "./MaintenanceChips.css"
import azTechTheme from "../environment/ColourTheme";



// Import fonts


//**************************** MAIN FUNCTION ***************************** */
function MaintenanceChips(props) {

    let maintenanceWindow = CalculateMaintenanceWindow(props.selectedAircraft)

        // console.log("In MaintenanceChips, props.maintenanceWindow->", maintenanceWindow)

    let colours = maintenanceWindow.colours;
    let serviceDaysRemaining = maintenanceWindow.serviceDaysRemaining;
    let serviceHoursRemaining = maintenanceWindow.serviceHoursRemaining;
    let maintenanceChipsDisplay = "maintenance-chips-display"
    let subtitleClassName = "maintenance-chips-subtitles"

    if (props.calledFrom === "TechLog") {
        subtitleClassName = "maintenance-chips-subtitles-banner"
    } else {
        subtitleClassName = "maintenance-chips-subtitles"
    }

    if (props.banner) {
        maintenanceChipsDisplay = "maintenance-chips-display"
    } else {
        maintenanceChipsDisplay = "maintenance-chips-display-body"
    }


    let excludedForAircraftType = ["SIM"];
    if (excludedForAircraftType.indexOf(props.selectedAircraft._planeType) === -1) {

        return (
            <Box className = "maintenance-chips">
                <Box className = "maintenance-chips-inner">
                    <Box className = {subtitleClassName}>Days Remaining</Box>
                    <Chip className = {maintenanceChipsDisplay}
                        type = "number" name = "daysRemaining" id="daysRemaining"
                        variant = "filled"
                        color = {colours.days}
                        disabled = {colours.days === "primary"}
                        label = {serviceDaysRemaining}>
                    </Chip>
                </Box> 
                <Box className = "maintenance-chips-inner">
                    <Box className = {subtitleClassName}>Hours Remaining</Box>
                    <Chip className = {maintenanceChipsDisplay}
                        type = "number" name = "hoursRemaining" id="hoursRemaining"
                        variant = "filled"
                        color = {colours.hours} 
                        disabled = {colours.hours === "primary"}
                        label = {serviceHoursRemaining}>
                    </Chip>
                </Box>
            </Box>
        );
    }
};

function MaintenanceChipsBanner(props) {
    let mediaWide = useMediaQuery(`(min-width:${mobileThresholdWide}px)`)
    let media = useMediaQuery(`(min-width:${mobileThreshold}px)`)

    let maintenanceWindowClassName = ""
    if (media) {
        maintenanceWindowClassName = ""
    } else {
        maintenanceWindowClassName = ""
    }

    let excludedForAircraftType = ["SIM"];
    if (excludedForAircraftType.indexOf(props.selectedAircraft._planeType) === -1) {

        if (props.calledFrom === "TechLog" || (props.calledFrom === "CamoLog" && mediaWide)) {
    
            return(
            <Box className = {maintenanceWindowClassName}>
                <Box className = "maintenance-chips-heading">Maintenance Window</Box>
                <Box className = "maintenance-chips-outer">
                    <MaintenanceChips selectedAircraft = {props.selectedAircraft} calledFrom = {props.calledFrom} banner = {true}/>
                {/* </Box> */}
                    <Box className = "maintenance-chips-footnote" sx = {{backgroundColor: azTechTheme.palette.blanchedAlmond}}> GREEN = OK TO FLY</Box>
                </Box>
            </Box>
            );
            
        }
    }

}

function MaintenanceChipsBody(props) {
    let media = useMediaQuery(`(min-width:${mobileThresholdWide}px)`)

    if (!media) {
            
        return(
        <Box>
            <MaintenanceChips selectedAircraft = {props.selectedAircraft}/>
        </Box>
        );
    }

}


export {MaintenanceChipsBanner, MaintenanceChipsBody, MaintenanceChips}