// Import libraries
import { TextField, Box } from "@mui/material"
import Media from "../../../utilities/environment/Media";

// Import CSS, images & icons
import "./Arrival.css";


export default function ArrivalTable2Navigation(props) {
    if (Media()) {
    return(
            <div className = "arrival-table2-navigation">
                <table>
                    <tbody>
                        <tr>
                            <td className = "label-extra-long" colSpan = "2">Flight Details</td>
                        </tr>
                        <tr>
                            <td className = "input" STYLE = {props.submission.data.flightDetails.background} colSpan = "2">
                                <TextField value={props.submission.data.flightDetails.value} 
                                            onChange={e => props.TechLogHandleChange(e.target)} 
                                            error = {props.submission.data.flightDetails.status === "error"} 
                                            variant = {props.submission.data.flightDetails.variant} 
                                            fullWidth 
                                            type = "text" 
                                            name = "flightDetails" 
                                            id = "flightDetails">
                                </TextField>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
    ); } else {
        return (
            <div className = "arrival-table2-navigation" >
                <table>
                    <tbody>
                        <tr>
                            <td className = "blank" colSpan = "2"></td>
                        </tr>
                        <tr>
                            <td className = "label-extra-long" colSpan = "2">Flight Details</td>
                        </tr>
                        <tr>
                            <td className = "input" STYLE = {props.submission.data.flightDetails.background} colSpan = "2">
                                <TextField value={props.submission.data.flightDetails.value} 
                                            onChange={e => props.TechLogHandleChange(e.target)} 
                                            error = {props.submission.data.flightDetails.status === "error"} 
                                            variant = {props.submission.data.flightDetails.variant} 
                                            fullWidth 
                                            type = "text" 
                                            name = "flightDetails" 
                                            id = "flightDetails">
                                </TextField>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }   
}