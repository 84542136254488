// Import libraries
import { Box } from "@mui/material";
import Media from "../../../utilities/environment/Media.js"

// Import CSS, images & icons
import "./Arrival.css";
import arrival from "../images/arrival.png";

// *********************** MAIN FUNCTION **************************
export default function ArrivalImage(props) {
    var mobile = `arr-image${Media()}`;
        // console.log("In DepartureImage Part 0, mobile -> ", mobile);

    if (mobile === "arr-image") {
            // console.log("In ArrivalImage Part I, mobile -> ", mobile);
        return(
            <Box className = {mobile} sx={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                <div className = "arr-title">Arrival</div>
                <img src = {arrival} alt ="Arrival"/>
            </Box>
        );
    } else {
            // console.log("In ArrivalImage, Part II, mobile ", mobile)
        return (
            <Box className = {mobile} sx={{display: "flex", flexDirection: "row", }}>
                <img src = {arrival} alt ="Arrival"/>
                <span className = "arr-title-mobile">Arrival</span>
                <div className = "aircraft-header-mobile" id = "registration" name = "registration" onChange={e => props.TechLogHandleChange(e.target)}>{props.registration}</div>
            </Box>
        );
    }
}