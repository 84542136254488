// Import libraries
import React, {useEffect, useState} from "react";
import {Box, TextField, Button} from "@mui/material";
import CreateSectionList from "./CreateSectionList.js";
import _ from "lodash";


// Import CSS, images, icons & static data
import "./AdminCourse.css"
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { emptyCourseSubmission, newLesson } from "./CourseData";


// ****************************** MAIN FUNCTION **********************************
export default function AdminCourseNewLessons(props) {
    const [addNewLessonPressed, setAddNewLessonPressed] = useState(false)
    const [lessonsDisplay, setLessonsDisplay] = useState();
    let editing = props.editing;
    let section = props.courseSubmission.newLesson.section.value

    let newLessonSaveDisabled = false

    if (props.courseSubmission.newLesson.section.value === 0 || (typeof props.courseSubmission.newLesson.section.value !== "number")) {
        newLessonSaveDisabled = true;
    }
    if (props.courseSubmission.newLesson.section.value > 0 && (typeof props.courseSubmission.newLesson.section.value !== "number")) {
        newLessonSaveDisabled = false;
    }

    // ******************** CONSTRUCT SECTIONLIST ********************** //
    let sectionList = CreateSectionList(props.courseSubmission);
    // setSectionList(sections);
    function HandleAddLesson() {

        setAddNewLessonPressed(true);
    }

    function HandleSaveLesson(e) {

        props.HandleSaveNewLesson(e);
        setAddNewLessonPressed(false);
        props.UpdateIsEdited(true);
    }

    function HandleCancelLesson() {
        props.HandleClearNewLesson();
        setAddNewLessonPressed(false);
    }

    useEffect(() => {
        setLessonsDisplay(ConstructSections());
    },[props.courseSubmission.lessons])



    if (addNewLessonPressed) {
        let newLessonObject = _.cloneDeep(newLesson);
        ConstructSections(newLessonObject);
    } else {
        return (
            <Box className = "admin-new-lesson-button">
                <Button 
                onClick={HandleAddLesson}
                disabled = {editing === false}>
                    <AddIcon></AddIcon>
                    ADD LESSON
                </Button>
            </Box>
        )
    }

    function ConstructSections() {
            // console.log("In AdminCourseLessons, section->", section);
       

        return (
            <Box >
                <Box className = "admin-new-lesson-subtitles"> NEW LESSON </Box>
                    <Box>
                        {ConstructNewLesson(newLesson)}
                    </Box>
                
            </Box>
        );
    }

    function ConstructNewLesson(lessonIndex) {
        // console.log("In ConstructLesson, lesson->", lesson, lessonIndex);
        // console.log("In ConstructLesson, props.courseSubmission->", props.courseSubmission);
        // console.log("In ConstructLesson, `${props.courseSubmission.lessons[lessonIndex].name.value}`->", `${props.courseSubmission.lessons[lessonIndex].name.value}`);
          
        return (
            
            <Box className= "admin-lessons-container">
                <TextField className = "admin-course-field-narrow" label = "Section"
                    // InputProps = {{ inputProps: {STYLE: emptyCourseSubmission.lessons[lessonIndex].section.background} }}
                    // InputProps={{style: { STYLE: props.courseSubmission.newLesson.section.background} }}
                    InputProps={{style: { backgroundColor: 'aliceblue' } , inputProps: { min:1 }}}
                    type = "number" fullWidth
                    name = "section"
                    id="section" 
                    InputLabelProps={{ shrink: true }}
                    onChange={e => props.HandleNewLessonChange(e.target)} 
                    error = {props.courseSubmission.newLesson.section.status === "error"}
                    variant = {props.courseSubmission.newLesson.section.variant}
                    // defaultValue = {"<< ENTER >>"}
                    value = {props.courseSubmission.newLesson.section.value}
                    disabled = {editing === false}></TextField>
                <TextField className = "admin-course-field" label = "Lesson Name"
                    // InputProps = {{ inputProps: {STYLE: emptyCourseSubmission.lessons[lessonIndex].name.background} }}
                    // InputProps={{style: { STYLE: props.courseSubmission.newLesson.name.background} }}
                    InputProps={{style: { backgroundColor: 'aliceblue' } }}
                    type = "text" fullWidth multiline
                    name = "name"
                    id = "name"
                    InputLabelProps = {{ shrink: true }}
                    onChange = {e => props.HandleNewLessonChange(e.target)} 
                    error = {props.courseSubmission.newLesson.name.status === "error"}
                    variant = {props.courseSubmission.newLesson.name.variant}
                    // defaultValue = {"<< ENTER >>"}
                    value = {props.courseSubmission.newLesson.name.value}
                    disabled = {editing === false}></TextField>
                <TextField className = "admin-lesson-field-wide" label = "Objective"
                    // InputProps = {{ inputProps: {STYLE: emptyCourseSubmission.lessons[lessonIndex].details.background} }}
                    // InputProps={{style: { STYLE:props.courseSubmission.newLesson.name.background}  }}
                    InputProps={{style: { backgroundColor: 'aliceblue' } }}
                    type = "text" fullWidth multiline
                    name = "objective"
                    id = "objective"
                    InputLabelProps ={ { shrink: true }}
                    onChange = {e => props.HandleNewLessonChange(e.target)} 
                    error = {props.courseSubmission.newLesson.objective.status === "error"}
                    variant = {props.courseSubmission.newLesson.objective.variant}
                    // defaultValue = {"<< ENTER >>"}
                    value = {props.courseSubmission.newLesson.objective.value}
                    disabled = {editing === false}></TextField>
                <TextField className = "admin-lesson-field-wide" label = "Performance Standard"
                    // InputProps = {{ inputProps: {STYLE: emptyCourseSubmission.lessons[lessonIndex].details.background} }}
                    // InputProps={{style: { STYLE:props.courseSubmission.newLesson.name.background}  }}
                    InputProps={{style: { backgroundColor: 'aliceblue' } }}
                    type = "text" fullWidth multiline
                    name = "details"
                    id = "details"
                    InputLabelProps ={ { shrink: true }}
                    onChange = {e => props.HandleNewLessonChange(e.target)} 
                    error = {props.courseSubmission.newLesson.details.status === "error"}
                    variant = {props.courseSubmission.newLesson.details.variant}
                    // defaultValue = {"<< ENTER >>"}
                    value = {props.courseSubmission.newLesson.details.value}
                    disabled = {editing === false}></TextField>
                <TextField className = "admin-course-field-narrow" label = "Minimum Hrs"
                    // InputProps = {{ inputProps: {STYLE: emptyCourseSubmission.lessons[lessonIndex].minimumHours.background} }}
                    // InputProps={{style: { STYLE: props.courseSubmission.newLesson.name.background} }}
                    InputProps={{style: { backgroundColor: 'aliceblue' }, inputProps: { min:0 } }}
                    // InputProps={{ inputProps: { min: 0, max: props.selectedAircraft._fuelTank2Capacity }}} 
                    type = "number" fullWidth
                    name = "minimumHours"
                    id = "minimumHours"
                    InputLabelProps = {{ shrink: true }}
                    onChange = {e => props.HandleNewLessonChange(e.target)} 
                    error = {props.courseSubmission.newLesson.minimumHours.status === "error"} 
                    variant = {props.courseSubmission.newLesson.minimumHours.variant} 
                    value = {props.courseSubmission.newLesson.minimumHours.value}
                    disabled = {editing === false}></TextField>

                <Box className = "admin-new-lesson-button">
                    <Button 
                        onClick={HandleSaveLesson}
                        disabled = {newLessonSaveDisabled === true}>
                        <SaveIcon fontSize="medium" sx = {{mr: "5px"}} ></SaveIcon>
                        ADD
                    </Button>
                    <Button onClick={HandleCancelLesson}>
                        <DeleteOutlineOutlinedIcon fontSize="medium" sx = {{mr: "5px"}} ></DeleteOutlineOutlinedIcon>
                        CANCEL
                    </Button>
                </Box>
            </Box>
        );
            // console.log("In AdminCourseLessons, lesson->", displayLesson);
    }

    return (
        <Box>
            {ConstructSections()}
        </Box>
    );

}   