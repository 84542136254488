// Import libraries
import dayjs from "dayjs";

// import DateTimeOptions from "../utilities/DateTimeOptions.js"


// ******************************* MAIN FUNCTION ************************************************
export function CreateCamoLogRow(row, period) {
    // console.log("In CreateCamoLogRow row.date->", row.date)
  let sortKey = new Date(row.date);
  sortKey = sortKey.getTime();
    // console.log("In CreateCamoLogRow row.date.format(MMM)->", dayjs(new Date(row.date)).format("MMM"),)
    // console.log("In CreateCamoLogRow, period)->", period)
    // console.log("In CreateCamoLogRow, row.date->", row.date, "sotKey)->", sortKey)

  let camoLogRow = {}
  switch(period) {
    case "daily":
      camoLogRow = 
        {
        id: sortKey,
        registration: row.registration,
        date: dayjs(new Date(row.date)).format("YYYY-MM-DD"),
        flights: row.flights,
        flightTime: row.flightTime,
        }
    break;
    case "weekly":
      camoLogRow = 
      {
        id: sortKey,
        registration: row.registration,
        date: dayjs(new Date(row.date)).format("YYYY-MMM-DD"),
        flights: row.flights,
        flightTime: row.flightTime,
        }
    break;
    case "monthly":
      camoLogRow = 
      {
        id: sortKey,
        registration: row.registration,
        date: dayjs(new Date(row.date)).format("YYYY-MMM"),
        flights: row.flights,
        flightTime: row.flightTime,
        }
    break;
    default: console.log("Unknown period")
  }

return camoLogRow
}
