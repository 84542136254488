const API_URL = window._env_.API_URL;
//export const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8080"
// export const API_URL = "https://aztechbackend-2ji6pvtpaa-uc.a.run.app"

// const apiKey = window._env_.FIREBASE_API_KEY
// const authDomain = window._env_.FIREBASE_AUTH_DOMAIN

var fireBaseConfig = {}
var firebaseTenantId = ""

if (process.env.NODE_ENV === "production") {
    firebaseTenantId = window._env_.FIREBASE_TENANT_ID
    fireBaseConfig = {
        apiKey: window._env_.FIREBASE_API_KEY,
        authDomain: window._env_.FIREBASE_AUTH_DOMAIN,
      
    };
} else {
    firebaseTenantId = process.env.REACT_APP_FIREBASE_TENANT_ID
    fireBaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
       
    };
}

export { API_URL, fireBaseConfig, firebaseTenantId }