// Import libraries
import { DataGrid, GridToolbar} from '@mui/x-data-grid';
import { Box } from '@mui/system';

// Import CSS, images, icons, static data & fonts
import "./CamoLog.css";


//************************* MAIN FUNCTION ************************* */

export default function CamoLogDataGrid (props) {

        // console.log("In CamoLogDataGrid, camoLogRows->", props.camoLogRows," camoLogColumns->", props.camoLogColumns," camoLogColumnGroups->", props.camoLogColumnGroups);
        // console.log("In CamoLogDataGrid, media->", props.media," camoLogPeriod->", props.camoLogPeriod);
        // console.log("In CamoLogDataGrid, props.camoLogRows.daily->", props.camoLogRows.daily," props.camoLogColumns.daily->", props.camoLogColumns.daily," props.camologColumnGroups.daily>", props.camoLogColumnGroups.daily);
        // console.log("In CamoLogDataGrid, props.camoLogRows.weekly->", props.camoLogRows.weekly," props.camologColumns.daily->", props.camoLogColumns.weekly," props.camologColumns.daily>", props.camoLogColumns.weekly);
        // console.log("In CamoLogDataGrid, props.camoLogRows.monthly->", props.camoLogRows.monthly," props.camologColumns.daily->", props.camoLogColumns.monthly," props.camologColumns.daily>", props.camoLogColumns.mothly);

    // const columnGroups = props.columnGroups;

    if (props.media) {
        return (
            <Box className = "admin-camoLog-frame">
                <Box className = "admin-camoLog-table">
                    <RenderDataGrid media = {props.media} rows = {props.camoLogRows.daily} columns = {props.camoLogColumns.daily} columnGroups = {props.camoLogColumnGroups.daily}/>
                </Box>
                <Box className = "admin-camoLog-table">
                    <RenderDataGrid media = {props.media} rows = {props.camoLogRows.weekly} columns = {props.camoLogColumns.weekly} columnGroups = {props.camoLogColumnGroups.weekly}/>
                </Box>
                <Box className = "admin-camoLog-table">
                    <RenderDataGrid media = {props.media} rows = {props.camoLogRows.monthly} columns = {props.camoLogColumns.monthly} columnGroups = {props.camoLogColumnGroups.monthly}/>
                </Box>
            </Box>
        )
    } else {
        return (
            <Box className = "admin-camoLog-frame">
                <Box className = "admin-camoLog-table">
                    <RenderDataGrid media = {props.media} rows = {props.camoLogRows[props.camoLogPeriod]} columns = {props.camoLogColumns[props.camoLogPeriod]} columnGroups = {props.camoLogColumnGroups[props.camoLogPeriod]}/>
                </Box>
            </Box>
        )
    }
        // console.log("In CamoLogDataGrid, columnGroups->", columnGroups);
    function RenderDataGrid(props) {
            // console.log("In RenderDataGrid, props.rows->", props.rows)
            // console.log("In RenderDataGrid, props.columns->", props.columns)
            // console.log("In RenderDataGrid, props.columnGroups->", props.columnGroups)
        let rows = props.rows;
        let columns = props.columns;
        let columnGroups = props.columnGroups;
        let camoLogToolbar = {}

        if (props.media) {
            camoLogToolbar = {toolbar: GridToolbar};
        } else {
            camoLogToolbar = {};
        }

        return (
            <DataGrid
                color = "info"
                initialState={{
                    sorting: {
                    sortModel: [{ field: 'id', sort: 'asc' }],
                    },
                    columns: {
                    columnVisibilityModel: {
                        // Hide column 'id', the other columns will remain visible
                        id: false,
                    },
                    },
                    pagination: {
                    paginationModel: {
                        pageSize: 100,
                    },
                    }
                }}
                slots = {camoLogToolbar}
                experimentalFeatures = {{ columnGrouping: true }}
                columnGroupingModel = {columnGroups}
                columnGroups = {columnGroups}
                columns={columns}
                rows={rows} // Dummy rows to allow columns to appear frozen at the top of the page
                
                pageSizeOptions={[100]}
                // checkboxSelection
                disableRowSelectionOnClick
            />
        );
    }
}