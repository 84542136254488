// Import libraries
import { useState, useEffect } from "react";
import {useMediaQuery} from '@mui/material';

//Import CSS & images
import "./Banner.css"
import Logo from "../images/ScuolaDiVoloLogoLight.png"
// import Logo from "../images/EFTLogo.png"

import { mobileThreshold } from "../../../utilities/environment/EnvironmentData";

// ******************************* MAIN FUNCTION ************************************************
export default function BannerLogo() {

    var media = useMediaQuery(`(min-width:${mobileThreshold}px)`);
    const blank = "";
    const [bannerLogo, setBannerLogo] = useState(blank);

    useEffect (() => {
        if (media) {
            setBannerLogo(Logo)
          } else {
            setBannerLogo(blank);
          } 
    
        }, [media]);
    
    
      return (
           <img src = {bannerLogo} alt = ""/>
          );

}