// Import libraries
import dayjs from "dayjs";
import { GetLatestFlight } from "../../utilities/database/GetLatestFlight";

// Import CSS, images, icons & static data


// ************************* MAIN FUNCTION *******************************
export default function PrefillTechLog(TechLogHandleChange, TechLogHandleOilChange, selectedAircraft, currentSvc) {
        // console.log("In PrefillTechLog, selectedAircraft->", selectedAircraft)
    let action = {
        type: "edit",
        name: "registration",
        value: selectedAircraft._registration
    }
    TechLogHandleChange(action);
    action = {
        type: "edit",
        name: "planeType",
        value: selectedAircraft._planeType
    }
    TechLogHandleChange(action);

    action = {
        type: "edit",
        name: "dateOfFlight",
        value: dayjs(new Date()).format("YYYY-MM-DD")
    }
    TechLogHandleChange(action);

    action = {
        type: "edit",
        name: "fuelUsed",
        value: 0.0
    }
    TechLogHandleChange(action);

    action = {
        type: "edit",
        name: "flightTime",
        value: 0.0
    }
    TechLogHandleChange(action);

    action = {
        type: "edit",
        name: "svcDifference",
        value: 0.0
    }
    TechLogHandleChange(action);

    action = {
        type: "edit",
        name: "blockDifference",
        value: 0.0
    }
    TechLogHandleChange(action);

    action = {
        type: "edit",
        name: "departureFuelLeft",
        value: selectedAircraft._fuelTank1State
    }
        // console.log("In PrefillTechLog, selectedAircraft->", selectedAircraft);
    TechLogHandleChange(action)

    action = {
        type: "edit",
        name: "departureFuelRight",
        value: selectedAircraft._fuelTank2State
    }
        console.log("In PrefillTechLog, selectedAircraft->", selectedAircraft);
    TechLogHandleChange(action)

    // NEEDS BE REFACTORED TO BE GENERIC FOR ANY AIRCRAFT WHICH DOES NOT HAVE A TACHO FUNCTION
        // console.log("In PrefillTechLog, selectedAircraft->", selectedAircraft);
    if (selectedAircraft._registration !== "GFOOJ") {

        // let responseObject = GetLatestFlight(selectedAircraft._registration)
        // if (responseObject.databaseConnection) {
            action = {
                type: "edit",
                name: "svcOff",
                value: currentSvc
            } 
        // } else {
        //     UpdateDatabaseConnection(false);
        // }    

    } else {
        action = {
            type: "edit",
            name: "svcOff",
            value: ""
        }
    }
    TechLogHandleChange(action)

    if (selectedAircraft._numberOfEngines === 2) {
        let action = {
            type: "editOilTwin"
        }
        TechLogHandleOilChange(action);
      
    } else {
        // reset the right oil to clear
        let action = {
            type: "editOilSingle",
        }
        TechLogHandleOilChange(action)
    }
}