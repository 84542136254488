// Import libraries
import React, {useState, useEffect, useReducer} from 'react';
import { GetCourseData } from '../utilities/database/GetCourseData.js';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import {Box} from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import  EnrollmentBanner from './EnrollmentBanner.js';
import CourseTable from './CourseTable.js';
import { CourseFormReducer } from '../dispatchersAndReducers/CourseFormReducer.js';
import Feedback from '../utilities/feedback/Feedback.js';
import FeedbackWithInput from '../utilities/feedback/FeedbackWithInput.js';
import Validate from '../utilities/validation/Validate.js';
// import { AdminCourseApprovals } from './AdminCourseApprovals.js';
import { PostCourse } from '../utilities/database/PostCourse.js';
// import AdminCourseLessons from './AdminCourseLessons.js';
// import AdminCourseNewLesson from './AdminCourseNewLesson.js';
// import AddApprovalFields from '../utilities/feedback/AddApprovalFields.js';
// import { CreateAuthoritiesList } from '../utilities/database/CreateAuthoritiesList.js';
import { PostApproval } from '../utilities/database/PostApproval.js';
import { useMediaQuery } from "@mui/material";
import { DatabaseConnectionStatus } from '../utilities/database/DatabaseConnectionStatus.js';
import { PostCourseStatus } from '../utilities/database/PostCourseStatus.js';
import { DeleteApproval } from '../utilities/database/DeleteApproval.js';
import CourseAssignmentButtons from './CourseAssignmentButtons.js';
import { useParams } from 'react-router-dom';
import _ from "lodash";



// Import fonts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// Import CSS, images, icons & static data
// import '../../flights/techLog/TechLog.css';
import './Course.css'
import { courseValidationMessages } from '../utilities/validation/CourseValidationMessages.js';
import { databaseConnectionMessages } from '../utilities/database/DatabaseConnectionMessages.js';
import { emptyCourseSubmission, newCourse} from '../admin/course/CourseData.js';
import { mobileThreshold } from "../utilities/environment/EnvironmentData.js";
import lessonsIcon from "./icons/lessonsIcon.png"



// import {emptyACourseSubmission} from "../../aircraft/AircraftData.js";




// ******************************* MAIN FUNCTION ************************************************
function CourseMain() {
    // Initialise context using default variables
    const title = "Course Progress";
    const courseSelectionKey = 0
    const calledFrom = "Admin"
    const [courseList, setCourseList] = useState([newCourse]);
    var media = useMediaQuery(`(min-width:${mobileThreshold}px)`);
    const [adminCourseFrameClass, setAdminCourseFrameClass] = useState("course-frame")
    const [adminCourseLessonsClass, setAdminCourseLessonsClass] = useState("course-lessons-container")
    const [courseIconClass, setCourseIconClass] = useState("course-icon-box");
    const { courseName } = useParams();

    console.log("In CourseMain, courseName->", courseName);
    
    let courses = [newCourse];
    let lessonsURL = lessonsIcon;
    // let databaseConnectionStatus = {
    //     overallStatus: true,
    // }

    const newAuthorityList =[{
        id: 0,
        authority: "NEW AUTHORITY",
        isoCountryCode: null,
        country: ""
    }]

    // *********************** SET MEDIA VARIABLES *****************************************
    useEffect (() => {
        if (media) {
            setAdminCourseFrameClass("course-frame");
            setAdminCourseLessonsClass("course-lessons-container");
            setCourseIconClass("course-icon-box");
          } else {
            setAdminCourseFrameClass("course-frame-mobile");
            setAdminCourseLessonsClass("course-lessons-container-mobile");
            setCourseIconClass("course-icon-box-mobile");
          } 
        }, [media]);
    
  
    // *********************** INITIALISE STATE VARIABLES ********************************** 
    
    const [selectedCourse, setSelectedCourse] = useState(courseName);
    const [selectedCourseName, setSelectedCourseName] = useState(newCourse.name);
    const [databaseConnection, setDatabaseConnection] = useState(true);
    const [databaseConnectionStatus, setDatabaseConnectionStatus] = useState({overallStatus: true,}); 
    const [enrollmentSubmission, dispatch] = useReducer(CourseFormReducer, emptyCourseSubmission);
    const [editing, setEditing] = useState(false);
    const [editingSwitch, setEditingSwitch] = useState(false);
    const [newAuthority, setNewAuthority] = useState();
    const [newApprovalDate, setNewApprovalDate] = useState();
    const [isEdited, setIsEdited] = useState(false);
    const [newLessonSaveDisabled, setNewLessonSaveDisabled] = useState(true);

    
    // Validations & SnackBar
    const [result, setResult] = useState({status: "", message: "" })
    const [snackBarStatus, setSnackBarStatus] = useState(false);
    const [dialogueStatus, setDialogueStatus] = useState(false);
    const [dialogueResponse, setDialogueResponse] = useState("dialogueCancel"); 
    const [dialogueStatusApproval, setDialogueStatusApproval] = useState(false);

    // Initialise the most severe issue container 
    let mostSevereIssue = {
        status: "success",
        message: courseValidationMessages.successMessages.update,
        field1: "",
        field2: "",
        field3: "",
        field4: ""
    };
        
        console.log("In CourseMain, selectedCourse->", selectedCourse);

    dayjs.extend(utc);

        // console.log("In AdminCourseMain, approvingAuthoritiesList->", approvingAuthorities);

    // ********************* Snackbar & Dialogue Update functions ***************************
    function UpdateSnackBarStatus (newStatus) {
            // console.log("In TechLog, UpdateSnackBarStatus, newStatus->", newStatus);
        setSnackBarStatus(newStatus);
            // console.log("In TechLog, UpdateSnackBarStatus, snackBarStatus->", snackBarStatus);
    }
    function UpdateDialogueStatus (newStatus) {
            // console.log("In TechLog, UpdateDialogueStatus, newStatus->", newStatus);
        setDialogueStatus(newStatus);
            // console.log("In TechLog, UpdateDialogueStatus, DialogueStatus->", DialogueStatus);
    }
    function UpdateDialogueStatusApproval (newStatus) {
        // console.log("In TechLog, UpdateDialogueStatus, newStatus->", newStatus);
    setDialogueStatusApproval(newStatus);
        // console.log("In TechLog, UpdateDialogueStatus, DialogueStatus->", DialogueStatus);
    }
    function HandleAuthorityChange (response) {
        // console.log("In AdminCourseMain, HandleAuthorityChange, response->", response);
    setNewAuthority(response);
        // console.log("In AdminCourseMain, HandleAuthorityChange, newAuthority->", newAuthority);
    }
    function HandleApprovalDateChange (response) {
        // console.log("In AdminCourseMain, HandleApprovalDateChange, response->", response);
    setNewApprovalDate(response);
        // console.log("In AdminCourseMain, HandleApprovalDateChange, newApprovalDate->", newApprovalDate);
    }
    function UpdateDialogueResponse (response) {
            // console.log("In TechLog, UpdateDialogueResponse, response->", response);
        setDialogueResponse(response);
            // console.log("In TechLog, UpdateDialogueResponse, DialogueStatus->", dialogueResponse);
    }
    function UpdateDatabaseConnection (response) {
        // console.log("In TechLog, UpdateDialogueResponse, response->", response);
    setDatabaseConnection(response);
        // console.log("In TechLog, UpdateDialogueResponse, DialogueStatus->", dialogueResponse);
    }
    // function UpdateDatabaseConnectionStatus (response) {
    //     // console.log("In TechLog, UpdateDialogueResponse, response->", response);
    // setDatabaseConnectionStatus(response);
    //     // console.log("In TechLog, UpdateDialogueResponse, DialogueStatus->", dialogueResponse);
    // }


    // Update Validation result functions
    function UpdateResult (newResult) {
        // console.log("In TechLog, UpdateResult, newResult -> ", newResult)
        if (newResult !== undefined) {
            setResult(newResult);
        }
            // console.log("In TechLog, UpdateResult, result -> ", result)
        }

    // ********************** ENABLE & DISABLE BUTTONS AND FIELDS ******************************

    function UpdateEditing(isChecked) {
            // console.log("In AdminAircraftMain, UpdateEditing, isChecked->", isChecked);
        setEditing(isChecked);
    }

    function UpdateEditingSwitch(isChecked) {
        // console.log("In AdminAircraftMain, UpdateEditing, isChecked->", isChecked);
    setEditingSwitch(isChecked);
}

    function UpdateIsEdited(value) {
        // console.log("In AdminAircraftMain, UpdateIsEdited, isEdited->", isEdited);
        setIsEdited(value);
    }

    function UpdateNewLessonSaveDisabled(value) {
        // console.log("In AdminAircraftMain, UpdateIsEdited, isEdited->", isEdited);
        setNewLessonSaveDisabled(value);
    }
    

  

    // *********************** HANDLE DIALOGUE BOX 'CONTINUE' APPROVAL *****************************   
    const HandleContinue = async () => {
        // Attempt to write the Approval to the database

        var responseObject = {};
        responseObject = await PostCourseStatus(enrollmentSubmission.id.value, "DELETED") 

        if (!responseObject.databaseConnection) { 
            UpdateDatabaseConnection(DatabaseConnectionStatus(databaseConnectionStatus, "deleteCourse", responseObject.databaseConnection))
                console.log("In CourseAdmin databaseConnectionContext->", databaseConnectionStatus); 
            // UpdateDatabaseConnection(responseObject.databaseConnection);
            mostSevereIssue = {
                status: "error",
                message: databaseConnectionMessages.error,
                field1: ""
            } 
        } else  {        
            mostSevereIssue = {
                status: "success",
                message: courseValidationMessages.successMessages.delete,
                field1: ""
            } 

            HandleReset();
            UpdateEditingSwitch(false);
            UpdateEditing(false);
            UpdateEditingSwitch(false);
            UpdateIsEdited(false);
            GetCourses();
            UpdateSelectedCourse("reset");
            UpdateResult(mostSevereIssue)
            FeedbackTrigger(mostSevereIssue);
        }
    }

    // *********************** HANDLE DIALOGUE BOX 'CONTINUE' APPROVAL *****************************   
    const HandleContinueApproval = async () => {
        // Attempt to write the Approval to the database
            // console.log("In HandleContinueApproval, newAuthority->", newAuthority);
            // console.log("In HandleContinueApproval, enrollmentSubmission->", enrollmentSubmission);
            // console.log("In AdminCourseMain, AddApproval, newApprovalDate->", newApprovalDate);
        let newApproval = {
            id: null,
            courseId: enrollmentSubmission.id.value,
            authorityId: newAuthority.id,
            // authority: {
            //     id: newAuthority.slice(0,newAuthority.indexOf(":")),
            //     name: newAuthority.slice(newAuthority.indexOf(":") + 1, newAuthority.indexOf("-")),
            //     country: newAuthority.slice(newAuthority.indexOf("-")+1),
            // },
            approvalDate: dayjs(new Date(newApprovalDate)),
            expiryDate: ""
        }
            // console.log("In HandleContinueApproval, newApproval->", newApproval);
        let responseObject = {}
        responseObject = await PostApproval(newApproval);
            // console.log("In HandleContinueApproval, responseObject->", responseObject);
        

        // If submission successful, clear the current render & reset the validation issues
        if (responseObject.databaseConnection) { 
            // HandleAddApproval(responseObject.data);
            UpdateDatabaseConnection(DatabaseConnectionStatus(databaseConnectionStatus, "AddApproval", responseObject.databaseConnection));
            // UpdateDatabaseConnection(responseObject.databaseConnection);
            UpdateDialogueResponse("dialogueCancel");
            mostSevereIssue = {
                status: "success",
                message: courseValidationMessages.successMessages.addApproval,
                field1: "",
                field2: "",
                field3: "",
                field4: ""
            };
                console.log("In AdminCourseMain, callingGetCourses from AddApproval");
            GetCourses();
            // UpdateSelectedCourse(selectedCourse);
            UpdateResult(mostSevereIssue);
            UpdateSnackBarStatus(true);

        } else {
            UpdateDatabaseConnection(DatabaseConnectionStatus(databaseConnectionStatus, "AddApproval", responseObject.databaseConnection));
                // console.log("In HandleContinueCourse, failed submission")
            mostSevereIssue = {
                status: "error",
                message: databaseConnectionMessages.error
            }
        }
        UpdateResult(mostSevereIssue);
        UpdateSnackBarStatus(true);
    }

    // ****************** GET COURSE DATA & UPDATE BASED ON COURSE SELECTED ***********************

    async function GetCourses() {
        let responseObject = await GetCourseData();
        UpdateDatabaseConnection(DatabaseConnectionStatus(databaseConnectionStatus, "GetCourses", responseObject.databaseConnection))
            console.log("In AdminCourseMain, databaseConnectionStatus ->", databaseConnectionStatus);
        if (responseObject.databaseConnection) {
            courses = [];
            courses.push(newCourse)
            responseObject.data.forEach((course) => {
                courses.push(course);
            });
        setCourseList(courses);
        } else {
            // UpdateDatabaseConnectionContext(databaseConnectionContext, responseObject.databaseConnection)
            UpdateDatabaseConnection(DatabaseConnectionStatus(databaseConnectionStatus, "GetCourses", responseObject.databaseConnection))
            setCourseList([newCourse]);
        }
            // console.log("In AdminCourseMain, courseList->", courseList);
    }

    useEffect(() => {
        // courseList = [newCourse];
        GetCourses(courseList);
            // console.log("In AdminCourseMain, courseList->", courseList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enrollmentSubmission.approvals]);

    // Change fields
    function HandleCourseChange(e) {
          
        let action = {
            type: "edit",
            name: e.name,
            event: e,
            value: e.value
        }
        
        if (action !== undefined) {
            dispatch(action)
        }
        UpdateIsEdited(true);
    }

    useEffect(() => {
        setSelectedCourse(newCourse)
            // console.log("In AdminCourseMain, selectedCourse->", selectedCourse)
        InitialiseCourseSubmission(newCourse);
    },[] )

    // Update Registration functions for other components
    function UpdateSelectedCourse (selection) {
            // console.log("In UpdatedSelectedCourse, selection->", selection);
            // console.log("In AdminCourseMain, courseList->", courseList);


        if (selection === "reset") {
            selection = courseList[0];
        }
        setSelectedCourse(selection);
        setSelectedCourseName(selection.name);
        InitialiseCourseSubmission(selection);
        UpdateEditing(false);
        UpdateEditingSwitch(false);
            // console.log("In AdminCourseMain, enrollmentSubmission->", enrollmentSubmission);
            // console.log("In UpdatedSelectedCourse, selectedCourse->", selectedCourse);
    }

    // *********************** ENROLLMENT DISPATCHERS FOR MANAGING THE SUBMISSION OBJECT ****************************

    function InitialiseCourseSubmission(course) {
        let action = {
            type: "initialise",
            selectedCourse: course
        }
            // console.log("In InitialiseAircraftSubmission, action->", action)
        dispatch(action);
        UpdateIsEdited(false);
            // console.log("In AdminCourseMain, InitialiseCourseSubmission->", enrollmentSubmission);
    }

    function UpdateCourseSubmission(selectedCourse) {
        let action = {
            type: "update",
            selectedCourse: selectedCourse
        }
            // console.log("In InitialiseAircraftSubmission, action->", action)
        dispatch(action);
        // UpdateIsEdited(true);
    }

    function HandleReset() {
        InitialiseCourseSubmission(newCourse);
        UpdateDatabaseConnection(true);
        UpdateIsEdited(false);
            // console.log("In AdminCourseMain, HandleCancel->", enrollmentSubmission);
    }

    function HandleLessonChange(e) {
        let action = {
            type: "editLesson",
            name: e.name,
            event: e,
            value: e.value
        }
        
            // console.log("In AdminCourseMain, HandleLessonChange, e->", e, "action->", action)
        if (action !== undefined) {
            dispatch(action)
        }
        UpdateIsEdited(true)
    }



    function HandleNewLessonChange(e) {
        let action = {
            type: "editNewLesson",
            name: e.name,
            event: e,
            value: e.value
        }
        
            // console.log("In AdminCourseMain, HandleLessonChange, e->", e, "action->", action)
        if (action !== undefined) {
            dispatch(action)
        }
        if (enrollmentSubmission.newLesson.section.value === 0 || (typeof enrollmentSubmission.newLesson.section.value !== "number")) {
            UpdateNewLessonSaveDisabled(true);
        }
        if (enrollmentSubmission.newLesson.section.value > 0 && (typeof enrollmentSubmission.newLesson.section.value !== "number")) {
            UpdateNewLessonSaveDisabled(false);
        }
    }

    function HandleSaveNewLesson(e) {
        UpdateIsEdited(true);
        let action = {
            type: "saveNewLesson",
            value: e
        }
        
            // console.log("In AdminCourseMain, HandleLessonChange, e->", e, "action->", action)
        if (action !== undefined) {
            dispatch(action)
        }
            // console.log("In AdminCourseMain, enrollmentSubmission->", enrollmentSubmission);
            // console.log("In AdminCourseMain, enrollmentSubmission->", isEdited);
        UpdateIsEdited(true);
        // HandleClearNewLesson();
        // UpdateSelectedCourse();
        // Add user feedback to make it obvious the new lesson as been added (without being saved to the database)
    }

    function MarkValidationErrors(validationResultList) {
        let action = {
            type: "markValidationErrors",
            value: validationResultList
        }
        
            // console.log("In AdminCourseMain, HandleLessonChange, e->", e, "action->", action)
        if (action !== undefined) {
            dispatch(action)
        }
        // UpdateIsEdited(false);

        // Add user feedback to make it obvious the new lesson as been added (without being saved to the database)
    }

    function ResetValidationErrors() {
        let action = {
            type: "resetValidationErrors",
        }
        
            // console.log("In AdminCourseMain, HandleLessonChange, e->", e, "action->", action)
        if (action !== undefined) {
            dispatch(action)
        }
        // UpdateIsEdited(false);

        // Add user feedback to make it obvious the new lesson as been added (without being saved to the database)
    }



    


  

   

    const CourseSubmit = async () => {

        let type = "updateCourse"
        if (selectedCourse.id === null) {
            type = "createCourse"
        }
            // console.log("In AdminCourseMain, type->", type);
        // Validate the course submission object
        let validationResultList = Validate(enrollmentSubmission, selectedCourse, type);
            // console.log("In AdminAircraftMain, post validation, validationResultList->", validationResultList);

           
        // Parse the validation results and identify the most severe issue
        if (validationResultList) {
            validationResultList.forEach((result) => {
                switch (mostSevereIssue.status) {
                    case "success":
                        if (result.status !== "success") {
                            mostSevereIssue = result;
                        }
                    break;
                    case "warning":
                        if (result.status !== "error") {
                            mostSevereIssue = result;
                        }
                    break;
                    default:
                    break;
                }
            });
        }
            console.log("In AdminCourseMain, mostSevereIssue->", mostSevereIssue);

        // ***************** If course has no errors, submit it to the database *****************
        if (mostSevereIssue.status === "success") {  
            ResetValidationErrors();
                
            var responseObject = {};
            responseObject = await PostCourse(enrollmentSubmission)
                console.log("In AdminCourseMain, responseObject->", responseObject);
            if (!responseObject.databaseConnection) { 
                       
                UpdateDatabaseConnection(DatabaseConnectionStatus(databaseConnectionStatus, "saveCourse", responseObject.databaseConnection))
                console.log("In CourseAdmin databaseConnectionContext->", databaseConnectionStatus); 
                // UpdateDatabaseConnection(responseObject.databaseConnection);
                mostSevereIssue = {
                    status: "error",
                    message: databaseConnectionMessages.error,
                    field1: ""
                } 
            } else {
                UpdateDatabaseConnection(DatabaseConnectionStatus(databaseConnectionStatus, "saveCourse", responseObject.databaseConnection))
                    console.log("In CourseAdmin databaseConnectionContext->", databaseConnectionStatus); 
                UpdateEditingSwitch(false);
                UpdateEditing(false);
                UpdateEditingSwitch(false);
                UpdateIsEdited(false);
                GetCourses();
                UpdateSelectedCourse("reset");
            }

        } 

        FeedbackTrigger(mostSevereIssue, validationResultList);
    }

    // ********************** FEEDBACK based on most severe issue and update fields ************************ 
    function FeedbackTrigger(mostSevereIssue, validationResultList) {
        // Feedback based on most severe issue and update form accordingly
        if (mostSevereIssue !== undefined) {
                // console.log("In TechLogHandleOpen, post dispatch, submission !== undefined -> ", submission)
            UpdateResult(mostSevereIssue);
                // console.log("In TechLogHandleOpen, result -> ", result);
            switch(mostSevereIssue.status) {
                case "success":
                    if (validationResultList) {
                        ResetValidationErrors();
                    }
                    // UpdateResult(mostSevereIssue);
                    UpdateSnackBarStatus(true);
                break;
    
                case "error":
                    if (mostSevereIssue.message === databaseConnectionMessages.error) {
                        UpdateDatabaseConnection(false);
                    } else {
                        MarkValidationErrors(validationResultList);
                        // UpdateResult(mostSevereIssue);
                    }
                    UpdateSnackBarStatus(true);
                    
                break;
    
                case "warning":
                    if (validationResultList) {
                        MarkValidationErrors(validationResultList);
                    }
                    // UpdateResult(mostSevereIssue)
                    UpdateDialogueStatus(true);
                break;
    
                default: console.log("Unknown validation result")
            }
        }
    }


    // *********************** COURSE ADMIN RENDER ****************************
      
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            
            <meta name="viewport" content="initial-scale=1, width=device-width" />
            
            <Box>
                <EnrollmentBanner 
                    UpdateSnackBarStatus = {UpdateSnackBarStatus} 
                    UpdateEditing = {UpdateEditing}
                    UpdateEditingSwitch = {UpdateEditingSwitch}
                    editingSwitch = {editingSwitch}
                    isEdited = {isEdited}
                    UpdateIsEdited = {UpdateIsEdited}
                    snackBarStatus = {snackBarStatus} 
                    UpdateResult = {UpdateResult}
                    HandleReset = {HandleReset}
                    CourseSubmit = {CourseSubmit}
                    className = "banner" 
                    title = {title} 
                    submission = {enrollmentSubmission} 
                    databaseConnection = {databaseConnection} 
                    courseList={courseList}
                    selectedCourse = {selectedCourse} 
                    courseName = {courseName}
                    selectedCourseName={selectedCourseName}
                    UpdateSelectedCourse={UpdateSelectedCourse}/> 
            </Box>
                < Box className = {adminCourseFrameClass}>
                <CourseTable 
                    editing = {editing}
                    selectedCourse = {selectedCourseName} 
                    enrollmentSubmission = {enrollmentSubmission}
                    HandleCourseChange = {HandleCourseChange}
                    />
                 {/*<CourseAssignmentButtons
                    HandleDeleteCourse = {HandleDeleteCourse}
                    enrollmentSubmission = {enrollmentSubmission}
                    editing = {editing}
                    /> */}
                {/* <AdminCourseApprovals 
                    editing = {editing}
                    selectedCourse = {selectedCourseName} 
                    enrollmentSubmission = {enrollmentSubmission}
                    HandleDeleteCourse = {HandleDeleteCourse}
                    UpdateDatabaseConnection = {UpdateDatabaseConnection}
                    UpdateStatus = {UpdateStatus}
                    HandleDeleteApproval = {HandleDeleteApproval}
                    HandleAddApproval = {HandleAddApproval}
                    // AddApproval = {AddApproval}
                    HandleReset = {HandleReset}
                    UpdateEditingSwitch = {UpdateEditingSwitch}
                    UpdateEditing = {UpdateEditing}
                    UpdateSelectedCourse = {UpdateSelectedCourse}
                    // UpdatedEditing = {UpdateEditingSwitch}
                    UpdateIsEdited = {UpdateIsEdited}
                    /> */}

                <Box className = {adminCourseLessonsClass}>
                    <Box sx = {{display: "flex", flexDirection: "row", justifyItems: "spaceBetween"}} className = "course-subtitles">
                        <Box >
                            Lessons
                        </Box>
                        <Box >   
                                <img className = {courseIconClass} src = {`${lessonsURL}`}/>
                        </Box>
                    </Box>
                        {/* <AdminCourseNewLesson 
                            HandleNewLessonChange = {HandleNewLessonChange}
                            HandleSaveNewLesson = {HandleSaveNewLesson}
                            HandleClearNewLesson = {HandleClearNewLesson}
                            newLessonSaveDisabled = {newLessonSaveDisabled}
                            UpdateNewLessonSaveDisabled = {UpdateNewLessonSaveDisabled}
                            enrollmentSubmission = {enrollmentSubmission}
                            // RemoveLesson = {RemoveLesson}
                            editing = {editing}
                            UpdateIsEdited = {UpdateIsEdited}
                        /> */}
                        {/* <AdminCourseLessons 
                            HandleLessonChange = {HandleLessonChange}
                            enrollmentSubmission = {enrollmentSubmission}
                            RemoveLesson = {RemoveLesson}
                            editing = {editing}
                        /> */}
                </Box>
            </Box>

            <Box className = "feedback" sx={{zIndex: "6", position: "sticky", left: "0px", bottom: "80px", margin: "80px"}}>
                <FeedbackWithInput
                    HandleContinueApproval = {HandleContinueApproval}
                    dialogueStatusApproval = {dialogueStatusApproval}
                    newAuthority = {newAuthority}
                    newApprovalDate = {newApprovalDate}
                    UpdateDialogueStatusApproval = {UpdateDialogueStatusApproval}
                    result = {result}
                />
                <Feedback  
                        HandleContinue = {HandleContinue} 
                        dialogueStatus = {dialogueStatus} 
                        UpdateDialogueStatus = {UpdateDialogueStatus} 
                        snackBarStatus = {snackBarStatus} 
                        UpdateSnackBarStatus = {UpdateSnackBarStatus} 
                        result = {result}/> 
            </Box>
        </LocalizationProvider>
        
    );
};

export {CourseMain};
