
const pilotLogColumns = [
  // {
  //   field: 'registration',
  //   headerName: 'Registration',
  //   headerAlign: "left",
  //   align: "left",
  //   width: 120,
  //   editable: false,
  // },
  {
    field: 'id',
    headerName: 'sort Key',
    headerAlign: "left",
    align: "left",
    width: 180,
    editable: false,
    visibility: false,
    sort: "desc"
  },
  {
    field: 'dateOfFlight',
    headerName: 'Date Of Flight',
    headerAlign: "left",
    align: "left",
    width: 160,
    type: 'date',
    editable: false,
  },
  {
    field: 'registration',
    headerName: 'Registration',
    headerAlign: "left",
    align: "left",
    width: 150,
    editable: false,
  },
  {
    field: 'planeType',
    headerName: 'Aircraft Type',
    headerAlign: "left",
    align: "left",
    width: 160,
    editable: false,
  },
  {
    field: 'from',
    headerName: 'From',
    headerAlign: "left",
    align: "left",
    width: 120,
    editable: false,
  },
  {
    field: 'to',
    headerName: 'To',
    headerAlign: "left",
    align: "left",
    width: 120,
    editable: false,
  },
  {
    field: 'pilot',
    headerName: 'Pilot In Command',
    headerAlign: "left",
    align: "left",
    width: 180,
    editable: false,
  },
  {
    field: 'offBlocks',
    headerName: 'Off Blocks',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 110,
    editable: false,
  },
  {
    field: 'onBlocks',
    headerName: 'On Blocks',
    headerAlign: "left",
    align: "left",
    description: 'Pilot Log Time',
    width: 110,
    editable: false,
  },
  {
    field: 'blockTime',
    headerName: 'Block Time',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 110,
    editable: false,
  },
  {
    field: 'pilotInCommand',
    headerName: 'PIC',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 140,
    editable: false,
  },
  {
    field: 'pilotUnderTraining',
    headerName: 'PUT',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 100,
    editable: false,
  },
  {
    field: 'multiPilot',
    headerName: 'Multi-Pilot',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 130,
    editable: false,
  },
  {
    field: 'instructor',
    headerName: 'Instructor',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 130,
    editable: false,
  },
  {
    field: 'sepTime',
    headerName: 'SEP',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 120,
    editable: false,
  },
  {
    field: 'mepTime',
    headerName: 'MEP',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 120,
    editable: false,
  },
  {
    field: 'jetTime',
    headerName: 'JET',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 120,
    editable: false,
  },
  {
    field: 'simulator',
    headerName: 'Simulator',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 120,
    editable: false,
  },
  {
    field: 'instrumentTime',
    headerName: 'Instrument',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 120,
    editable: false,
  },
  {
    field: 'nightTime',
    headerName: 'Night',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 90,
    editable: false,
  },
  {
    field: 'landings',
    headerName: 'All Landings',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 120,
    editable: false,
  },
  {
    field: 'nightLandings',
    headerName: 'Night Landings',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 120,
    editable: false,
  },
  {
    field: 'flightDetails',
    headerName: 'Flight Details',
    headerAlign: "left",
    align: "left",
    width: 160,
    editable: false,
  },
];


export {pilotLogColumns};

   

