// Import libraries
import React, {useEffect, useState} from 'react';
import {Box, useMediaQuery} from "@mui/material";


// Import fonts

// Import CSS, images, icons, static data
import "./Banner.css";
import { mobileThreshold } from '../../../utilities/environment/EnvironmentData';

// ******************************* MAIN FUNCTION ************************************************
function MakeAndModel(props) {
    const defaultMakeAndModel = props.aircraftList[0]._make + ' ' + props.aircraftList[0]._model;
    const [aircraftMakeAndModel, setAircraftMakeAndModel] = useState(defaultMakeAndModel);
    var aircraftMakeAndModelClass

    var media = useMediaQuery(`(min-width:${mobileThreshold}px)`);
    if (media) {
        aircraftMakeAndModelClass = "aircraft-type"
    } else {
        aircraftMakeAndModelClass = "aircraft-type-mobile"
    }


    useEffect(() => {
            props.aircraftList.forEach((aircraft) => {
                if (props.registration === aircraft.registration) {
                        setAircraftMakeAndModel(aircraft._make + ' ' + aircraft._model);
                 } 
                });
    }, [props.aircraft, props.selectedAircraft, props.registration]);
return (
    <Box>
        <Box className = "aircraft-label">Type</Box>
        <Box className = {aircraftMakeAndModelClass} id = "aircraft-type" name ="aircraft-type">{aircraftMakeAndModel}</Box>
    </Box>
);

}

export default MakeAndModel;