// Import libraries
import * as React from 'react';
import {useState, useEffect} from 'react';
import { Chip, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Paper } from '@mui/material';
import Typography from '../components/Typography';
import TextField from '../components/TextField';
import Snackbar from '../components/Snackbar';
import { IconButton } from '@mui/material';
import flightIoW2 from "../../../../images/flightIoW2.JPG"
import { useMediaQuery } from '@mui/material';
// import flightCommercial from "../../../../components/homePage/images/flightCommercial.jpg"

// Import CSS, images, icons & static data
import { currentSchool } from "../../../../schoolDetails.js";
import { ContentCopy, FitScreen } from '@mui/icons-material';
import { baseBlue } from '../../../../../utilities/environment/EnvironmentData';
import { mobileThreshold } from '../../../../../utilities/environment/EnvironmentData';
import azTechTheme from '../../../../../utilities/environment/ColourTheme';


//************************** MAIN FUNCTION ********************************/
function ProductCTA() {
  const [open, setOpen] = useState(false);
  const[copyState, setCopyState] = useState("Copy")
  var media = useMediaQuery(`(min-width:${mobileThreshold}px)`);
  var emailFontSize = "x-small"
  const backgroundColour = azTechTheme.palette.blanchedAlmond.rgb;

  if (media) {
    emailFontSize = "large";
  } else {
    emailFontSize = "medium";
  }
  

  function handleClick() {
    // setCopyState("Copied");
    navigator.clipboard.writeText(currentSchool.email);
      // console.log("handleClick triggered, navigator.clipboard->", navigator.clipboard);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  

  return (
    <Container component="section" sx = {{ mt: 5, display: 'flex', minWidth: "760px"}}>
      <Grid container>
        <Grid item xs={6} md={0} sx={{ zIndex: 1 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: backgroundColour,
              py: 8,
              px: 3,
            }}
          >
            <Box component="form" sx = {{maxWidth: "400px", backgroundColor: backgroundColour,}}>
              <Typography variant="h3" component="h2" gutterBottom sx = {{ml: "15px", mr: "15px"}}>
                Get in touch... 
              </Typography>
              <Typography variant="h5" sx = {{ml: "15px", mr: "15px"}}>
                Send us a mail and we'll arrange a time to chat.
              </Typography>
              <Tooltip title = {copyState} arrow>
              <Box
                variant = "contained"
                borderRadius = "25px" 
                sx={{ backgroundColor: baseBlue,
                      verticalAlign: "bottom",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      mt: "20px", height: "45px", width: '300px',
                      textAlign: "center", padding: "10px",  
                      fontFamily: "arial" }}>
                <IconButton onClick = {handleClick} >
                  <Box sx = {{mt: "5px", mr: "15px", fontSize: "medium", color: "white"}}>{currentSchool.email}</Box>
                  <ContentCopy 
                    fontSize = "small" 
                    sx = {{color: "white", zIndex: "5", mb: "10px"}}/> 
                </IconButton>
              </Box>
              </Tooltip>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={8}
          md={6}
          sx={{ display: { md: 'block', xs: 'none' }, position: 'relative' }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: -67,
              left: -67,
              right: 0,
              bottom: 0,
              width: '100%',
              background: 'url(/static/themes/onepirate/productCTAImageDots.png)',
            }}
          />
          <Box
            component="img"
            src = {flightIoW2}
            // src = {flightCommercial}
            // src="https://images.unsplash.com/photo-1527853787696-f7be74f2e39a?auto=format&fit=crop&w=750"
            alt="call to action"
            sx={{
              position: 'absolute',
              top: -28,
              left: -28,
              right: 0,
              bottom: 0,
              width: '100%',
              maxWidth: 600,
            }}
          />
        </Grid>
      </Grid>
      <Snackbar
        open={open}
        closeFunc={handleClose}
        message="We will set up time so you can get to know us..."
      />
    </Container>
  );
}

export default ProductCTA;
