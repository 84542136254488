// Import libraries
// import axios from "axios";
import axiosInstance from "../security/AxiosConfig.js";
import _ from "lodash";

// Import CSS, images, icons and static data
import { API_URL } from '../../../config/config.js';
import { databaseConnectionMessages } from "./DatabaseConnectionMessages.js";

// import { useAuthFirebase } from "../../utilities/AuthContextFirebase.js";




    // ************************* SEND FLIGHT TO DATABASE *******************************
    
export async function PostApproval(approval) {

    var responseObject = {}
    // const databaseSubmission = {}
        console.log("In PostApproval approval->",approval);
       
        // console.log("In PostFlight, approval->", approval)
        // Object.keys(submission).forEach((object) => {
        //     // console.log("In TechLog, SubmitFlight, object->",object ,"submission[object]->", submission[object])
        //     databaseSubmission[object] = submission[object].value;

        // });
        // console.log("In PostFlight, PostFlight, databaseSubmission->", databaseSubmission)
    try {
            // console.log("In PostFlight, databaseSubmission -> ", databaseSubmission)
        let response = await axiosInstance.post(`${API_URL}/courses/add-approval`, approval); 

        responseObject.data = response.data;
        responseObject.databaseConnection = true;
            console.log("In PostFlight, responseObject->", responseObject)
            // console.log("In SubmitFlight, databaseSubmission->", databaseSubmission);
            // console.log("In SubmitFlight, response.status->", response.status);
        return responseObject;
    } catch (error) {
        console.error("Error adding an approval", error);
        responseObject.data = databaseConnectionMessages.error;
            // console.log("In PostFlight, resopnseObject.data->", responseObject.data)
        responseObject.databaseConnection = false;
        return responseObject;
    }
}