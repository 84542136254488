// Import libraries
// import {useState} from "react";
import {flightLogColumnsPart1} from "./FlightLogColumns.js";
import {flightLogColumnsOilOneEngine} from "./FlightLogColumns.js"
import {flightLogColumnsOilTwoEngines} from "./FlightLogColumns.js"
import {flightLogColumnsPart2} from "./FlightLogColumns.js"
import { flightLogColumnsSim } from "./FlightLogColumnsSim.js";
import _ from "lodash";

// import aircraft from "../aircraft/CreateAircraftList.js";





// ******************************* MAIN FUNCTION ************************************************
  export default function CreateFlightLogColumns(selectedAircraft) {
    // console.log("flightLogColumnsPart1 -> stuff and selected aircraft reg.", selectedAircraft._registration, flightLogColumnsPart1);
    // console.log("flightLogColumnsOneEngine -> other stuff", flightLogColumnsOilOneEngine);
    // console.log("flightLogColumnsTwoEngines -> more stuff", flightLogColumnsOilTwoEngines);
    // console.log("flightLogColumnsPart2 -> even more stuff", flightLogColumnsPart2);


    let flightLogColumns = []

    if (selectedAircraft._planeType === "SIM") { 
      flightLogColumns = _.cloneDeep(flightLogColumnsSim);

    } else {

      // Flight Part 1 Columns
      flightLogColumnsPart1.forEach((column) => {
        flightLogColumns.push(column);
      })
      // Flight Oils Columns - One Engine or Two
        // console.log("Oil Column Build ", selectedAircraft._numberOfEngines)
      if (selectedAircraft._numberOfEngines === 1) {
        flightLogColumnsOilOneEngine.forEach((column) => {
          flightLogColumns.push(column);
        })} else {
          flightLogColumnsOilTwoEngines.forEach((column) => {
            flightLogColumns.push(column);
          })
        }
      // // Flight Part 2 Columns
        flightLogColumnsPart2.forEach((column) => {
          flightLogColumns.push(column);
        })
    }
          // console.log("In CreateFlightLogColumns, flightLogColumns->",flightLogColumns)
        return flightLogColumns
    };
    