// Import libraries
import React, { useEffect, useState } from "react";
import { TextField, Button } from "@mui/material";
import {emptySubmission} from "../../flightData/FlightData.js";
import Media from "../../../utilities/environment/Media.js";
import ArrivalTimesAndDefects from "./ArrivalTimesAndDefects.js";
import ArrivalTableFuel from "./ArrivalTableFuel.js"
import ArrivalTableTimes from "./ArrivalTableTimes.js"
import ArrivalTableDefects from "./ArrivalTableDefects.js"
import AircraftHeader from "../../../utilities/AircraftHeader.js";
import {Box} from "@mui/material"
import CalculateOffset from "../../../utilities/dateAndTime/CalculateOffset.js";
import BlueArrow from "../arrival/BlueArrow.js";
import ArrivalImage from "./ArrivalImage.js";
import ArrivalNavAndFuel from "./ArrivalNavAndFuel.js";
import millisecondsToHours from "date-fns/millisecondsToHours";
import differenceInHours from "date-fns/differenceInHours";
import differenceInMinutes from "date-fns/differenceInMinutes";
import { parse } from "date-fns";



// Import CSS & images
import './Arrival.css';
import onBlock from "./onBlock.js";



// ******************************* MAIN FUNCTION ************************************************
export default function Arrival(props) {

    // ******************** CONSTANTS & CALCULATED FIELDS ***********************
    const blockTimeOffset = 6;
    const [blockDifference, setBlockDifference] = useState(0.0);
    const [flightTime, setFlightTime] = useState(0.0);
    const [svcDifference, setSVCDifference] = useState(0.0);
    const [calculatedFuelUsed, setCalculatedFuelUsed] = useState(0.0);

    let blockDifferenceDisplay = 0.0;
    let flightTimeDisplay = 0.0;
    let blockDifferenceTmp = 0.0;

    let mobile = Media();
    let takeOff = props.submission.data.takeOff.value;
    let landing = props.submission.data.landing.value
    let offBlocks = props.submission.data.offBlocks.value;
    let onBlocks = props.submission.data.onBlocks.value;
    let flightTimeTmp = 0.0;


    // Submit Button Function
    const ArrivalSubmit = () => {
        let action = {
            type: "edit",
            name: "registration",
            value: props.registration
        }
        props.TechLogHandleChange(action)        
        action = {
            type: "edit",
            name: "planeType",
            value: props.selectedAircraft._planeType
        }
        props.TechLogHandleChange(action)

            // console.log("In ArrivalSubmit, Submit Button pressed.")
        props.TechLogHandleOpenSubmit("arrival");
    }       


    function BlueArrowOptional () {
        let excludedForAircraftType = ["SIM"];
        if (excludedForAircraftType.indexOf(props.selectedAircraft._planeType) === -1) {
                
            return (
                <BlueArrow/>  
            ) 
        } else {
            return (
                <Box></Box>
            )
        }
        
    }

    if (flightTime !== "" && flightTime !== null && Number(flightTime) >= 0 && flightTime !== undefined) {
        // console.log("In Arrival, blockDifference -> ", blockDifference);
    flightTimeDisplay = parseFloat(flightTime);
    flightTimeDisplay = flightTimeDisplay.toFixed(1);
}

    if (blockDifference !== "" && blockDifference !== null && Number(blockDifference) >= 0 && blockDifference !== undefined) {
            // console.log("In Arrival, blockDifference -> ", blockDifference);
        blockDifferenceDisplay = parseFloat(blockDifference);
        blockDifferenceDisplay = blockDifferenceDisplay.toFixed(1);
    }
    // let flightTimeDisplay = flightTime.toFixed(1);

    let svcDifferenceDisplay = 0.0
    if (svcDifference !== "" && svcDifference !== null && Number(svcDifference) >= 0 && svcDifference !== undefined) {
            // console.log("In Arrival, svcDifference ->", svcDifference);
        svcDifferenceDisplay = parseFloat(svcDifference);
        svcDifferenceDisplay = svcDifferenceDisplay.toFixed(1);
    }

    useEffect(() => {
        var takeOffFuel = Number(props.submission.data.departureFuelLeft.value) + Number(props.submission.data.departureFuelRight.value);
        var landingFuel = Number(props.submission.data.arrivalFuelLeft.value) + Number(props.submission.data.arrivalFuelRight.value) - Number(props.submission.data.arrivalFuelUpliftLeft.value) - Number(props.submission.data.arrivalFuelUpliftRight.value);
        
        if (isNaN(takeOffFuel) || 
            isNaN(landingFuel) || 
            landingFuel === "" || 
            takeOffFuel === "" || 
            // landingFuel === 0 || 
            // takeOffFuel === 0 || 
            landingFuel === null || 
            takeOffFuel === null ) {
            setCalculatedFuelUsed(0.0);  
        } else {
                // console.log("In Arrival, takeOffFuel -> ", takeOffFuel, " landingFuel ->", landingFuel);
            let fuelUsed = parseFloat(parseFloat(takeOffFuel - landingFuel).toFixed(1));
            setCalculatedFuelUsed((parseFloat(takeOffFuel - landingFuel)).toFixed(1));

            let action = {
                type: "edit",
                name: "fuelUsed",
                value: fuelUsed
                };
            props.TechLogHandleChange(action);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[
        props.submission.data.departureFuelLeft.value, 
        props.submission.data.departureFuelRight.value, 
        props.submission.data.arrivalFuelUpliftLeft.value, 
        props.submission.data.arrivalFuelUpliftRight.value, 
        props.submission.data.arrivalFuelLeft.value, 
        props.submission.data.arrivalFuelRight.value,
        calculatedFuelUsed
    ]);


    useEffect (() => {
        // *********************** Calculate and set Block Difference *******************************

            // console.log("In Arrivals, onBlocks->", onBlocks);
            // console.log("In Arrivals, onBlocks->", offBlocks);

        if (offBlocks && onBlocks) {
            blockDifferenceTmp = differenceInMinutes(parse(onBlocks, "HH:mm", new Date()), parse(offBlocks, "HH:mm", new Date()))

            blockDifferenceTmp = Number((blockDifferenceTmp / 60).toFixed(1));

                // console.log("In Arrivals, blockDifferenceTmp->", blockDifferenceTmp);

            if (blockDifferenceTmp < 0) {
                blockDifferenceTmp += 24;
            }

            setBlockDifference(blockDifferenceTmp); 
        }
            
            // console.log("In Arrivals, blockDifferenceTmp->", blockDifferenceTmp);

        // ********************* Calculate and set flight time **************************

        if (takeOff && landing && takeOff !== 0 && landing !== 0) {
            flightTimeTmp = differenceInMinutes(parse(landing,"HH:mm", new Date()), parse(takeOff, "HH:mm", new Date()));

            flightTimeTmp = Number((flightTimeTmp / 60).toFixed(1));

                // console.log("In Arrivals, flightTimeTmp->", flightTimeTmp);

            if (flightTimeTmp < 0) {
                flightTimeTmp += 24;
            }

            setFlightTime(flightTimeTmp)
        }

            // console.log("In Arrivals, flightTimeTmp->", flightTimeTmp);

        // *********************** Calculate and set SVC Difference **************************
            // console.log("In Arrival, useEffect, isNaN(svcOff) & svcOn -> ", isNaN(props.submission.svcOff.value), isNaN(props.submission.svcOn.value), isNaN(null))
        if (isNaN(props.submission.data.svcOff.value) ||
            isNaN(props.submission.data.svcOn.value) ||
            props.submission.data.svcOff.value === "" || props.submission.data.svcOff.value === null ||
            props.submission.data.svcOn.value === "" || props.submission.data.svcOn.value === "") {
            setSVCDifference(0.0);
        }  else {
            let svcDifferenceDisplay = (parseFloat(props.submission.data.svcOn.value - props.submission.data.svcOff.value)).toFixed(1)
            setSVCDifference(svcDifferenceDisplay);
        }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
        props.submission.data.offBlocks.value, 
        props.submission.data.onBlocks.value, 
        props.submission.data.takeOff.value, 
        props.submission.data.landing.value, 
        props.submission.data.svcOff.value, 
        props.submission.data.svcOn.value,
    ]);

    useEffect(() => {
        let action = {};

        // Update submission with new blockDifference
        action = {
            type: "edit",
            name: "blockDifference",
            value: blockDifference
        };
        props.TechLogHandleChange(action);

        // Update submission with new flight time
        action = {
            type: "edit",
            name: "flightTime",
            value: flightTime
        };
        props.TechLogHandleChange(action);

        // Update submission with new svcDifference
        action = {
            type: "edit",
            name: "svcDifference",
            value: svcDifference
        };
        props.TechLogHandleChange(action);

        // Update offBlocks when takeOff time changes
        let offBlockOffset = CalculateOffset(props.submission.data.takeOff.value, blockTimeOffset)
        action = {
            type: "edit",
            name: "offBlocks",
            value: offBlockOffset
        };
        props.TechLogHandleChange(action);

        // Update onBlocks when landing time changes
        let onBlockOffset = CalculateOffset(props.submission.data.landing.value, (blockTimeOffset * -1))
        action = {
            type: "edit",
            name: "onBlocks",
            value: onBlockOffset
        };
        props.TechLogHandleChange(action);
            // console.log("In Arrival, useEffect Times, submission -> ", props.submission)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[
        blockDifference,
        flightTime,
        svcDifference,
        props.submission.data.takeOff.value,
        props.submission.data.landing.value
    ] )

    if (blockDifference !== "" && blockDifference !== null && blockDifference !== undefined && blockDifference >= 0) {
        let blockDifferenceDisplay = parseFloat(blockDifference);
        blockDifferenceDisplay = blockDifferenceDisplay.toFixed(1);
    }
    if (svcDifference !== "" && svcDifference !== null && svcDifference !== undefined && Number(svcDifference) >= 0) {
        let svcDifferenceDisplay = parseFloat(svcDifference);
        svcDifferenceDisplay = svcDifferenceDisplay.toFixed(1);
    }
        // console.log("In Arrival, arr-details$mobile -> ", `arr-details${mobile}`)
            
    return (
        <Box className = {`arrival${mobile}`}>
            <ArrivalImage registration = {props.registration} TechLogHandleChange = {props.TechLogHandleChange}/>
            <Box className = {`arr-details${mobile}`}>
                <Box className = "arr-details-row1">
                    <AircraftHeader TechLogHandleChange = {props.TechLogHandleChange} registration = {props.registration}/>
                </Box>
                <Box className = "arr-details-row2">
                    <ArrivalNavAndFuel
                        TechLogHandleChange = {props.TechLogHandleChange} 
                        TechLogHandleChangeNumber = {props.TechLogHandleChangeNumber} 
                        submission = {props.submission}
                        selectedAircraft = {props.selectedAircraft}
                        calculatedFuelUsed = {calculatedFuelUsed}/>
                </Box>
                <Box>
                    <ArrivalTimesAndDefects
                        flightTimeDisplay = {flightTimeDisplay}
                        TechLogHandleChange = {props.TechLogHandleChange} 
                        blockDifferenceDisplay = {blockDifferenceDisplay} 
                        svcDifferenceDisplay = {svcDifferenceDisplay} 
                        submission = {props.submission}/>
                </Box>
                <Box className = "arr-details-row4">
                    {/* <Box className = "dep-details-row3">
                        <Button className = "buttonOpen" disabled = {props.deleteButton === false} size = "medium" variant = "contained" name = "flightDelete" onClick={HandleDeleteClick}>DELETE</Button>
                    </Box> */}
                    <div colSpan = "1">
                        <Button 
                            className = "buttonSubmit" 
                            size = "medium" 
                            variant = "contained" 
                            name = "TechLog" 
                            onClick={ArrivalSubmit}>SUBMIT
                        </Button>
                    </div>
                </Box>
            
            </Box> 
            <BlueArrowOptional/>
        </Box>
    );
}