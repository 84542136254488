// ************************************************ DEPARTURE OBJECTS *************************************************

// Departure (13)
let departureMandatoryFields = {
    // Base details (3)
    registration: true, 
    dateOfFlight: true, 
    pilot: true, 

    // Departure details (10)
    passenger1: false,
    passenger2: false,
    departureFuelUpliftLeft: false,
    departureFuelUpliftRight: false,
    departureFuelLeft: true, 
    departureFuelRight: true, 
    oilUpliftLeft: false,
    oilUpliftRight: false,
    oilStatusLeft: false, 
    oilStatusRight: false, 
}

let simDepartureMandatoryFields = {
    // Base details (3)
    registration: true, 
    dateOfFlight: true, 
    pilot: true, 

    // Departure details (10)
    passenger1: false,
    passenger2: false,
    departureFuelUpliftLeft: false,
    departureFuelUpliftRight: false,
    departureFuelLeft: false, 
    departureFuelRight: false, 
    oilUpliftLeft: false,
    oilUpliftRight: false,
    oilStatusLeft: false, 
    oilStatusRight: false, 
}

// Empty departure (13)
let emptyDeparture = {
    // Base details (3)
    registration: "", 
    dateOfFlight: "", 
    pilot: "", 
    
    // Departure details (10)
    passenger1: "",
    passenger2: "",
    departureFuelUpliftLeft: "",
    departureFuelUpliftRight: "",
    departureFuelLeft: "", 
    departureFuelRight: "", 
    oilUpliftLeft: "",
    oilUpliftRight: "",
    oilStatusLeft: "", 
    oilStatusRight: "", 
}

// Empty Departure List (13)
let emptyDepartureList = [
    //Base details (3)
    "", // registration
    "", // dateOfFlight
    "", // pilot:
    //Departure details (10)
    "", // passenger1
    "", // passenger2
    "", // departureFuelUpliftLeft
    "", // departureFuelUpliftRight
    "", // departureFuelLeft
    "", // departureFuelRight
    "", // oilUpliftLeft
    "", // oilUpliftRight
    "", // oilStatusLeft
    "", // oilStatusRight
]




export {emptyDeparture, emptyDepartureList, departureMandatoryFields, simDepartureMandatoryFields};
