//Import libraries
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import React, {useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import AdminCourseMain from "../admin/course/AdminCourseMain";
import {useMediaQuery} from '@mui/material';
import { useAuthFirebase } from '../utilities/security/AuthContextFirebase';
import { HasPermission }  from '../utilities/security/HasPermission';
import CourseProgress from "./CourseProgress";
import _ from "lodash";


// Import CSS, images, icons & static data
import "./CourseSelection.css"
import { mobileThreshold } from '../utilities/environment/EnvironmentData.js';


// Import fonts


//**************************** MAIN FUNCTION ***************************** */
export default function CourseTile(props) {
        // console.log("In CourseTiles, Object.keys(props.course.image)->", Object.keys(props.course.icon))

    let iconURL = Object.values(props.courseStatic.icon)[0];
    let courseTileClass = "";
    let courseIconClass = "";  
    let tileDisabled = false;
    let progressType = "line";
    let buttonClassName = "courses-button"
    let imageComponent

    var media = useMediaQuery(`(min-width:${mobileThreshold}px)`)
        // console.log("In CourseTile, props.courseStatic->", props.courseStatic);


    if (media) {
        if (props.courseStatic.tileDisabled) {
            courseTileClass = "courses-tile-disabled";
            imageComponent = (<span>Not Available</span>);
        }   else {
            courseTileClass = "courses-tile";
            imageComponent = (
                <img 
                    className = "courses-icon"
                    src={`${iconURL}`} 
                    alt="Course Icon"
                />
            );
        }
        // courseIconClass = "courses-icon";
        progressType = "line";
    } else {
        if (props.courseStatic.tileDisabled) {
            courseTileClass = "courses-tile-mobile-disabled";
            imageComponent = (<span>Not Available</span>);
        }   else {
            courseTileClass = "courses-tile-mobile";
            imageComponent = (
                <img 
                    className = "courses-icon-mobile"
                    src={`${iconURL}`} 
                    alt="Course Icon"
                />
            );
        }
        // courseIconClass = "courses-icon-mobile"; 
        progressType = "circle";
    }

    const handleClick = (path) => {
        props.handleNavigate(path)
    }





    if (props.courseStatic.tileDisabled) {
            // console.log("In CourseTile, props.courseStatic.tileDisabled->", props.courseStatic.tileDisabled);
            // console.log("In CourseTile, props.courseStatic->", props.courseStatic);
        tileDisabled = true;
        buttonClassName = "courses-button-disabled"
    }

    return (
        // <Box>
            <Button 
                className = {buttonClassName}
                name = {props.courseStatic.name} 
                id = {props.courseStatic.name} 
                disabled = {tileDisabled === true}
                onClick = {() => handleClick(`/CourseMain/${props.courseStatic.name}`)}>
                <Box className = {courseTileClass}>
                    <Box sx = {{mt: "10px", ml: "0px"}}>
                        {props.courseStatic.name}
                    </Box>
                    <Box>
                        {/* <img className = {courseIconClass} src = {`${iconURL}`}/> */}
                        {imageComponent}
                    </Box>
                    <CourseProgress
                        chosenRole = {props.chosenRole}
                        courseStaticName = {props.courseStatic.name}
                        courseStatic = {props.courseStatic}
                        progress = {props.progress}
                        progressType = {progressType}
                        authUser = {props.authUser}
                        userId = {props.userId}
                        enrollments = {props.enrollments}
                        UpdateDatabaseConnection = {props.UpdateDatabaseConnection}/>
                </Box>
            </Button>

        // {/* </Box> */}
    );
};