export const daily = [
  {
    groupId: 'DAILY TOTALS',
    children: [{field: 'id'}, {field: 'date'},{ field: 'flights'}, {field: 'flightTime'}]
  }
]

export const weekly = [
    {
      groupId: 'WEEKLY TOTALS',
      children: [{field: 'id'}, {field: 'weekNumber'}, {field: 'date'},{ field: 'flights'}, {field: 'flightTime'}]
    }
]

export const monthly = [
    {
      groupId: 'MONTHLY TOTALS',
      children: [{field: 'id'},{field: 'date'},{ field: 'flights'}, {field: 'flightTime'}]
    }
  ]

export const camoLogColumnGroups = {
    daily,
    weekly,
    monthly
  };