//Import libraries
// import { GetPostPerson } from "../database/GetPostPerson";
import { GetPostPerson } from "../database/GetPostPerson";


// Import CSS, images, icons & static data
import { authorisationMessages } from "./AuthorisationMessages";


// Import fonts


//**************************** MAIN FUNCTION ***************************** */
export async function HandleNewUser(personRequest) {

    var responseObject = {};

        // console.log("In HandleNewUser, personRequest->", personRequest) 
    
    // Check user exists and set up if not.
    if (personRequest.firstName && personRequest.lastName) {
        responseObject = await GetPostPerson("new", personRequest);
        // UpdateDatabaseConnection(responseObject.databaseConnection)
    }
};