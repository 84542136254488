

var columnGroupingModel = 
  [
    {
      groupId: 'ADMINISTRATION',
      children: [{field: 'invoice'},{field: 'registration'},{ field: 'databaseId'},{ field: 'id'}, {field: 'invoiceNumber'}]
    },
    {
      groupId: 'TIMES',
      children: [{ field: 'dateOfFlight' }, 
                  {field: 'offBlocks'}, 
                  {field: 'onBlocks'}, 
                  {field: 'blockDifference'}, 
                  {field: 'takeOff'}, 
                  {field: 'landing'}, 
                  {field: 'flightTime'}, 
                  {field: 'svcOff'}, 
                  {field: 'svcOn'}, 
                  {field: 'svcDifference'},
                  {field: 'instrumentTime'},
                  {field: 'nightTime'}]
    },
    {
      groupId: 'PILOTS & PASSENGERS',
      children: [{ field: 'pilot' }, {field: 'passenger1'}, {field: 'passenger2'}]
    },
    {
      groupId: 'ROUTE DETAILS',
      children: [{ field: 'fromAirfield' }, 
                  {field: 'toAirfield'}, 
                  {field: 'flightDetails'}, 
                  {field: 'homeLandings'},
                  {field: 'awayLandings'},
                  {field: 'nightLandings'}]
    },
    {
      groupId: 'DEPARTURE FUEL',
      children: [{ field: 'departureFuelUpliftLeft' }, 
                  {field: 'departureFuelUpliftRight' }, 
                  {field: 'departureFuelLeft'}, 
                  {field: 'departureFuelRight'}]
    },
    {
      groupId: 'ARRIVAL FUEL',
      children: [{field: 'arrivalFuelUpliftLeft'}, 
                  {field: 'arrivalFuelLeft'}, 
                  {field: 'arrivalFuelUpliftRight'}, 
                  {field: 'arrivalFuelRight'}, 
                  {field: 'fuelUsed'}]
    },
    {
      groupId: 'OIL',
    //   // children: {oilGroup}
      children: [{ field: 'oilUpliftLeft' }, {field: 'oilStatusLeft'}, {field: 'oilUpliftRight'}, {field: 'oilStatusRight'}]
    },
    {
      groupId: 'DEFECTS',
      children: [{ field: 'newDefect1' }, {field: 'newDefect2'}]
    },
    {
      groupId: 'AWAY LANDINGS',
      children: [{ field: 'awayLandingLocation1' }, 
                  {field: 'awayLandingQuantity1'}, 
                  {field: 'awayLandingDetails1'}, 
                  {field: 'awayLandingPaid1'}, 
                  {field: 'awayLandingLocation2'}, 
                  {field: 'awayLandingQuantity2'}, 
                  {field: 'awayLandingDetails2'}, 
                  {field: 'awayLandingPaid2'}, 
                  {field: 'awayLandingLocation3' }, 
                  {field: 'awayLandingQuantity3'}, 
                  {field: 'awayLandingDetails3'}, 
                  {field: 'awayLandingPaid3'}, 
                  {field: 'awayLandingLocation4'}, 
                  {field: 'awayLandingQuantity4'}, 
                  {field: 'awayLandingDetails4'}, 
                  {field: 'awayLandingPaid4'}, 
                  {field: 'awayLandingLocation5'}, 
                  {field: 'awayLandingQuantity5'}, 
                  {field: 'awayLandingDetails5'}, 
                  {field: 'awayLandingPaid5'}]
    }
    ]
  
    export {
      columnGroupingModel
    };


 