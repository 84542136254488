//Import libraries
import { Button, IconButton, Tooltip } from "@mui/material";
import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";
import { useAuthFirebase } from '../utilities/security/AuthContextFirebase.js';
import { HandleNewUser } from "../utilities/security/HandleNewUser.js";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import azTechTheme from "../utilities/environment/ColourTheme.js";


// import { isAdminRole, isGuestRole, isInstructorRole, isPilotRole, isSchoolAdminRole } from "../utilities/database/RoleNames.js";
// import { getIdTokenResult } from "firebase/auth";

// Import fonts


//**************************** MAIN FUNCTION ***************************** */
export default function LoginMenu(props) {

    const avatarColor = azTechTheme.palette.primary.main;
    // let UpdateDatabaseConnection = props.UpdateDatabaseConnection();
    const {authUser, signInWithGoogle, signOut} = useAuthFirebase();
    // props.UpdateUser(authUser.displayName)
    let currentUser = ""
    authUser ? currentUser  = authUser.displayName : currentUser = ""

    var personRequest = {
        id: null,
        name:"",
        firstName: "",
        lastName: "",
        email: "",
        acknowledged: false,
        registeredDate: ""
    } 
            // console.log("In LoginMenu, authUser(I)->", authUser);
            // console.log("In LoginMenu, currentUser(I)->", currentUser);
            // console.log("In LoginMenu, personRequest(I)->", personRequest);

    // ***************** SECURITY - CURRENT UER CONTEXT AND ROLE **********************

    function BuildPersonRequest(personRequest, currentUser) {
        if (currentUser) {
            if (currentUser.indexOf(" ") !== -1) {
                personRequest.firstName = currentUser.slice(0, currentUser.indexOf(" "))
                personRequest.lastName = currentUser.slice(currentUser.indexOf(" ")+1)
            } 
        }
        authUser ? personRequest.email = authUser.email : personRequest.email = "" 
        authUser ? personRequest.name = authUser.displayName : personRequest.name = "" 
            // console.log("In LoginMenu, BuildPersonRequest, authUser(III)->", authUser);
            // console.log("In LoginMenu, BuildPersonRequest, currentUser(III)->", currentUser);
            // console.log("In LoginMenu, BuildPersonRequest, personRequest(III)->", personRequest);
        return personRequest;
    }

    // personRequest = BuildPersonRequest(personRequest, currentUser);

    const navigate = useNavigate();
    const handleNavigate = (path) => {
      navigate(path);
    }

    const HandleClick = async () => {
        props.UpdateProgressStatus(true);
        if (currentUser) {
            await signOut();
            await props.ResetUser()
            handleNavigate("/");
    
        } else {
            await signInWithGoogle();
           
                // Add Progress Feedback tool
            authUser ? currentUser  = authUser.displayName : currentUser = ""
        }    
        props.UpdateProgressStatus(false);
    }
    
  

    useEffect(() => {
        authUser ? currentUser  = authUser.displayName : currentUser = ""

            // console.log("In LoginMenu, useEffect, authUser(III)->", authUser);
        if (currentUser) {

            personRequest = BuildPersonRequest(personRequest, currentUser);
                // console.log("In LoginMenu, useEffect, currentUser(II)->", currentUser);
                // console.log("In LoginMenu, useEffect, personRequest(II)->", personRequest);
            HandleNewUser(personRequest);
        }
        
        // if (personRequest.email) {
        //         console.log("In LoginMenu, HandleNewUser, personRequest->", personRequest) 
        //     const newUser = async (personRequest) => await HandleNewUser(personRequest);
              
        //     newUser(personRequest);
        // }
        // console.log("In LoginMenu, useEffect, authUser(IV)->", authUser);

       
    },[authUser])

    var iconSxSettings = {};
    if (props.calledFrom  === "MenuMobile") {
        iconSxSettings = '15px'
    } else {
        iconSxSettings = '5px'
    }

    function StringAvatar(name) {
        if (name.indexOf(" ") === -1) {
            name = name + " _";
        }
        return {

                children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
            
        };
      }  

    if (currentUser) {
        // setInitials(StringAvatar(props.currentUser))
        return (
            <Tooltip title = {`${authUser.displayName}... Sign Out?`}>
                <IconButton onClick = {HandleClick}>
                    <Avatar 
                        {...StringAvatar(authUser.displayName)}
                        sx = {{ width: 30, height: 30, bgcolor: avatarColor, mt: iconSxSettings, mt: "17px", mb: "17px", fontSize: "small" }}/>
                </IconButton>
            </Tooltip>
        )
    } else {
        if (props.calledFrom === "MenuMobile") {
            return (
                <Tooltip title = {"Login"}>
                    <IconButton onClick = {HandleClick}>
                        <AccountCircleIcon 
                        id = "account" 
                        sx={{mt: '17px', mb: "17px"}}
                        color="inherit"/>
                    </IconButton>
                </Tooltip>
              )
        } else {
            return (
                <Button sx = {{mt: "7px"}} color="inherit" onClick = {HandleClick} >Login</Button>
            );
        }
    }
};