// Import libraries
import React, {useState, useEffect, useReducer} from 'react';
import { GetCourseData } from '../../utilities/database/GetCourseData.js';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import {Box} from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import AdminCourseBanner from './AdminCourseBanner.js';
import AdminCourseTable from './AdminCourseTable.js';
import { CourseFormReducer } from '../../dispatchersAndReducers/CourseFormReducer.js';
import Feedback from '../../utilities/feedback/Feedback.js';
import FeedbackWithInput from '../../utilities/feedback/FeedbackWithInput.js';
import Validate from '../../utilities/validation/Validate.js';
import { AdminCourseApprovals } from './AdminCourseApprovals.js';
import { PostCourse } from '../../utilities/database/PostCourse.js';
import AdminCourseLessons from './AdminCourseLessons.js';
import AdminCourseNewLesson from './AdminCourseNewLesson.js';
import AddApprovalFields from '../../utilities/feedback/AddAppropvalFields.js';
import { CreateAuthoritiesList } from '../../utilities/database/CreateAuthoritiesList.js';
import { PostApproval } from '../../utilities/database/PostApproval.js';
import { useMediaQuery } from "@mui/material";
import { DatabaseConnectionStatus } from '../../utilities/database/DatabaseConnectionStatus.js';
import { PostCourseStatus } from '../../utilities/database/PostCourseStatus.js';
import { DeleteApproval } from '../../utilities/database/DeleteApproval.js';
import CourseManagementButtons from './CourseManagementButtons.js';
import _ from "lodash";



// Import fonts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// Import CSS, images, icons & static data
// import '../../flights/techLog/TechLog.css';
import './AdminCourse.css'
import { courseValidationMessages } from '../../utilities/validation/CourseValidationMessages.js';
import { databaseConnectionMessages } from '../../utilities/database/DatabaseConnectionMessages.js';
import { emptyCourseSubmission, newCourse} from './CourseData.js';
import { mobileThreshold } from "../../utilities/environment/EnvironmentData.js";
import lessonsIcon from "../../courses/icons/lessonsIcon.png"



// import {emptyACourseSubmission} from "../../aircraft/AircraftData.js";




// ******************************* MAIN FUNCTION ************************************************
function AdminCourseMain(props) {
    // Initialise context using default variables
    const title = "Course Admin.";
    const courseSelectionKey = 0
    const calledFrom = "Admin"
    const [courseList, setCourseList] = useState([newCourse]);
    var media = useMediaQuery(`(min-width:${mobileThreshold}px)`);
    const [adminCourseFrameClass, setAdminCourseFrameClass] = useState("admin-course-frame")
    const [adminCourseLessonsClass, setAdminCourseLessonsClass] = useState("admin-course-lessons-container")
    const [courseIconClass, setCourseIconClass] = useState("course-icon-box");
    
    let courses = [newCourse];
    let lessonsURL = lessonsIcon;
    // let databaseConnectionStatus = {
    //     overallStatus: true,
    // }

    const newAuthorityList =[{
        id: 0,
        authority: "NEW AUTHORITY",
        isoCountryCode: null,
        country: ""
    }]

    // *********************** SET MEDIA VARIABLES *****************************************
    useEffect (() => {
        if (media) {
            setAdminCourseFrameClass("admin-course-frame");
            setAdminCourseLessonsClass("admin-course-lessons-container");
            setCourseIconClass("course-icon-box");
          } else {
            setAdminCourseFrameClass("admin-course-frame-mobile");
            setAdminCourseLessonsClass("admin-course-lessons-container-mobile");
            setCourseIconClass("course-icon-box-mobile");
          } 
        }, [media]);
    
  
    // *********************** INITIALISE STATE VARIABLES ********************************** 
    
    const [selectedCourse, setSelectedCourse] = useState(newCourse);
    const [selectedCourseName, setSelectedCourseName] = useState(newCourse.name);
    const [databaseConnection, setDatabaseConnection] = useState(true);
    const [databaseConnectionStatus, setDatabaseConnectionStatus] = useState({overallStatus: true,}); 
    const [courseSubmission, dispatch] = useReducer(CourseFormReducer, emptyCourseSubmission);
    const [editing, setEditing] = useState(false);
    const [editingSwitch, setEditingSwitch] = useState(false);
    const [newAuthority, setNewAuthority] = useState();
    const [newApprovalDate, setNewApprovalDate] = useState();
    const [isEdited, setIsEdited] = useState(false);
    const [newLessonSaveDisabled, setNewLessonSaveDisabled] = useState(true);

    
    // Validations & SnackBar
    const [result, setResult] = useState({status: "", message: "" })
    const [snackBarStatus, setSnackBarStatus] = useState(false);
    const [dialogueStatus, setDialogueStatus] = useState(false);
    const [dialogueResponse, setDialogueResponse] = useState("dialogueCancel"); 
    const [dialogueStatusApproval, setDialogueStatusApproval] = useState(false);

    // Initialise the most severe issue container 
    let mostSevereIssue = {
        status: "success",
        message: courseValidationMessages.successMessages.update,
        field1: "",
        field2: "",
        field3: "",
        field4: ""
    };
        
    dayjs.extend(utc);

        // console.log("In AdminCourseMain, approvingAuthoritiesList->", approvingAuthorities);

    // ********************* Snackbar & Dialogue Update functions ***************************
    function UpdateSnackBarStatus (newStatus) {
            // console.log("In TechLog, UpdateSnackBarStatus, newStatus->", newStatus);
        setSnackBarStatus(newStatus);
            // console.log("In TechLog, UpdateSnackBarStatus, snackBarStatus->", snackBarStatus);
    }
    function UpdateDialogueStatus (newStatus) {
            // console.log("In TechLog, UpdateDialogueStatus, newStatus->", newStatus);
        setDialogueStatus(newStatus);
            // console.log("In TechLog, UpdateDialogueStatus, DialogueStatus->", DialogueStatus);
    }
    function UpdateDialogueStatusApproval (newStatus) {
        // console.log("In TechLog, UpdateDialogueStatus, newStatus->", newStatus);
    setDialogueStatusApproval(newStatus);
        // console.log("In TechLog, UpdateDialogueStatus, DialogueStatus->", DialogueStatus);
    }
    function HandleAuthorityChange (response) {
        // console.log("In AdminCourseMain, HandleAuthorityChange, response->", response);
    setNewAuthority(response);
        // console.log("In AdminCourseMain, HandleAuthorityChange, newAuthority->", newAuthority);
    }
    function HandleApprovalDateChange (response) {
        // console.log("In AdminCourseMain, HandleApprovalDateChange, response->", response);
    setNewApprovalDate(response);
        // console.log("In AdminCourseMain, HandleApprovalDateChange, newApprovalDate->", newApprovalDate);
    }
    function UpdateDialogueResponse (response) {
            // console.log("In TechLog, UpdateDialogueResponse, response->", response);
        setDialogueResponse(response);
            // console.log("In TechLog, UpdateDialogueResponse, DialogueStatus->", dialogueResponse);
    }
    function UpdateDatabaseConnection (response) {
        // console.log("In TechLog, UpdateDialogueResponse, response->", response);
    setDatabaseConnection(response);
        // console.log("In TechLog, UpdateDialogueResponse, DialogueStatus->", dialogueResponse);
    }
    // function UpdateDatabaseConnectionStatus (response) {
    //     // console.log("In TechLog, UpdateDialogueResponse, response->", response);
    // setDatabaseConnectionStatus(response);
    //     // console.log("In TechLog, UpdateDialogueResponse, DialogueStatus->", dialogueResponse);
    // }


    // Update Validation result functions
    function UpdateResult (newResult) {
        // console.log("In TechLog, UpdateResult, newResult -> ", newResult)
        if (newResult !== undefined) {
            setResult(newResult);
        }
            // console.log("In TechLog, UpdateResult, result -> ", result)
        }

    // ********************** ENABLE & DISABLE BUTTONS AND FIELDS ******************************

    function UpdateEditing(isChecked) {
            // console.log("In AdminAircraftMain, UpdateEditing, isChecked->", isChecked);
        setEditing(isChecked);
    }

    function UpdateEditingSwitch(isChecked) {
        // console.log("In AdminAircraftMain, UpdateEditing, isChecked->", isChecked);
    setEditingSwitch(isChecked);
}

    function UpdateIsEdited(value) {
        // console.log("In AdminAircraftMain, UpdateIsEdited, isEdited->", isEdited);
        setIsEdited(value);
    }

    function UpdateNewLessonSaveDisabled(value) {
        // console.log("In AdminAircraftMain, UpdateIsEdited, isEdited->", isEdited);
        setNewLessonSaveDisabled(value);
    }
    

    //************************* ADD APPROVALS ******************************
    async function AddApproval() {

        let approvingAuthoritiesList = await CreateAuthoritiesList();
            // console.log("In AdminCourseMain, AddApproval, approvingAuthoritiesList->", approvingAuthoritiesList);
        // Construct the authority and data selection fields (incl. the authority list from the database)
        let approvingAuthoritiesDisplayList = AddApprovalFields(approvingAuthoritiesList, HandleAuthorityChange, HandleApprovalDateChange)
        // Call the Dialogue With Input using the pre-built fields

        let addApprovalResult = 
        {
            status: "approval",
            message: approvingAuthoritiesDisplayList
        }
          
        UpdateResult(addApprovalResult);
        UpdateDialogueStatusApproval(true);
    }

    // *********************** HANDLE DIALOGUE BOX 'CONTINUE' APPROVAL *****************************   
    const HandleContinue = async () => {
        // Attempt to write the Approval to the database

        var responseObject = {};
        responseObject = await PostCourseStatus(courseSubmission.id.value, "DELETED") 

        if (!responseObject.databaseConnection) { 
            UpdateDatabaseConnection(DatabaseConnectionStatus(databaseConnectionStatus, "deleteCourse", responseObject.databaseConnection))
                // console.log("In CourseAdmin databaseConnectionContext->", databaseConnectionStatus); 
            // UpdateDatabaseConnection(responseObject.databaseConnection);
            mostSevereIssue = {
                status: "error",
                message: databaseConnectionMessages.error,
                field1: ""
            } 
        } else  {        
            mostSevereIssue = {
                status: "success",
                message: courseValidationMessages.successMessages.delete,
                field1: ""
            } 

            HandleReset();
            UpdateEditingSwitch(false);
            UpdateEditing(false);
            UpdateEditingSwitch(false);
            UpdateIsEdited(false);
            GetCourses();
            UpdateSelectedCourse("reset");
            UpdateResult(mostSevereIssue)
            FeedbackTrigger(mostSevereIssue);
        }
    }

    // *********************** HANDLE DIALOGUE BOX 'CONTINUE' APPROVAL *****************************   
    const HandleContinueApproval = async () => {
        // Attempt to write the Approval to the database
            // console.log("In HandleContinueApproval, newAuthority->", newAuthority);
            // console.log("In HandleContinueApproval, courseSubmission->", courseSubmission);
            // console.log("In AdminCourseMain, AddApproval, newApprovalDate->", newApprovalDate);
        let newApproval = {
            id: null,
            courseId: courseSubmission.id.value,
            authorityId: newAuthority.id,
            // authority: {
            //     id: newAuthority.slice(0,newAuthority.indexOf(":")),
            //     name: newAuthority.slice(newAuthority.indexOf(":") + 1, newAuthority.indexOf("-")),
            //     country: newAuthority.slice(newAuthority.indexOf("-")+1),
            // },
            approvalDate: dayjs(new Date(newApprovalDate)),
            expiryDate: ""
        }
            // console.log("In HandleContinueApproval, newApproval->", newApproval);
        let responseObject = {}
        responseObject = await PostApproval(newApproval);
            // console.log("In HandleContinueApproval, responseObject->", responseObject);
        

        // If submission successful, clear the current render & reset the validation issues
        if (responseObject.databaseConnection) { 
            HandleAddApproval(responseObject.data);
            UpdateDatabaseConnection(DatabaseConnectionStatus(databaseConnectionStatus, "AddApproval", responseObject.databaseConnection));
            // UpdateDatabaseConnection(responseObject.databaseConnection);
            UpdateDialogueResponse("dialogueCancel");
            mostSevereIssue = {
                status: "success",
                message: courseValidationMessages.successMessages.addApproval,
                field1: "",
                field2: "",
                field3: "",
                field4: ""
            };
                // console.log("In AdminCourseMain, callingGetCourses from AddApproval");
            GetCourses();
            // UpdateSelectedCourse(selectedCourse);
            UpdateResult(mostSevereIssue);
            UpdateSnackBarStatus(true);

        } else {
            UpdateDatabaseConnection(DatabaseConnectionStatus(databaseConnectionStatus, "AddApproval", responseObject.databaseConnection));
                // console.log("In HandleContinueCourse, failed submission")
            mostSevereIssue = {
                status: "error",
                message: databaseConnectionMessages.error
            }
        }
        UpdateResult(mostSevereIssue);
        UpdateSnackBarStatus(true);
    }

    // ****************** GET COURSE DATA & UPDATE BASED ON COURSE SELECTED ***********************

    async function GetCourses() {
        let responseObject = await GetCourseData();
        UpdateDatabaseConnection(DatabaseConnectionStatus(databaseConnectionStatus, "GetCourses", responseObject.databaseConnection))
            // console.log("In AdminCourseMain, databaseConnectionStatus ->", databaseConnectionStatus);
        if (responseObject.databaseConnection) {
            courses = [];
            courses.push(newCourse)
            responseObject.data.forEach((course) => {
                courses.push(course);
            });
        setCourseList(courses);
        } else {
            // UpdateDatabaseConnectionContext(databaseConnectionContext, responseObject.databaseConnection)
            UpdateDatabaseConnection(DatabaseConnectionStatus(databaseConnectionStatus, "GetCourses", responseObject.databaseConnection))
            setCourseList([newCourse]);
        }
            // console.log("In AdminCourseMain, courseList->", courseList);
    }

    useEffect(() => {
        // courseList = [newCourse];
        GetCourses(courseList);
            // console.log("In AdminCourseMain, courseList->", courseList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courseSubmission.approvals]);

    // Change fields
    function HandleCourseChange(e) {
          
        let action = {
            type: "edit",
            name: e.name,
            event: e,
            value: e.value
        }
        
        if (action !== undefined) {
            dispatch(action)
        }
        UpdateIsEdited(true);
    }

    useEffect(() => {
        setSelectedCourse(newCourse)
            // console.log("In AdminCourseMain, selectedCourse->", selectedCourse)
        InitialiseCourseSubmission(newCourse);
    },[] )

    // Update Registration functions for other components
    function UpdateSelectedCourse (selection) {
            // console.log("In UpdatedSelectedCourse, selection->", selection);
            // console.log("In AdminCourseMain, courseList->", courseList);


        if (selection === "reset") {
            selection = courseList[0];
        }
        setSelectedCourse(selection);
        setSelectedCourseName(selection.name);
        InitialiseCourseSubmission(selection);
        UpdateEditing(false);
        UpdateEditingSwitch(false);
            // console.log("In AdminCourseMain, courseSubmission->", courseSubmission);
            // console.log("In UpdatedSelectedCourse, selectedCourse->", selectedCourse);
    }

    // *********************** COURSE DISPATCHERS FOR MANAGING THE SUBMISSION OBJECT ****************************

    function InitialiseCourseSubmission(course) {
        let action = {
            type: "initialise",
            selectedCourse: course
        }
            // console.log("In InitialiseAircraftSubmission, action->", action)
        dispatch(action);
        UpdateIsEdited(false);
            // console.log("In AdminCourseMain, InitialiseCourseSubmission->", courseSubmission);
    }

    function UpdateCourseSubmission(selectedCourse) {
        let action = {
            type: "update",
            selectedCourse: selectedCourse
        }
            // console.log("In InitialiseAircraftSubmission, action->", action)
        dispatch(action);
        // UpdateIsEdited(true);
    }

    function HandleReset() {
        InitialiseCourseSubmission(newCourse);
        UpdateDatabaseConnection(true);
        UpdateIsEdited(false);
            // console.log("In AdminCourseMain, HandleCancel->", courseSubmission);
    }

    function HandleLessonChange(e) {
        let action = {
            type: "editLesson",
            name: e.name,
            event: e,
            value: e.value
        }
        
            // console.log("In AdminCourseMain, HandleLessonChange, e->", e, "action->", action)
        if (action !== undefined) {
            dispatch(action)
        }
        UpdateIsEdited(true)
    }

    function RemoveLesson(lessonId) {
        let action = {
            type: "removeLesson",
            value: {
                lessonId: lessonId
            }
        }
            // console.log("In InitialiseAircraftSubmission, action->", action)
        dispatch(action);
        UpdateIsEdited(true);
    }

    function HandleNewLessonChange(e) {
        let action = {
            type: "editNewLesson",
            name: e.name,
            event: e,
            value: e.value
        }
        
            // console.log("In AdminCourseMain, HandleLessonChange, e->", e, "action->", action)
        if (action !== undefined) {
            dispatch(action)
        }
        if (courseSubmission.newLesson.section.value === 0 || (typeof courseSubmission.newLesson.section.value !== "number")) {
            UpdateNewLessonSaveDisabled(true);
        }
        if (courseSubmission.newLesson.section.value > 0 && (typeof courseSubmission.newLesson.section.value !== "number")) {
            UpdateNewLessonSaveDisabled(false);
        }
    }

    function HandleSaveNewLesson(e) {
        UpdateIsEdited(true);
        let action = {
            type: "saveNewLesson",
            value: e
        }
        
            // console.log("In AdminCourseMain, HandleLessonChange, e->", e, "action->", action)
        if (action !== undefined) {
            dispatch(action)
        }
            // console.log("In AdminCourseMain, courseSubmission->", courseSubmission);
            // console.log("In AdminCourseMain, courseSubmission->", isEdited);
        UpdateIsEdited(true);
        HandleClearNewLesson();
        // UpdateSelectedCourse();
        // Add user feedback to make it obvious the new lesson as been added (without being saved to the database)
    }

    function MarkValidationErrors(validationResultList) {
        let action = {
            type: "markValidationErrors",
            value: validationResultList
        }
        
            // console.log("In AdminCourseMain, HandleLessonChange, e->", e, "action->", action)
        if (action !== undefined) {
            dispatch(action)
        }
        // UpdateIsEdited(false);

        // Add user feedback to make it obvious the new lesson as been added (without being saved to the database)
    }

    function ResetValidationErrors() {
        let action = {
            type: "resetValidationErrors",
        }
        
            // console.log("In AdminCourseMain, HandleLessonChange, e->", e, "action->", action)
        if (action !== undefined) {
            dispatch(action)
        }
        // UpdateIsEdited(false);

        // Add user feedback to make it obvious the new lesson as been added (without being saved to the database)
    }

    function HandleClearNewLesson() {
        let action = {
            type: "clearNewLesson",
        }
        
            // console.log("In AdminCourseMain, HandleLessonChange, e->", e, "action->", action)
        if (action !== undefined) {
            dispatch(action)
        }
        // UpdateIsEdited(false);

        // Add user feedback to make it obvious the new lesson as been added (without being saved to the database)
    }

    function HandleAddApproval(approval) {
        // let authority = newAuthority.slice(0,newAuthority.indexOf("-"));
        // let country = newAuthority.slice(newAuthority.indexOf("-")+1);
            // console.log("In AdminCourseMain, HandleAddApproval, approval->", approval)
        let action = {
            type: "addApproval",
            value: approval
        }
        
            // console.log("In AdminCourseMain, HandleAddApproval, action->", action)
        if (action !== undefined) {
            dispatch(action)
        }

    }

    async function HandleDeleteApproval(e) {
            // console.log("In HandleDeleteApproval, e.target.id->", e.target.id)
            // console.log("In HandleDeleteApproval, courseSubmission->", courseSubmission);
        let courseId = courseSubmission.id.value;

        let approval = courseSubmission.approvals[Number(e.target.id)]
        approval = {...approval, courseId}
            // console.log("In HandleDeleteApproval, approval->", approval);    
        

        let responseObject = await DeleteApproval(approval)
        if (responseObject.databaseConnection) {
                // console.log("In HandleDeleteApproval, responseObject.data->", responseObject.data);
            let action = {
                type: "deleteApproval",
                value: responseObject.data,
            }
            
            if (action !== undefined) {
                dispatch(action)
            }
            // UpdateDatabaseConnection(true)
            UpdateDatabaseConnection(DatabaseConnectionStatus(databaseConnectionStatus, "DeleteApproval", responseObject.databaseConnection))
            mostSevereIssue = {
                status: "success",
                message: courseValidationMessages.successMessages.removeApproval
            }
                // console.log("In AdminCourseMain, callingGetCourses from DeleteApproval");
            // GetCourses();
            // let currentCourse = selectedCourse
            // UpdateSelectedCourse(newCourse);
            // UpdateSelectedCourse(currentCourse);
            UpdateResult(mostSevereIssue);
            UpdateSnackBarStatus(true);
            // UpdateSnackBarStatus(responseObject.databaseConnection)
        } else {
            UpdateDatabaseConnection(DatabaseConnectionStatus(databaseConnectionStatus, "DeleteApproval", responseObject.databaseConnection))
            // UpdateDatabaseConnection(responseObject.databaseConnection);
            mostSevereIssue = {
                status: "error",
                message: databaseConnectionMessages.error
            }
            UpdateResult(mostSevereIssue);
            UpdateSnackBarStatus(true);

        }
    }

    function UpdateStatus(status) {
        let action = {
            type: "updateStatus",
            value: status,
        }
        
            // console.log("In AdminCourseMain, HandleApprovalChange, e->", e, "action->", action)
        if (action !== undefined) {
            dispatch(action)
        }
    }


    // *********************** VALIDATE & SUBMIT COURSE **************************************

    // Handle course Submit

    const HandleDeleteCourse = async () => {
        mostSevereIssue = {
            status: "warning",
            message: courseValidationMessages.warningMessages.delete,
            field1: ""
        } 
        FeedbackTrigger(mostSevereIssue);
    }

    const CourseSubmit = async () => {

        let type = "updateCourse"
        if (selectedCourse.id === null) {
            type = "createCourse"
        }
            // console.log("In AdminCourseMain, type->", type);
        // Validate the aircraft submission object
        let validationResultList = Validate(courseSubmission, selectedCourse, type);
            // console.log("In AdminAircraftMain, post validation, validationResultList->", validationResultList);

           
        // Parse the validation results and identify the most severe issue
        if (validationResultList) {
            validationResultList.forEach((result) => {
                switch (mostSevereIssue.status) {
                    case "success":
                        if (result.status !== "success") {
                            mostSevereIssue = result;
                        }
                    break;
                    case "warning":
                        if (result.status !== "error") {
                            mostSevereIssue = result;
                        }
                    break;
                    default:
                    break;
                }
            });
        }
            // console.log("In AdminCourseMain, mostSevereIssue->", mostSevereIssue);

        // ***************** If aircraft has no errors, submit it to the database *****************
        if (mostSevereIssue.status === "success") {  
            ResetValidationErrors();
                
            var responseObject = {};
            responseObject = await PostCourse(courseSubmission)
                // console.log("In AdminCourseMain, responseObject->", responseObject);
            if (!responseObject.databaseConnection) { 
                       
                UpdateDatabaseConnection(DatabaseConnectionStatus(databaseConnectionStatus, "saveCourse", responseObject.databaseConnection))
                // console.log("In CourseAdmin databaseConnectionContext->", databaseConnectionStatus); 
                // UpdateDatabaseConnection(responseObject.databaseConnection);
                mostSevereIssue = {
                    status: "error",
                    message: databaseConnectionMessages.error,
                    field1: ""
                } 
            } else {
                UpdateDatabaseConnection(DatabaseConnectionStatus(databaseConnectionStatus, "saveCourse", responseObject.databaseConnection))
                    // console.log("In CourseAdmin databaseConnectionContext->", databaseConnectionStatus); 
                UpdateEditingSwitch(false);
                UpdateEditing(false);
                UpdateEditingSwitch(false);
                UpdateIsEdited(false);
                GetCourses();
                UpdateSelectedCourse("reset");
            }

        } 

        FeedbackTrigger(mostSevereIssue, validationResultList);
    }

    // ********************** FEEDBACK based on most severe issue and update fields ************************ 
    function FeedbackTrigger(mostSevereIssue, validationResultList) {
        // Feedback based on most severe issue and update form accordingly
        if (mostSevereIssue !== undefined) {
                // console.log("In TechLogHandleOpen, post dispatch, submission !== undefined -> ", submission)
            UpdateResult(mostSevereIssue);
                // console.log("In TechLogHandleOpen, result -> ", result);
            switch(mostSevereIssue.status) {
                case "success":
                    if (validationResultList) {
                        ResetValidationErrors();
                    }
                    // UpdateResult(mostSevereIssue);
                    UpdateSnackBarStatus(true);
                break;
    
                case "error":
                    if (mostSevereIssue.message === databaseConnectionMessages.error) {
                        UpdateDatabaseConnection(false);
                    } else {
                        MarkValidationErrors(validationResultList);
                        // UpdateResult(mostSevereIssue);
                    }
                    UpdateSnackBarStatus(true);
                    
                break;
    
                case "warning":
                    if (validationResultList) {
                        MarkValidationErrors(validationResultList);
                    }
                    // UpdateResult(mostSevereIssue)
                    UpdateDialogueStatus(true);
                break;
    
                default: console.log("Unknown validation result")
            }
        }
    }


    // *********************** COURSE ADMIN RENDER ****************************
        // console.log("In AdminAircraftMain, aircraftSubmission->", aircraftSubmission)
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            
            <meta name="viewport" content="initial-scale=1, width=device-width" />
            
            <Box>
                <AdminCourseBanner 
                    UpdateSnackBarStatus = {UpdateSnackBarStatus} 
                    UpdateEditing = {UpdateEditing}
                    UpdateEditingSwitch = {UpdateEditingSwitch}
                    editingSwitch = {editingSwitch}
                    isEdited = {isEdited}
                    UpdateIsEdited = {UpdateIsEdited}
                    snackBarStatus = {snackBarStatus} 
                    UpdateResult = {UpdateResult}
                    HandleReset = {HandleReset}
                    CourseSubmit = {CourseSubmit}
                    className = "banner" title = {title} 
                    submission = {courseSubmission} 
                    databaseConnection = {databaseConnection} 
                    courseList={courseList} 
                    selectedCourse={selectedCourse} 
                    selectedCourseName={selectedCourseName}
                    UpdateSelectedCourse={UpdateSelectedCourse}/> 
            </Box>
            < Box className = {adminCourseFrameClass}>
                <AdminCourseTable 
                    editing = {editing}
                    selectedCourse = {selectedCourseName} 
                    courseSubmission = {courseSubmission}
                    HandleCourseChange = {HandleCourseChange}
                    />
                <CourseManagementButtons
                    HandleDeleteCourse = {HandleDeleteCourse}
                    courseSubmission = {courseSubmission}
                    editing = {editing}
                    />
                <AdminCourseApprovals 
                    editing = {editing}
                    selectedCourse = {selectedCourseName} 
                    courseSubmission = {courseSubmission}
                    HandleDeleteCourse = {HandleDeleteCourse}
                    UpdateDatabaseConnection = {UpdateDatabaseConnection}
                    UpdateStatus = {UpdateStatus}
                    HandleDeleteApproval = {HandleDeleteApproval}
                    HandleAddApproval = {HandleAddApproval}
                    AddApproval = {AddApproval}
                    HandleReset = {HandleReset}
                    UpdateEditingSwitch = {UpdateEditingSwitch}
                    UpdateEditing = {UpdateEditing}
                    UpdateSelectedCourse = {UpdateSelectedCourse}
                    // UpdatedEditing = {UpdateEditingSwitch}
                    UpdateIsEdited = {UpdateIsEdited}
                    />

                <Box className = {adminCourseLessonsClass}>
                    <Box sx = {{display: "flex", flexDirection: "row", justifyItems: "spaceBetween"}} className = "admin-course-subtitles">
                        <Box >
                            Lessons
                        </Box>
                        <Box >   
                                <img className = {courseIconClass} src = {`${lessonsURL}`}/>
                        </Box>
                    </Box>
                        <AdminCourseNewLesson 
                            HandleNewLessonChange = {HandleNewLessonChange}
                            HandleSaveNewLesson = {HandleSaveNewLesson}
                            HandleClearNewLesson = {HandleClearNewLesson}
                            newLessonSaveDisabled = {newLessonSaveDisabled}
                            UpdateNewLessonSaveDisabled = {UpdateNewLessonSaveDisabled}
                            courseSubmission = {courseSubmission}
                            // RemoveLesson = {RemoveLesson}
                            editing = {editing}
                            UpdateIsEdited = {UpdateIsEdited}
                        />
                        <AdminCourseLessons 
                            HandleLessonChange = {HandleLessonChange}
                            courseSubmission = {courseSubmission}
                            RemoveLesson = {RemoveLesson}
                            editing = {editing}
                        />
                </Box>
            </Box>

            <Box className = "feedback" sx={{zIndex: "6", position: "sticky", left: "0px", bottom: "80px", margin: "80px"}}>
                <FeedbackWithInput
                    HandleContinueApproval = {HandleContinueApproval}
                    dialogueStatusApproval = {dialogueStatusApproval}
                    newAuthority = {newAuthority}
                    newApprovalDate = {newApprovalDate}
                    UpdateDialogueStatusApproval = {UpdateDialogueStatusApproval}
                    result = {result}
                />
                <Feedback  
                        HandleContinue = {HandleContinue} 
                        dialogueStatus = {dialogueStatus} 
                        UpdateDialogueStatus = {UpdateDialogueStatus} 
                        snackBarStatus = {snackBarStatus} 
                        UpdateSnackBarStatus = {UpdateSnackBarStatus} 
                        result = {result}/> 
            </Box>
        </LocalizationProvider>
        
    );
};

export default AdminCourseMain;
