// Import libraries
import {Box} from "@mui/material";
import Oil from "../oil/Oil.js";
import OilLeftRight from "../oil/OilLeftRight.js";
import OilUplift from "../oil/OilUplift.js";

// Import CSS, 
import "./Departure.css";
import oilCan from "../images/oilCan.png";

// ************************* MAIN FUNCTION ****************************
export default function TableOil(props) {

    let excludedForAircraftType = ["JET", "SIM"];
    // if (props.selectedAircraft._planeType.indexOf()) 

        // console.log("In TableOil, props.selectedAircraft->",props.selectedAircraft);
    if (excludedForAircraftType.indexOf(props.selectedAircraft._planeType) === -1) {

        return (
            <Box className = "table3">
                <table>
                    <tbody>
                        <tr>
                            <td className = "label">Oil</td>
                            <td className = "oilImg"><img src = {oilCan} alt = "Oil Can" className = "oilImg"></img></td>
                            <td className = "blank" colSpan ="1"></td>
                        </tr>
                        <tr>
                            <td className = "LR" colSpan = "1"></td>
                            <td className = "LR" colSpan = "1"><OilLeftRight selectedAircraft={props.selectedAircraft} engine="Left"/></td>
                            <td className = "LR" colSpan = "1"><OilLeftRight selectedAircraft={props.selectedAircraft} engine="Right"/></td>
                        </tr>
                        <tr>
                            <td className = "label" colSpan = "1">Uplift<br></br><span className = "footnote">(ml)</span></td>
                            <td className = "inputNum" colSpan = "1" STYLE = {props.submission.data.oilUpliftLeft.background}><OilUplift submission = {props.submission} TechLogHandleChange={props.TechLogHandleChange} selectedAircraft={props.selectedAircraft} engine="Left"/></td>
                            <td className = "inputNum" colSpan = "1" STYLE = {props.submission.data.oilUpliftRight.background}><OilUplift submission = {props.submission} TechLogHandleChange={props.TechLogHandleChange} selectedAircraft={props.selectedAircraft} engine="Right"/></td>
                        </tr>
                        <tr>
                            <td className = "label">Status* <br></br><span className = "footnote">(1/x)</span></td>
                            <td className = "oilCell" STYLE = {props.submission.data.oilStatusLeft.background}><Oil TechLogHandleChange={props.TechLogHandleChange} submission = {props.submission} className = "oil" selectedAircraft={props.selectedAircraft} engine = "Left"/></td>
                            <td className = "oilCellBlank" ><Oil TechLogHandleChange={props.TechLogHandleChange} submission = {props.submission} selectedAircraft = {props.selectedAircraft} engine = "Right"/></td>         
                        </tr>
                    </tbody>
                </table>
        </Box>
        );
    }
}