//Import libraries
import React, {useEffect, useState} from 'react';
import { Select, Option } from "@mui/joy";
import { useMediaQuery, Box } from "@mui/material";
import { useAuthFirebase } from '../../utilities/security/AuthContextFirebase';
import { GetRoles } from '../../utilities/database/GetRoles';
import { GetPilotData } from '../../utilities/database/GetPilotData';
import { GetUserRoles } from '../../utilities/security/AuthUtilities';
import { PersonSelect } from '../../admin/person/PersonSelect';

// Import CSS, images, icons & static data
import azTechTheme from "../../utilities/environment/ColourTheme";
import { mobileThreshold } from "../../utilities/environment/EnvironmentData";
import { rolesTemplate } from '../../utilities/database/GetRolePermissions';




// Import fonts
import "./PilotLog.css";
// import { permissionedRole } from '../../utilities/database/RoleNames';

//**************************** MAIN FUNCTION ***************************** */
const PilotSelect = (props) => {

        // console.log("In PilotSelect, props.selectedPilot->", props.selectedPilot);

    const [pilotList, setPilotList] = useState([])
    var selectionList = [];
    var pilotSelectTopMargin = "80px"

    let media = useMediaQuery(`(min-width:${mobileThreshold}px)`);

    // ***************** SECURITY - CURRENT UER CONTEXT AND ROLE **********************
    const {authUser, loading, signInWithGoogle, signOut} = useAuthFirebase();
    const [currentUser, setCurrentUser] = useState("");
    const [currentUserRoles, setCurrentUserRoles] = useState(rolesTemplate);
    let responseObject = {};
    let calledFrom = "PilotLog";

    useEffect (() => {
        authUser ? setCurrentUser(authUser.displayName) : setCurrentUser("");
    }, [authUser]); 
  
    const UpdateRoles = async () => {
        responseObject = await GetUserRoles();
            // console.log("In PilotSelect, UpdateRoles responseObject", responseObject)
        setCurrentUserRoles(responseObject.data);
        props.UpdateDatabaseConnection(responseObject.databaseConnection)
    }
        

    useEffect(() => {
      if (currentUser !== "") {
        UpdateRoles();
      }
    }, [currentUser])

    const BuildPilotList = async () => {
        responseObject = await GetPilotData();
            // console.log("In PilotSelect, BuildPilotList, responseObject", responseObject)
        let filteredList = responseObject.data.filter(name => name !== "" && name !== null)
            // console.log("In PilotSelect, BuildPilotList, filteredList", filteredList)
        setPilotList(filteredList);
        props.UpdateDatabaseConnection(responseObject.databaseConnection)
            // console.log("In PilotSelect, BuildPilotList, responseObject", responseObject)
    }

    useEffect(() => {
            // console.log("In PilotSelect, useEffect, currentUserRoles->", currentUserRoles);
        if (currentUserRoles.admin.status || currentUserRoles.schoolAdmin.status) {
            BuildPilotList();
        }
    }, [currentUserRoles])
   
    pilotList.forEach((pilot) => {
        selectionList.push(
        <Option
            key = {pilot} 
            id = {pilot} 
            value = {pilot}>
            {pilot}
        </Option>)
        })

    if (currentUserRoles.admin.status || currentUserRoles.schoolAdmin.status) {
                // console.log("In PilotSelect, RENDER, currentUserRoles->", currentUserRoles, "pilotList->", pilotList);
            let personList = []
            pilotList.forEach((pilot) => {
                personList.push(
                    {
                        name: pilot
                    }
                )
            })
        return (
            <Box sx = {{m: "20px", alignItems: "bottom"}}>
                <PersonSelect personList = {personList} calledFrom = {calledFrom} HandleSelect = {props.UpdateSelectedPilot}/>
            </Box>
        );
    }
};

export {PilotSelect}