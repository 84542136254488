//Import libraries

// Import CSS, images, icons & static data

// Import fonts


//**************************** MAIN FUNCTION ***************************** */
function DatabaseConnectionStatus(databaseConnectionStatus, connectionContext, status) {
    // let databaseConnectionStatus = {};
        // console.log("In DatabaseConnectionStatus, databaseConnectionStatus->", databaseConnectionStatus);
        // console.log("In DatabaseConnectionStatus, connectionContext->", connectionContext);
        // console.log("In DatabaseConnectionStatus, status->", status);
    databaseConnectionStatus = {
        ...databaseConnectionStatus,
        [connectionContext]: status
    }
    Object.keys(databaseConnectionStatus).forEach((context) => {
        switch (context) {
            case "overallStatus":
                databaseConnectionStatus[context] = true;
            break;
            default:
                if (!status) {
                    databaseConnectionStatus.overallStatus = false;
                } 
           break;
        }
    });

    return databaseConnectionStatus.overallStatus;

};


export {DatabaseConnectionStatus}
