// Import libraries
import { TextField } from "@mui/material";

// Import CSS, images, icons & static data



// Import fonts


//**************************** MAIN FUNCTION ***************************** */

export function TimePickerEditor(props) {
  const { id, value} = props;



//   function onChange() {
//         console.log("In TimePicker, onChange, ->e.target". e)
    // let action = {
    //   type: "edit",
    //   contents: {
    //     //row: displayRows[e.target.rowIndex],
    //     field: e.target.field,
    //     value: e.target.value
    //   }
    // }
    
// }

  return (
    <TextField
      id={id.toString()}
      type="time"
      value={value}
    //   onChange={() => onChange()}
      inputProps={{
        step: 300, // Optional: adjust as needed to set the time interval
      }}
    />
  );
}