// Import libraries

// Import CSS & images
import '../TechLog.css';


// ******************************* MAIN FUNCTION ************************************************
export default function OilLeftRight(props) {
    if (props.selectedAircraft._numberOfEngines === 2) {
        return (
            <span className = "LR">{props.engine}</span>
        );
    } else {
        return (
            <span className = "blank" colSpan = "1"></span>
        );
    }
}