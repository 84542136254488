// Import libraries
import { GetAuthoritiesList } from "./GetAuthoritiesList";
import { MenuItem} from "@mui/material";
import _ from "lodash";
import { SortObjectList } from "../numbersAndStrings/SortObjectList";

async function CreateAuthoritiesList() {
    // const newAuthorityList =[{
    //     id: 0,
    //     authority: "SELECT",
    //     isoCountryCode: null,
    //     country: ""
    // }]

    var approvingAuthoritiesList = [] //_.cloneDeep(newAuthorityList);

    // let tempList = _.cloneDeep(newAuthorityList);
    let tempList = [];
       
    let responseObject = await GetAuthoritiesList()
    let sortedAuthorityList = [];
        console.log("In CreateAuthoritiesList, responseObject->", responseObject);

    // props.UpdateDatabaseConnection(responseObject.databaseConnection)
    if (responseObject.databaseConnection) {
        responseObject.data.forEach((authority) => {
            tempList.push(authority)
        })
                // console.log("In AddApprovalsFields, tempList->", tempList);
        sortedAuthorityList = SortObjectList(tempList, "authority")
        sortedAuthorityList.forEach((authority) => {
            approvingAuthoritiesList.push(authority);
        })
        // approvingAuthorities = _.cloneDeep(sortedAuthorityList);
    }
        // console.log("In CreateAuthoritiesList, approvingAuthoritiesList->", approvingAuthoritiesList);
    return approvingAuthoritiesList;
}


export {CreateAuthoritiesList}