// Import libraries
import { emptyCourseSubmission } from "../../admin/course/CourseData";
import _ from "lodash";
import { API_URL } from "../../../config/config";
import axiosInstance from "../security/AxiosConfig";



// ************************* DATABASE FUNCTION - UPDATE AIRCRAFT *******************************
const PostCourseStatus = async (courseId, courseStatus) => {

    let responseObject = {};
    let databaseSubmission = {};
    let tempLesson = {};
    let tempApproval = {};

    try {
        const response = await axiosInstance.post(`${API_URL}/courses/update-course-status`, null, {
            params: {
                courseId: courseId,
                courseStatus: courseStatus
            }
        });
            // console.log("In submitAircraft, response.status->", response.status)
        responseObject.databaseConnection = true;
        responseObject.data = response.data;
        return responseObject;
    } catch(error) {
        console.error("Error updating a Course Status", error)
        responseObject.databaseConnection = false;
        responseObject.data = "error";
        return responseObject;
    }
}

export{PostCourseStatus}