// Import libraries
import { useMediaQuery } from "@mui/material";
import {Switch} from "@mui/material";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import { useState, useEffect } from "react";

// Import CSS, images, icons & static
import "./AdminCourse.css"
import { mobileThreshold } from "../../utilities/environment/EnvironmentData";


// ************************* MAIN FUNCTION *******************************
export default function EditSwitchCourse(props) {

    // const blank = "";
    const [switchClass, setSwitchClass] = useState("switch");
    const [direction, setDirection] = useState("row")
    const [justification, setJustification] = useState("flex-end")
    var media = useMediaQuery(`(min-width:${mobileThreshold}px)`);
    const [checked, setChecked] = useState(props.editingSwitch)
    const [buttonLabel, setButtonLabel] = useState("SAVE")
    var editing


    useEffect (() => {
        if (media) {    
            setSwitchClass("switch-box")
            // setDirection("row")
            // setJustification("flex-end")
          } else {
            setSwitchClass("switch-box-mobile")
            // setDirection("row")
            // setJustification("flex-end")
          } 
        }, [media]);

        // console.log("In EditSwitchCourse, useEffect triggered to->", props.editingSwitch);

    useEffect(() => {
        setChecked(props.editingSwitch);
            // console.log("In EditSwitchCourse, useEffect triggered to->", props.editingSwitch);
    }, [props.editingSwitch])

    const HandleCancel = () => {
        props.HandleReset();
        setChecked(false);
        props.UpdateEditing(false);
        props.UpdateSelectedCourse("reset");
        props.UpdateEditingSwitch(false);
        props.UpdateIsEdited(false)
    }
    
    const handleChangeToggle = (e) => {
        // setChecked(e.target.checked);
            // console.log("In EditSwitch, checked->", e.target.checked);
        setChecked(e.target.checked);
        
        props.UpdateEditing(e.target.checked);
        props.UpdateEditingSwitch(e.target.checked)
    }

        // console.log("In EditSwitchCourse, props.isEdited->", props.isEdited);

    return (
        <Box className = {switchClass}>
            <Box>
                EDIT
                <Switch 
                checked = {checked === true}
                onChange = {handleChangeToggle}/> 
            </Box> 
            <Button 
                disabled = {!checked || (props.isEdited === false)}
                size = "medium" 
                variant = "outlined" 
                name = "adminAircraftCancel" 
                sx = {{background: "primary"}}
                className = "course-admin-buttons"
                onClick = {HandleCancel}
                >CANCEL 
            </Button>
            <Button 
                disabled = {!checked || (props.isEdited === false)}
                size = "medium" 
                variant = "contained" 
                name = "adminAircraftSubmit" 
                sx = {{background: "primary"}}
                className = "course-admin-buttons"
                onClick = {props.CourseSubmit}
                >{buttonLabel} 
            </Button>
        </Box>
    );
};