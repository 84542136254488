//Import libraries
import React, {useState} from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';

// Import CSS, mages, icons & static data
import flightCommercial from "../../../../images/flightCommercial.jpg";
import flightIoW from "../../../../images/flightIoW.JPG";
import flightNightLanding from "../../../../images/flightNightRating.jpeg";
import flightMEP2 from "../../../../images/flightMEP2.jpeg"
import flightLAPL from "../../../../images/flightLAPL.jpeg"
import flightIMC from "../../../../images/flightIMC.jpeg"
import flightInstruments from "../../../../images/flightInstruments.jpeg"
import flightSimulator from "../../../../images/flightSimulator.jpeg"
import flightRadioTelephony from "../../../../images/flightRadioTelephony.jpeg"
import { ClockIcon } from '@mui/x-date-pickers';
import { Close, HealthAndSafety } from '@mui/icons-material';
import { Flight } from '@mui/icons-material';
import { courseData } from '../../../../../utilities/database/GetCourseData';
import "./HomePage.css"



// Import CSS, images, icons & static data
const ImageBackdrop = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  background: '#000',
  opacity: 0.5,
  transition: theme.transitions.create('opacity'),
}));

const ImageIconButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  display: 'block',
  padding: 0,
  borderRadius: 0,
  height: '40vh',
  [theme.breakpoints.down('md')]: {
    width: '100% !important',
    height: 100,
  },
  '&:hover': {
    zIndex: 1,
  },
  '&:hover .imageBackdrop': {
    opacity: 0.10,
  },
  '&:hover .imageMarked': {
    opacity: 0,
  },
  '&:hover .imageTitle': {
    border: '1px solid currentColor',
  },
  '& .imageTitle': {
    position: 'relative',
    padding: `${theme.spacing(2)} ${theme.spacing(4)} 14px`,
  },
  '& .imageMarked': {
    height: 3,
    width: 0,
    background: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
  // onclick = {handleClick};
  // onClick={e => handleClick(e.target)}
}));

const images = [
  {
    url: `${flightLAPL}`,
    title: courseData[0].name,
    key: courseData[0].key,
    width: '35%',
  },
  {
    url: `${flightIoW}`,
    title: courseData[1].name,
    key: courseData[1].key,
    width: '37%',
  },
  {
    url: `${flightIMC}`,
    title: courseData[2].name,
    key: courseData[2].key,
    width: '28%',
  },
  {
    url: `${flightNightLanding}`,
    title: courseData[3].name,
    key: courseData[3].key,
    width: '25%',
  },
  {
    url: `${flightMEP2}`,
    title: courseData[4].name,
    key: courseData[4].key,
    width: '45%',
  },
  {
    url: `${flightInstruments}`,
    title: courseData[5].name,
    key: courseData[5].key,
    width: '30%',
  },
  {
    url: `${flightCommercial}`,
    title: courseData[6].name,
    key: courseData[6].key,
    width: '35%',
  },
  {
    url: `${flightSimulator}`,
    title: courseData[7].name,
    key: courseData[7].key,
    width: '40%',
  },
  // {
  //   url: `${flightBriefingRoom}`,
  //   title: 'On-site Briefing Rooms',
  //   key: "on"
  //   width: '20%',
  // },
  {
    url: `${flightRadioTelephony}`,
    title: courseData[8].name,
    key: courseData[8].key,
    width: '25%',
  },
];

export default function ProductCategories() {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [heading, setHeading] = useState("");
  const [selectedCourse, setSelectedCourse] = useState({});
  const [abbreviation, setAbbreviation] = useState("")

  const style = {
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
    position: 'absolute',
    top: '50%',
    left: '50%',
    font: "black",
    transform: 'translate(-50%, -50%)',
    width: 375,
    height: 600,
    bgcolor: 'background.paper',
    // border: '3px solid rgb(7,201,245)',
    // border: '3px solid lightgrey',
    // boxShadow: 24,
    // p: 4,
  };

  function handleClick(e) {
    setHeading(e.target.innerText);
    setOpen(true);
    setSelectedCourse({})
    courseData.forEach((course) => {
        // console.log("In ProductCategories, Modal, course->", course)
        // console.log(course.name, e.target.innerText);
      if (course.name === e.target.innerText) {
        setSelectedCourse(course);
        if (course.abbreviation !== "") {
          setAbbreviation(` - ${course.abbreviation}`)
        } else {
          setAbbreviation("")
        }
      } 
    })
  }

  // console.log("In ProductCategories, selectedCourse->", selectedCourse)
  // console.log("In ProductCategories, heading->", heading)
  // console.log("In ProductCategories, abbreviation->", abbreviation)

  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        >
        <Fade in={open}>
          <Box sx={style}>
            <Box className = "course-info-header">
              <Box sx={{padding: "10px", display: "flex", flexDirection: "row", wrap:"noWrap", justifyContent: "space-between" }}>
                <Box>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    {heading}{abbreviation}
                  </Typography>
                </Box>
                <Box sx = {{alignItems: "right"}}>
                  <Close onClick = {handleClose} sx = {{fontSize: "large"}}/>
                </Box>
              </Box>
            </Box>
            <Box>
              <Typography id="modal-modal-description" sx={{ mt: 2, ml: "15px", mr: "10px" }}>
                {selectedCourse.description}
                <br></br>
                <br></br>
                {selectedCourse.duration}<br></br>
                <br></br>
                <Box sx = {{mb: "10px", verticalAlign: "center"}}><ClockIcon sx={{fontSize: "xx-large", mr: "10px", color: "rgb(0, 200,255)"}}/><br></br>{selectedCourse.hours}</Box>
                
                <Box sx = {{mb: "10px"}}><HealthAndSafety sx={{fontSize: "xx-large", mr: "10px", color: "rgb(0, 200,255)"}}/><br></br>{selectedCourse.medical}</Box>
              
                <Box sx = {{mb: "10px"}}><Flight sx = {{fontSize: "xx-large", mr: "10px", color: "rgb(0, 200,255)"}}/><br></br>{selectedCourse.planeType}</Box>
              </Typography>
            </Box>
          </Box>
        </Fade>
      </Modal>

      <Container component="section" sx={{ mt: 1, mb: 4 }}>
        <Typography variant="h6" marked="center" align="center" component="h2" sx = {{fontWeight: "bold"}}>
          A VARIETY OF COURSES AND FACILITIES TO SUIT YOUR GOALS...
        </Typography>
        <Box sx={{ mt: 6, display: 'flex', flexWrap: 'wrap' }}>
          {images.map((image) => (
            <ImageIconButton
              name = {image}
              key={image.title}
              style={{
                width: image.width,
                minHeight: 150,
              }}
              onClick={e => handleClick(e)}
            >
              <Box
                sx={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center 40%',
                  backgroundImage: `url(${image.url})`,
                }}
              />
              <ImageBackdrop className="imageBackdrop" />
              <Box
                sx={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'common.white',
                }}
              >
                <Typography
                  component="h3"
                  variant="h6"
                  color="inherit"
                  className="imageTitle"
                >
                  {image.title}
                  <div className="imageMarked" />
                </Typography>
              </Box>
            </ImageIconButton>
          ))}
        </Box>
      </Container>
    </Box>
  );
}
