// Import libraries
import ClearButton from "./ClearButton";
import AircraftSelect from "./AircraftSelect.js";
import MakeAndModel from "./MakeAndModel.js";
import MaintenanceTable1 from "./MaintenanceTable1.js";
import MaintenanceTable2 from "./MaintenanceTable2.js";
import MaintenanceTable3 from "./MaintenanceTable3.js";
import {Box} from "@mui/material"
import BannerLogo from "./BannerLogo.js";
import BannerTitle from "./BannerTitle.js"


// Import CSS & images
import "./Banner.css";

//Import fonts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import DaysToNextService from "../../../utilities/maintenance/DaysToNextService";
import { MaintenanceChipsBanner } from "../../../utilities/maintenance/MaintenanceChips.js";



// ******************************* MAIN FUNCTION ************************************************
export default function BannerMaintenanceRow(props) {
    // const calledFrom = "TechLog"
    // Calculate maintenance values
        // console.log("In Banner, props.selectedAircraft -> ", props.selectedAircraft);
    // var daysToNextService = DaysToNextService(props.selectedAircraft);
  
    // Render
    if (props.calledFrom === "TechLog") {
        return (
            <Box className = "bannerRow2">
                {/* <div className = "maintenanceTable1"> <MaintenanceTable1 selectedAircraft= {props.selectedAircraft} daysToNextService = {daysToNextService}/></div>
                <div className = "maintenanceTable2"> <MaintenanceTable2 selectedAircraft= {props.selectedAircraft} daysToNextService = {daysToNextService}/></div> */}
                <div className = "maintenanceTable3"> <MaintenanceTable3 selectedAircraft= {props.selectedAircraft}/></div>
            </Box >
        );
    }
};