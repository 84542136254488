// Import libraries


// Import CSS, images, icons & static data

const mobileThresholdNarrow = "710";
const mobileThreshold = "980";
const mobileThresholdWide = "1300";

const baseBlue = "#0288d1"


export {mobileThresholdNarrow, mobileThreshold, mobileThresholdWide, baseBlue}