// Import libraries
import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Switch from '@mui/material/Switch';
import CourseSelect from "./CourseSelect.js";
import MakeAndModel from "../../flights/techLog/banner/MakeAndModel.js";
// import MaintenanceTable1 from "./MaintenanceTable1.js";
// import MaintenanceTable2 from "./MaintenanceTable2.js";
// import MaintenanceTable3 from "./MaintenanceTable3.js";
import {Box, Button} from "@mui/material"
import BannerLogo from "../../flights/techLog/banner/BannerLogo.js";
import BannerTitle from "../../flights/techLog/banner/BannerTitle.js"
import MuiAlert from '@mui/material/Alert';
import ClearButton from "../../flights/techLog/banner/ClearButton.js";
import EditSwitchCourse from "./EditSwitchCourse.js";
import DatabaseConnectionWarning from "../../utilities/database/DatabaseConnectionWarning.js";
import { useMediaQuery } from "@mui/material";


// Import CSS & images
import "./AdminCourse.css";
import { mobileThreshold } from "../../utilities/environment/EnvironmentData.js";
import azTechTheme from "../../utilities/environment/ColourTheme.js";

//Import fonts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';




// ******************************* MAIN FUNCTION ************************************************
export default function AdminCourseBanner(props) {
    const calledFrom = "courses"
    const [switchClass, setSwitchClass] = useState("switch");
    const [direction, setDirection] = useState("row")
    const [justification, setJustification] = useState("flex-end")
    var media = useMediaQuery(`(min-width:${mobileThreshold}px)`);
    const backgroundColour = azTechTheme.palette.blanchedAlmond;

    const navigate = useNavigate();
    const handleClick = (path) => {
        // console.log("In MenuDeskTop, handleMenuClick, path->", path);
        navigate(path);
    }
        // console.log("In EditSwitchCourse, useEffect triggered to->", props.editingSwitch);
    // console.log("In AdminCourseBanner, props.courseList->", props.courseList);

    useEffect (() => {
        if (media) {
            setSwitchClass("switch")
            setDirection("row")
            setJustification("flex-end")
          } else {
            setSwitchClass("switch")
            setDirection("column")
            setJustification("flex-bottom")
          } 
        }, [media]);

        // console.log("In AdminCourseBanner, props.isEdited->", props.isEdited);

    // Render
    return (
        <Box className = "banner" sx = {{backgroundColor: backgroundColour}}>
            <Box className = "bannerRow1" sx = {{backgroundColor: backgroundColour}}>
                <Box sx = {{display: "flex", flexDirection: "row", selfJustify: "flexStart"}}>
                    <BannerTitle title = {props.title}/>
                    <Box className = "databaseConnectionWarning">
                            <DatabaseConnectionWarning databaseConnection = {props.databaseConnection}/>
                    </Box>  
                </Box>
                <div className = "logo"><BannerLogo/></div>
            </Box>
            <Box className = "banner-row-admin" >
                <Box className = "course-select-admin" > 
                    <CourseSelect 
                        // calledFrom = {calledFrom}
                        databaseConnection = {props.databaseConnection} 
                        selectedCourse = {props.selectedCourse}
                        // courseName = {props.selectedCourse.name} 
                        // newCourse = {props.newCourse}
                        courseList = {props.courseList} 
                        UpdateSelectedCourse={props.UpdateSelectedCourse}/>
                </Box>
                {/* <Box sx = {{color: "red", ml: "10px", mr: "10px", display: "flex", flexDirection: "row", justifyContent: "flex-end", fontSize: "large"}}>
                    UNDER CONSTRUCTION
                </Box> */}
                <Box >
                    <EditSwitchCourse 
                        UpdateEditing = {props.UpdateEditing}
                        UpdateEditingSwitch = {props.UpdateEditingSwitch}
                        editingSwitch = {props.editingSwitch}
                        isEdited = {props.isEdited}
                        UpdateIsEdited = {props.UpdateIsEdited}
                        CourseSubmit = {props.CourseSubmit} 
                        selectedCourse = {props.selectedCourse}
                        UpdateSelectedCourse={props.UpdateSelectedCourse}
                        HandleReset = {props.HandleReset}>
                    </EditSwitchCourse>
                </Box>
            </Box>
        </Box>
    );
};
