// Import libraries
import {Box, TextField, Button} from "@mui/material";
import { AdminCourseStatusChip } from "./AdminCourseStatusChip";
import DeleteCourse from "./CourseManagementButtons";

// Import CSS, images & icons
import "./AdminCourse.css"
import { useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import {useMediaQuery} from '@mui/material';
import CreateApprovalsDisplayList from "./CreateApprovalsDisplayList";
import { mobileThreshold } from "../../utilities/environment/EnvironmentData";
import icao from "../../courses/icons/icao.png"






// ****************************** MAIN FUNCTION **********************************
export function AdminCourseApprovals(props) {
    var editing = props.editing;
    var newApprovalEnabled = (props.editing === true && props.courseSubmission.id.value !== null);
    var icaoURL = icao;
        // console.log("In AdminCourseApprovals, props.courseSubmission.id.value->", props.courseSubmission.id.value);


    var media = useMediaQuery(`(min-width:${mobileThreshold}px)`)
    const [approvalsClassName, setApprovalsClassName] = useState("admin-course-approvals-container")
    const [courseIconClass, setCourseIconClass] = useState("course-icon-box");
    // const [defectListLength, setDefectListLength] = useState(props.selectedAircraft._defects.length)
    const [selectedCourse, setSelectedCourse] = useState(props.selectedCourse);
    const [courseApprovalDisplayList, setCourseApprovalDisplayList] = useState([]);




        // console.log("In AdminCourseApprovals, props.approvingAuthoritiesList->", approvingAuthoritiesList);

        // console.log("In AminAircraftDefects, props.selectedAircraft._defects->", props.selectedAircraft._defects)

    // function AircraftHandleChange(e) {
    //     console.log("In AdminAircraftDefects, AircraftHandleChange, e->", e)

    // }

    useEffect(() => {
        if (media) {
            setApprovalsClassName("admin-course-approvals-container");
            setCourseIconClass("course-icon-box-large-mobile");
        } else {
            setApprovalsClassName("admin-course-approvals-container-mobile");
            setCourseIconClass("course-icon-box-large-mobile");
        }
    },[media]);

        // console.log("In AminAircraftDefects, props.selectedAircraft->", props.selectedAircraft)
        // console.log("In AdminAircraftDefects, props.aircraftSubmission->", props.aircraftSubmission)
    return (
        
        <Box className = {approvalsClassName} disabled = {props.editing === false}>
            <Box>
                <Box className = "admin-course-subtitles">
                    <Box sx = {{mt: "10px"}}>Status</Box>
                    <Box>   
                        <img className = {courseIconClass} src = {`${icaoURL}`}/>
                    </Box>
                </Box>
                <Box className = "admin-course-subtitles">
                    <AdminCourseStatusChip 
                        UpdateDatabaseConnection = {props.UpdateDatabaseConnection}
                        UpdateStatus = {props.UpdateStatus}
                        courseSubmission = {props.courseSubmission}/>
                </Box>
                < Box className = "admin-course-subtitles">
                    <Box>
                    Approvals
                    </Box>

                </Box>
                <Box disabled = {props.editing === false}>
                    <CreateApprovalsDisplayList 
                        editing = {props.editing} 
                        courseSubmission = {props.courseSubmission}
                        HandleDeleteApproval = {props.HandleDeleteApproval} 
                        selectedCourse = {selectedCourse} 
                        from = {"Admin"}/>
                    {/* {courseApprovalDisplayList} */}
                </Box>
                <Box className = "admin-course-approval-button" >
                    <Button 
                    onClick={props.AddApproval}
                    disabled = {newApprovalEnabled === false}>
                        <AddIcon></AddIcon>
                        NEW APPROVAL
                    </Button>
                </Box>
            </Box>

        </Box>
    );

}