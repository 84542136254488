// Import libraries
import {useState} from "react";
import {Box, TextField, Button, Badge, ThemeProvider} from "@mui/material";


// Import CSS, images, icons & static data
import "./AdminCourse.css"
import CreateSectionList from "./CreateSectionList.js";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import azTechTheme from "../../utilities/environment/ColourTheme";
import { blue } from "@mui/material/colors";
import { grey } from "@mui/material/colors";
import { orange } from "@mui/material/colors"
import { background } from "../../flights/flightData/FlightData";
import { useEffect } from "react";


// ****************************** MAIN FUNCTION **********************************
export default function AdminCourseLessons(props) {
   
    let editing = props.editing;
  
        // console.log("In AdminCourseLesson, props.courseSubmission->", props.courseSubmission);

    function HandleDeleteLesson(e) {
            // console.log("In AdminCourseLesson, HandleDeleteLesson, e.target.id.indexOf(-)->", e.target.id.indexOf("-"));
            console.log("In AdminCourseLesson, HandleDeleteLesson, e->", e)
        let lessonId = Number(e.target.id.slice(0, e.target.id.indexOf("-")));
            console.log("In AdminCourseLesson, lessonId->", lessonId);
            // console.log("In AdminCourseLesson, HandleDeleteLesson, e.target.id->", e.target.id)
        props.RemoveLesson(lessonId);
    }

    // ******************** CONSTRUCT SECTIONLIST ********************** //
    let sectionList = CreateSectionList(props.courseSubmission);
    // setSectionList(sections);
    const allSections = sectionList.map((section) => {
        ConstructSections(section)
    });
            // console.log("In AdminCourseLessons, sectionList->", sectionList);

    

    function ConstructSections() {
            // console.log("In AdminCourseLessons, section->", section);
        // if (sectionList !== undefined) {
            const allSections = sectionList.map(section => (
                <Box key = {section}>
                    <Box className = "admin-section-subtitles" key = {section}> SECTION - {section} </Box>
                    <Box>
                        {ConstructSectionLessons(section)}
                    </Box>
                </Box>
            ));
        // }
            // console.log("In AdminCourseLessons, allSections->", allSections);
        return allSections;
    }

    function ConstructSectionLessons (section) {
            // console.log("In AdminCourseLessons, props.courseSubmission.lessons->", props.courseSubmission.lessons);
        var sectionLessons = props.courseSubmission.lessons.filter(lesson => lesson.section === section).map((lesson) => 
                ConstructLesson(props.courseSubmission.lessons.indexOf(lesson), lesson.id, section)
        );
            // console.log("In AdminCourseLessons, sectionLessons->", sectionLessons);
        
        return sectionLessons
    }

    function ConstructLesson(lessonIndex, lessonId, section) {
        // console.log("In AdminCourseLesson, ConstructLesson, lessonIndex->", lessonIndex, "lessonId->", lessonId, "section->", section);

        return (
            <ThemeProvider theme = {azTechTheme} key = {lessonId}>
                <Box className= "admin-lessons-container" key = {lessonId}>
                    <Badge
                        disabled = {editing === false}
                        badgeContent={props.courseSubmission.lessons[lessonIndex].displayNumber}
                        sx={{ '.MuiBadge-badge': { backgroundColor: orange[100] } }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}>
                        <TextField className = "admin-course-field" label = "Lesson Name"
                            InputProps = {{ inputProps: {STYLE: props.courseSubmission.lessons[lessonIndex].name.background} }}
                            type = "text" 
                            name = {[lessonId] + "-name"}
                            id={[lessonId] + "-name"} 
                            InputLabelProps={{ shrink: true }}
                            onChange={e => props.HandleLessonChange(e.target)} 
                            error = {props.courseSubmission.lessons[lessonIndex].name.status === "error"}
                            variant = {props.courseSubmission.lessons[lessonIndex].name.variant}
                            value = {props.courseSubmission.lessons[lessonIndex].name.value}
                            disabled = {editing === false}></TextField>
                    </Badge>
                    <Box className = "admin-lesson-last-row-container">
                        <TextField className = "admin-course-field-narrow" label = "Minimum Hrs"
                            InputProps = {{ inputProps: {STYLE: props.courseSubmission.lessons[lessonIndex].minimumHours.background} }}
                            type = "number" fullWidth
                            name = {[lessonId] + "-minimumHours"} 
                            id={[lessonId] + "-minimumHours"} 
                            InputLabelProps={{ shrink: true }}
                            onChange={e => props.HandleLessonChange(e.target)} 
                            error = {props.courseSubmission.lessons[lessonIndex].minimumHours.status === "error"} 
                            variant = {props.courseSubmission.lessons[lessonIndex].minimumHours.variant} 
                            value = {props.courseSubmission.lessons[lessonIndex].minimumHours.value}
                            disabled = {editing === false}></TextField> 
                        <Box className = "admin-lesson-delete-icon">
                            <Button disabled = {editing === false}>
                                <DeleteOutlineOutlinedIcon 
                                    id = {[lessonId] + "-deleteIcon"} 
                                    onClick = {HandleDeleteLesson}
                                    sx = {{mr: "5px"}}
                                />
                                DELETE LESSON
                            </Button>
                        </Box>
                    </Box>
                    <TextField className = "admin-lesson-field-wide" label = "Objective"
                        InputProps = {{ inputProps: {STYLE: props.courseSubmission.lessons[lessonIndex].objective.background} }}
                        type = "text" fullWidth multiline
                        name = {[lessonId] + "-objective"} 
                        id={[lessonId] + "-objective"} 
                        InputLabelProps={{ shrink: true }}
                        onChange={e => props.HandleLessonChange(e.target)} 
                        error = {props.courseSubmission.lessons[lessonIndex].objective.status === "error"}
                        variant = {props.courseSubmission.lessons[lessonIndex].objective.variant}
                        value = {props.courseSubmission.lessons[lessonIndex].objective.value}
                        disabled = {editing === false}></TextField>
                    <TextField className = "admin-lesson-field-wide" label = "Performance Standard"
                        InputProps = {{ inputProps: {STYLE: props.courseSubmission.lessons[lessonIndex].details.background} }}
                        type = "text" fullWidth multiline
                        name = {[lessonId] + "-details"} 
                        id={[lessonId] + "-details"} 
                        InputLabelProps={{ shrink: true }}
                        onChange={e => props.HandleLessonChange(e.target)} 
                        error = {props.courseSubmission.lessons[lessonIndex].details.status === "error"}
                        variant = {props.courseSubmission.lessons[lessonIndex].details.variant}
                        value = {props.courseSubmission.lessons[lessonIndex].details.value}
                        disabled = {editing === false}></TextField>
                    {/* </Box> */}
                </Box>
            </ThemeProvider>
        );
            // console.log("In AdminCourseLessons, lesson->", displayLesson);
    }

    useEffect(() => {
        ConstructSections();
    },[props.courseSubmission.lessons]);

    return (
        <Box>
            {ConstructSections()}
        </Box>
    );

}   