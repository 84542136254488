import { createTheme} from '@mui/material/styles';


const colours = {
      lightGrey: "rgb(250, 251, 250)",
      blanchedAlmond: "rgb(254, 255, 237)",
      gunMetal: "rgb(37, 50,55)",
      background: "rgb(37, 50,55)",
      burgundy: "rgb(113, 3, 3)",
      blueBright: "rgb(0, 200, 255)",
      white: "rgb(255,255,255)",
      mainBlue: '#1976d2',
      aliceBlue: "rgb(240,248,255)"
      // aliceBlue: "#f0f8ff"
}

// console.log("In ColourTheme, colours.aliceBlue->", colours.aliceBlue);

const azTechTheme = createTheme({
  palette: {
    lightGrey: colours.lightGrey,
    blanchedAlmond: colours.blanchedAlmond,
    gunMetal: colours.gunMetal,
    // background: colours.background,
    burgundy: colours.burgundy,
    aliceBlue: colours.aliceBlue,
    components: {
      MuiBadge: {
        styleOverrides: {
          colorAliceBlue: {
            color: colours.aliceBlue,
            backgroundColor: colours.aliceBlue,
          },
        },
      },
    },
    blueBright: colours.blueBright,
    white: colours.white,
    mainBlue: colours.mainBlue
  }
});


  // console.log("In ColourTheme, azTechTheme.palette.aliceBlue->", azTechTheme.palette.aliceBlue);


export default azTechTheme;