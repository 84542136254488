export const courseValidationMessages = {
    // ********** ERROR & WARNING MESSAGES **********
    courseSelectionMessage: "PLEASE ENTER A COURSE NAME",
    missingMandatoryField: "ONE OR MORE REQUIRED FIELDS ARE MISSING",

    warningMessages: {
        dateInPast: "DATE IS IN THE PAST...    CONTINUE?",
        dateInFuture: "DATE IS IN THE FUTURE...    CONTINUE?",
        update: "ARE YOU SURE YOU WANT TO UPDATE THIS COURSE... CONTINUE?",
        delete: "ARE YOU SURE YOU WANT TO DELETE THIS COURSE... CONTINUE?",
    },
    successMessages: {
        cancel: "CHANGES DISCARDED",
        create: "COURSE SUCCESSFULLY CREATED",
        update: "COURSE SUCCESSFULLY UPDATED",
        delete: "COURSE SUCCESSFULLY DELETED",
        removeApproval: "APPROVAL SUCCESSFULLY DELETED",
        addApproval: "APPROVAL SUCCESSFULLY ADDED"
    }
}