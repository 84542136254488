//Import libraries
import React from "react";
import Tooltip from '@mui/material/Tooltip';
import { Box } from "@mui/system";

//Import CSS, images & icons
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

// ******************************* MAIN FUNCTION ************************************************
export default function databaseConnectionWarning(props) {
    // console.log("from dbCW component ", props.databaseConnection);
    if (!props.databaseConnection) {
    return(
        // <Tooltip title="DATABASE NOT CONNECTED">
        <Box>
            <WarningAmberOutlinedIcon sx = {{marginLeft: "0px", marginTop: "20px"}} color = "error"/>
            <Box sx={{fontSize: "xx-small", color: "red"}}>
                DATABASE NOT <br></br>CONNECTED
            </Box>
        </Box>
        // {/* </Tooltip> */}
    );
    };
}