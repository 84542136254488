// Import libraries


// Import CSS, images and icons

// Main Function
export default function CreateDisplayDate(dateString) {
    var displayDate
    displayDate = dateString.replace(/-/g, "/");
    return displayDate;
}