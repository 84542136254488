// Import libraries
import * as React from 'react';
import Box from '@mui/material/Box';
import { useEffect, useState, useReducer} from 'react';
import CreateFlightLogColumns from './CreateFlightLogColumns.js';
import { CreateFlightLogRow } from './CreateFlightLogRow.js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Banner from "../../flights/techLog/banner/Banner.js"
import { FlightLogGrid } from './FlightLogGrid.js';
import { TimePicker } from '@mui/x-date-pickers';
import { GetAircraftData } from '../../utilities/database/GetAircraftData.js';
import { GetFlightList } from '../../utilities/database/GetFlightList.js';
import { PostFlight } from '../../utilities/database/PostFlight.js';
import { DeleteFlight } from '../../utilities/database/DeleteFlight.js';

import Feedback from '../../utilities/feedback/Feedback.js';
import Validate from '../../utilities/validation/Validate.js';
import { FlightLogFormReducer } from '../../dispatchersAndReducers/FlightLogFormReducer.js';
import _ from "lodash";

// import { UpdateSnackBarStatus, 
//   UpdateDialogueStatus,
//   UpdateDialogueResponse, 
//   UpdateDatabaseConnection,
//   UpdateResult,
//   UpdateSelection } from '../../utilities/UpdateFunctions.js';

// Set-up Date Localisation Provider
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

// Import CSS, images, icons & static data
import "./FlightLog.css"
// import { CreateFlightSubmission } from './CreateFlightSubmission.js';
import { defaultAircraftList } from "../../aircraft/AircraftData.js";
import { defaultFlightList } from '../../flights/flightData/FlightData.js'
import { successMessages } from '../../utilities/validation/AircraftValidations.js';
import { flightValidationMessages } from '../../utilities/validation/FlightValidationMessages.js';





// ******************************* MAIN FUNCTION ************************************************
function FlightLog(props, {children}) {
  // Initialise context using default variables
  // const [submission, dispatch] = useReducer(FlightLogFormReducer, {});
  let submission = {};
  const title = "Flight Log";
  const calledFrom = "flightLog";
  const aircraftSelectionKey = 0
  const [aircraftList, setAircraftList] = useState(defaultAircraftList);
  const [selectedAircraft, setSelectedAircraft] = useState(aircraftList[aircraftSelectionKey]);
  const [serviceEventList, setServiceEventList] = useState([]);
  const [flightList, setFlightList] = useState([]);
  const [registration, setRegistration] = useState(aircraftList[aircraftSelectionKey]._registration);
  dayjs.extend(utc);
  // Rows for the grid
  let initialRows = [];
  const [gridRows, setGridRows] = useState([]);
 // Common Feedback and Data Connection Status Functions
  const [snackBarStatus, setSnackBarStatus] = useState(false);
  const [dialogueStatus, setDialogueStatus] = useState(false);
  const [databaseConnection, setDatabaseConnection] = useState(true);
  const [submissionUpdated, setSubmissionUpdated] = useState(false);
  const [result, setResult] = useState({status: "", message: "" })
  let deleteResponseObject = {}
  var mostSevereIssue = {
    status: "success",
    message: ""
  }

// ************* MANAGE SUBMISSION FUNCTIONS *******************
async function ManageFlightLogSubmission(type, row) {

  // console.log("In PostFlight, ManageFlightLogSubmission, submission.action->", type);

  let action = {
    type: type,
    data: {
      row: row,
      selectedAircraft: selectedAircraft
    }
  }
    // console.log("In FlightLog, action->", action);
  submission = await FlightLogFormReducer(action);
    // console.log("In FlightLog, post-validation and post submission attempt, submission->", submission);

  return submission;
}

function PackageRow(row, selectedAircraft, action) {
  
  let rowForUpdate = {};
  rowForUpdate = _.cloneDeep(row);
  rowForUpdate.registration = selectedAircraft._registration;
  rowForUpdate.planeType = selectedAircraft._planeType;
  if (action === "delete") {
    rowForUpdate.status = "DELETED";
  } else {
    rowForUpdate.status = "CLOSED";
  }
    // console.log("In FlightLog, PackageRow, rowForUpdate->", rowForUpdate);
  return rowForUpdate;
}

const HandleContinue = async (result) => {
  // Handle - Save (in progress)
  
  let row = result.context;
  let submission = {};
  if (row) {
    if (!row.id.toString().includes("new")) {
        // console.log("In FlightLog, selectedAircraft->", selectedAircraft);
      let rowForUpdate = PackageRow(row, selectedAircraft, result.action)
        // console.log("In FlightLog, rowForUpdate->", rowForUpdate);
      switch(result.action) {
        case "delete":
          submission = await ManageFlightLogSubmission("delete", rowForUpdate);
        break;
        case "update":
          // submission.mostSevereIssue.status = "warning";
          submission = await ManageFlightLogSubmission("save", rowForUpdate);
          break;
        default:
          console.log("Unknown grid action.");
      }
    }
      // console.log("In FlightLog, HandleContinue, submission.responseObject->", submission.responseObject);
    if (submission.responseObject) {
      TriggerFeedback(submission);
    }
  }
}


function TriggerFeedback(submission) {
  if (submission.responseObject && submission.mostSevereIssue) {
    let databaseConnection = submission.responseObject.databaseConnection;
    let mostSevereIssue = submission.mostSevereIssue;
    UpdateDatabaseConnection(databaseConnection);
    if (databaseConnection) {
      PlaneData();
      UpdateSelection(selectedAircraft._registration, aircraftList);
    } 
    UpdateResult(mostSevereIssue);
    UpdateSnackBarStatus(true);
  }
};
 

//************************************** MANAGE AIRCRAFT SELECTION ******************************
  async function UpdateSelection (selection, planeList) {
   
    let responseObject = {
      data: {},
      databaseConnection: true
    }
    let selectedPlane = {};
    setRegistration(selection);
        // console.log(planeList);

    if (planeList != null) {
      planeList.forEach((plane) => {
        if (plane._registration === selection) {
          setSelectedAircraft(plane);
          selectedPlane = plane;
            // console.log("In FlightLog, UpdateSelection, plane->", plane);
            var oilGroup = [];
          if (plane._numberOfEngines === 1) {
            oilGroup = [{ field: 'oilUpliftLeft' }, {field: 'oilStatusLeft'}];
          } else {
            oilGroup = [{ field: 'oilUpliftLeft' }, {field: 'oilStatusLeft'}, {field: 'oilUpliftRight'}, {field: 'oilStatusRight'}]
          }
        }
          // console.log("In FlightLog, UpdateSelection, selection->", selection);
      });

      if (selection !== "SELECT") {
        responseObject = await GetFlightList(setFlightList, selection);
         
        if (responseObject.databaseConnection) { 
          UpdateDatabaseConnection(responseObject.databaseConnection);
          let flights = [];
          responseObject.data.forEach((flight) => {
            
            flights.push(CreateFlightLogRow(selectedPlane, flight));
              
          })
          UpdateGridRows(flights);
            // console.log("In FlightLog, flights->", flights);
        } else {
          UpdateDatabaseConnection(responseObject.databaseConnection);
        }
      } else {
        UpdateGridRows([]);
      }
    }
  }

  function UpdateFlightList (registration, flightList) {
    let list = flightList.filter((flight) => flight.registration.value === registration);
    setFlightList(list);
  }
  // // Snackbar & Dialogue Update functions
  function UpdateSnackBarStatus (newStatus) {
    // console.log("In TechLog, UpdateSnackBarStatus, newStatus->", newStatus);
    setSnackBarStatus(newStatus);
        // console.log("In TechLog, UpdateSnackBarStatus, snackBarStatus->", snackBarStatus);
  }
  function UpdateResult (mostSevereIssue) {
    // console.log("In TechLog, UpdateSnackBarStatus, newStatus->", newStatus);
    setResult(mostSevereIssue);
        // console.log("In TechLog, UpdateSnackBarStatus, snackBarStatus->", snackBarStatus);
  }
  function UpdateDialogueStatus (newStatus) {
      // console.log("In UpdateDialogueStatus, newStatus->", newStatus); 
    setDialogueStatus(newStatus);
  }

  function UpdateGridRows (newList) {
    setGridRows(newList);
  }

  // // *********************** Feedback Functions **********************************
  function UpdateDatabaseConnection (response) {
    setDatabaseConnection(response);
  }

  //************************ RETRIEVE AIRCRAFT LIST AND SERVICE EVENT DATA FROM DATABASE *******************
  const PlaneData = async () => {
    let responseObject = await GetAircraftData()
    UpdateDatabaseConnection(responseObject.databaseConnection);
    if (responseObject.databaseConnection) {
      // UpdateSelection(responseObject.data.aircraftList[0]._registration, responseObject.data.aircraftList);
      setAircraftList(responseObject.data.aircraftList);
      setServiceEventList(responseObject.data.serviceEventList)
    }
  }

  useEffect(() => {
    PlaneData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update aircraft selection based on user choice of registration
  useEffect(() => {
    UpdateFlightList(registration, defaultFlightList);
    UpdateSelection(registration, aircraftList);
      // console.log("In FlightLog, selectedAircraft->", selectedAircraft);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registration, aircraftList]);

  //*********************************** DEFINE TABLE *******************************************************

  const other = {
    autoHeight: true,
    // showCellVerticalBorder: true,
    showColumnVerticalBorder: true,
  };
  const columns = CreateFlightLogColumns(selectedAircraft);
    
  let flights = [];
  flightList.forEach((flight) => {
      // console.log("In FlightLog, flight->", flight);
    flights.push(CreateFlightLogRow(selectedAircraft, flight));
    // setGridRows(flights);
  })
    // console.log("In FlightLog, flights->", flights);

  var oilGroup = [];
  if (selectedAircraft._numberOfEngines === 1) {
    oilGroup = [{ field: 'oilUpliftLeft' }, {field: 'oilStatusLeft'}];
  } else {
    oilGroup = [{ field: 'oilUpliftLeft' }, {field: 'oilStatusLeft'}, {field: 'oilUpliftRight'}, {field: 'oilStatusRight'}]
  }
  
    // console.log("In flightLog, selectedAircraft->", selectedAircraft);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {children}
      <meta name="viewport" content="initial-scale=1, width=device-width" />
      <Box>
          <Banner className = "banner-admin" 
                  title = {title} 
                  databaseConnection = {databaseConnection} 
                  aircraftList={aircraftList} 
                  selectedAircraft={selectedAircraft} 
                  registration={registration} 
                  UpdateSelection={UpdateSelection}
                  calledFrom = "FlightLog"/>
      </Box>
      <Box sx = {{minWidth: "400px"}}>  {/* DATA GRID CONTAINER */}
        <Box> {/* DATA GRID HEADER */}
        <FlightLogGrid 
          flights = {flights} 
          columns = {columns} 
          selectedAircraft = {selectedAircraft}
          submission = {submission}
          PackageRow = {PackageRow}
          gridRows = {gridRows}
          UpdateGridRows = {UpdateGridRows}
          UpdateDatabaseConnection = {UpdateDatabaseConnection} 
          UpdateResult = {UpdateResult}
          UpdateSnackBarStatus = {UpdateSnackBarStatus}
          UpdateDialogueStatus = {UpdateDialogueStatus}
          ManageFlightLogSubmission = {ManageFlightLogSubmission}
          Validate = {Validate}
          Feedback = {Feedback}
          TimePicker = {TimePicker}/>
        </Box> {/* END DATA GRID HEADER */}
      </Box>
      <Box className = "feedback" sx={{zIndex: "6", position: "sticky", left: "0px", bottom: "80px", margin: "80px"}}>
        <Feedback
          HandleContinue = {HandleContinue} 
          dialogueStatus = {dialogueStatus} 
          UpdateDialogueStatus = {UpdateDialogueStatus} 
          snackBarStatus = {snackBarStatus} 
          UpdateSnackBarStatus = {UpdateSnackBarStatus} 
          submission = {submission}
          calledFrom = {calledFrom}
          result = {result}/>          
      </Box>
        
    </LocalizationProvider>
  );
};

export default FlightLog;