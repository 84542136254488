// Import libraries
import axiosInstance from "../security/AxiosConfig";
import _ from "lodash";
import { InitialiseClaims } from "../security/AuthUtilities";

// Import CSS, images, icons and Static Data
import { API_URL } from "../../../config/config";
// import { authorisationMessages } from "../security/AuthorisationMessages";
import {databaseConnectionMessages} from "./DatabaseConnectionMessages";
// import { rolesTemplate } from "./GetRolePermissions";


// ************************* RETRIEVE AIRCRAFT LIST FROM DATABASE *******************************
async function GetPerson(email) {


    var responseObject = {};
    try {
        var response = {};
            // console.log("In GetPerson, email->", email);
        if (email) {
            response = await axiosInstance.get(`${API_URL}/person/findByEmail`, {params: { email: email }});
                // console.log("In GetPostPerson, response->", response);
        }
        let person = {};
        person = _.cloneDeep(response.data);
    
        responseObject =            
            {
                data: person,
                databaseConnection: true
            }
          
        return responseObject;
           
    } catch (error) {
            console.error('Error fetching person', error);
        let responseObject =            
            {
                data: databaseConnectionMessages.error,
                databaseConnection: false
            }
            // console.log("In GetPerson, returnObject->", returnObject);
        return responseObject;
    }
};



export {GetPerson}