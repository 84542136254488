// Import libraries
import { Box } from "@mui/system";

// Import CSS, images, icons & static data
import "./Banner.css"
import blueArrow from "../images/blueArrow.png";


//************************** MAIN FUNCTION ******************************** */
export default function ScrollingArrow(props) {

    if (props.defectListLength > 2) {
        return (
            <Box>
                <img src = {blueArrow} alt = "Arrow" className = "blue-arrow-defects"></img>
            </Box>
        );
    }

}