// Import libraries
import axiosInstance from "../security/AxiosConfig";

// Import CSS, images, icons and Static Data
import { API_URL } from "../../../config/config";


// ************************* RETRIEVE AIRCRAFT LIST FROM DATABASE *******************************
export async function GetPilotLogSummaryData(pilotForDisplay)  {
    // const [pilotList, setPilotList] = useState([]);
   
        // console.log("In GetPilotLogSummaryData, responseObject->", pilotForDisplay)

    let responseObject = {};
    responseObject.data = [];

    try {
        const response = await axiosInstance.get(`${API_URL}/flights/pilotLogSummary?selectedPilot=${pilotForDisplay}`);
        responseObject.data.raw = response.data;
        responseObject.databaseConnection = true;
           
    } catch (error) {
        console.error('Error fetching pilots:', error);
        responseObject.databaseConnection = false;
    }
        // console.log("In GetPilotLogSummaryData, responseObject->", responseObject)
    return responseObject;

};