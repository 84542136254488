// Import libraries
import axiosInstance from "../security/AxiosConfig";
import _ from "lodash";
import { InitialiseClaims } from "../security/AuthUtilities";

// Import CSS, images, icons and Static Data
import { API_URL } from "../../../config/config";
import { authorisationMessages } from "../security/AuthorisationMessages";
import {databaseConnectionMessages} from "./DatabaseConnectionMessages";
import { rolesTemplate } from "./GetRolePermissions";


// ************************* RETRIEVE AIRCRAFT LIST FROM DATABASE *******************************
async function GetPostPerson(type, personRequest) {
        // console.log("In GetPostPerson, type->", type);
    if (type === "new") {
        // console.log("personRequest->", personRequest);
    }

    var responseObject = {};
    try {
        var response = {};
        switch (type) {
            case "unacknowledged": 
                response = await axiosInstance.get(`${API_URL}/person/unacknowledged`);
            break;
            case "acknowledged": {
                response = await axiosInstance.get(`${API_URL}/person/acknowledged`);
                    // console.log("In GetPostPerson, response->", response);
                response.data.forEach((person) => {
                    person.name = person.firstName + " " + person.lastName;
                    person.roles = rolesTemplate;
                });
            
            break;
            }
            case "new": {
                if (personRequest.firstName) {
                    response = await axiosInstance.get(`${API_URL}/person/find`,  {params: personRequest});
                }
            break;
            }
            default:
                console.log("ERROR - REQUEST NOT RECOGNISED");
        }
        let returnList = _.cloneDeep(response.data);
    
        responseObject =            
            {
                data: returnList,
                databaseConnection: true
            }
          
        return responseObject;
           
    } catch (error) {
            console.error('Error fetching people', error);
        let responseObject =            
            {
                data: databaseConnectionMessages.error,
                databaseConnection: false
            }
            // console.log("In GetPerson, returnObject->", returnObject);
        return responseObject;
    }
};



export {GetPostPerson}