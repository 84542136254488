// Import libraries
import CreateCourseSubmission from "../admin/course/CreateCourseSubmission";
import _ from "lodash";
import dayjs from "dayjs";

// Import CSS, icons, images & Static Data
import { emptyCourseSubmission } from "../admin/course/CourseData";
import { background } from "../flights/flightData/FlightData";

function CourseFormReducer(courseSubmission, action) {
        // console.log("In CourseFormReducer, courseSubmission->", courseSubmission);
        // console.log("In CourseFormReducer, action->", action);
    let field = "";
    let updatedLessons = [];
    let updatedApprovals = {};
    let lessonIndex = 0;
    let lessonId = 0;
    switch(action.type) {
        case "removeLesson":
                // console.log("In CourseFormReducer, action.value.lessonId->", action.value.lessonId)
                // console.log("In CourseFormReducer, CourseSubmission pre-filer->", courseSubmission);
            updatedLessons = courseSubmission.lessons.filter(lesson => lesson.id !== action.value.lessonId);
                // console.log("In CourseFormReducer, CourseSubmission post-filter->", courseSubmission);
            return {
                ...courseSubmission,
                lessons: updatedLessons
            }

        case "initialise":

            let newCourseSubmission = CreateCourseSubmission(action.selectedCourse);
                // console.log("In CourseFormReducer, newCourseSubmission->", newCourseSubmission);
            courseSubmission = _.cloneDeep(newCourseSubmission);
                // console.log("In CourseFormReducer, courseSubmission->", courseSubmission);
            return {
                ...courseSubmission
            }

        case "markValidationErrors":
            // const clearedLesson = _.cloneDeep(emptyCourseSubmission.newLesson);
                // console.log("In CourseFormReducer, courseSubmission->", courseSubmission);
                console.log("In CourseFormReducer, action->", action);

            action.value.forEach((result) => {
                if (result.status === "error") {
                    switch(result.field1) {
                    case "lessons":
                        courseSubmission.lessons[result.field2][result.field3].status = "error";
                        courseSubmission.lessons[result.field2][result.field3].variant = "outlined";
                        courseSubmission.lessons[result.field2][result.field3].background = background.error;
                    break;

                    default:
                        courseSubmission[result.field1].status = "error"
                        courseSubmission[result.field1].variant = "outlined"
                        courseSubmission[result.field1].background = background.error
                    break;
                    }
                }
            });
              
            return {
                ...courseSubmission
            }

            case "resetValidationErrors":
                // const clearedLesson = _.cloneDeep(emptyCourseSubmission.newLesson);
                    // console.log("In CourseFormReducer, courseSubmission->", courseSubmission);

                // let clearedCourseSubmission = _.cloneDeep(courseSubmission);
                Object.keys(courseSubmission).forEach((field) => {
                    switch(field) {
                    case "approvals":
                    case "courseImage":
                    case "courseIcon":
                    break;
                    case "lessons":
                        courseSubmission.lessons.forEach((lesson, index) =>{
                                // console.log("In CourseFormReducer, lesson->", lesson); 
                            Object.keys(lesson).forEach((field) => {
                                switch(field) {
                                    case "id":
                                    case "displayNumber":
                                    case "section":
                                    case "courseId":
                                    break;
                                    default:
                                            // console.log("In courseFormReducer, courseSubmission[lesson]->", field); 
                                        courseSubmission.lessons[index][field].status = "success";
                                        courseSubmission.lessons[index][field].variant = "outlined";
                                        courseSubmission.lessons[index][field].background = background.white;
                                    break;
                                }
                            });
                        });
                    break;

                    default:
                        courseSubmission[field].status = "success"
                        courseSubmission[field].variant = "outlined"
                        courseSubmission[field].background = background.white
                    break;

                    }
                });
            
            return {
                ...courseSubmission
            }

        case "saveNewLesson":
            // Add newLesson to the Lessons list
            lessonIndex = courseSubmission.lessons.length
            let tempLesson = _.cloneDeep(emptyCourseSubmission.lessons[0]);
            tempLesson.id = _.cloneDeep(lessonIndex + 1);
            tempLesson.courseId = _.cloneDeep(courseSubmission.id.value);
            tempLesson.displayNumber = null;
            tempLesson.section = _.cloneDeep(Number(courseSubmission.newLesson.section.value));
            tempLesson.name.value = _.cloneDeep(courseSubmission.newLesson.name.value);
            tempLesson.objective.value = _.cloneDeep(courseSubmission.newLesson.objective.value);
            tempLesson.details.value = _.cloneDeep(courseSubmission.newLesson.details.value);
            tempLesson.minimumHours.value = _.cloneDeep(courseSubmission.newLesson.minimumHours.value);
                // console.log("In CourseFormReducer, newLesson 1st->", tempLesson);

            updatedLessons = _.cloneDeep(courseSubmission.lessons);
            updatedLessons.push(tempLesson);


                // console.log("In CourseFormReducer, courseSubmission.lessons->", courseSubmission.lessons);
                // console.log("In CourseFormReducer, updatedLessons->", updatedLessons);

            return {
                ...courseSubmission,
                lessons: updatedLessons
            }

        case "clearNewLesson":
            const clearedLesson = _.cloneDeep(emptyCourseSubmission.newLesson);
                // console.log("In CourseFormReducer, courseSubmission->", courseSubmission);

            clearedLesson.section.value = 0;
            clearedLesson.name.value = "";
            clearedLesson.objective.value = "";
            clearedLesson.details.value = "";
            clearedLesson.minimumHours.value = 0;

            return {
                ...courseSubmission,
                newLesson:clearedLesson
                
            }

        case "addApproval":
                // console.log("In CourseFormReducer, action->", action);
            // let newApprovalId = courseSubmission.approvals.length + 1
            let newApproval = {
                id: action.value.id,
                authority: 
                {
                    name: action.value.authority.name,
                    country: action.value.authority.country,
                },
                approvalDate: dayjs(new Date(action.value.approvalDate)),
                expiryDate: null
            }

            updatedApprovals = [...courseSubmission.approvals, newApproval];
                console.log("In CourseFormReducer, courseSubmission->", courseSubmission);
        
            return {
                ...courseSubmission,
                approvals: updatedApprovals,
                status: {
                    ...courseSubmission.status,
                    value: "APPROVED"
                }
            }    

        case "deleteApproval":
                // console.log("In CourseFormReducer, action.value->", action.value);
                // console.log("In CourseFormReducer, courseSubmission.approvals[Number(action.event.target.id)]->", courseSubmission.approvals[Number(action.event.target.id)]);
            // courseSubmission.approvals[Number(action.event.target.id)].expiryDate = action.value.expiryDate;
            courseSubmission.approvals.forEach((approval) => {
                if (approval.id === action.value.id) {
                    approval.expiryDate = action.value.expiryDate;
                }
            })
            let approvalCount = 0;
            let mostRecentExpiryDate = "";
            let newStatus = "DEPRECATED";

            updatedApprovals = _.cloneDeep(courseSubmission.approvals);
            updatedApprovals.forEach((approval) => {
                if (approval.expiryDate === "" || approval.expiryDate === undefined || approval.expiryDate === null) {
                    approvalCount++
                } else {
                    if (mostRecentExpiryDate === "") {
                        mostRecentExpiryDate = approval.expiryDate;
                    } else {
                        if (dayjs(mostRecentExpiryDate) < dayjs(approval.expiryDate)) {
                            mostRecentExpiryDate = approval.expiryDate;
                        } 
                    }
                }
                    // console.log("In CourseFormReducer, approvalCount->", approvalCount);
            if (approvalCount === 0) {
                newStatus = "DEPRECATED"
                // courseSubmission.deprecatedDate = mostRecentExpiryDate;
            } else {
                newStatus = "APPROVED"
            }
            });
                // console.log("In CourseFormReducer, courseSubmission->", courseSubmission);
            
            return {
                ...courseSubmission,
                approvals: updatedApprovals,
                deprecatedDate:mostRecentExpiryDate,
                status: {
                    ...courseSubmission.status,
                    value: newStatus
                }
            }  

        case "updateStatus":
            courseSubmission.status.value = action.value

            return {
                ...courseSubmission
            }

        case "editNewLesson":
            field = action.name;
                courseSubmission.newLesson[field] =
                {
                    value: action.value,
                    status: courseSubmission.newLesson[field].status,
                    background: courseSubmission.newLesson[field].background,
                    variant: courseSubmission.newLesson[field].variant,
                    reset: courseSubmission.newLesson[field].reset,
                    required: courseSubmission.newLesson[field].required
                }
            return {
                ...courseSubmission
            };

        case "editLesson":
                // console.log("In CourseFormReducer, action->", action);
            lessonId = Number(action.name.slice(0,action.name.indexOf("-")));
            field = action.name.slice(action.name.indexOf("-") + 1);
            updatedLessons = courseSubmission.lessons.map((lesson, index) => {
                if (lesson.id === lessonId) {
                        // console.log("In CourseFormReducer, field->", field);
                        // console.log("In CourseFormReducer, lessonId->", lessonId);
                    return {
                        ...lesson,
                        [field]: {
                            value: action.value,
                            status: lesson[field].status,
                            background: lesson[field].background,
                            variant: lesson[field].variant,
                            reset: lesson[field].reset,
                            required: lesson[field].required
                        }
                    };
                }
                return lesson;
            });
                // console.log("In CourseFormReducer, updatedLessons->", updatedLessons);
                // console.log("In CourseFormReducer, courseSubmission->", courseSubmission);
            return {
                ...courseSubmission,
                lessons: updatedLessons
            };

        default: 
            field = action.name
            return {
                ...courseSubmission,
                [field]: {
                    value: action.value,
                    status: courseSubmission[field].status,
                    background: courseSubmission[field].background,
                    variant: courseSubmission[field].variant,
                    reset: courseSubmission[field].reset,
                    required: courseSubmission[field].required
                }
            };
    }


}

export {CourseFormReducer}
