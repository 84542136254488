//Import libraries
import { Box } from "@mui/system";
import React, { useEffect, useState } from 'react';
import { DataGrid } from "@mui/x-data-grid";
import {PilotLogSummaryRows} from "./PilotLogSummaryRows";
import PilotLogTotalHours from "./PilotLogTotalHours";


// Import CSS, images, icons & static data
import "./PilotLog.css";
import { pilotLogSummaryColumns1 } from "./PilotLogSummaryColumns";
import { pilotLogSummaryColumns2 } from "./PilotLogSummaryColumns";
import { pilotLogSummaryColumns3 } from "./PilotLogSummaryColumns";
import { columnGroups1, columnGroups2, columnGroups3 } from "./PilotLogSummaryColumnGroupingModel";
import { pilotLogSummaryRow1, pilotLogSummaryRow2, pilotLogSummaryRow3, pilotLogSummaryTotalHours } from "./PilotLogSummaryRows";



// Import fonts & Static data

const columnList1 = pilotLogSummaryColumns1;
const columnList2 = pilotLogSummaryColumns2;
const columnList3 = pilotLogSummaryColumns3;


//**************************** MAIN FUNCTION ***************************** */
export function PilotLogSummary(props) {

  var row1 = pilotLogSummaryRow1;
  var row2 = pilotLogSummaryRow2;
  var row3 = pilotLogSummaryRow3;
  var totalHours = pilotLogSummaryTotalHours;

    // console.log("In PilotLogSummary, row1->", row1);

  // useEffect(() => {
    if (props.pilotForDisplay !== undefined && 
      props.pilotForDisplay !== "" && 
      props.pilotForDisplay !== null && 
      props.pilotLogSummary !== undefined && 
      props.pilotLogSummary !== null && 
        props.pilotLogSummary !== {})  {
          if (props.pilotLogSummary.pilotLogSummaryRow1 !== undefined) {
            // console.log("In PilotLogSummary, props.pilotForDisplay->", props.pilotForDisplay);
            // console.log("In PilotLogSummary, props.pilotLogSummary->", props.pilotLogSummary);
       
        row1 = props.pilotLogSummary.pilotLogSummaryRow1;
        row2 = props.pilotLogSummary.pilotLogSummaryRow2;
        row3 = props.pilotLogSummary.pilotLogSummaryRow3;
        totalHours = props.pilotLogSummary.totalHours;
        }
      
    }
  // }, [props.pilotForDisplay]);


  // console.log("In PilotLogSummary, totalHours ->", totalHours);

function RenderPilotLogSummary() {
  return (
    <Box sx = {{flexWrap: "wrap", 
        display: "flex", 
        flexDirection: "row", 
        justifyContent: "flexStart", 
        minWidth: "370px",  
        mt: "10px", 
        mb: "10px", 
        ml:"10px", 
        gap: "15px"}}>
      <PilotLogTotalHours totalHours = {totalHours}/>
      <Box className = "pilot-log-summary">
        <DataGrid
          sx = {{border: "0px", minWidth: "360px"}}
          color = "info"
          initialState = {{ }}
          experimentalFeatures = {{ columnGrouping: true }}
          columnGroupingModel = {columnGroups1}
          columnGrouping = {columnGroups1}
          hideFooter
          rows={row1}
          columns={columnList1}
          pageSizeOptions={[1]}
          disableRowSelectionOnClick
        />
      </Box>
      <Box className = "pilot-log-summary">
        <DataGrid
          sx = {{border: "0px", minWidth: "360px"}}
          color = "info"
          initialState = {{ }}
          experimentalFeatures = {{ columnGrouping: true }}
          columnGroupingModel = {columnGroups2}
          columnGrouping = {columnGroups2}
          hideFooter
          rows={row2}
          columns={columnList2}
          pageSizeOptions={[1]}
          disableRowSelectionOnClick
        />
      </Box>
      <Box className = "pilot-log-summary">
        <DataGrid
          sx = {{border: "0px", minWidth: "300px"}}
          color = "info"
          initialState = {{ }}
          experimentalFeatures = {{ columnGrouping: true }}
          columnGroupingModel = {columnGroups3}
          columnGrouping = {columnGroups3}
          hideFooter
          rows={row3}
          columns={columnList3}
          pageSizeOptions={[1]}
          disableRowSelectionOnClick
        />
      </Box> 
    </Box>
  );
}

  return (
    <Box sx = {{flexWrap: "wrap", display: "flex", flexDirection: "row", justifyContent: "flexStart", mt: "10px",mb: "5px", ml:"10px", gap: "15px"}}>
      <PilotLogTotalHours totalHours = {totalHours}/>
      <Box className = "pilot-log-summary">
        <DataGrid
          sx = {{border: "0px", minWidth: "360px"}}
          color = "info"
          initialState = {{ }}
          experimentalFeatures = {{ columnGrouping: true }}
          columnGroupingModel = {columnGroups1}
          columnGrouping = {columnGroups1}
          hideFooter
          rows={row1}
          columns={columnList1}
          pageSizeOptions={[1]}
          disableRowSelectionOnClick
        />
      </Box>
      <Box className = "pilot-log-summary">
        <DataGrid
          sx = {{border: "0px", minWidth: "360px"}}
          color = "info"
          initialState = {{ }}
          experimentalFeatures = {{ columnGrouping: true }}
          columnGroupingModel = {columnGroups2}
          columnGrouping = {columnGroups2}
          hideFooter
          rows={row2}
          columns={columnList2}
          pageSizeOptions={[1]}
          disableRowSelectionOnClick
        />
      </Box>
      <Box className = "pilot-log-summary">
        <DataGrid
          sx = {{border: "0px", minWidth: "300px"}}
          color = "info"
          initialState = {{ }}
          experimentalFeatures = {{ columnGrouping: true }}
          columnGroupingModel = {columnGroups3}
          columnGrouping = {columnGroups3}
          hideFooter
          rows={row3}
          columns={columnList3}
          pageSizeOptions={[1]}
          disableRowSelectionOnClick
        />
      </Box> 
    </Box>
  );
};