// Import libraries
import { useMediaQuery } from "@mui/material";
import {Switch} from "@mui/material";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import { useState, useEffect } from "react";

// Import CSS, images, icons & static
import "./AdminPerson.css"
import { mobileThreshold } from "../../utilities/environment/EnvironmentData";


// ************************* MAIN FUNCTION *******************************
export default function EditSwitch(props) {

    // const blank = "";
    const [switchClass, setSwitchClass] = useState("switch");
    const [direction, setDirection] = useState("row")
    const [justification, setJustification] = useState("flex-end")
    const [checked, setChecked] = useState(false)
    var media = useMediaQuery(`(min-width:${mobileThreshold}px)`);
   
    const [buttonLabel, setButtonLabel] = useState("ACKNOWLEDGE")

    useEffect (() => {
        if (media) {
            setSwitchClass("switch")
            setDirection("row")
            setJustification("flex-end")
          } else {
            setSwitchClass("switch-mobile")
            setDirection("column")
            setJustification("flex-bottom")
          } 
        }, [media]);

    
    const handleChangeToggle = (e) => {
        // setChecked(e.target.checked);
            // console.log("In EditSwitch, checked->", e.target.checked);
        setChecked(e.target.checked);
        
        props.UpdateEditing(e.target.checked);
    }
    

    // if (media) {
        return (
            <Box sx = {{display: "flex", flexDirection: direction, justifyContent: justification}}>
                <Box sx = {{display: "flex", flexDirection: 'column', justifyContent: 'flex-start'}}>
                    <Box className = {switchClass} sx = {{fontSize: "large"}}></Box>
                    <Box sx = {{ml: "10px", fontFamily: "arial"}}>
                        EDIT
                        <Switch 
                        onChange = {handleChangeToggle}
                        /> 
                    </Box> 
                </Box>

            </Box>
        )
    // } else {

    // }

};