
const pilotLogSummaryColumns1 = [
    {
      field: 'pilotInCommand',
      headerName: 'PIC',
      headerAlign: "center",
      align: "center",
      type: 'number',
      width: 90,
      editable: false,
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'pilotUnderTraining',
      headerName: 'PUT',
      headerAlign: "center",
      align: "center",
      type: 'number',
      width: 90,
      editable: false,
      sortable: false,
      disableColumnMenu: true
    },
    {
        field: 'multiPilot',
        headerName: 'Multi Pilot',
        headerAlign: "center",
        align: "center",
        type: 'number',
        width: 90,
        editable: false,
        sortable: false,
        disableColumnMenu: true
    },
    {
        field: 'instructor',
        headerName: 'Instructor',
        headerAlign: "center",
        align: "center",
        type: 'number',
        width: 90,
        editable: false,
        sortable: false,
        disableColumnMenu: true
    }
]
const pilotLogSummaryColumns2 = [
    {
        field: 'sepTime',
        headerName: 'SEP',
        headerAlign: "center",
        align: "center",
        type: 'number',
        width: 90,
        editable: false,
        sortable: false,
        disableColumnMenu: true
      },
      {
        field: 'mepTime',
        headerName: 'MEP',
        headerAlign: "center",
        align: "center",
        type: 'number',
        width: 90,
        editable: false,
        sortable: false,
        disableColumnMenu: true
      },
      {
        field: 'jetTime',
        headerName: 'JET',
        headerAlign: "center",
        align: "center",
        type: 'number',
        width: 90,
        editable: false,
        sortable: false,
        disableColumnMenu: true
      },
      {
        field: 'simulator',
        headerName: 'Sim.',
        headerAlign: "center",
        align: "center",
        type: 'number',
        width: 90,
        editable: false,
        sortable: false,
        disableColumnMenu: true
      }
]
const pilotLogSummaryColumns3 = [
    {
      field: 'instrumentTime',
      headerName: 'IFR',
      headerAlign: "center",
      align: "center",
      type: 'number',
      width: 90,
      editable: false,
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'nightTime',
      headerName: 'Night',
      headerAlign: "center",
      align: "center",
      type: 'number',
      width: 90,
      editable: false,
      sortable: false,
      disableColumnMenu: true
    },  
    {
      field: 'landings',
      headerName: 'Total',
      headerAlign: "center",
      align: "center",
      type: 'number',
      width: 90,
      editable: false,
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'nightLandings',
      headerName: 'Night',
      headerAlign: "center",
      align: "center",
      type: 'number',
      width: 90,
      editable: false,
      sortable: false,
      disableColumnMenu: true
    } 
]
  
  
  export {pilotLogSummaryColumns1, pilotLogSummaryColumns2, pilotLogSummaryColumns3};