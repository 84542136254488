// Import libraries
import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import PropTypes from 'prop-types';
import { Select as BaseSelect, selectClasses } from '@mui/base/Select';
import { Option as BaseOption, optionClasses } from '@mui/base/Option';
import { Option, Select } from "@mui/joy";
import { styled } from '@mui/system';
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';

// Import CSS, images, icons and static data
// import '../TechLog.css';
import './Banner.css'
import azTechTheme from "../../../utilities/environment/ColourTheme";


// ******************************* MAIN FUNCTION ************************************************
export default function AircraftSelect(props) {
        // console.log(props.registration);

    var selectionList = []
    var registration = props.registration
    var defaultRegistration = "PLANE"


    // var registration = props.registration

    if (props.calledFrom === "AdminAircraft") {
        defaultRegistration= "NEW PLANE";

    } else {
        defaultRegistration = "SELECT";
    }

    props.aircraftList.forEach((plane) => {

        if (props.calledFrom === "AdminAircraft" && plane._registration === "SELECT") {
                plane._registration = "NEW PLANE";
                // props.Update(registration,"NEW PLANE");
        }
        if ((props.calledFrom === "TechLog" || props.calledFrom === "CamoLog" || props.calledFrom === "FlightLog") && plane._registration === "NEW PLANE") {
            plane._registration = "SELECT";
            // props.Update(registration,"PLANE");
        }
        if (plane.registration === props.registration) {


            selectionList.push(<Option  
                      
                        // sx = {{color: azTechTheme.palette.blueBright.rgb}}
                        key = {plane._registration} 
                        id = {plane._registration} 
                        value = {plane._registration}>
                        {plane._registration}
                        </Option>)
        } 
        else {
            selectionList.push(<Option 
                       
                        // sx = {{color: azTechTheme.palette.blueBright.rgb}}
                        key = {plane._registration} 
                        id = {plane._registration} 
                        value = {plane._registration}>
                        {plane._registration}
                        </Option>)
        }

    });

    // console.log("In AircraftSelect, selectionList->", selectionList)

    const handleChange = (e) => {
            // console.log("In AircraftSelect, event->", e.target.id);
        props.UpdateSelection(e.target.id, props.aircraftList)
    }


        return (
            <Box>
                <Box className = "aircraft-label">Registration</Box>
                <Select 
                        sx = {{
                            backgroundColor: azTechTheme.palette.blanchedAlmond, 
                            color: azTechTheme.palette.blueBright, 
                            fontWeight: "bold", 
                            fontSize: "x-large",
                            mt: "25px"
                        }}
                        defaultValue={defaultRegistration}
                        value = {props.registration}
                        className = "aircraft-selection" 
                        type = "text" 
                        id = "registration" 
                        // list = "aircraft" 
                        name ="registration"
                        onChange={handleChange}>
                        {selectionList}
                </Select>
            </Box>
        );
   
}
