// Import libraries
import { Option } from "@mui/joy/";
import {MenuItem} from "@mui/base/"

// Import CSS & images

// selectionList.push(<Option  
                      
//     // sx = {{color: azTechTheme.palette.blueBright.rgb}}
//     key = {plane._registration} 
//     id = {plane._registration} 
//     value = {plane._registration}>
//     {plane._registration}
//     </Option>)

// ******************************* MAIN FUNCTION ************************************************
export default function CreateMenuItems(list) {
    // console.log("list.slice(0,1) ->",list.slice(0,1))
    // console.log("list.slice(1) ->",list.slice(1))
    const selectionList = list.slice(0,1).map((item) =>
        // <option selected = {true} className = "oil" key = {item} value = {item}>{item}</option>
        <option className = "oil" key = {item} value = {item}>{item}</option>
        // <MenuItem selected = {true} key = {item} value = {item}>{item}</MenuItem>
    );
    // console.log("selectionList step1 ->",selectionList)
    selectionList.push(list.slice(1).map((item) => 
        // <option selected = {false} className = "oil" key = {item} value = {item}>{item}</option>
        <option className = "oil" key = {item} value = {item}>{item}</option>
        // <MenuItem selected = {false} key = {item} value = {item}>{item}</MenuItem>
    ));
    // console.log("selectionList complete ->",selectionList)

    return selectionList;
}