import * as React from 'react';
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import '../../../../homePage.css';
import homeNew from "../../../../images/homeNew.jpeg";
import { currentSchool } from '../../../../schoolDetails';
import { useMediaQuery } from '@mui/material';
import { mobileThreshold } from '../../../../../utilities/environment/EnvironmentData';

const backgroundImage = homeNew;

var titleFontSize = "xxx-large";
 

export default function ProductHero() {
  var media = useMediaQuery(`(min-width:${mobileThreshold}px)`);
  if (media) {
    titleFontSize = "xxx-large";
  } else {
    titleFontSize = "xx-large";
  }


  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        // backgroundColor: 'black', 
        // backgroundColor: '#7fc7d9', // Average color of the background image.
        backgroundPosition: 'center',
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: 'none' }}
        src={backgroundImage}
        alt="increase priority"
      />
      <Typography 
        align="left" 
        color= "RGB(113,3,3)"
        // marked="center"
        sx={{fontFamily: "arial", fontSize: `${titleFontSize}`, fontWeight: "normal}"}}>
        {currentSchool.name1}
      </Typography>

      <Typography
        align="left"
        // color= "RGB(113,3,3)"
        color= "black"
        fontWeight= "bolder"
        fontSize="x-large"
       
        
        // variant="h5"
        //sx={{ fontFamily: "arial", mb: 30, ml: "0%", mt: { xs: 2, sm: 1 }, textDecorationLine: "underline" }}
        sx={{ fontFamily: "arial", mb: 30, ml: "0%", mt: { xs: 2, sm: 1 }, }}
        // sx={{ fontFamily: "arial", textDecoration: "underline", mb: 30, ml: "0%", mt: { xs: 2, sm: 1 }, }}
      >
        {currentSchool.name2}
      </Typography>
    </ProductHeroLayout>
  );
}
