// Import libraries

import Media from "./environment/Media.js"

// Import CSS, images & icons


export default function AircraftHeader(props) {
    if (Media() === "") {
        return (
                <div className = "aircraft-header" id = "registration" name = "registration" onChange={e => props.TechLogHandleChange(e.target)}>{props.registration}</div>
        );
    }
}