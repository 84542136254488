// Import libraries
import { TextField, Box } from "@mui/material"

// Import CSS, images & icons
import map from "../images/map.png";
import "./Arrival.css";


export default function ArrivalTable1Navigation(props) {
    let excludedForAircraftType = ["SIM"];
    if (excludedForAircraftType.indexOf(props.submission.data.planeType.value) === -1) {

        return(
            <div className="arrival-table1-navigation">
                    <table>
                        <tbody>
                            <tr>
                                <td rowSpan = "1"><img className = "map" src = {map} alt = "Map"></img></td>
                            </tr>
                            <tr>
                                <td className = "label" rowSpan = "2">Route</td>
                                <td className = "label">From*</td>
                                <td className = "label">To*</td>
                            </tr>
                            <tr>
                                <td className = "input" STYLE = {props.submission.data.fromAirfield.background}>
                                    <TextField 
                                        value={props.submission.data.fromAirfield.value.toUpperCase()} 
                                        onChange={e => props.TechLogHandleChange(e.target)} 
                                        error = {props.submission.data.fromAirfield.status === "error"} 
                                        variant = {props.submission.data.fromAirfield.variant} 
                                        fullWidth 
                                        type = "text" 
                                        name = "fromAirfield" 
                                        id = "fromAirfield">
                                    </TextField>
                                </td>
                                <td className = "input" STYLE = {props.submission.data.toAirfield.background}>
                                    <TextField 
                                        value={props.submission.data.toAirfield.value.toUpperCase()} 
                                        onChange={e => props.TechLogHandleChange(e.target)} 
                                        error = {props.submission.data.toAirfield.status === "error"} 
                                        variant = {props.submission.data.toAirfield.variant} 
                                        fullWidth 
                                        type = "text" 
                                        name = "toAirfield" 
                                        id = "toAirfield">
                                    </TextField>
                                </td>
                            </tr>
                            <tr>
                                <td className = "note" colSpan = "4"><span >* Mandatory Fields</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
        );
    }
}