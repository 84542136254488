// *************** IMPORT LIBRARIES *****************

import _ from "lodash";



// ************** MAIN FUNCTION *****************

function PersonFormReducer(personSubmission, action) {
        // console.log("In PersonFormReducer, action->", action);
        // console.log("In PersonFormReducer, personSubmission->", personSubmission);



    switch (action.type) {   

        case 'addRoles':
                // console.log("In PersonFormReducer, pre-addRoles, action->", action)
                // console.log("In PersonFormReducer, pre-addRoles, personSubmission->", personSubmission)
            if (personSubmission && action.value.person) {
                Object.keys(personSubmission).forEach((person) => {
                    if (action.value.person.name === person) {
                        personSubmission[person].roles = _.cloneDeep(action.value.roles)
                            // console.log("In PersonFormReducer, personSubmission[person].roles->", personSubmission[person].roles)
                    }
                })
            }
                // console.log("In PersonFormReducer, post-addRoles, personSubmission->", personSubmission)
            return {...personSubmission}

        case 'mergeNames':
            let tempObject= {};
            action.value.forEach((person) => {
                let name = person.firstName + " " + person.lastName;
                person.name = name;
                tempObject[name] = person;
            })
                // console.log("In NewPersonFormReducer, tempObject->", tempObject)
            personSubmission = _.cloneDeep(tempObject);
                // console.log("In NewPersonFormReducer, newPersonSubmission->", newPersonSubmission)
            return {...personSubmission}

        case 'update':
            if (personSubmission) {
                    // console.log("In PersonFormReducer, update, personSubmission->", personSubmission);
                    // console.log("In PersonFormReducer, update, action->", action);
                Object.keys(personSubmission).forEach((person) => {
                        // console.log("In PersonFormReducer, update, person->", person);
                    if (action.value.person.name === person) {
                        // console.log("In PersonFormReducer, update, person->", person);
                        // let roles = _.cloneDeep(action.value.person.roles);
                        // personSubmission[person].roles = _.cloneDeep(action.value.person.roles);

                        personSubmission[person].roles[action.value.roleForUpdate].status = _.cloneDeep(action.value.requestedValue);
                           
                        // Object.keys(action.value.person.roles).forEach((role) => {
                        //     if (role === action.value.roleForUpdate) {
                        //             console.log("In PersonFormReducer, in loopII, action.value.requestedValue->", action.value.requestedValue);
                        //         personSubmission[person].roles[role].status = _.cloneDeep(action.value.requestedValue);
                        //     } else {
                        //         personSubmission[person].roles[role].status = _.cloneDeep(action.value.person.roles[role].status)
                        //     }
                        // })
                    }
                })
            }
                // console.log("In PersonFormReducer, Update personSubmission->", personSubmission)
            return {...personSubmission}
        
        default: 
            console.log("Unknown action updating Person Submission")
            }   
};

export default PersonFormReducer;