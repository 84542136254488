//Import libraries
import React, { useEffect, useState } from 'react';
import { Box, Container } from "@mui/system";
import CourseTile from "./CourseTile";
import { useNavigate } from 'react-router-dom';
import BannerTitle from '../flights/techLog/banner/BannerTitle';
import CourseRadioButtons from './CourseRadioButtons';
import { useAuthFirebase } from '../utilities/security/AuthContextFirebase';
import { GetUserRoles } from '../utilities/security/AuthUtilities';
import { GetPerson } from '../utilities/database/GetPerson';
import Feedback from '../utilities/feedback/Feedback';
import { GetEnrollments } from '../utilities/database/GetEnrollments';
import FeedbackWithInput from '../utilities/feedback/FeedbackWithInput';
import { DatabaseConnectionStatus } from '../utilities/database/DatabaseConnectionStatus.js';
import { databaseConnectionMessages } from '../utilities/database/DatabaseConnectionMessages';

import _ from "lodash";


// Import CSS, images, icons & static data
import "./CourseSelection.css"
import "../flights/techLog/banner/Banner.css"
import { GetCourseData, courseData } from '../utilities/database/GetCourseData';
import { courseValidationMessages } from '../utilities/validation/CourseValidationMessages';
// import { co } from '@fullcalendar/core/internal-common';


// Import CSS, Static Data, Colours and Fonts
import { rolesTemplate } from '../utilities/database/GetRolePermissions';


//**************************** MAIN FUNCTION ***************************** */
function CourseSelection(props) {
    let uniqueCourses = [];
    const [courseStaticList, setCourseStatic] = useState(courseData); 
    const {authUser, loading, signInWithGoogle, signOut} = useAuthFirebase();
    const [userId, setUserId] = useState();
    const [currentUserRoles, setCurrentUserRoles] = useState();
    const [enrollments, setEnrollments] = useState([]);
    let userRoles = null;
    let responseObject = {};
    let totalEnrollments = 0;
    // let chosenRole
    const [courseList, setCourseList] = useState([]); 
    let courseSelection = "Courses"
    const [highestUserRole, setHighestUserRole] = useState("student")
    const [chosenRole, setChosenRole] = useState("student")
    // const HandleChange = (event) => {setHighestUserRole(event.target.value)};
    const [databaseConnection, setDatabaseConnection] = useState(true);
    const [databaseConnectionStatus, setDatabaseConnectionStatus] = useState({overallStatus: true,}); 
    const [snackBarStatus, setSnackBarStatus] = useState(false);
    const [dialogueStatus, setDialogueStatus] = useState(false);
    const [dialogueResponse, setDialogueResponse] = useState("dialogueCancel"); 
    const [dialogueStatusApproval, setDialogueStatusApproval] = useState(false);
    const [result, setResult] = useState({status: "", message: "" })

    // Initialise the most severe issue container 
    let mostSevereIssue = {
        status: "success",
        message: courseValidationMessages.successMessages.update,
        field1: "",
        field2: "",
        field3: "",
        field4: ""
    };

    // ********************* Snackbar & Dialogue Update functions ***************************
    function UpdateSnackBarStatus (newStatus) {
            // console.log("In TechLog, UpdateSnackBarStatus, newStatus->", newStatus);
        setSnackBarStatus(newStatus);
            // console.log("In TechLog, UpdateSnackBarStatus, snackBarStatus->", snackBarStatus);
    }
    function UpdateDialogueStatus (newStatus) {
            // console.log("In TechLog, UpdateDialogueStatus, newStatus->", newStatus);
        setDialogueStatus(newStatus);
            // console.log("In TechLog, UpdateDialogueStatus, DialogueStatus->", DialogueStatus);
    }
    // function UpdateDialogueStatusApproval (newStatus) {
    // // console.log("In TechLog, UpdateDialogueStatus, newStatus->", newStatus);
    //     setDialogueStatusApproval(newStatus);
    // // console.log("In TechLog, UpdateDialogueStatus, DialogueStatus->", DialogueStatus);
    // }

    // function UpdateDialogueResponse (response) {
    //     // console.log("In TechLog, UpdateDialogueResponse, response->", response);
    // setDialogueResponse(response);
    //     // console.log("In TechLog, UpdateDialogueResponse, DialogueStatus->", dialogueResponse);
    // }

    function UpdateDatabaseConnection (response) {
        // console.log("In TechLog, UpdateDialogueResponse, response->", response);
        setDatabaseConnection(response);
        // console.log("In TechLog, UpdateDialogueResponse, DialogueStatus->", dialogueResponse);
    }
    
    function UpdateResult (newResult) {
        // console.log("In TechLog, UpdateResult, newResult -> ", newResult)
        if (newResult !== undefined) {
            setResult(newResult);
        }
            // console.log("In TechLog, UpdateResult, result -> ", result)
    }

    function HandleChange(event) {
        setChosenRole(event.target.value);
            // console.log("In CourseSelection, chosenRole->", chosenRole);
    }

    useEffect(() => { 
        const GetUserId = async (email) => {
            responseObject = await GetPerson(email);
                // console.log("In CourseProgress, responseObject", responseObject);
            if (responseObject.databaseConnection) {
                let id = _.cloneDeep(responseObject.data.id);
                setUserId(id);
                    // console.log("In CourseProgress, GetPerson, id->", id);
                return (id);
            } else {
                props.UpdateDatabaseConnection(responseObject.databaseConnection)
            }
        }
            
        if (authUser !== null && authUser !== undefined) {
                // console.log("In CourseProgress, props.authUser->", authUser);
            let email = authUser.email;
                // console.log("In CourseProgress, mail->", props.authUser.email);
            GetUserId(email);
                // console.log("In CourseProgress, userId->", userId);
                // console.log("In CourseProgress, GetUserId, id->", id);
        }
    },[authUser])

   async function UpdateRoles() {
       
        responseObject = await GetUserRoles();
        if (responseObject.databaseConnection) {
            setCurrentUserRoles(responseObject.data);
            UpdateDatabaseConnection(responseObject.databaseConnection);
            userRoles = _.cloneDeep(responseObject.data);
            return (
                userRoles
            );
        } else {
            UpdateDatabaseConnection(responseObject.databaseConnection);
            UpdateResult(responseObject.data);
        }
    }

    useEffect(() => {
        const RefreshRoles = async () => {
            setCurrentUserRoles(await UpdateRoles());
        }
        RefreshRoles();
    }, [authUser])

    // UPDATE ENROLLMENTS BASED ON USER ID & CHOSEN ROLE
    useEffect(() => {
        const GetUserEnrollments = async (chosenRole, id) => {
                // console.log("In CourseProgress, GetEnrollments, id->", id);
        
            responseObject = await GetEnrollments(chosenRole, id)
                if (responseObject.databaseConnection) { 
                    setEnrollments(responseObject.data);
                } else {
                    props.UpdateDatabaseConnection(responseObject.databaseConnection);
                }
            }
        
        if (userId !== null && userId !== undefined) {
            // if (typeof id.then !== "function") {
                    // console.log("In CourseProgress, GetEnrollments, id->", id);
                    // console.log("In CourseProgress, GetEnrollments, userId->", userId);
                switch(chosenRole) {
                    case "student":
                    case "instructor":
                        GetUserEnrollments(chosenRole, userId);
                            // console.log("In CourseProgress, responseObject.data ->", responseObject.data);
                            // totalEnrollments = responseObject.data.length;
                    break;
                    case "admin":
                        GetUserEnrollments(chosenRole);
                            // console.log("In CourseProgress, responseObject.data ->", responseObject.data);
                            if (responseObject.databaseConnection) {
                                totalEnrollments = responseObject.data.length;
                            }
                // }
            }
        }
    }, [authUser, chosenRole])

    function RenderCourseRadioButtons() {
            // console.log("In RenderCourseRadioButtons, userRole->", userRoles);
            // console.log("In RenderCourseRadioButtons, currentUserRole->", currentUserRoles);
        if (currentUserRoles && (typeof currentUserRoles.then !== "function")) {
                // console.log("In RenderCourseRadioButtons, currentUserRole->", currentUserRoles);
            return (
                <CourseRadioButtons currentUserRoles = {currentUserRoles} HandleChange = {HandleChange} chosenRole = {chosenRole}/>
            );
        } else {
            return (
                <Box></Box>
            );
        }

    }


    async function CreateCourseList() {

        let responseObject = {}
        responseObject = await GetCourseData();
        if (responseObject.databaseConnection) {
            let courses = responseObject.data;
                // console.log("In CourseSelection, courseList I->", courses);

            if (courses.length !== 0) {

                let statusPriority = {
                    APPROVED: 4,
                    DRAFT: 3,
                    DEPRECATED: 2,
                    DELETED: 1
                }

                uniqueCourses = courses.reduce((accumulator, current) => {
                    // courses.reduce((accumulator, current) => {
                        const existing = accumulator.find(course => course.name === current.name);
                    
                        if (!existing || statusPriority[current.status] > statusPriority[existing.status]) {
                        // If the course does not exist, or the current course has a higher priority, replace it
                        return accumulator.filter(course => course.name !== current.name).concat(current);
                        }
                    
                        // Otherwise, keep the existing one
                        return accumulator;
                    }, []);

                    // console.log("In CourseSelection, uniqueCourses I->", uniqueCourses);

            } else {
                    // console.log("In CourseSelection, uniqueCourses II->", uniqueCourses);
                uniqueCourses = []
            }
        } else {
            // setUniqueCourses([])
            uniqueCourses = []
            // console.log("In CourseSelection, uniqueCourses III->", uniqueCourses);
        }

            // console.log("In CourseSelection, uniqueCourses IV->", uniqueCourses);
        return uniqueCourses
    }

    useEffect(() => {
        const GetCourses = async () => {
            setCourseList(await CreateCourseList());
                // console.log("In CourseSelection, courseList II ->", courseList);
        }
        GetCourses();
    }, [])    



    // console.log("In CourseSelection, courseList III ->", courseList);

    const HandleContinue = async () => {
        // Attempt to write the Approval to the database

        var responseObject = {};
        // responseObject = await PostCourseStatus(courseSubmission.id.value, "DELETED") 

        if (!responseObject.databaseConnection) { 
            UpdateDatabaseConnection(DatabaseConnectionStatus(databaseConnectionStatus, "deleteCourse", responseObject.databaseConnection))
                // console.log("In CourseAdmin databaseConnectionContext->", databaseConnectionStatus); 
            // UpdateDatabaseConnection(responseObject.databaseConnection);
            mostSevereIssue = {
                status: "error",
                message: databaseConnectionMessages.error,
                field1: ""
            } 
        } else  {        
            mostSevereIssue = {
                status: "success",
                message: courseValidationMessages.successMessages.delete,
                field1: ""
            } 

            // HandleReset();
            // UpdateEditingSwitch(false);
            // UpdateEditing(false);
            // UpdateEditingSwitch(false);
            // UpdateIsEdited(false);
            // GetCourses();
            // UpdateSelectedCourse("reset");
            UpdateResult(mostSevereIssue)

            // FeedbackTrigger(mostSevereIssue);
        }
    }

    function RenderCourseTiles() {

        // let courseList = []
        // courseList = await CreateCourseList();

        let courseTilesList = []
            // console.log("In RenderCourseTiles, courseList->", courseList);
            // console.log("In RenderCourseTiles, courseStaticList->", courseStaticList);
        if (courseStaticList.length !== 0) {
            courseStaticList.forEach((courseStatic) => {
                courseStatic.tileDisabled = true;
                if (courseList.length !== 0) {
                    // if (courseStatic.name === "Multi-Engine Piston Rating") {
                    //     // console.log("In RenderCourseTiles, courseList III->", courseList)
                    // }
                    courseList.forEach((course) => {
                        // console.log("In CourseSelection, course->", course.abbreviation,"courseStatic->", courseStatic.abbreviation);
                        // console.log("In CourseSelection, courseStatic->", courseStatic);
                        if(course.abbreviation === courseStatic.abbreviation) {
                                // console.log("In CourseSelection, courseStatic->", courseStatic);
                            courseStatic.tileDisabled = false;
                        } else {
                            courseStatic.tileDisabled = true;
                        }
                    })
                    // uniqueCourses.forEach((course) => {
                    //     if (courseStatic.name === course.name) {
                    //         courseStatic = _.cloneDeep(course);
                    //             // console.log("In RenderCourseTiles, courseStatic->", courseStatic)
                    //     }
                    // })
                }
                if (courseStatic.hasCurriculum) {
                    courseTilesList.push(
                        <CourseTile 
                            courseStatic = {courseStatic} 
                            key = {courseStatic.name} 
                            chosenRole = {props.chosenRole}
                            authUser = {authUser}
                            userId = {userId}
                            enrollments = {enrollments}
                            UpdateDatabaseConnection = {UpdateDatabaseConnection}
                            handleNavigate = {handleNavigate}/>
                    )
                }
            });
        }
        return (courseTilesList);
    }
        // console.log("In RenderCourseTiles, courseBoxes->", courseTiles)

 
    const navigate = useNavigate();
    const handleNavigate = (path) => {
            // console.log("In CourseSelection, handleNavigate, path->", path);   
        navigate(path);
    }

        // console.log("In CourseSelection, courseRadioButtons->", courseRadioButtons);

        // console.log("In CourseProgress, userId->", userId);

    return (
        <Box>
            <Box className = "course-selection-title-row">
                <Box className = "course-selection-title">
                    <BannerTitle title = {courseSelection}/>
                </Box>
                <Box className = "course-selection-radio">
                    <RenderCourseRadioButtons/>
                </Box>
                {/* <Box sx = {{color: "red", ml: "10px", fontSize: "large"}}>
                    UNDER CONSTRUCTION
                </Box> */}
            </Box>
            <Box className = "courses-page">
                <Box className = "courses-frame" sx = {{ backgroundColor: "white"}}>
                    {/* {courseTiles} */}
                    <RenderCourseTiles
                        chosenRole = {chosenRole}
                        authUser = {authUser}
                        userId = {userId}
                        enrollments = {enrollments}
                        UpdateDatabaseConnection = {UpdateDatabaseConnection}
                    />
                </Box>
            </Box>
            <Box className = "feedback" sx={{zIndex: "6", position: "sticky", left: "0px", bottom: "80px", margin: "80px"}}>
                {/* <FeedbackWithInput
                    HandleContinueApproval = {HandleContinueApproval}
                    dialogueStatusApproval = {dialogueStatusApproval}
                    result = {result}
                /> */}
                <Feedback  
                        HandleContinue = {HandleContinue} 
                        dialogueStatus = {dialogueStatus} 
                        UpdateDialogueStatus = {UpdateDialogueStatus} 
                        snackBarStatus = {snackBarStatus} 
                        UpdateSnackBarStatus = {UpdateSnackBarStatus} 
                        result = {result}/> 
            </Box>
        </Box>
    );


};

export {CourseSelection}