import axios from 'axios';
import { GetAuthToken } from './AuthUtilities';

const axiosInstance = axios.create();

// Set up a global request interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    const token = await GetAuthToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }


    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;