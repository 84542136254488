// Import libraries
import * as React from 'react';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import CamoLogDataGrid from './CamoLogDataGrid.js';
// import axios from 'axios';
import axiosInstance from '../../utilities/security/AxiosConfig.js';
import { CreateAircraftList } from '../../aircraft/CreateAircraftList.js';
import { defaultCamoLogLists } from '../../flights/flightData/FlightData.js'
import { defaultServiceEventList } from '../../aircraft/AircraftData.js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// import CreateFlightList from "../flights/flightData/Flight.js";
import Banner from '../../flights/techLog/banner/Banner.js';
import { CreateCamoLogRow } from "./CreateCamoLogRow.js";
import { GetAircraftData } from '../../utilities/database/GetAircraftData.js';
import { MaintenanceChipsBody  } from '../../utilities/maintenance/MaintenanceChips.js';

// Set-up Date Localisation Provider
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { defaultAircraftList } from "../../aircraft/AircraftData.js";
import { useMediaQuery } from '@mui/material';

// Import CSS, images, icons & static data\
import "./CamoLog.css";
import { API_URL } from '../../../config/config.js';
import { mobileThresholdWide } from '../../utilities/environment/EnvironmentData.js';
import { camoLogColumnGroups } from './CamoLogColumnGroupingModel.js';
import { camoLogColumns } from './CamoLogColumns.js';


import azTechTheme from '../../utilities/environment/ColourTheme.js';



// ******************************* MAIN FUNCTION ************************************************
function CamoLog(props, {children}) {
  const title = "CAMO Log";
  const aircraftSelectionKey = 0
  const [aircraftList, setAircraftList] = useState(defaultAircraftList);
  const [selectedAircraft, setSelectedAircraft] = useState(aircraftList[aircraftSelectionKey]);
  const [serviceEventList, setServiceEventList] = useState([]);
  const [camoLogLists, setCamoLogLists] = useState({daily:[],weekly:[], monthly:[]});
  const [filteredCamoLogLists, setFilteredCamoLogLists] = useState({daily:[],weekly:[], monthly:[]});
  const [databaseConnection, setDatabaseConnection] = useState(true);
  const [registration, setRegistration] = useState(selectedAircraft._registration);
  const [camoLogPeriod, setCamoLogPeriod] = useState('daily');
  const calledFrom = "CamoLog";
  

 
  dayjs.extend(utc);
  
  // Manage mobile vs desktop rendering
  var media = useMediaQuery(`(min-width:${mobileThresholdWide}px)`);

  //************************************** AIRCRAFT SELECTION ******************************
  function UpdateSelection (selection, planeList) {
    setRegistration(selection);
        // console.log(planeList);
    if (planeList != null) {
      planeList.forEach((plane) => {
        // console.log(plane);
        if (plane._registration === selection) {
            // console.log(selection);
          setSelectedAircraft(plane);
        }
      });
    }
  }

  // Manage Data Lists by registration 
  function UpdateCamoLogLists (registration, camoLogLists) {
    let list = {};
    let daily = [];
    let weekly = [];
    let monthly = [];
      // console.log("In CamoLog, UpdateCamoLogLists, comoLogLists->", camoLogLists)
    if (camoLogLists.daily.length !== 0) {
      daily = camoLogLists.daily.filter((row) => row.registration === registration);
    }
    if (camoLogLists.weekly.length !== 0) {
      weekly = camoLogLists.weekly.filter((row) => row.registration === registration);
    }
    if (camoLogLists.weekly.length !== 0) {
      monthly = camoLogLists.monthly.filter((row) => row.registration === registration);
    }
      list = {
        daily: daily, 
        weekly: weekly,
        monthly: monthly
      };
    
    setFilteredCamoLogLists(list);
      // console.log("In CamoLog, post UpdateCamoLogLists, filteredCamoLogLists->", filteredCamoLogLists);
  }

   

  // Update aircraft selection based on user choice of registration
  useEffect(() => {
      // console.log("UE-pre", registration, aircraftList);
    UpdateCamoLogLists(registration, camoLogLists);
    UpdateSelection(registration, aircraftList);
      // console.log("UE-post", registration, aircraftList)
  }, [registration, aircraftList, camoLogLists]);

  //************************ RETRIEVE AIRCRAFT LIST AND SERVICE EVENT DATA FROM DATABASE *******************

  const PlaneData = async () => {
    let responseObject = await GetAircraftData()
        // console.log("In CamoLog, responseObject->", responseObject);
    if (responseObject.databaseConnection) {
        UpdateSelection(responseObject.data.aircraftList[0]._registration, responseObject.data.aircraftList);
        setDatabaseConnection(responseObject.databaseConnection);
        setAircraftList(responseObject.data.aircraftList);
        setServiceEventList(responseObject.data.serviceEventList)

    }
}

useEffect(() => {

    PlaneData();
        
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [databaseConnection]);

  //*********************************** RETRIEVE AGGREGATED FLIGHTS FOR SELECTED PLANE FROM DATABASE *************************
  useEffect(() => {
    // let list = [];
    const GetCamoLogLists =  async () => {
        try {
            // Modify to retrieve flights by selectedAircraft.registration
            if (registration !== "PLANE") {
                // console.log("In FLightLog, GetFlightList, API_URL ->", `${API_URL}/flights/closedFlights?registration=${registration}`)
              const response = await axiosInstance.get(`${API_URL}/flights/camolog`);
              let list = response.data;
                // console.log("In FlightLog, list success -> ", list)
              // setCamoLogLists({});
              setCamoLogLists(list);
                // console.error('In CamoLog GetCamoLogLists response.data->', response.data);
            }
        } catch (error) {
              // console.error('Error fetching camo log rows', error);
 
            setCamoLogLists(defaultCamoLogLists)
              // console.log("In CamoLog, GetCamoLists, CamoLists -> ", camoLogLists)
            setDatabaseConnection(false);
        }
    }
    GetCamoLogLists()

  }, [registration, aircraftList]);

//*********************************** DEFINE TABLE *******************************************************

    // console.log("in CamoLog, filteredCamoLogLists->", filteredCamoLogLists)
  let camoLogRows = {
    daily:[],
    weekly:[],
    monthly:[]
  };
  if (filteredCamoLogLists.length !== 0) {
    filteredCamoLogLists.daily.forEach((row) => {
      camoLogRows.daily.push(CreateCamoLogRow(row, "daily"))
    });
    filteredCamoLogLists.weekly.forEach((row) => {
      camoLogRows.weekly.push(CreateCamoLogRow(row, "weekly"))
    });
    filteredCamoLogLists.monthly.forEach((row) => {
      camoLogRows.monthly.push(CreateCamoLogRow(row, "monthly"))
    });
  }

    // console.log("In FlightLog, after retrieval of Flights, flightList -> ", flightList)
    // console.log("In CamoLog, rows->", rows);

  const handleChange = (event) => {
    setCamoLogPeriod(event.target.value)
  };
  


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {children}
      <meta name="viewport" content="initial-scale=1, width=device-width" />
      <Box>
        <Banner 
                title = {title} 
                databaseConnection = {databaseConnection} 
                aircraftList={aircraftList} 
                selectedAircraft={selectedAircraft} 
                registration={registration} 
                UpdateSelection={UpdateSelection}
                calledFrom = {calledFrom}
                handleChange = {handleChange}
                camoLogPeriod = {camoLogPeriod}
                />
      </Box>
      <Box> 
        <MaintenanceChipsBody selectedAircraft = {selectedAircraft}/>
        <CamoLogDataGrid media = {media} camoLogPeriod = {camoLogPeriod} camoLogRows = {camoLogRows} camoLogColumns = {camoLogColumns} camoLogColumnGroups = {camoLogColumnGroups} />
      </Box>
    </LocalizationProvider>
  );
};

export default CamoLog;