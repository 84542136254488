


let rolesTemplate = {
    admin:
    {
        claimName: "role_admin",
        status: false,
        menuItems: [
            "home", 
            "techLog", 
            "flightLog", "camoLog", "pilotLog", 
            "bookings", 
            "courseSelection", "lessonReports", 
            "adminAdmin", "adminCourse", "adminAircraft", "adminPerson", 
            "login"] 
    },
    schoolAdmin:
    {
        claimName: "role_schoolAdmin",
        status: false,
        menuItems: [
            "home", 
            "techLog", 
            "flightLog", "camoLog", "pilotLog", 
            
            "lessons", "lessonReports", 
            "adminAdmin", "adminCourse", "adminAircraft", "adminPerson", 
            "login"]
    },
    instructor:
    {
        claimName: "role_instructor",
        status: false,
        menuItems: [
            "home", 
            "techLog", 
            "flightLog","pilotLog", 
            
         
            // "lessons", "lessonReports", 
            "login"]
    },
    pilot:
    {
        claimName: "role_pilot",
        status: false,
        menuItems: [
            "home", 
            "techLog", 
            "flightLog",
            "pilotLog", 
          
            "lessonReports", 
            "login"]
    },
    guest:
    {
        claimName: "role_guest",
        status: true,
        menuItems: [
            "home", 
            "login"]
    }
}

let emptyPersonSubmission = [
    {
        name:"",
        firstName: "",
        lastName: "",
        email:"",
        acknowledged: false,
        acknowledgedDate: new Date(),
        roles: rolesTemplate
    }
]

let emptyNewPersonSubmission = [
    {
        name: "",
        firstName: "",
        lastName: "",
        email: "",
        acknowledged: false,
        acknowledgedDate: new Date(),
        roles: rolesTemplate
    }
]



export {rolesTemplate, emptyPersonSubmission, emptyNewPersonSubmission}