// Import libraries
import { emptyCourseSubmission } from "../../admin/course/CourseData";
import _ from "lodash";
import { API_URL } from "../../../config/config";
import axiosInstance from "../security/AxiosConfig";



// ************************* DATABASE FUNCTION - UPDATE AIRCRAFT *******************************
const PostCourse = async (courseSubmission) => {

    let responseObject = {};
    let databaseSubmission = {};
    let tempLesson = {};
    let tempApproval = {};

    try {
        databaseSubmission = _.cloneDeep(emptyCourseSubmission);
        databaseSubmission.lessons = [];
        // console.log("In PostCourse, courseSubmission->", courseSubmission);
        Object.keys(courseSubmission).forEach((field) => {
                // console.log("In PostCourse, field->", field);
            switch(field) {
                case "approvals":
                break;
                case "lessons":
                        // console.log("In PostCourse, courseSubmission[field]", courseSubmission[field]);
                    courseSubmission[field].forEach((lesson) => {
                        // if () {
                                // console.log("In PostCourse, lesson", lesson);
                            Object.keys(lesson).forEach((lessonField) => {
                                    // console.log("In PostCourse, lesson", lesson);
                                    // console.log("In PostCourse, lessonField", lessonField);
                                switch(lessonField) {
                                    case "displayNumber":
                                    case "id":
                                    case "courseId":
                                    case "section":
                                            // console.log("In PostCourse, lesson[lessonField]", lesson[lessonField]);
                                            // console.log("In PostCourse, tempLesson", tempLesson);
                                        tempLesson[lessonField] = lesson[lessonField]
                                            // console.log("In PostCourse, tempLesson[lessonField]", tempLesson[lessonField]);
                                    break;
                                    case "minimumHours":
                                        tempLesson[lessonField] = Number(lesson[lessonField].value);
                                            console.log("In PostCourse, Number(lesson[lessonField].value)", Number(lesson[lessonField].value));
                                    break;
                                    default:
                                            // console.log("In PostCourse, lesson[lessonField].value", lesson[lessonField].value);
                                        tempLesson[lessonField] = lesson[lessonField].value
                                    break
                                }
                                // tempLesson[lessonField] = courseSubmission.lessons[lesson][lessonField].value;
                            })
                            databaseSubmission.lessons.push(tempLesson);
                            tempLesson = _.cloneDeep({});
                                // console.log("In PostCourse, tempLesson", tempLesson);
                        // }
                    })
                break;
                case "courseIcon":
                case "courseImage":
                    databaseSubmission[field] = _.cloneDeep(courseSubmission[field]);
                break;
                case "minimumHoursToComplete":
                    databaseSubmission[field] = Number(_.cloneDeep(courseSubmission[field].value));
                break;
                default:      
                    databaseSubmission[field] = _.cloneDeep(courseSubmission[field].value);
            }
        });
            // console.log("In SubmitCourse, databaseSubmission -> ", databaseSubmission)
        const response = await axiosInstance.post(`${API_URL}/courses/update-course`, databaseSubmission)
            // console.log("In submitAircraft, response.status->", response.status)
        responseObject.databaseConnection = true;
        responseObject.data = response.data;
        return responseObject;
    } catch(error) {
        console.error("Error submitting a Course", error)
        responseObject.databaseConnection = false;
        responseObject.data = "error";
        return responseObject;
    }
}

export{PostCourse}