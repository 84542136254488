//Import libraries
import * as React from 'react';
import { useEffect, useState } from 'react';
import {useMediaQuery} from '@mui/material';
import { Box } from '@mui/system';
import MenuDesktop from './MenuDesktop.js';
import MenuMobile from './MenuMobile.js'
import { useAuthFirebase } from '../utilities/security/AuthContextFirebase.js';
import { GetUserRoles } from '../utilities/security/AuthUtilities.js';
import { Progress } from '../utilities/feedback/Progress.js';
import Feedback from '../utilities/feedback/Feedback.js';
import _ from "lodash";

//Import CSS, images, icons, static data & fonts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { mobileThreshold } from '../utilities/environment/EnvironmentData.js';
import { rolesTemplate } from '../utilities/database/GetRolePermissions.js';




// ******************************* MAIN FUNCTION ************************************************
export default function MainMenu() {


  // ***************** SECURITY - CURRENT UER CONTEXT AND ROLE **********************
  const [currentUser, setCurrentUser] = useState();
  const [snackBarStatus, setSnackBarStatus] = useState(false);
  const [databaseConnection, setDatabaseConnection] = useState(false);
  const [result, setResult] = useState({status: "", message: "" });
  const {authUser, loading, signInWithGoogle, signOut} = useAuthFirebase();
  var guestRoles = _.cloneDeep(rolesTemplate);
  var calledFrom = "MainMenu";
    // console.log("In MainMenu, post deep clone, guestRoles->", guestRoles, "rolesTemplate->", rolesTemplate);
  const [currentUserRoles, setCurrentUserRoles] = useState(guestRoles);
  

    // **************** DATABASE CONNECTION FACILITIES *********************


  // ***************** FEEDBACK MANAGEMENT ******************************************
  function UpdateSnackBarStatus(newStatus) {
    setSnackBarStatus(newStatus);
  }

  function UpdateResult(newStatus) {
    setResult(newStatus);
  }

  function UpdateDatabaseConnection (newStatus) {
    setDatabaseConnection(newStatus);
}

  // ***************** SECURITY - UPDATE FUNCTIONS **********************************
  useEffect (() => {
    UpdateUser();
  }, [authUser]); 

    // console.log("In MainMenu, currentUser->", currentUser);

  const UpdateRoles = async (mode) => {
 
    if (mode === "reset") {
      guestRoles = _.cloneDeep(rolesTemplate);
      setCurrentUserRoles(guestRoles);
    } else {
        var responseObject = await GetUserRoles(authUser);
        setCurrentUserRoles(responseObject.data);
    }
  }

  const UpdateUser = async () => {
    authUser ? setCurrentUser(authUser.displayName) : setCurrentUser()
     
    // setCurrentUser(user);
    await UpdateRoles();
    // RenderMenu();
  
  }

  const ResetUser = async () => {
    setCurrentUser();
    await UpdateRoles("reset");
  }

  // ***********************************************************************

  // var media = useMediaQuery('(min-width:800px)')
  var media = useMediaQuery(`(min-width:${mobileThreshold}px)`)
 
      // console.log("In MainMenu, calledFrom->", calledFrom)
    if (media) {
      return (
        <Box>
          <MenuDesktop 
            currentUser = {currentUser} 
            currentUserRoles = {currentUserRoles} 
            UpdateResult = {UpdateResult}
            UpdateSnackBarStatus = {UpdateSnackBarStatus}
            UpdateDatabaseConnection = {UpdateDatabaseConnection}
            UpdateUser = {UpdateUser} 
            ResetUser = {ResetUser}/>
  
        </Box> 
      );
    } else {
      return (
        <Box>
          <MenuMobile 
          currentUser = {currentUser} 
          currentUserRoles = {currentUserRoles} 
          UpdateUser = {UpdateUser} 
          ResetUser = {ResetUser}
          />
        </Box>
      );
    }
};