// Import libraries
import {Button, Box} from "@mui/material";
import React from "react";

// Import CSS & images
import "../TechLog.css";
import { successMessages } from "../../../utilities/validation/AircraftValidations";

// ******************************* MAIN FUNCTION ************************************************
export default function ClearButton(props) {


    // SnackBar Close

    function handleClick() {
            // console.log("In ClearButton, ClearButton pressed");
        if (props.submission.status === "OPEN") {
            // props.TechLogDelete();
        }
        props.TechLogHandleClear();
        let result = {status: "info", message: successMessages.clear}
            // console.log("In ClearButton, submission -> ", props.submission);
        props.UpdateResult(result)
        props.UpdateSnackBarStatus(true);
            // console.log("In ClearButton, props.SnackBarStatus -> ", props.snackBarStatus);
    }

    // console.log("In ClearButton, props.result.validationStatus ->", props.result.validationStatus)

    if (props.title === "Tech Log") {
        return (
            <Box  sx = {{maxWidth: "90px", m: "5px"}}>
                <Button size = "medium" variant = "contained" name = "clearButton" onClick = {handleClick}>CLEAR</Button>
            </Box>
        );
        };
};