//Import libraries
import { Box } from "@mui/system";
import React from 'react';
import { useMediaQuery } from '@mui/material';
import { GridToolbar } from "@mui/x-data-grid";
import Button from '@mui/material/Button';
import {
    GridRowModes,
    GridToolbarContainer,
  } from '@mui/x-data-grid';

// Import CSS, images, icons & static data

import AddIcon from '@mui/icons-material/Add';
import { mobileThreshold } from './environment/EnvironmentData.js';

// Import fonts

// ************************** CREATE ID FUNCTION ***********************************
export function CreateId(date) {
    var sortKeyHours = new Date(date).getHours() * 60;
    var sortKeyMinutes = new Date(date).getMinutes();
    var sortKeyTime = sortKeyHours + sortKeyMinutes;
    var id = ((date.valueOf()/100) + sortKeyTime) + Number(Math.random().toFixed(4))

    return id
}

// ************************** EDIT ROW TOOL BAR ***********************************
// const initialRows = [];

function EditToolbar(props) {
    const { setRows, setRowModesModel} = props;
    
    const handleClick = () => {
            // console.log("In FlightLogEditableGrid, e.target.value->",e);
        var today = new Date();
        today = today.setHours(0,0,0,0)
        var sortId = CreateId(today)
        var id = Math.floor(Math.random()*100000) + "new";

            // console.log("In FlightLogGrid, newRow->", newRow);
        let newRow = {}
        newRow.id = id;
        newRow.sortId = sortId;
        newRow.dateOfFlight = new Date(today);
            
        setRows((oldRows) => [{...newRow, isNew: true,}, ...oldRows]);
            // console.log("In FlightLogGrid, newRow->", newRow);
        
        setRowModesModel((oldModel) => ({
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'dateOfFlight' },
        ...oldModel
        }));

    };

        // console.log("In FlightLogGrid, currentUserRoles->", props.currentUserRoles);
    return (
        <GridToolbarContainer>
            {/* <Button color="primary" startIcon={<AddIcon />} onClick={handleClick} disabled>
                Add record
            </Button> */}
        </GridToolbarContainer>
    );
}   


//**************************** MAIN FUNCTION ***************************** */
export const CustomGridToolBar = (props) => {

    const { setRows, setRowModesModel} = props;

    var media = useMediaQuery(`(min-width:${mobileThreshold}px)`)

    if (media) {
        return (
            <Box sx = {{display: "flex", flexDirection: "row", justifySelf: "flexStart  "}}>
                 <GridToolbar/>
                <EditToolbar setRows = {setRows} setRowModesModel = {setRowModesModel}/>
            </Box>
        );
    } else {
        return (
            <Box>
                <EditToolbar setRows = {setRows} setRowModesModel = {setRowModesModel}/>
            </Box>
        );
    }
};