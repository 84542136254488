// Import libraries
import {Box, TextField, Button} from "@mui/material";
// import Checkbox from '@mui/material/Checkbox';

// Import CSS, images & icons
import "./AdminAircraft.css"
import { useEffect, useState } from "react";
// import { FixedSizeList } from 'react-window';
// import ListItem from '@mui/material/ListItem';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
import {useMediaQuery} from '@mui/material';
import CreateDefectDisplayList from "./CreateDefectDisplayList";
import { mobileThreshold } from "../../utilities/environment/EnvironmentData";


// ****************************** MAIN FUNCTION **********************************
export default function AdminAircraftDefects(props) {
    var editing = props.editing;
    var media = useMediaQuery(`(min-width:${mobileThreshold}px)`)
    const [defectClassName, setDefectClassName] = useState("admin-aircraft-defects-container")
    // const [defectListLength, setDefectListLength] = useState(props.selectedAircraft._defects.length)
    const [selectedAircraft, setSelectedAircraft] = useState(props.selectedAircraft);

        // console.log("In AminAircraftDefects, props.selectedAircraft._defects->", props.selectedAircraft._defects)

    // function AircraftHandleChange(e) {
    //     console.log("In AdminAircraftDefects, AircraftHandleChange, e->", e)

    // }

    useEffect(() => {
        if (media) {
            setDefectClassName("admin-aircraft-defects-container");
        } else {
            setDefectClassName("admin-aircraft-defects-container-mobile");
        }
    },[media]);
        // console.log("In AminAircraftDefects, props.selectedAircraft->", props.selectedAircraft)
        // console.log("In AdminAircraftDefects, props.aircraftSubmission->", props.aircraftSubmission)
    return (
        <Box className = {defectClassName} disabled = {props.editing === false}>
            <Box className = "admin-aircraft-subtitles">
                <Box>Open Defects</Box>
                <Box>Close</Box>
            </Box>
            <Box disabled = {props.editing === false}>
                <CreateDefectDisplayList 
                    editing = {props.editing} 
                    aircraftSubmission = {props.aircraftSubmission}
                    AircraftHandleChange = {props.AircraftHandleChange} 
                    selectedAircraft = {selectedAircraft} 
                    from = {"Admin"}/>
            </Box>

        </Box>
    );

}