// Import libraries
import {Box, TextField, Select, Chip} from "@mui/material";
// import { NumberInput } from "@mui/base/Unstable_NumberInput/NumberInput";
import { FormControl, MenuItem, InputLabel, FormHelperText } from "@mui/material";
import { useEffect, useState } from "react";
// import DaysRemaining from "../../utilities/maintenance/DaysRemaining";
// import HoursRemaining from "../../utilities/maintenance/HoursRemaining";
import dayjs from "dayjs";
// import DaysToNextService from "../../utilities/maintenance/DaysToNextService";
// import { MaintenanceChips } from "../../utilities/maintenance/MaintenanceChips";
import { CalculateMaintenanceWindow } from "../../utilities/maintenance/CalculateMaintenanceWindow";
// import InputMask from 'react-input-mask';

// Import CSS, images, icons & static data
import "./AdminAircraft.css"
import { aircraftComplexities } from "../../aircraft/AircraftData";
import { aircraftTypes } from "../../aircraft/AircraftData";
import { fuelTypes } from "../../aircraft/AircraftData";
import { registeringAuthorities } from "../../aircraft/AircraftData";
import AdminAircraftSvc from "./AdminAircraftSvc";
// import { DatePicker } from "@mui/x-date-pickers";







// ****************************** MAIN FUNCTION **********************************
export default function AdminAircraftOilAndFuel(props) {
    const [newPlane, setNewPlane] = useState(false)
    const [hoursRemaining, setHoursRemaining] = useState(0);
    const [daysRemaining, setDaysRemaining] = useState(0);
    // const [selectedAircraft, setSelectedAircraft] = useState(props.selectedAircraft);
 
    
    let editing = props.editing;
    let planeType = props.aircraftSubmission.planeType.value
    let maintenanceWindow = {};

        // console.log("In OilAndFuel, props.selectedAircraft->", props.selectedAircraft);
        // console.log("In OilAndFuel, props.aircraftSubmission->", props.aircraftSubmission);

      
    maintenanceWindow = CalculateMaintenanceWindow(props.selectedAircraft);
        //  console.log("In AdiminAircraftTable, maintenanceWindow->", maintenanceWindow);

    useEffect(() => {
        if (props.selectedAircraft.registration === "NEW PLANE") {
            setNewPlane(true);            
        } else {
            setNewPlane(false)
        }
    },[props.selectedAircraft.registration] )



    const registeringAuthorityList = registeringAuthorities.map((type) =>
    <MenuItem className = "select-options" selected = {false} key = {type} value = {type}>{type}</MenuItem>
    );

    const aircraftComplexitiesList = aircraftComplexities.map((complexity) =>
        <MenuItem className = "select-options" selected = {false} key = {complexity} value = {complexity}>{complexity}</MenuItem>
    );

    const aircraftTypesList = aircraftTypes.map((type) =>
        <MenuItem className = "select-options" selected = {false} key = {type} value = {type}>{type}</MenuItem>
    );

    const fuelTypesList = fuelTypes.map((type) =>
        <MenuItem className = "select-options" selected = {false} key = {type} value = {type}>{type}</MenuItem>
    );

    if (planeType !== "SIM") {
        return (
            <div>
                
                <Box className = "admin-aircraft-fields-container">
                    <Box className = "admin-aircraft-subtitles">Maintenance Details</Box>
                    <Box className = "admin-aircraft-fields">
                        <TextField className = "admin-aircraft-field" label = "Date Of Next Service" 
                            InputProps = {{ inputProps: {STYLE: props.aircraftSubmission.dateOfNextService.background}}}
                            sx = {{minHeight: "35px", padding: "0px"}}
                            type = "date" size = "large" timezone = "UTC" name = "dateOfNextService" id="dateOfNextService" 
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={e => props.AircraftHandleChange(e.target)} 
                            error = {props.aircraftSubmission.dateOfNextService.status === "error"} 
                            variant = {props.aircraftSubmission.dateOfNextService.variant} 
                            value = {dayjs(props.aircraftSubmission.dateOfNextService.value).format("YYYY-MM-DD")}
                            disabled = {editing === false}>
                        </TextField>
                        <TextField
                            className = "admin-aircraft-field" label = "Next Service Hours"
                            InputProps = {{ inputProps: { min: 0, STYLE: props.aircraftSubmission.nextServiceHours.background} }}
                            type = "number" name = "nextServiceHours" id="nextServiceHours" 
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={e => props.AircraftHandleChange(e.target)} 
                            error = {props.aircraftSubmission.nextServiceHours.status === "error"} 
                            variant = {props.aircraftSubmission.nextServiceHours.variant} 
                            value = {props.aircraftSubmission.nextServiceHours.value}
                            disabled = {editing === false}>
                        </TextField>
                        <TextField className = "admin-aircraft-field" label = "Current Hours"
                            InputProps = {{ inputProps: {STYLE: props.aircraftSubmission.currentHours.background, min: 0} }}
                            type = "number" name = "currentHours" id="currentHours" fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={e => props.AircraftHandleChange(e.target)} 
                            error = {props.aircraftSubmission.currentHours.status === "error"} 
                            variant = {props.aircraftSubmission.currentHours.variant} 
                            value = {props.aircraftSubmission.currentHours.value}
                            disabled = {editing === false}></TextField>   
                        {/* <AdminAircraftSvc 
                            aircraftSubmission = {props.aircraftSubmission} 
                            editing = {editing}
                            AircraftHandleChange = {props.AircraftHandleChange}/> */}
                    </Box>
                    {/* <MaintenanceChips maintenanceWindow = {maintenanceWindow} selectedAircraft = {props.selectedAircraft}/> */}
                    <Box className = "admin-aircraft-fields-2">
                        <Box>
                            <Box className = "admin-aircraft-subtitles-2">Days Remaining</Box>
                            <Chip className = "admin-aircraft-field-display"
                                type = "number" name = "daysRemaining" id="daysRemaining"
                                variant = "filled"
                                color = {maintenanceWindow.colours.days}
                                disabled = {maintenanceWindow.colours.days === "primary"}
                                label = {maintenanceWindow.serviceDaysRemaining}>
                            </Chip>
                        </Box> 
                        <Box>
                            <Box className = "admin-aircraft-subtitles-2">Hours Remaining</Box>
                            <Chip className = "admin-aircraft-field-display"
                                type = "number" name = "hoursRemaining" id="hoursRemaining"
                                variant = "filled"
                                color = {maintenanceWindow.colours.hours} 
                                disabled = {maintenanceWindow.colours.hours === "primary"}
                                label = {maintenanceWindow.serviceHoursRemaining}>
                            </Chip>
                        </Box>
                    </Box>
                </Box>
            
                <Box className = "admin-aircraft-fields-container">
                    <Box className = "admin-aircraft-subtitles">Oil Levels</Box>
                    <Box className = "admin-aircraft-fields">
                        <TextField className = "admin-aircraft-field-small" label = "Level 1"
                            InputProps = {{ inputProps: {STYLE: props.aircraftSubmission.oilLevels[1].background} }}
                            type = "text" name = "oilLevel1" id="oilLevel1" fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={e => props.AircraftHandleChange(e.target)} 
                            error = {props.aircraftSubmission.oilLevels[1].status === "error"} 
                            variant = {props.aircraftSubmission.oilLevels[1].variant} 
                            value = {props.aircraftSubmission.oilLevels[1].value}
                            disabled = {editing === false || planeType === "SIM"}></TextField>
                        <TextField className = "admin-aircraft-field-small" label = "Level 2"
                            InputProps = {{ inputProps: {STYLE: props.aircraftSubmission.oilLevels[2].background} }}
                            type = "text" name = "oilLevel2" id="oilLevel2" fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={e => props.AircraftHandleChange(e.target)} 
                            error = {props.aircraftSubmission.oilLevels[2].status === "error"} 
                            variant = {props.aircraftSubmission.oilLevels[2].variant} 
                            value = {props.aircraftSubmission.oilLevels[2].value}
                            disabled = {editing === false || planeType === "SIM"}></TextField>
                        <TextField className = "admin-aircraft-field-small" label = "Level 3"
                            InputProps = {{inputProps: {STYLE: props.aircraftSubmission.oilLevels[3].background} }}
                            type = "text" name = "oilLevel3" id="oilLevel3" fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={e => props.AircraftHandleChange(e.target)} 
                            error = {props.aircraftSubmission.oilLevels[3].status === "error"} 
                            variant = {props.aircraftSubmission.oilLevels[3].variant} 
                            value = {props.aircraftSubmission.oilLevels[3].value}
                            disabled = {editing === false || planeType === "SIM"}></TextField>    
                        <TextField className = "admin-aircraft-field-small" label = "Level 4"
                            InputProps = {{inputProps: {STYLE: props.aircraftSubmission.oilLevels[4].background} }}
                            type = "text" name = "oilLevel4" id="oilLevel4" fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={e => props.AircraftHandleChange(e.target)} 
                            error = {props.aircraftSubmission.oilLevels[4].status === "error"} 
                            variant = {props.aircraftSubmission.oilLevels[4].variant} 
                            value = {props.aircraftSubmission.oilLevels[4].value}
                            disabled = {editing === false || planeType === "SIM"}></TextField> 
                        <TextField className = "admin-aircraft-field-small" label = "Level 5"
                            InputProps = {{ inputProps: {STYLE: props.aircraftSubmission.oilLevels[5].background} }}
                            type = "text" name = "oilLevel5" id="oilLevel5" fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={e => props.AircraftHandleChange(e.target)} 
                            error = {props.aircraftSubmission.oilLevels[5].status === "error"} 
                            variant = {props.aircraftSubmission.oilLevels[5].variant} 
                            value = {props.aircraftSubmission.oilLevels[5].value}
                            disabled = {editing === false || planeType === "SIM"}></TextField> 
                    </Box>
                </Box>
                <Box className = "admin-aircraft-fields-container">
                    <Box className = "admin-aircraft-subtitles">Fuel</Box>
                    <Box className = "admin-aircraft-fields">
                        <FormControl>
                            <InputLabel>Fuel Type</InputLabel>
                            <Select className = "admin-aircraft-select-field" label = "Fuel Type" 
                                // InputProps = {{STYLE: props.aircraftSubmission.fuelType.background}}
                                type = "text" name = "fuelType" id="fuelType" fullWidth
                                onChange={e => props.AircraftHandleChange(e.target)} 
                                error = {props.aircraftSubmission.fuelType.status === "error"} 
                                variant = {props.aircraftSubmission.fuelType.variant} 
                                value = {props.aircraftSubmission.fuelType.value}
                                disabled = {editing === false || planeType ==="SIM"}>{fuelTypesList}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box className = "admin-aircraft-fields-2">
                        <TextField className = "admin-aircraft-field" label = "Left Tank Capacity (USg)" 
                            InputProps = {{ inputProps: {STYLE: props.aircraftSubmission.fuelTank1Capacity.background, min: 0} }}
                            type = "number" name = "fuelTank1Capacity" id="fuelTank1Capacity" fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={e => props.AircraftHandleChange(e.target)} 
                            error = {props.aircraftSubmission.fuelTank1Capacity.status === "error"} 
                            variant = {props.aircraftSubmission.fuelTank1Capacity.variant} 
                            value = {props.aircraftSubmission.fuelTank1Capacity.value}
                            disabled = {editing === false || planeType ==="SIM"}></TextField>
                        <TextField className = "admin-aircraft-field" label = "Right Tank Capacity (USg)"
                            InputProps = {{ inputProps: {STYLE: props.aircraftSubmission.fuelTank2Capacity.background, min: 0} }}
                            type = "number" name = "fuelTank2Capacity" id="fuelTank2Capacity" fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={e => props.AircraftHandleChange(e.target)} 
                            error = {props.aircraftSubmission.fuelTank2Capacity.status === "error"} 
                            variant = {props.aircraftSubmission.fuelTank2Capacity.variant} 
                            value = {props.aircraftSubmission.fuelTank2Capacity.value}
                            disabled = {editing === false || planeType ==="SIM"}></TextField>
                    </Box>
                    <Box className = "admin-aircraft-fields-3">
                        <TextField className = "admin-aircraft-field" label = "Left Tank State (USg)"
                            InputProps = {{ inputProps: {STYLE: props.aircraftSubmission.fuelTank1State.background, min: 0, max: props.aircraftSubmission.fuelTank1Capacity.value} }}
                            type = "number" name = "fuelTank1State" id="fuelTank1State" fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={e => props.AircraftHandleChange(e.target)} 
                            error = {props.aircraftSubmission.fuelTank1State.status === "error"} 
                            variant = {props.aircraftSubmission.fuelTank1State.variant} 
                            value = {props.aircraftSubmission.fuelTank1State.value}
                            disabled = {editing === false || planeType ==="SIM"}></TextField>
                        <TextField className = "admin-aircraft-field" label = "Right Tank State (USg)" 
                            InputProps = {{ inputProps: {STYLE: props.aircraftSubmission.fuelTank2State.background, min: 0, max: props.aircraftSubmission.fuelTank2Capacity.value} }}
                            type = "number" name = "fuelTank2State" id="fuelTank2State" fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={e => props.AircraftHandleChange(e.target)} 
                            error = {props.aircraftSubmission.fuelTank2State.status === "error"} 
                            variant = {props.aircraftSubmission.fuelTank2State.variant} 
                            value = {props.aircraftSubmission.fuelTank2State.value}
                            disabled = {editing === false || planeType ==="SIM"}></TextField>
                    </Box>
                </Box>

            </div> 
        );
    }
}   