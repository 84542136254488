//Import libraries
// import { Box } from "@mui/system";
// import React from 'react';
import { API_URL } from "../../../config/config";
import axiosInstance from "../security/AxiosConfig";

// Import CSS, images, icons & static data



// Import fonts


//**************************** MAIN FUNCTION ***************************** */
const PostAcknowledgement = async (newPersonList) => {

    var responseObject = {};
        // console.log("In PostAcknowledgement, newPersonList->", newPersonList)

    try {
        // var response = {};
            console.log("In AcknowledgeNewPerson, newPersonList->", newPersonList);
        
        let response = await axiosInstance.post(`${API_URL}/person/acknowledge`, newPersonList);
            
        responseObject =    
            {
                list: response.data,
                databaseConnection: true
            }
            console.log("In AcknowledgeNewPerson, responseObject->", responseObject);
        return responseObject;
           
    } catch (error) {
        console.error("Error acknowledging person, error");
        responseObject =            
            {
                list: [],
                databaseConnection: false
            }
            // console.log("In GetPerson, returnObject->", returnObject);
        return responseObject;
    }
};

export {PostAcknowledgement}