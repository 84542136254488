// Import libraries
import React from "react";
import '../TechLog.css';
import { NativeSelect } from "@mui/material";
import { Select } from "@mui/joy";
import {FormControl} from "@mui/base";
import CreateMenuItems from "./CreateMenuItems";


// Import CSS & images



// ******************************* MAIN FUNCTION ************************************************
export default function Oil(props) {

    let sim = false;

    if (props.selectedAircraft._planeType === "SIM") {
        sim = true
    } else {
        sim = false
    }
        // console.log("In Oil, sim->", sim);

    const selectionList = CreateMenuItems(props.selectedAircraft._oilLevels, props.submission);
    if (props.selectedAircraft._numberOfEngines === 2) {
            // console.log("In Oil, props.selectedAircraft->", props.selectedAircraft);
            // console.log("In Oil, props.submission.data->", props.submission.data);
        if (props.selectedAircraft._planeType !== "SIM") {
            props.submission.data.oilStatusRight.requiredOpen = true;
            props.submission.data.oilStatusLeft.requiredSubmit = true;
                // console.log("In Oil, OilStatusRight.requiredSubmit was set to->", props.submission.data.oilStatusRight.requiredSubmit);
        } 
        if (props.selectedAircraft._planeType === "SIM") {
            props.submission.data.oilStatusRight.requiredOpen = false;
            props.submission.data.oilStatusLeft.requiredOpen = false;
            props.submission.data.oilStatusRight.requiredSubmit = false;
            props.submission.data.oilStatusLeft.requiredSubmit = false;
                // console.log("In Oil, OilStatusRight.requiredSubmit was set to->", props.submission.data.oilStatusRight.requiredSubmit);
        }
            // console.log("In Oil, props.submission.data->", props.submission.data);
        var nameAndId = `oilStatus${props.engine}`;
        return (
            <div>
                <FormControl disabled = {sim === true}>
                    <NativeSelect 
                        // defaultValue = {""}
                        STYLE = {props.submission.data[nameAndId].background} 
                        value = {props.submission.data[nameAndId].value} className = "oil" 
                        label = {props.submission.data[nameAndId].required} 
                        error = {props.submission.data[nameAndId].status === "error"} 
                        variant = {props.submission.data[nameAndId].variant} 
                        fullWidth name = {nameAndId} id = {nameAndId} 
                        disabled = {sim === true}
                        onChange={e => props.TechLogHandleChange(e.target)}>
                        {selectionList}
                    </NativeSelect>
                </FormControl>
            </div>
        );
        } else {
            if (props.engine === "Left") {
                return (
                    <div>
                        <FormControl disabled = {sim === true}>
                            <NativeSelect  
                                // defaultValue = {""}
                                STYLE = {props.submission.data.oilStatusLeft.background}
                                value = {props.submission.data.oilStatusLeft.value} 
                                className = "oil" 
                                label = {props.submission.data.oilStatusLeft.required} 
                                error = {props.submission.data.oilStatusLeft.status === "error"} 
                                variant = {props.submission.data.oilStatusLeft.variant} 
                                fullWidth name = "oilStatusLeft" id = "oilStatusLeft" 
                                disabled = {sim === true}
                                onChange={e => props.TechLogHandleChange(e.target)}>
                                {selectionList}
                            </NativeSelect>
                        </FormControl>
                    </div>
                );
            } else {
                return (
                    <span STYLE = "background-color: white" value = {props.submission.data.oilStatusRight} className = "oilCellBlank"></span>
                );}
            }

}