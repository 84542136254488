// Import libraries


// Import CSS, images, icons & static data
import { background } from "../flights/flightData/FlightData";


// **************************** MAIN FUNCTION *******************************************
export default function SubmissionFieldReset(submission, calledFrom) {
        console.log("In FieldReset, submission->", submission)
    if (calledFrom === "aircraftAdmin") {
        submission.data.oilLevels.forEach((level) => {
            if (level.reset) {
                level.background = background.normal;
                level.status = "";
            }
        });
    }
    Object.keys(submission.data).forEach((key) => {
        if (submission.data[key].reset) {
                // console.log("In FieldReset, key->", key);
            if (calledFrom === "aircraftAdmin") {
                if (key.indexOf("oilLevels") !== -1) {
                    submission.data.oilLevels.forEach((level) => {
                            // console.log("In FieldReset, key->", key,"level->", level)
                        submission.data.oilLevels[level].background = background.normal;
                        submission.data.oilLevels[level].status = "";
                    })
                } else {    
                    submission.data[key].background = background.normal;
                    submission.data[key].status = "";
                } 
            } else {
                if (key.indexOf("oilStatus") !== -1) {
                    submission.data[key].background = background.grey;
                } else {
                    submission.data[key].background = background.normal;
                }
                submission.data[key].variant = "filled";
                submission.data[key].status = "";
            }

        };
    });
        // console.log("In Reducer, reset complete");
    return submission;
}