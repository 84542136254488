// Import libraries
import React, { useEffect, useState } from "react";
import Media from "../../../utilities/environment/Media";
import { TextField, Checkbox, FormGroup, FormControlLabel, Box } from "@mui/material";
import { Container } from "@mui/system";

// Import CSS & images
import '../awayLandings/AwayLandings.css';
import blueArrow from "../images/blueArrow.png";
export default function BlueArrow() {
    if (!Media()) {
        return (
            <Box>
                <img src = {blueArrow} alt = "Arrow" className = "blue-arrow"></img>
            </Box>
        );
    } else {
        return (
            <Box>
                <img src = {blueArrow} alt = "Arrow" className = "blue-arrow-mobile"></img>
            </Box>
        );
    }
}