// Import libraries
import React, {useState, useEffect} from "react";
import { TextField, Box } from "@mui/material"
import Media from "../../../utilities/environment/Media";

// Import CSS, images & icons


import "./Arrival.css";


export default function ArrivalTableDefects(props) {

    if (!Media()) {
        return (
            <Box className = "arrival-table-defects">
                <table >
                    <tbody>
                        <tr>
                            <td className = "label" colSpan = "4">New Defects</td>
                        </tr>
                        <tr>
                            <td className = "input" colSpan = "4" STYLE = {props.submission.data.newDefect1.background} ><TextField value={props.submission.data.newDefect1.value} onChange={e => props.AircraftHandleChange(e.target)} error = {props.submission.data.newDefect1.status === "error"} variant = {props.submission.data.newDefect1.variant} fullWidth type = "text" name = "newDefect1" id ="newDefect1"></TextField></td>
                        </tr>
                        <tr>
                            <td className = "input" colSpan = "4" STYLE = {props.submission.data.newDefect2.background} ><TextField value={props.submission.data.newDefect2.value} onChange={e => props.AircraftHandleChange(e.target)} error = {props.submission.data.newDefect2.status === "error"} variant = {props.submission.data.newDefect2.variant} fullWidth type = "text" name = "newDefect2" id ="newDefect2"></TextField></td>
                        </tr>
                    </tbody>
                </table>
            </Box>
        );
        } else {
            return (
                <Box className = "arrival-table-defects-mobile">
                     <table >
                        <tbody>
                             <tr>
                                <td className = "label" colSpan = "4">New Defects</td>
                            </tr>
                            <tr>
                                <td className = "input" colSpan = "4" STYLE = {props.submission.data.newDefect1.background} ><TextField value={props.submission.data.newDefect1.value} onChange={e => props.TechLogHandleChange(e.target)} error = {props.submission.data.newDefect1.status === "error"} variant = {props.submission.data.newDefect1.variant} fullWidth type = "text" name = "newDefect1" id ="newDefect1"></TextField></td>
                            </tr>
                            <tr>
                                <td className = "input" colSpan = "4" STYLE = {props.submission.data.newDefect2.background} ><TextField value={props.submission.data.newDefect2.value} onChange={e => props.TechLogHandleChange(e.target)} error = {props.submission.data.newDefect2.status === "error"} variant = {props.submission.data.newDefect2.variant} fullWidth type = "text" name = "newDefect2" id ="newDefect2"></TextField></td>
                            </tr>
                        </tbody>
                    </table>  
                </Box>
            );
        }
}
