// Import libraries
import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import PropTypes from 'prop-types';
import { Select as BaseSelect, selectClasses } from '@mui/base/Select';
import { Option as BaseOption, optionClasses } from '@mui/base/Option';
import { Option, Select } from "@mui/joy";
import { styled } from '@mui/system';
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';

// Import CSS, images, icons and static data
// import '../TechLog.css';
import './AdminCourse.css'
import azTechTheme from "../../utilities/environment/ColourTheme";


// ******************************* MAIN FUNCTION ************************************************
export default function CourseSelect(props) {
    var selectedCourse = props.selectedCourse 
        // console.log("In CourseSelect, props.selectedCourse->", props.selectedCourse);
    let selectionList = CreateSelectionList(props.courseList)


    function CreateSelectionList(courseList) {
        let selectionList = [];
        let id = null
        if (courseList) {
            courseList.forEach((course) => {
                // id = courseList.indexOf(course);
                selectionList.push(
                    <Option  
                        sx = {{ backgroundColor: "inherit" }}
                        key = {course.name} 
                        id = {course.id} 
                        value = {course.name}>
                        {course.name}
                    </Option>
                );
            });
                // console.log("In CourseSelect, CreateCourseSelection, selectionList->", selectionList);
        }
            
        return selectionList;
    }

    const handleChange = (e) => {
            // console.log("In CourseSelect, event->", e);
        let selection = {}
        props.courseList.forEach((course) => {
            if (course.name === e.target.textContent) {
                selection = course
            }
        });
            
        props.UpdateSelectedCourse(selection)
    }


    // function RenderCourseSelect(selectionList) {
        return (
            <Box>
                <Box className = "course-label">Course Name</Box>
                <Select 
                        sx = {{
                            backgroundColor: azTechTheme.palette.blanchedAlmond, 
                            color: azTechTheme.palette.blueBright, 
                            fontWeight: "bold", 
                            fontSize: "x-large",
                            mt: "25px"
                        }}
                        defaultValue={"SELECT"}
                        value = {selectedCourse.name}
                        className = "course-selection" 
                        type = "text" 
                        id = "courseName" 
                        // list = "aircraft" 
                        name = "courseName"
                        onChange={handleChange}>
                        {selectionList}
                </Select>
            </Box>
        );
    
    }
