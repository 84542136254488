// Import libraries
import React, {useState, useEffect} from "react";
import {Box} from "@mui/material"
import BannerLogo from "../../flights/techLog/banner/BannerLogo.js";
import BannerTitle from "../../flights/techLog/banner/BannerTitle.js"
import { useAuthFirebase } from "../../utilities/security/AuthContextFirebase.js";

import DatabaseConnectionWarning from "../../utilities/database/DatabaseConnectionWarning.js";
import {PilotLogSummary} from "./PilotLogSummary.js";
import { AuthProviderFirebase } from "../../utilities/security/AuthContextFirebase.js";
import { PilotSelect } from "./PilotSelect.js";
import { useMediaQuery } from "@mui/material";
import { mobileThreshold } from "../../utilities/environment/EnvironmentData.js";


// Import CSS & images
import "./PilotLog.css";
import azTechTheme from "../../utilities/environment/ColourTheme.js";
import { totalTime } from "./PilotLogSummaryRows.js";
import PilotLogTotalHours from "./PilotLogTotalHours.js";

//Import fonts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import TotalHours from "./PilotLogTotalHours.js";






// ******************************* MAIN FUNCTION ************************************************
export function PilotLogBanner(props) {
    const calledFrom = "PilotLog"
    const backgroundColour = azTechTheme.palette.blanchedAlmond;
        // console.log("In PilotLogBanner, props.pilotForDisplay->", props.pilotForDisplay)
   

    return (
        <Box className = "banner" sx = {{backgroundColor: backgroundColour, minWidth: "370px"}}>
            <Box className = "banner-row1" >
                <Box sx = {{display: "flex", flexDirection: "row", selfJustify: "flexStart"}}>
                    <BannerTitle title = {props.title}/>
                    <Box className = "databaseConnectionWarning">
                            <DatabaseConnectionWarning databaseConnection = {props.databaseConnection}/>
                    </Box>
                    {/* <Box sx = {{color: "red", mt: "50px", ml: "10px", fontSize: "large"}}>
                        UNDER CONSTRUCTION
                    </Box> */}
                </Box>
                <div className = "logo"><BannerLogo/></div>
            </Box>
            <Box className = "banner-row2" >
                <PilotLogSummary pilotForDisplay = {props.pilotForDisplay} pilotLogSummary = {props.pilotLogSummary}/>
                <PilotSelect
                    UpdateDatabaseConnection = {props.UpdateDatabaseConnection}
                    // selectedPilot = {props.selectedPilot}
                    UpdateSelectedPilot={props.UpdateSelectedPilot}/>
            </Box>
        </Box>
    );
};
