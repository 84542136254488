//Import libraries
import CreateOpenFlightsList from "../../flights/techLog/openFlights/CreateOpenFlightList";
import axiosInstance from '../security/AxiosConfig.js';
import { API_URL } from '../../../config/config.js';

// Import CSS, images, icons & static data
import { emptySubmission } from "../../flights/flightData/FlightData";


// Import fonts


//**************************** MAIN FUNCTION ***************************** *//
const GetOpenFlightList =  async (selectedAircraft) => {
    let responseObject = {};
    try {
        const response = await axiosInstance.get(`${API_URL}/flights/openFlights?registration=${selectedAircraft._registration}`);
        let list = response.data;
        responseObject.data = CreateOpenFlightsList(list, emptySubmission);
        responseObject.UpdateDatabaseConnection = true;
      
    } catch (error) {
        console.error('Error fetching open flights', error);
        // let list = defaultOpenFlightList[props.selectedAircraft.registration];
        // responseObject.data = CreateOpenFlightsList(list, emptySubmission);
        responseObject.data = [];
        responseObject.databaseConnection = false;
    }

    return responseObject;
}

export {GetOpenFlightList};