//Import libraries
import { useEffect, useState } from 'react';
import { GetPilotLogSummaryData } from "../../utilities/database/GetPilotLogSummaryData";

// Import CSS, images, icons & static data

// Import fonts


//**************************** MAIN FUNCTION ***************************** */
export async function PilotLogSummaryRows(pilotForDisplay) { 

    var responseObject = {}
    var responseBuilder = {
        data: {
            totalHours: 0
        }
    }
    const defaultPilotList = [];



    // useEffect(() => {
            // console.log("In PilotLogSummaryRows, pilotForDisplay->", pilotForDisplay)
        const GetData = async (pilot) => {
                // console.log("In PilotLogSummaryRows, pilot->", pilot)
            if (pilot !== undefined && pilot !== "" && pilot !== null) {
                responseBuilder = await GetPilotLogSummaryData(pilot)
                if (responseBuilder.databaseConnection) {
                        // console.log("In PilotLogSummaryRows, responsibleBuilder.data->", responseBuilder);
                    if (responseBuilder.data.raw.totalHours !== null) {
                    responseBuilder.data.totalHours = new Intl.NumberFormat("en-UK", {maximumSignificantDigits: 8}).format(Number(responseBuilder.data.raw.totalHours.toFixed(1)));
                    } else {
                        responseBuilder.data.totalHours = 0;
                    }

                }
                    // console.log("In PilotSummaryLogRows, totalHours->", responseBuilder.data.totalHours);
                // } else {
                //     responseBuilder.data.totalHours = 0
                // }
                // UpdateDatabaseConnection(responseObject.databaseConnection)   
            }
        }

        if (pilotForDisplay) {
                // console.log("In PilotSummaryLogRows, responseObject->", responseObject);
            await GetData(pilotForDisplay)
        }
            // console.log("In PilotSummaryLogRows, responseObject->", responseObject);

        if (responseBuilder.databaseConnection) {
                // console.log("In PilotSummaryLogRows, responseObject->", responseObject);
            responseBuilder.data.pilotLogSummaryRow1 = [{
                id: 1,
                pilotInCommand: Number(responseBuilder.data.raw.pic),
                pilotUnderTraining: Number(responseBuilder.data.raw.put),
                multiPilot: Number(responseBuilder.data.raw.multiPilot),
                instructor: Number(responseBuilder.data.raw.instructor)
            }]

            responseBuilder.data.pilotLogSummaryRow2 = [{
                id: 1,
                sepTime: Number(responseBuilder.data.raw.sep),
                mepTime: Number(responseBuilder.data.raw.mep),
                jetTime: Number(responseBuilder.data.raw.jet),
                simulator: Number(responseBuilder.data.raw.sim)
            }]

            responseBuilder.data.pilotLogSummaryRow3 = [{
                id: 1,
                instrumentTime: Number(responseBuilder.data.raw.ifr),
                nightTime: Number(responseBuilder.data.raw.night),
                landings: Number(responseBuilder.data.raw.landings),
                nightLandings: Number(responseBuilder.data.raw.nightLandings)
            }]
        }
        responseObject = responseBuilder
            // console.log("In PilotLogSummaryRows, responseObject->", responseObject)

        return responseObject 
     // }, [pilotForDisplay])
}

    const pilotLogSummaryRow1 = [{
        id: 1,
        pilotInCommand: "-",
        pilotUnderTraining: "-",
        multiPilot: "-",
        instructor: "-"
    }]

    const pilotLogSummaryRow2 = [{
        id: 1,
        sepTime: "-",
        mepTime: "-",
        jetTime: "-",
        simulator: "-"
    }]

    const pilotLogSummaryRow3 = [{
        id: 1,
        instrumentTime: "-",
        nightTime: "-",
        landings: "-",
        nightLandings: "-"
    }]

    const pilotLogSummaryTotalHours = new Intl.NumberFormat("en-UK", {maximumSignificantDigits: 8}).format(Number("0000").toFixed(1),);

    export {pilotLogSummaryTotalHours, pilotLogSummaryRow1, pilotLogSummaryRow2, pilotLogSummaryRow3}
