// Import libraries
import * as React from 'react';
import {useState, useEffect, useRef} from 'react';
import {Box} from "@mui/material"
import {useMediaQuery} from '@mui/material';
import dayjs from 'dayjs';
import { FixedSizeList } from 'react-window';
import List from '@mui/material/List';
import { ListItem, ListItemText, ListItemButton, ListItemIcon } from '@mui/material';
import {Checkbox} from '@mui/material';
import { mobileThreshold } from '../../utilities/environment/EnvironmentData';

// Import CSS & images
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';

// function AircraftHandleChange(e) {
//     console.log("In CreateDefectDisplayList, AircraftHandleChange, e->", e)
// }


// ******************************* MAIN FUNCTION ************************************************
export default function CreateApprovalsDisplayList(props) {

        // console.log("In CreateListItems, props.aircraftSubmission.defects.length-> ", props.aircraftSubmission.defects.length)
    var media = useMediaQuery(`(min-width:${mobileThreshold}px)`)
    let editing = props.editing;
 

    const [selectedCourse, setSelectedCourse] = useState(props.selectedCourse);
    const [approvalsListLength, setApprovalsListLength] = useState(props.courseSubmission.approvals.length)

        // console.log("In CreateApprovalsDisplayList, props.courseSubmission->", props.courseSubmission);

    // function HandleDeleteApproval(e) {
    //     props.HandleDeleteApproval(e);
    // }
   

    function RenderCurrentRow(approval) {
        let date = dayjs(approval.approvalDateDisplay)
        let id = props.courseSubmission.approvals.indexOf(approval);
        let approvalDateDisplay = "Approved " + dayjs(approval.approvalDate).format("DD MMM YYYY");
        let approvalDisplayText = approval.authority.name + " - " + approval.authority.country

        // console.log("In CreateApprovalsDisplayList, props.courseSubmission->", props.courseSubmission);
        // console.log("In CreateApprovalsDisplayList, approval->", approval);
       
        return (
            <ListItem id = {id} key= {id} component = "div" disabled = {editing === false}>
                <ListItemText primary = {approvalDisplayText} secondary = {approvalDateDisplay} />
                <ListItemButton id = {id} disabled = {editing === false}  sx = {{width: "15px"}}>
                    <ListItemIcon >
                        <DeleteOutlineOutlinedIcon 
                            color = "primary" 
                            id = {id} 
                            onClick = {props.HandleDeleteApproval}/>
                    </ListItemIcon>
                </ListItemButton>
            </ListItem>
        );
    }

    function RenderExpiredRow(approval) {
        let date = dayjs(approval.approvalDateDisplay)
        let id = props.courseSubmission.approvals.indexOf(approval);
        let approvalDateDisplay = "Expired " + dayjs(approval.ExpiryDate).format("DD MMM YYYY");
        let approvalDisplayText = approval.authority.name + " - " + approval.authority.country
       
        return (
            <ListItem id = {id} key= {id} component = "div" disabled = {editing === false}>
                <ListItemText primary = {approvalDisplayText} secondary = {approvalDateDisplay} />
                {/* <ListItemButton id = {id} disabled = {editing === false}> */}
                    <ListItemIcon  sx = {{width: "10px"}}>
                        <CloseIcon id = {id} sx = {{ color: "red", ml: "7px"}}/>
                    </ListItemIcon>
                {/* </ListItemButton> */}
            </ListItem>
        );
    }
    if (props.courseSubmission.approvals.length > 0) {
            // console.log("In CreateApprovalsDisplayList, props.courseSubmission.approvals->", props.courseSubmission.approvals);
        return (
            <Box>
                <List
                    height={400}
                    width={"auto"}
                >   
                    {props.courseSubmission.approvals.filter(approval => approval.expiryDate === "" || approval.expiryDate === null).map((approval) => {
                        return RenderCurrentRow(approval)
                    })
                    }
                    {/* {props.courseSubmission.approvals.filter(approval => approval.expiryDate !== "").map((approval) => {
                        return RenderExpiredRow(approval)
                    })
                    } */}
                </List>
            </Box>
        )
    }

}