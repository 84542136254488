//Import libraries
import React, {useState, useEffect} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuMiniMobileBookingsAndCourses from './MenuMiniMobileBookingsAndCourses';
import azTechTheme from "../utilities/environment/ColourTheme";
import MenuMiniMobileLogs from "./MenuMiniMobileLogs.js"
import { Avatar } from '@mui/material';
// import MenuMiniMobileAdmin from "./MenuMiniMobileAdmin.js"
import { ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import AdminMenu from "./AdminMenu.js"
import { Progress } from '../utilities/feedback/Progress';

// Import CSS, images & icons
// import "./MainMenu.css";
import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';



//Import fonts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import LoginMenu from './LoginMenu';



// ******************************* MAIN FUNCTION ************************************************
export default function MenuMobile(props) {

  const backgroundColour = azTechTheme.palette.blanchedAlmond
  let currentUser = props.currentUser;
  let currentUserRoles = props.currentUserRoles;
  const [progressStatus, setProgressStatus] = useState(false);

  function UpdateProgressStatus(newStatus) {
    setProgressStatus(newStatus);
  }

  const navigate = useNavigate();
  const handleNavigate = (path) => {
      // console.log("In MenuDeskTop, handleMenuClick, path->", path);
    navigate(path);
  }

  return (
    <ThemeProvider theme = {azTechTheme}>
    {/* <Box sx={{ flexGrow: 1, display: "grid", maxHeight: "60px"}}> */}
      <AppBar 
        position = "fixed"
        top = "0" 
        sx = {{verticalAlign: "center", backgroundColor: backgroundColour, 
        maxHeight: "70px",
        display: 'flex', 
        gap: "10px", 
        flexDirection: 'row', 
        justifyContent: 'start', 
        flexWrap: 'noWrap',
        // width: "100vw"
        }}
        >
        <Toolbar sx = {{
            backgroundColor: backgroundColour, 
            marginRight: "5px",
            display: 'flex', 
            flexGrow: 1, 
            flexDirection: 'row', 
            justifyContent: 'space-between', 
            // gap: "300px",
            flexWrap: 'wrap',
            maxWidth: "100vw",
            minWidth: "100wv"
            }}>
            <Box sx = {{
              backgroundColor: backgroundColour, 
              marginRight: "5px",
              display: 'flex', 
              flexGrow: 1, 
              flexDirection: 'row', 
              justifyContent: 'space-between', 
              flexWrap: 'noWrap'}}>
              <Box 
                variant="h6"  
                sx={{ verticalAlign: "center", 
                      color: "black", 
                      backgroundColor: "inherit",
                      // maxWidth: "200px",
                      display: 'flex', 
                      flexGrow: 1, 
                      flexDirection: 'row', 
                      justifyContent: 'start', 
                      flexWrap: 'noWrap'}}>
                  <Box sx = {{verticalAlign: "middle", gap: "15px"}}>
                    <HomeIcon 
                      sx={{marginLeft: "15px", marginTop: '15px', marginRight: "15px", verticalAlign: "center"}} 
                      color = "inherit" id = "home" 
                      onClick = {() => handleNavigate("/")}></HomeIcon>
                  </Box>
                  <MenuMiniMobileLogs id = "Logs" handleNavigate = {handleNavigate} currentUserRoles = {currentUserRoles}/>
                  <MenuMiniMobileBookingsAndCourses id = "Logs" handleNavigate = {handleNavigate} currentUserRoles = {currentUserRoles}/>
              </Box>
              <Box sx = {{verticalAlign: "bottom", display: "flex", flexDirection: "row", justifyContent: "middle", backgroundColor: "inherit"}}> 
                <Progress progressStatus = {progressStatus}/>
              </Box>
              <Box sx = {{
                  color: "black", 
                  backgroundColor: "inherit", 
                  maxHeight: "60px",
                  marginRight: "0px", 
                  padding: "10px",
                  maxWidth: "100px",
                  display: 'flex', 
                  gap: "10px", 
                  flexDirection: 'row', 
                  justifyContent: 'end', 
                  flexWrap: 'noWrap'}}>
                  <AdminMenu id = "admin" handleNavigate = {handleNavigate} currentUserRoles = {currentUserRoles}/>
                  <LoginMenu 
                    calledFrom = "MenuMobile" 
                    currentUserRoles = {currentUserRoles} 
                    currentUser = {currentUser}
                    UpdateProgressStatus = {UpdateProgressStatus}
                    ResetUser = {props.ResetUser}/>
              </Box>
            </Box>
            {/* // </AccountCircleIcon> */}
        </Toolbar>
      </AppBar>
    {/* </Box> */}
    </ThemeProvider>
  );
}
