// Import libraries 

// Import CSS, Images, Icons & Static Data

const euroFlightTraining = {
    name1: "Euro Flight Training",
    name2: "",
    email: "infor@euroflightTraining.copm",
    phone: "01329 888091",
    addressLine1: "Fareham Innovation Centre",
    addressLine2: "Merlin House",
    addressLine3: "Meteor Way",
    city: "Lee-On-The-Solent",
    State: "Fareham",
    PostCode: "PO139FU"
}

const defaultSchool = {
    name1: "Una Meravigliosa",
    name2: "Scuola Di Volo",
    email: "Informazione@LaNuvola.IT",
    phone: "1-800-SCUOLA-DI-VOLO",
    addressLine1: "Una Strada",
    addressLine2: "In Un Posto Figo",
    addressLine3: "",
    city: "Aeroporto Città",
    State: "Italia",
    PostCode: "DA42NG"
}

const currentSchool = defaultSchool;
// const currentSchool = euroFlightTraining;





export {currentSchool}
